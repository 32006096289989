import { gql } from 'apollo-boost'

export default gql`
  mutation pingFile($id: ID!) {
    pingFile(id: $id) {
      id
      filename
      mimetype
      encoding
      url
      thumbnail
    }
  }
`
