import styled, { keyframes } from 'styled-components'
import React from 'react'

const Rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div{
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${p => p.theme.colors.loader};
    border-radius: 50%;
    animation: ${Rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${p =>
      p.theme.colors.loader} transparent transparent transparent;
  }
  &:nth-child(1){
    animation-delay: -0.45s;
  }
  &:nth-child(2){
    animation-delay: -0.3s;
  }
  &:nth-child(3){
    animation-delay: -0.15s;
  }
}
  
`

const Loader: React.FC = () => (
  <Ring>
    <div />
    <div />
    <div />
    <div />
  </Ring>
)

export default Loader
