import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import { DivLayout as D, UtiliesStyle as U } from 'styles'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import BulkPricingTable from 'components/BulkPricingTable'
import GET_ADD_ON from 'gql/GET_ADD_ON'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import AddOnForm from './AddOnForm'

interface AddOnPageProps {
  match: {
    params: {
      addOnId: string
    }
  }
  theme: ITheme
}

const AddOnPage: React.FC<AddOnPageProps> = props => {
  const {
    theme: { colors },
  } = props
  const {
    loading,
    data,
    error: getAddOnError,
    refetch: refetchProduct,
  } = useQuery(GET_ADD_ON, {
    variables: {
      id: props.match.params.addOnId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  return (
    <>
      {getAddOnError && <Error message={getAddOnError.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getAddOn.name}
          </Heading>
          <Box margin="30px 0">
            <U.status
              color={data.getAddOn.available ? 'green' : 'red'}
              style={{ cursor: 'pointer' }}
              // onClick={() =>
              //   data.getAddOn.available ? handleMakeUnavailableClick() : null
              // }
            >
              <span>●</span>
              {data.getAddOn.available ? 'available' : 'unavailable'}
            </U.status>
            {/* {data.getAddOn.available === false && (
              <U.status>
                <B.subtle onClick={handleMakeAvailableClick}>
                Make Available
              </B.subtle>
              </U.status>
            )} */}
          </Box>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Decoration Type
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <AddOnForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchProduct}
                  addOn={data.getAddOn}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Name:{' '}
                </Heading>
                <Text color={colors.text.default}>{data.getAddOn.name}</Text>
              </Flex>
              <Flex justifyContent="space-between" margin="40px 0">
                <Heading element="h4" color={colors.text.default}>
                  Public Name:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getAddOn.publicName}
                </Text>
              </Flex>
              <Flex flexDirection="column" margin="40px 0">
                <Heading element="h4" color={colors.text.default}>
                  Description:{' '}
                </Heading>
                <Box marginTop="10px">
                  <Text color={colors.text.default}>
                    {data.getAddOn.description}
                  </Text>
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  BasePrice:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  ${data.getAddOn.basePrice}
                </Text>
              </Flex>
              <BulkPricingTable
                basePrice={data.getAddOn.basePrice}
                priceBreaks={data.getAddOn.priceBreaks}
                isAddOn={true}
              />
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(AddOnPage)
