import React, { useState, useEffect } from 'react'
import {
  TAddOnByLocation,
  TPrintLocation,
  TProduct,
  TVariant,
  TView,
} from 'types'
import {
  Card,
  Flex,
  Heading,
  Button,
  Text,
  Box,
} from '@sweaterplanet/nucleus-style'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader, ColorPicker } from 'utils'
import {
  ButtonStyle as B,
  CardStyle as C,
  DivLayout as D,
  FormStyle as F,
  HrStyle as HR,
  ModalLayout as M,
  TableLayout as T,
  UtiliesStyle as U,
  GridLayout as G,
} from 'styles'
import { DesignViewer, VariantPicker } from '../../Design'
// import Mockup from 'components/Mockup'
import { TAddOnID } from 'types/AddOn'
import MAKE_PRODUCT_AVAILABLE from './gql/MAKE_PRODUCT_AVAILABLE'
import GET_PRODUCT from './gql/GET_PRODUCT'
import GET_SOME_PRINT_LOCATIONS from './gql/GET_SOME_PRINT_LOCATIONS'
import DELETE_ADD_ON_BY_LOCATION from './gql/DELETE_ADD_ON_BY_LOCATION'
import CREATE_ADD_ON_BY_LOCATION from './gql/CREATE_ADD_ON_BY_LOCATION'
import GET_ADD_ONS from './gql/GET_ADD_ONS'
import BulkPricingTable from 'components/BulkPricingTable'
import ProductForm from './ProductForm'
import MAKE_PRODUCT_UNAVAILABLE from './gql/MAKE_PRODUCT_UNAVAILABLE'
import * as ADMIN_ROUTES from 'routes/admin'
import { Link, useHistory } from 'react-router-dom'
import AvailableVariantPickerModal from './AvailableVariantPickerModal'
import REORDER_ADD_ON_BY_LOCATION from '../../../gql/REORDER_ADD_ON_BY_LOCATION'
import DUPLICATE_PRODUCT from './gql/DUPLICATE_PRODUCT'
import Markdown from 'components/Markdown'
import DELETE_PRODUCT from './gql/DELETE_PRODUCT'
// import ViewCreateForm from './ViewCreateForm'

interface IAdminProductViewProps {
  match: {
    params: {
      productId: string
    }
  }
  location: {
    state?: {
      isStore: boolean
    }
  }
}

const AdminProductView: React.FC<IAdminProductViewProps> = props => {
  const {
    loading,
    data,
    error: getProductError,
    refetch: refetchProduct,
  } = useQuery(GET_PRODUCT, {
    variables: {
      id: props.match.params.productId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [activeView, setActiveView] = useState<TView | null>(null)
  const [makeAvailableError, setMakeAvailableError] = useState<string | null>(
    null
  )

  const [colorIndex, setColorIndex] = useState(-1)
  const [selectedColor, setSelectedColor] = useState<TVariant>({
    id: '',
    name: '',
    value: '#808080',
    available: true,
    variantGroup: {
      id: '',
      name: 'Color',
      variants: [],
    },
  })
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)
  const [variantModalOpen, setVariantModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (data) {
      const colorIndexData = data.getProduct.variantGroups.findIndex(
        (variantGroup: any) => variantGroup.name === 'Color'
      )
      setColorIndex(colorIndexData)
      if (colorIndexData !== -1) {
        const defaultColor =
          data.getProduct.defaultColor &&
          data.getProduct.variantGroups[colorIndexData].variants.filter(
            (s: TVariant) => s.value === data.getProduct.defaultColor
          )[0]
        defaultColor
          ? setSelectedColor(defaultColor)
          : setSelectedColor(
              data.getProduct.variantGroups[colorIndexData].variants[0]
            )
      }
    }
  }, [data])

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      if (data.getProduct.store)
        history.push(`${ADMIN_ROUTES.ADMIN_STORES}/${data.getProduct.store.id}`)
      else history.push('/admin/products')
    },
  })

  const handleDeleteProduct = () => {
    deleteProduct({
      variables: {
        id: props.match.params.productId,
      },
    })
  }

  const [MakeProductAvailable] = useMutation(MAKE_PRODUCT_AVAILABLE, {
    variables: {
      id: props.match.params.productId,
    },
  })

  const [MakeProductUnavailable] = useMutation(MAKE_PRODUCT_UNAVAILABLE, {
    variables: {
      id: props.match.params.productId,
    },
  })

  const handleSetActiveView = (view: TView) => {
    setActiveView(view)
  }

  const handleMakeAvailableClick = () => {
    MakeProductAvailable()
      .then(response => {
        refetchProduct()
      })
      .catch(e => {
        setMakeAvailableError(e.message)
      })
  }

  const handleMakeUnavailableClick = () => {
    MakeProductUnavailable()
      .then(response => {
        refetchProduct()
      })
      .catch(e => {
        setMakeAvailableError(e.message)
      })
  }

  const history = useHistory()

  const [DuplicateProduct] = useMutation(DUPLICATE_PRODUCT, {
    variables: {
      id: props.match.params.productId,
    },
  })

  const handleDuplicateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    DuplicateProduct()
      .then(response => {
        console.log(response)
        history.push(
          `${ADMIN_ROUTES.ADMIN_PRODUCTS}/${response.data.duplicateProduct.id}`
        )
      })
      .catch(e => {
        return <Error message={e.message} />
      })
  }

  const [isWarningOpen, setIsWarningOpen] = useState(false)

  if (getProductError) {
    return <Error message={getProductError.message} />
  }
  if (loading && !data) {
    return <Loader />
  }
  if (
    !loading &&
    data &&
    data.getProduct.inventoryGroup.views.length > 0 &&
    !activeView
  ) {
    setActiveView(data.getProduct.inventoryGroup.views[0])
  }

  return (
    <>
      {data && (
        <>
          <AvailableVariantPickerModal
            product={data.getProduct}
            visible={variantModalOpen}
            toggle={setVariantModalOpen}
            refetchProduct={refetchProduct}
          />
          <D.flex justify={'space-around'}>
            <h1 style={{ paddingRight: '20px' }}>{data.getProduct.name}</h1>
            <B.SubtleButton onClick={handleDuplicateClick}>
              Duplicate
            </B.SubtleButton>
            <div style={{ marginLeft: '20px' }}>
              <Box
                margin="10px 0 10px 0"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsWarningOpen(true)}
              >
                <Text color="red">Delete Product</Text>
              </Box>
            </div>
            {isWarningOpen && (
              <M.Modal>
                <Box marginBottom="20px">
                  <Heading element="h4" color={'red'}>
                    Are you sure you want to delete this product?
                  </Heading>
                </Box>
                <Flex flexDirection="row" justifyContent="space-evenly">
                  <Button
                    bg="red"
                    color="paper"
                    onClick={() => handleDeleteProduct()}
                  >
                    Delete Product
                  </Button>
                  <Button onClick={() => setIsWarningOpen(false)}>
                    Cancel
                  </Button>
                </Flex>
              </M.Modal>
            )}
          </D.flex>
          {data.getProduct.store ? (
            <Link
              to={`${ADMIN_ROUTES.ADMIN_STORES}/${data.getProduct.store.id}`}
            >
              <h2>Go back to Store</h2>
            </Link>
          ) : (
            <Link to={`${ADMIN_ROUTES.ADMIN_PRODUCTS}`}>
              <h2>Go back to Products</h2>
            </Link>
          )}
          <U.status
            color={data.getProduct.available ? 'green' : 'red'}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              data.getProduct.available ? handleMakeUnavailableClick() : null
            }
          >
            <span>●</span>
            {data.getProduct.available ? 'available' : 'unavailable'}
          </U.status>
          {data.getProduct.available === false && (
            <U.status>
              <h3>Available Checklist</h3>
              <p>Product is connected to an inventory group</p>
              <p>Inventory Group has at least 1 view</p>
              <p>Product has at least 1 add on by location</p>
              <p>Product has at least one variant group</p>
              <B.SubtleButton onClick={handleMakeAvailableClick}>
                Make Available
              </B.SubtleButton>
            </U.status>
          )}
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <C.card width="120px">
              <G.row>
                <h3>Product Info</h3>
                <B.Button onClick={() => toggleModalOpen(!modalOpen)}>
                  Edit
                </B.Button>
                <ProductForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchProduct}
                  product={data.getProduct}
                />
              </G.row>
              <G.row>
                <h4>Name: </h4>
                <p>{data.getProduct.name}</p>
              </G.row>
              <h4>Description: </h4>
              <p>
                <Markdown
                  markdown={`${
                    data.getProduct.description
                      ? data.getProduct.description.replaceAll('| |', '|\n|')
                      : ' '
                  }`}
                />
              </p>
              <G.row>
                <h4>BasePrice: </h4>
                <p>$ {data.getProduct.basePrice}</p>
              </G.row>
              {data.getProduct.thumbnail && (
                <G.row>
                  <h4>Thumbnail: </h4>
                  <img
                    width={'200px'}
                    alt={'thumbnail'}
                    src={data.getProduct.thumbnail.url}
                  />
                </G.row>
              )}
              <BulkPricingTable
                basePrice={data.getProduct.basePrice}
                priceBreaks={data.getProduct.priceBreaks}
              />
            </C.card>
            <C.card>
              <D.flex>
                <h3>Variant Management</h3>
                <B.SubtleButton onClick={() => setVariantModalOpen(true)}>
                  Edit
                </B.SubtleButton>
              </D.flex>
              {data.getProduct.variantGroups.length !== 0 &&
                data.getProduct.variantGroups.map((variantGroup: any) => (
                  <>
                    <h4>{variantGroup.name}</h4>
                    <tr>
                      {variantGroup.name === 'Color' ? (
                        <ColorPicker
                          colors={variantGroup.variants}
                          setColor={setSelectedColor}
                          unavailableVariants={
                            data.getProduct.unavailableVariants
                          }
                        />
                      ) : (
                        <VariantPicker
                          variants={variantGroup.variants}
                          unavailableVariants={
                            data.getProduct.unavailableVariants
                          }
                        />
                      )}
                    </tr>
                  </>
                ))}
            </C.card>

            {/*.filter(*/}
            {/*(v: any) =>*/}
            {/*!data.getProduct.unavailableVariants*/}
            {/*.map((uv: any) => uv.id)*/}
            {/*.includes(v.id)*/}
            {/*)*/}
            {/*<C.card maxWidth={'600px'} minWidth={'300px'}>*/}
            {/*  <h3>Create View</h3>*/}
            {/*  <ViewCreateForm*/}
            {/*    inventoryGroupId={data.getProduct.inventoryGroup.id}*/}
            {/*    refetch={refetchProduct}*/}
            {/*  />*/}
            {/*</C.card>*/}
            <C.card>
              <G.row>
                <h3>{activeView?.name || 'view'}</h3>

                {/*{data.getProduct.store && (*/}
                <Link
                  to={{
                    pathname: `${ADMIN_ROUTES.ADMIN_PRODUCTS}/${data.getProduct.id}/design`,
                    state: {
                      product: data.getProduct,
                      color: selectedColor,
                      colorIndex: colorIndex,
                      isStore: true,
                    },
                  }}
                >
                  <B.SubtleButton>Edit Designs</B.SubtleButton>
                </Link>
              </G.row>
              <DesignViewer
                views={data.getProduct.inventoryGroup.views}
                activeView={activeView}
                color={selectedColor.value || data.getProduct.defaultColor}
                setActiveView={handleSetActiveView}
                designs={data.getProduct.designs}
              />
            </C.card>
            {/*<C.card maxWidth={'600px'}>*/}
            {/*  <h3>Manage Views</h3>*/}
            {/*  <D.flex>*/}
            {/*    {data.getProduct.inventoryGroup.views.map((view: TView) => {*/}
            {/*      return (*/}
            {/*        <D.pad v={'20px'} h={'20px'}>*/}
            {/*          <D.flex>*/}
            {/*            <h4>{view.name}</h4>*/}
            {/*            <Link*/}
            {/*              to={`${ADMIN_ROUTES.ADMIN_PRINT_LOCATION_EDITOR}/${view.id}`}*/}
            {/*            >*/}
            {/*              Edit*/}
            {/*            </Link>*/}
            {/*          </D.flex>*/}
            {/*          <Mockup*/}
            {/*            key={view.id}*/}
            {/*            view={view}*/}
            {/*            color={selectedColor.value}*/}
            {/*          />*/}
            {/*        </D.pad>*/}
            {/*      )*/}
            {/*    })}*/}
            {/*  </D.flex>*/}
            {/*</C.card>*/}
            <C.card>
              <h3>Manage Decorations</h3>
              <AddOnManager
                product={data.getProduct}
                refetchProduct={refetchProduct}
              />
            </C.card>
          </D.flex>
        </>
      )}
      {makeAvailableError && <Error message={makeAvailableError} />}
    </>
  )
}

interface IAddOnTableRow {
  product: TProduct
  refetchProduct: () => any
}

const AddOnManager: React.FC<IAddOnTableRow> = props => {
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)
  const [reOrder] = useMutation(REORDER_ADD_ON_BY_LOCATION)
  const handleNewAddByLocationOnClick = () => {
    toggleModalOpen(!modalOpen)
  }

  const sendUp = (addOnByLocationId: string) => {
    reOrder({
      variables: {
        productId: props.product.id,
        addOnByLocationId: addOnByLocationId,
        up: true,
      },
    }).then(() => props.refetchProduct())
  }

  const sendDown = (addOnByLocationId: string) => {
    reOrder({
      variables: {
        productId: props.product.id,
        addOnByLocationId: addOnByLocationId,
        up: false,
      },
    }).then(() => props.refetchProduct())
  }

  const [DeleteAddOnByLocation] = useMutation(DELETE_ADD_ON_BY_LOCATION)

  const handleDelete = async (addOnByLocationId: string) => {
    try {
      await DeleteAddOnByLocation({ variables: { id: addOnByLocationId } })
      if (props.refetchProduct) await props.refetchProduct()
    } catch (error: any) {
      return <Error message={error.message} />
    }
  }

  return (
    <>
      <AddOnByLocationCreateForm
        product={props.product}
        visible={modalOpen}
        toggle={toggleModalOpen}
        refetchProduct={props.refetchProduct}
      />
      <T.Table>
        <>
          <tr>
            <th></th>
            <th>Decoration</th>
            <th>Locations</th>
            <th>Action</th>
            <th>
              <B.SubtleButton onClick={handleNewAddByLocationOnClick}>
                +
              </B.SubtleButton>
            </th>
          </tr>
          {props.product.addOnsByLocation.map(
            (addOnByLocation: TAddOnByLocation) => (
              <>
                <tr>
                  <td>
                    <B.SubtleButton onClick={() => sendUp(addOnByLocation.id)}>
                      ↑
                    </B.SubtleButton>{' '}
                    <B.SubtleButton
                      onClick={() => sendDown(addOnByLocation.id)}
                    >
                      ↓
                    </B.SubtleButton>
                  </td>
                  <td>{addOnByLocation.addOn.name}</td>
                  <td>{addOnByLocation.location.name}</td>
                  <td>
                    <B.SubtleButton
                      onClick={() => handleDelete(addOnByLocation.id)}
                    >
                      Delete
                    </B.SubtleButton>
                  </td>
                  <td></td>
                </tr>
              </>
            )
          )}
        </>
      </T.Table>
    </>
  )
}

interface IAddOnByLocationCreateFormProps {
  product: TProduct
  visible: boolean
  toggle: any
  refetchProduct?: () => any
}

const AddOnByLocationCreateForm: React.FC<
  IAddOnByLocationCreateFormProps
> = props => {
  const [addOnId, setAddOnId] = useState<TAddOnID | undefined>(undefined)
  const [locations, setLocations] = useState<any>({})

  const {
    data: addOns,
    loading: GetAddOnsQueryLoading,
    error: GetAddOnsQueryError,
  } = useQuery(GET_ADD_ONS, {
    variables: {
      searches: [],
    },
  })

  const {
    data: printLocations,
    loading: GetPrintLocationsQueryLoading,
    error: GetPrintLocationsQueryError,
  } = useQuery(GET_SOME_PRINT_LOCATIONS, {
    variables: {
      tags: [],
    },
  })

  // TODO: print locations should get from inventory group views
  // let tempPL: any = {};
  // props.product.inventoryGroup.views.forEach(view => view.printLocations.forEach(pL => tempPL[pL.printLocation.id] = pL))
  // const printLocations: TPrintLocation[] = Object.values(tempPL);

  const [CreateAddOnByLocation] = useMutation(CREATE_ADD_ON_BY_LOCATION, {
    variables: {
      addOnId,
      locations: Object.keys(locations).filter(l => locations[l]),
      product: props.product.id,
    },
  })

  const invalid = addOnId === undefined || !locations.length

  if (GetAddOnsQueryLoading) return <Loader />
  if (GetPrintLocationsQueryLoading) return <Loader />

  if (GetAddOnsQueryError)
    return <Error message={GetAddOnsQueryError.message} />
  if (GetPrintLocationsQueryError)
    return <Error message={GetPrintLocationsQueryError.message} />

  const handleCheckPrintLocation = (event: any) => {
    // updating an object instead of a Map
    setLocations({ ...locations, [event.target.name]: event.target.checked })
  }

  return (
    <>
      {props.visible && (
        <M.Modal>
          {/*{JSON.stringify({*/}
          {/*    addOnId,*/}
          {/*    locations: Object.keys(locations).filter(l => locations[l]),*/}
          {/*    product: props.product.id*/}
          {/*})}*/}
          <h2>New Decoration Type by Location</h2>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form
            onSubmit={async (e: any) => {
              e.preventDefault()
              try {
                await CreateAddOnByLocation()
                if (props.refetchProduct) await props.refetchProduct()
                props.toggle(false)
              } catch (error: any) {
                return <Error message={error.message} />
              }
            }}
          >
            <F.Label>
              Decoration Type
              <F.select
                value={addOnId}
                onChange={(e: any) => setAddOnId(e.target.value)}
              >
                <option disabled={true} selected>
                  Select
                </option>
                <option disabled={true}>________________</option>
                <option disabled={true}></option>
                <option disabled={true}>Enabled:</option>
                {addOns &&
                  addOns.getSomeAddOns
                    .filter(
                      (a: any) =>
                        a.available &&
                        a.templateInventoryGroups
                          ?.map((t: { id: string }) => t.id)
                          .includes(props.product.inventoryGroup.id)
                    )
                    .map((addOn: any) => (
                      <option value={addOn.id}>{addOn.name}</option>
                    ))}
                <option disabled={true}>________________</option>
                <option disabled={true}></option>
                <option disabled={true}>Not enabled by default:</option>
                {addOns &&
                  addOns.getSomeAddOns
                    .filter(
                      (a: any) =>
                        a.available &&
                        !a.templateInventoryGroups
                          ?.map((t: { id: string }) => t.id)
                          .includes(props.product.inventoryGroup.id)
                    )
                    .map((addOn: any) => (
                      <option value={addOn.id}>{addOn.name}</option>
                    ))}
              </F.select>
            </F.Label>
            <br />
            <HR.Line />
            <br />
            {printLocations &&
              printLocations.getSomePrintLocations
                .filter((p: TPrintLocation) => p.available)
                .map((printLocation: TPrintLocation) => (
                  <F.Label
                    key={printLocation.id}
                    style={{
                      opacity: `${
                        props.product.inventoryGroup.views
                          .flatMap(v =>
                            v.printLocations.flatMap(
                              pLCM => pLCM.printLocation.id
                            )
                          )
                          .includes(printLocation.id)
                          ? '1'
                          : '0.5'
                      }`,
                    }}
                  >
                    {printLocation.name}
                    <F.checkbox
                      type={'checkbox'}
                      checked={locations[printLocation.id]}
                      name={printLocation.id}
                      onChange={handleCheckPrintLocation}
                      disabled={
                        !props.product.inventoryGroup.views
                          .flatMap(v =>
                            v.printLocations.flatMap(
                              pLCM => pLCM.printLocation.id
                            )
                          )
                          .includes(printLocation.id)
                      }
                    />
                  </F.Label>
                ))}
            <B.Button disabled={false} type={'submit'}>
              Create Decoration Type by Location
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default AdminProductView
