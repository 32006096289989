import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import ADD_PRODUCT_TAGS from './gql/ADD_PRODUCT_TAGS'
import { TTagID } from 'types/Tag'
import TagViewer from './TagViewer'
import { TProduct } from 'types'
import { Error, Loader } from 'utils'
import REMOVE_PRODUCT_TAGS from './gql/REMOVE_PRODUCT_TAGS'

interface IProductTagEditorProps {
  product: TProduct
  refetch?: () => void
}

const ProductTags: React.FC<IProductTagEditorProps> = props => {
  const [addTags, { loading: add_loading }] = useMutation(ADD_PRODUCT_TAGS)
  const [deleteTags] = useMutation(REMOVE_PRODUCT_TAGS)
  const [error, setError] = useState<string | null>(null)

  const newTag = (tagId: TTagID) => {
    addTags({
      variables: {
        id: props.product.id,
        tags: [tagId],
      },
    })
      .then(data => {
        props.refetch && props.refetch()
      })
      .catch(e => setError(e.message))
  }

  const deleteTag = (tagId: TTagID) => {
    deleteTags({
      variables: {
        id: props.product.id,
        tags: [tagId],
      },
    })
      .then(data => {
        props.refetch && props.refetch()
      })
      .catch(e => setError(e.message))
  }
  return (
    <>
      <TagViewer
        tags={props.product.tags}
        newTag={newTag}
        deleteTag={deleteTag}
      />
      {add_loading && <Loader />}
      {error && <Error message={error} />}
    </>
  )
}

export default ProductTags
