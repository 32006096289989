import React from 'react'
import { Link } from 'react-router-dom'

export default () => (
  <Link to={'/'}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path d="M426.72,324.68c-33.82-1.86-63.86-24.22-73.07-55.95-6.8-23.41-13.26-47.34-29.19-66.92A129.59,129.59,0,0,0,313.81,190c-4.69-4.51-6-9.84-3.89-15.63,8.27-22.62,19.09-43.68,39-58.55s41.35-18,65.38-11.79c21.7,5.6,41.17,14.65,56.57,30.88,16.44,17.33,26.68,38.13,28.06,62.24.88,15.45,2.16,31.43-.63,46.44-6.37,34.12-20.34,64.18-55.45,78.4-4.92,2-10.71,1.84-16.1,2.69" />
      <path d="M73.27,324.68c33.81-1.86,63.85-24.22,73.06-55.95,6.81-23.41,13.28-47.34,29.19-66.92A131,131,0,0,1,186.18,190c4.68-4.51,6-9.84,3.88-15.63-8.27-22.62-19.1-43.68-39-58.55S109.71,97.84,85.7,104c-21.71,5.6-41.17,14.65-56.57,30.88C12.73,152.24,2.45,173,1.07,197.15c-.88,15.45-2.16,31.43.63,46.44C8.06,277.71,22,307.77,57.15,322c4.92,2,10.71,1.84,16.11,2.69" />
      <path d="M317.06,316.48c-12.15-3.86-40.51-6-68.58-6.13-28.08.11-56.43,2.27-68.59,6.13-28.79,8.24-30.93,36.65-7.29,41.3,30.76,6.05,55.36,22,66.62,37.09,2.74,2.77,5.37,3.92,7.9,4.1h.74a5.92,5.92,0,0,0,.62-.12c.2,0,.42.1.62.12h.74c2.53-.19,5.16-1.34,7.9-4.1,11.26-15.06,35.86-31,66.61-37.09,23.65-4.65,21.51-33.06-7.29-41.3" />
    </svg>
  </Link>
)
