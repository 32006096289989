import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import {
  ButtonStyle as B,
  CardStyle as C,
  FormStyle as F,
  ModalLayout as M,
} from 'styles'
import REGISTER_WAREHOUSE from 'gql/REGISTER_WAREHOUSE'

interface INewInventoryProviderGroupFormProps {
  visible: boolean
  toggle: any
  refetch?: () => any
}

const NewInventoryProviderGroupForm: React.FC<
  INewInventoryProviderGroupFormProps
> = props => {
  const [name, setName] = useState('')
  const [value, setValue] = useState('')
  const [id, setId] = useState('')
  const [RegisterInventoryProviderGroup, { called, loading, data, error }] =
    useMutation(REGISTER_WAREHOUSE, {
      variables: {
        name,
        value,
        id,
      },
    })

  const invalid = name === '' || id === '' || value === ''

  if (called && loading) return <Loader />

  if (data) {
    props.toggle()
    props.refetch && props.refetch()
  }

  if (error) return <Error message={error.message} />

  return (
    <>
      {props.visible && (
        <M.Modal>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <C.card>
            <F.Form>
              <h1>Register New Warehouse</h1>
              <F.Label>
                Name
                <F.Input
                  type={'text'}
                  placeholder={'Bossy Panda Miniac'}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </F.Label>
              <F.Label>
                Provider Code (id)
                <F.Input
                  type={'text'}
                  placeholder={'bp-miniac'}
                  value={id}
                  onChange={e => setId(e.target.value)}
                />
              </F.Label>
              <F.Label>
                Value
                <F.Input
                  type={'text'}
                  placeholder={'bossy-miniac'}
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </F.Label>
              <B.Button
                disabled={invalid}
                onClick={() =>
                  RegisterInventoryProviderGroup().catch(error => (
                    <Error message={error.message} />
                  ))
                }
              >
                Register
              </B.Button>
            </F.Form>
          </C.card>
        </M.Modal>
      )}
    </>
  )
}

export default NewInventoryProviderGroupForm
