import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import React, { FC, useEffect, useState } from 'react'
import { CONFIRMATION } from '../../../routes/user'
import { Error } from '../../../utils'
import { useMutation, useQuery } from '@apollo/react-hooks'
import CONFIRM_PAYMENT from '../../../gql/CONFIRM_PAYMENT'
import { withTheme } from 'styled-components'
import { ITheme } from '../../../types'
import GET_PAYMENT_METHODS from '../../../gql/GET_PAYMENT_METHODS'
import { useHistory } from 'react-router-dom'
import { FormStyle } from '../../../styles'
import { Box, Flex, Heading, Switch } from '@sweaterplanet/nucleus-style'
import { FormStyle as F } from '../../../styles'

interface IStripePaymentProps {
  theme: ITheme
  orderId: String
  orderNotes?: String
  clientSecret: string
}

const StripePayment: FC<IStripePaymentProps> = props => {
  const {
    theme: { colors },
  } = props

  const stripe = useStripe()
  const elements: any = useElements()

  const [paymentMethods, setPaymentMethods] = useState<object[] | undefined>(
    undefined
  )
  const [error, setError] = useState<string | undefined>(undefined)

  const [selectedSavedCard, setSelectedSavedCard] = useState<
    string | undefined
  >(undefined)
  const [ccFormDisabled, setCcFormDisabled] = useState(false)

  const [ConfirmPayment] = useMutation(CONFIRM_PAYMENT)

  const { data } = useQuery(GET_PAYMENT_METHODS)

  const history = useHistory()

  useEffect(() => {
    if (data) {
      console.log(data)
      setPaymentMethods(data.getPaymentMethods)
    }
  }, [data])

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    if (selectedSavedCard) {
      const result = await stripe.confirmCardPayment(props.clientSecret, {
        payment_method: selectedSavedCard,
      })
      if (result.error) {
        // Show error to your customer (e.g., payment details incomplete)
        setError(result.error.message)
      } else {
        setError(undefined)
        ConfirmPayment({
          variables: {
            redirect_status: 'succeeded',
            payment_intent: result.paymentIntent.id,
            orderId: props.orderId,
            orderNotes: props.orderNotes,
            saveCard: saveCard,
          },
        })
        history.push({
          pathname: `${CONFIRMATION}/store`,
          search: `?pay_in_store=true`,
        })
      }
    } else {
      if (!elements) {
        return
      }
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url:
            window.location.href.split(window.location.pathname)[0] +
            CONFIRMATION +
            '/' +
            props.orderId,
        },
      })

      if (result.error) {
        // Show error to your customer (e.g., payment details incomplete)
        setError(result.error.message)
      } else {
        setError(undefined)
        ConfirmPayment({
          variables: {
            redirect_status: 'succeeded',
            payment_intent: result.paymentIntent.id,
            orderId: props.orderId,
            orderNotes: props.orderNotes,
            saveCard: saveCard,
          },
        })
        history.push({
          pathname: `${CONFIRMATION}/store`,
          search: `?pay_in_store=true`,
        })
      }
    }
  }

  const handleSelectSavedCard = (value: string) => {
    if (value) {
      setSelectedSavedCard(value)
      if (value !== undefined) {
        setCcFormDisabled(true)
      }
      if (value === undefined || value === '+ New Card') {
        setCcFormDisabled(false)
      }
    }
  }

  const [saveCard, setSaveCard] = useState(true)

  // @ts-ignore
  return (
    <>
      {error && <Error message={error} showInProd />}
      <Box marginLeft="10px">
        <form onSubmit={handleSubmit}>
          <Box margin="0 0 20px 0">
            <Flex justifyContent={'space-between'}>
              <Heading element="h4" color={colors.text.default}>
                Select a saved card
              </Heading>
            </Flex>
          </Box>
          <FormStyle.checkoutSelect
            width={'100%'}
            style={{
              backgroundColor:
                selectedSavedCard === undefined
                  ? colors.card.background2
                  : colors.button.background,
            }}
            onChange={e => handleSelectSavedCard(e.target.value)}
          >
            <option value={undefined} selected={true}>
              + New Card
            </option>
            {paymentMethods &&
              paymentMethods.length &&
              paymentMethods.map((d: any) => (
                <option value={d.id}>{`**** **** **** ${d.card.last4}`}</option>
              ))}
          </FormStyle.checkoutSelect>
          {!ccFormDisabled && (
            <Box margin="20px 0 0 0">
              <F.Label>
                <Flex alignItems="center" marginTop="10px" marginBottom="20px">
                  <F.Input
                    value={saveCard.toString()}
                    checked={saveCard}
                    type={'checkbox'}
                    onChange={e => setSaveCard(e.target.checked)}
                  />
                  <Box marginLeft="10px">
                    <Heading element="h4" color={colors.text.default}>
                      Save Card
                    </Heading>
                  </Box>
                </Flex>
              </F.Label>
              <PaymentElement />
            </Box>
          )}
          <br />
          <button
            id={'creditCardFormButton'}
            disabled={!stripe}
            style={{ visibility: 'hidden' }}
          >
            Submit
          </button>
        </form>
      </Box>
    </>
  )
}

export default withTheme(StripePayment)
