import React, { useState, useEffect } from 'react'
import { ModalLayout as M, ButtonStyle as B, FormStyle as F } from 'styles'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import GET_INVENTORY_GROUPS from './gql/GET_INVENTORY_GROUPS'
import { TInventoryGroup, TStore } from 'types'
import BULK_CREATE_PRODUCTS from './gql/BULK_CREATE_PRODUCTS'

interface IBulkProductForm {
  visible: boolean
  toggle: any
  refetch: () => void
  store?: TStore
}

const BulkProductForm: React.FC<IBulkProductForm> = props => {
  const { visible, toggle, refetch, store } = props

  const [inventoryGroupItemCodes, setInventoryGroupItemCodes] = useState<
    string[]
  >([])
  const [inventoryGroups, setInventoryGroups] = useState<TInventoryGroup[]>([])
  const [name, setName] = useState<string>('')
  const [file, setFile] = useState<any | undefined>(undefined)
  const [isDesignable, setIsDesignable] = useState<string>(
    store ? 'false' : 'true'
  )
  const [allOn, setAllOn] = useState<boolean>(false)

  const { loading, error, data } = useQuery(GET_INVENTORY_GROUPS, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data && data.getSomeInventoryGroups) {
      const inventoryGroups = data.getSomeInventoryGroups.sort(
        (a: TInventoryGroup, b: TInventoryGroup) => a.name.localeCompare(b.name)
      )
      setInventoryGroups(inventoryGroups)
    }
  }, [data, loading])

  const [BulkCreateProducts] = useMutation(BULK_CREATE_PRODUCTS)

  const handleSubmitClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()

    try {
      await BulkCreateProducts({
        variables: {
          inventoryGroupItemCodes,
          name,
          storeId: store?.id,
          file,
          isDesignable: JSON.parse(isDesignable),
        },
      })
      setName('')
      setIsDesignable(store ? 'false' : 'true')
      setInventoryGroups([])
      toggle(false)
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const invalid = !inventoryGroupItemCodes.length || name === ''

  if (loading) return <Loader />

  if (error) return <Error message={error.message} />

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      console.log('here')
      console.log('file: ', file)
      setFile(file)
    }
  }

  const handleToggleAll = (e: any) => {
    e.preventDefault()
    if (allOn) {
      setInventoryGroupItemCodes([])
    } else {
      setInventoryGroupItemCodes(
        inventoryGroups.map((d: TInventoryGroup) => d.id)
      )
    }
    setAllOn(!allOn)
  }

  return (
    <>
      {visible && (
        <M.Modal>
          <h2>New Products</h2>
          <B.Exit onClick={() => toggle(false)}>X</B.Exit>
          <F.Form>
            <B.SubtleButton onClick={handleToggleAll}>
              Toggle All
            </B.SubtleButton>
            <br />
            {inventoryGroups &&
              inventoryGroups.map((inventoryGroup: TInventoryGroup) => (
                <label>
                  <input
                    type={'checkbox'}
                    checked={inventoryGroupItemCodes.includes(
                      inventoryGroup.id
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        setInventoryGroupItemCodes([
                          ...inventoryGroupItemCodes,
                          inventoryGroup.id,
                        ])
                      } else {
                        setInventoryGroupItemCodes(
                          inventoryGroupItemCodes.filter(
                            s => s !== inventoryGroup.id
                          )
                        )
                      }
                    }}
                  ></input>
                  {inventoryGroup.name} ({inventoryGroup.id})
                </label>
              ))}

            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              {' '}
              Upload File (Optional)
              <F.upload
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event)
                }
              />
            </F.Label>
            <F.Label>
              {' '}
              Is Designable ?
              <select
                value={isDesignable}
                onChange={e => setIsDesignable(e.target.value)}
              >
                <option value={'false'}>False</option>
                <option value={'true'}>True</option>
              </select>
            </F.Label>
            <B.Button disabled={invalid} onClick={handleSubmitClick}>
              Create Products
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default BulkProductForm
