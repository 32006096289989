import { TScope } from './Scope'
import { TCart } from './Cart'
import { TAddress } from './Address'
import { TBillingAccount } from './BillingAccount'
import { TUserGroup } from './UserGroup'
import { TOrder } from './Order'
import { Store } from 'redux'
import { TRetailLocation } from './RetailLocation'

export type TUserID = string

export type TUser = {
  id: TUserID
  role?: Role
  scope?: TScope
  name?: string // TODO: get rid of name and use First/Last name
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  company?: string
  blacklistFeatures: string[]
  whitelistFeatures: string[]
  googleId?: string
  cart?: TCart
  userGroups: TUserGroup[]
  shippingAddresses: TAddress[]
  billingAddresses: TAddress[]
  billingAccount: TBillingAccount[]
  defaultShippingAddresses?: TAddress
  defaultBillingAddresses?: TAddress
  darkMode?: boolean
  orders?: TOrder
  managerPermissions?: String[]
  managerStores?: Store[]
  retailLocation?: TRetailLocation
}

export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  GUEST = 'GUEST',
}
