import React, { useState, Fragment, useEffect, useRef } from 'react'
import { TView } from 'types/View'
import { TDesign } from 'types/Design'
import { TPrintLocationCoordinateMap } from 'types/PrintLocationCoordinateMap'
import { TTempDesign } from 'types/Design'
import { SLEEVE_CUSTOMIZATION } from 'constants/sleeveCustomization'
import { lightOrDark } from '../../utils/lib/color'
import { ITheme, TAddOnByLocation } from '../../types'
import uuid from 'react-uuid'
import MiniGallery from 'components/Gallery/lib/MiniGallery'
import { Flex, Heading } from '@sweaterplanet/nucleus-style'
import { ModalLayout as M, ButtonStyle as B } from 'styles'
import { withTheme } from 'styled-components'
import { flex } from 'styles/lib/divLayout'
import { TPrintLocation } from 'types/PrintLocation'
import { TProduct } from 'types/Product'
import { TFile } from 'types/File'
import GET_FILE from 'gql/GET_FILE'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import GET_FILES from 'gql/GET_FILES'
import { Loader } from 'utils'
import { TAddOn } from 'types/AddOn'

interface IMockupProps {
  view: TView
  isActiveView?: boolean
  color: string
  width?: string
  height?: string
  designs?: TDesign[]

  tempDesigns?: TTempDesign[]
  setTempDesigns?: (designs: TTempDesign[]) => void
  selectedDesign?: TTempDesign
  setSelectedDesign?: (design: TTempDesign) => void

  showPrintLocations?: boolean
  selectedPrintLocationIndex?: number
  setSelectedPrintLocationIndex?: (index: number) => void

  selectedAddOn?: TAddOn
  selectedAddOnIndex?: number

  isDesignStudio?: boolean
  isSleeveCustomization?: boolean
  addOnsByLocation?: TAddOnByLocation[]
  previewURL?: string
  previewAddOnByLocation?: TAddOnByLocation
  theme: ITheme
  product?: TProduct
}

const Mockup: React.FC<IMockupProps> = props => {
  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
    CTMa: 1,
    CTMe: 0,
    CTMf: 0,
  })
  const [selectedElement, setSelectedElement] =
    useState<HTMLOrSVGImageElement | null>(null)
  const [image, setImage] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [selectedCorner, setSelectedCorner] = useState<SVGRectElement | null>(
    null
  )

  const {
    view: { printLocations, boundingBox, texture, base },
    isActiveView,
    color,
    width,
    height,
    designs,
    tempDesigns,
    setTempDesigns,
    selectedDesign,
    setSelectedDesign,
    showPrintLocations,
    selectedPrintLocationIndex,
    setSelectedPrintLocationIndex,
    isDesignStudio,
    isSleeveCustomization,
    previewURL,
    previewAddOnByLocation,
    theme: { colors },
  } = props
  const cornerWidth = 30

  function getMousePosition(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) {
    const CTM = event.currentTarget.getScreenCTM() //Current Transformation Matrix for screen

    return CTM
      ? {
          x: (event.clientX - CTM.e) / CTM.a,
          y: (event.clientY - CTM.f) / CTM.d,
          CTMa: CTM.a,
          CTMe: CTM.e,
          CTMf: CTM.f,
        }
      : {
          x: event.clientX,
          y: event.clientY,
          CTMa: 1,
          CTMe: 0,
          CTMf: 0,
        }
  }
  function getTouchPosition(event: React.TouchEvent<any>) {
    const CTM = event.currentTarget.getScreenCTM() //Current Transformation Matrix for screen

    return CTM
      ? {
          x: (event.touches[0].clientX - CTM.e) / CTM.a,
          y: (event.touches[0].clientY - CTM.f) / CTM.d,
          CTMa: CTM.a,
          CTMe: CTM.e,
          CTMf: CTM.f,
        }
      : {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
          CTMa: 1,
          CTMe: 0,
          CTMf: 0,
        }
  }

  const handleMouseDown = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const element = event.target as HTMLElement | SVGElement
    let offsetData: typeof offset

    if (element.classList.contains('draggable')) {
      const designIndex = element.id.split('image')[1]
      if (setSelectedDesign && tempDesigns) {
        setSelectedDesign(tempDesigns[parseInt(designIndex)])
      }

      setSelectedCorner(null)
      setSelectedElement(
        document.getElementById(
          `selectedimage${designIndex}`
        ) as HTMLOrSVGImageElement
      )

      offsetData = getMousePosition(event)
      const x = element.getAttribute('x')
      const y = element.getAttribute('y')
      offsetData.x -= x ? parseFloat(x) : 0
      offsetData.y -= y ? parseFloat(y) : 0

      setOffset(offsetData)
    } else if (element.classList.contains('resize')) {
      setSelectedCorner(element as SVGRectElement)

      const designIndex = element.id.substr(2)
      const image = document.getElementById(
        `selectedimage${designIndex}`
      ) as HTMLOrSVGImageElement
      setSelectedElement(image)

      if (setSelectedDesign && tempDesigns) {
        setSelectedDesign(tempDesigns[parseInt(designIndex)])
      }

      offsetData = getMousePosition(event)
      const x = image.getAttribute('x')
      const y = image.getAttribute('y')
      offsetData.x -= x ? parseFloat(x) : 0
      offsetData.y -= y ? parseFloat(y) : 0
      setOffset(offsetData)

      const bBox = image.getBoundingClientRect()
      setImage({
        x: (bBox.x - offsetData.CTMe) / offsetData.CTMa,
        y: (bBox.y - offsetData.CTMf) / offsetData.CTMa,
        width: bBox.width / offsetData.CTMa,
        height: bBox.height / offsetData.CTMa,
      })
    } else if (element.classList.contains('upload')) {
      const index = element.id.split('upload')[1]
      if (setSelectedPrintLocationIndex) {
        setSelectedPrintLocationIndex(parseInt(index))
        if (selectedDesign && setSelectedDesign)
          setSelectedDesign({ ...selectedDesign, designIndex: -2 })
      }
    }
  }

  const handleTouchStart = (event: React.TouchEvent<SVGSVGElement>) => {
    const element = event.target as HTMLElement | SVGElement
    let offsetData: typeof offset

    if (element.classList.contains('draggable')) {
      const designIndex = element.id.split('image')[1]
      if (setSelectedDesign && tempDesigns) {
        setSelectedDesign(tempDesigns[parseInt(designIndex)])
      }

      setSelectedCorner(null)
      setSelectedElement(
        document.getElementById(
          `selectedimage${designIndex}`
        ) as HTMLOrSVGImageElement
      )

      offsetData = getTouchPosition(event)
      const x = element.getAttribute('x')
      const y = element.getAttribute('y')
      offsetData.x -= x ? parseFloat(x) : 0
      offsetData.y -= y ? parseFloat(y) : 0

      setOffset(offsetData)
    } else if (element.classList.contains('resize')) {
      setSelectedCorner(element as SVGRectElement)

      const designIndex = element.id.substr(2)
      const image = document.getElementById(
        `selectedimage${designIndex}`
      ) as HTMLOrSVGImageElement
      setSelectedElement(image)

      if (setSelectedDesign && tempDesigns) {
        setSelectedDesign(tempDesigns[parseInt(designIndex)])
      }

      offsetData = getTouchPosition(event)
      const x = image.getAttribute('x')
      const y = image.getAttribute('y')
      offsetData.x -= x ? parseFloat(x) : 0
      offsetData.y -= y ? parseFloat(y) : 0
      setOffset(offsetData)

      const bBox = image.getBoundingClientRect()
      setImage({
        x: (bBox.x - offsetData.CTMe) / offsetData.CTMa,
        y: (bBox.y - offsetData.CTMf) / offsetData.CTMa,
        width: bBox.width / offsetData.CTMa,
        height: bBox.height / offsetData.CTMa,
      })
    } else if (element.classList.contains('upload')) {
      const index = element.id.split('upload')[1]
      if (setSelectedPrintLocationIndex) {
        setSelectedPrintLocationIndex(parseInt(index))
        if (selectedDesign && setSelectedDesign)
          setSelectedDesign({ ...selectedDesign, designIndex: -2 })
      }
    }
  }

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.preventDefault()

    let index = -1
    if (selectedElement) {
      const printLocationIndex = selectedElement.getAttribute(
        'data-printlocationindex'
      )
      index = printLocationIndex ? parseInt(printLocationIndex) : -1

      if (index !== -1) {
        const bBox = selectedElement.getBoundingClientRect()
        const designIndex = parseInt(selectedElement.id.split('image')[1])

        // Coordinate of the click event with respect to the SVG origin
        const coord = getMousePosition(event)

        const width = bBox.width / offset.CTMa
        const height = bBox.height / offset.CTMa

        const left = coord.x - offset.x
        const right = left + width
        const top = coord.y - offset.y
        const bottom = top + height

        if (!selectedCorner) {
          // Check if image is out of printlocation bounds
          if (
            right < printLocations[index].coordinateMap[3].x &&
            left > printLocations[index].coordinateMap[0].x
          ) {
            selectedElement.setAttribute('x', left.toFixed(2))
            document
              .getElementById(`nw${designIndex}`)
              ?.setAttribute('x', left.toFixed(2))
            document
              .getElementById(`ne${designIndex}`)
              ?.setAttribute('x', (right - cornerWidth).toFixed(2))
            document
              .getElementById(`sw${designIndex}`)
              ?.setAttribute('x', left.toFixed(2))
            document
              .getElementById(`se${designIndex}`)
              ?.setAttribute('x', (right - cornerWidth).toFixed(2))
          }
          if (
            bottom < printLocations[index].coordinateMap[3].y &&
            top > printLocations[index].coordinateMap[0].y
          ) {
            selectedElement.setAttribute('y', top.toFixed(2))
            document
              .getElementById(`nw${designIndex}`)
              ?.setAttribute('y', top.toFixed(2))
            document
              .getElementById(`ne${designIndex}`)
              ?.setAttribute('y', top.toFixed(2))
            document
              .getElementById(`sw${designIndex}`)
              ?.setAttribute('y', (bottom - cornerWidth).toFixed(2))
            document
              .getElementById(`se${designIndex}`)
              ?.setAttribute('y', (bottom - cornerWidth).toFixed(2))
          }
        } else {
          const x1 = printLocations[index].coordinateMap[0].x
          const x2 = printLocations[index].coordinateMap[3].x
          const y1 = printLocations[index].coordinateMap[0].y
          const y2 = printLocations[index].coordinateMap[3].y

          let dx = image.x - left
          let dy = image.y - top

          switch (selectedCorner.id.substring(0, 2)) {
            case 'nw':
              // Check if image is resizing out of bounds
              if (left <= x1) {
                dx = image.x - x1 // set dx to its maximum value within the bounds
              } else {
                selectedElement.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
              }
              if (top <= y1) {
                dy = image.y - y1 // set dy to its maximum value within the bounds
              } else {
                selectedElement.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
              }

              selectedElement.setAttribute(
                'width',
                (image.width + dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height + dy).toFixed(2)
              )
              break

            case 'ne':
              if (image.x + image.width - dx >= x2) {
                dx = image.x + image.width - x2
              } else {
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
              }
              if (top <= y1) {
                dy = image.y - y1
              } else {
                selectedElement.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
              }

              selectedElement.setAttribute(
                'width',
                (image.width - dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height + dy).toFixed(2)
              )
              break

            case 'sw':
              if (left <= x1) {
                dx = image.x - x1
              } else {
                selectedElement.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
              }
              if (image.y + image.height - dy >= y2) {
                dy = image.y + image.height - y2
              } else {
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
              }

              selectedElement.setAttribute(
                'width',
                (image.width + dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height - dy).toFixed(2)
              )
              break

            case 'se':
              if (image.x + image.width - dx >= x2) {
                dx = image.x + image.width - x2
              } else {
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
              }
              if (image.y + image.height - dy >= y2) {
                dy = image.y + image.height - y2
              } else {
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
              }

              selectedElement.setAttribute(
                'width',
                (image.width - dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height - dy).toFixed(2)
              )
              break
          }
        }
      }
    }
  }

  const handleTouchMove = (event: React.TouchEvent<any>) => {
    event.preventDefault()

    let index = -1
    if (selectedElement) {
      const printLocationIndex = selectedElement.getAttribute(
        'data-printlocationindex'
      )
      index = printLocationIndex ? parseInt(printLocationIndex) : -1

      if (index !== -1) {
        const bBox = selectedElement.getBoundingClientRect()
        const designIndex = parseInt(selectedElement.id.split('image')[1])

        // Coordinate of the click event with respect to the SVG origin
        const coord = getTouchPosition(event)

        const width = bBox.width / offset.CTMa
        const height = bBox.height / offset.CTMa

        const left = coord.x - offset.x
        const right = left + width
        const top = coord.y - offset.y
        const bottom = top + height

        if (!selectedCorner) {
          // Check if image is out of printlocation bounds
          if (
            right < printLocations[index].coordinateMap[3].x &&
            left > printLocations[index].coordinateMap[0].x
          ) {
            selectedElement.setAttribute('x', left.toFixed(2))
            document
              .getElementById(`nw${designIndex}`)
              ?.setAttribute('x', left.toFixed(2))
            document
              .getElementById(`ne${designIndex}`)
              ?.setAttribute('x', (right - cornerWidth).toFixed(2))
            document
              .getElementById(`sw${designIndex}`)
              ?.setAttribute('x', left.toFixed(2))
            document
              .getElementById(`se${designIndex}`)
              ?.setAttribute('x', (right - cornerWidth).toFixed(2))
          }
          if (
            bottom < printLocations[index].coordinateMap[3].y &&
            top > printLocations[index].coordinateMap[0].y
          ) {
            selectedElement.setAttribute('y', top.toFixed(2))
            document
              .getElementById(`nw${designIndex}`)
              ?.setAttribute('y', top.toFixed(2))
            document
              .getElementById(`ne${designIndex}`)
              ?.setAttribute('y', top.toFixed(2))
            document
              .getElementById(`sw${designIndex}`)
              ?.setAttribute('y', (bottom - cornerWidth).toFixed(2))
            document
              .getElementById(`se${designIndex}`)
              ?.setAttribute('y', (bottom - cornerWidth).toFixed(2))
          }
        } else {
          const x1 = printLocations[index].coordinateMap[0].x
          const x2 = printLocations[index].coordinateMap[3].x
          const y1 = printLocations[index].coordinateMap[0].y
          const y2 = printLocations[index].coordinateMap[3].y

          let dx = image.x - left
          let dy = image.y - top

          switch (selectedCorner.id.substring(0, 2)) {
            case 'nw':
              // Check if image is resizing out of bounds
              if (left <= x1) {
                dx = image.x - x1 // set dx to its maximum value within the bounds
              } else {
                selectedElement.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
              }
              if (top <= y1) {
                dy = image.y - y1 // set dy to its maximum value within the bounds
              } else {
                selectedElement.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
              }

              selectedElement.setAttribute(
                'width',
                (image.width + dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height + dy).toFixed(2)
              )
              break

            case 'ne':
              if (image.x + image.width - dx >= x2) {
                dx = image.x + image.width - x2
              } else {
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
              }
              if (top <= y1) {
                dy = image.y - y1
              } else {
                selectedElement.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute('y', top.toFixed(2))
              }

              selectedElement.setAttribute(
                'width',
                (image.width - dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height + dy).toFixed(2)
              )
              break

            case 'sw':
              if (left <= x1) {
                dx = image.x - x1
              } else {
                selectedElement.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`nw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute('x', left.toFixed(2))
              }
              if (image.y + image.height - dy >= y2) {
                dy = image.y + image.height - y2
              } else {
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
              }

              selectedElement.setAttribute(
                'width',
                (image.width + dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height - dy).toFixed(2)
              )
              break

            case 'se':
              if (image.x + image.width - dx >= x2) {
                dx = image.x + image.width - x2
              } else {
                document
                  .getElementById(`ne${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'x',
                    (image.x + width - cornerWidth).toFixed(2)
                  )
              }
              if (image.y + image.height - dy >= y2) {
                dy = image.y + image.height - y2
              } else {
                document
                  .getElementById(`sw${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
                document
                  .getElementById(`se${designIndex}`)
                  ?.setAttribute(
                    'y',
                    (image.y + height - cornerWidth).toFixed(2)
                  )
              }

              selectedElement.setAttribute(
                'width',
                (image.width - dx).toFixed(2)
              )
              selectedElement.setAttribute(
                'height',
                (image.height - dy).toFixed(2)
              )
              break
          }
        }
      }
    }
  }

  const endDrag = () => {
    let index = -1
    if (selectedElement) {
      const printLocationIndex = selectedElement.getAttribute(
        'data-printlocationindex'
      )
      index = printLocationIndex ? parseInt(printLocationIndex) : -1

      if (index !== -1) {
        const designIndex = parseInt(selectedElement.id.split('image')[1])

        const bBox = selectedElement.getBoundingClientRect()
        const imageX = (bBox.x - offset.CTMe) / offset.CTMa
        const imageY = (bBox.y - offset.CTMf) / offset.CTMa
        const width = bBox.width / offset.CTMa
        const height = bBox.height / offset.CTMa

        if (
          selectedDesign &&
          setSelectedDesign &&
          tempDesigns &&
          setTempDesigns &&
          index !== -1
        ) {
          const designData = {
            ...tempDesigns[designIndex],
            origin: {
              x: Math.round(imageX - printLocations[index].coordinateMap[0].x),
              y: Math.round(imageY - printLocations[index].coordinateMap[0].y),
            },
            w: Math.round(width),
            h: Math.round(height),
          }
          setSelectedDesign(designData)

          const newTempDesigns = tempDesigns.splice(0)
          newTempDesigns[designIndex] = designData
          setTempDesigns(newTempDesigns)
        }
      }
    }

    setSelectedElement(null)
    setSelectedCorner(null)
  }

  const SVGref = useRef<SVGSVGElement>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        SVGref.current &&
        !SVGref.current.contains(event.target) &&
        !event.target.classList.contains('uploadBox') &&
        !event.target.classList.contains('layer') &&
        !event.target.classList.contains('addOn') &&
        event.target.tagName !== 'path' &&
        event.target.tagName !== 'image' &&
        event.target.tagName !== 'BUTTON' &&
        event.target.tagName !== 'svg' &&
        event.target.dataset.tourElem !== 'right-arrow'
      ) {
        // console.log(event.target)
        if (selectedDesign && setSelectedDesign)
          setSelectedDesign({
            ...selectedDesign,
            designIndex: -2,
            addOn: null,
            addOnIndex: -1,
          })
        if (setSelectedPrintLocationIndex) setSelectedPrintLocationIndex(-1)
      }
    }

    if (isActiveView) document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [
    SVGref,
    isActiveView,
    selectedDesign,
    setSelectedDesign,
    setSelectedPrintLocationIndex,
  ])
  const uid = uuid()

  const [modalOpen, setModalOpen] = useState<
    { id: string; pL: TPrintLocation; pLI: number } | undefined
  >(undefined)

  return (
    <>
      <DesignModal
        open={modalOpen}
        setOpen={setModalOpen}
        theme={props.theme}
        selectedDesign={selectedDesign as TTempDesign}
        setSelectedDesign={
          setSelectedDesign as React.Dispatch<
            React.SetStateAction<TTempDesign | null>
          >
        }
        tempDesigns={tempDesigns as TTempDesign[]}
        setTempDesigns={
          setTempDesigns as React.Dispatch<React.SetStateAction<TTempDesign[]>>
        }
        product={props.product as TProduct}
        view={props.view as TView}
        selectedAddOn={props.selectedAddOn}
        selectedAddOnIndex={props.selectedAddOnIndex}
      />
      <svg
        id="mockup"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={endDrag}
        onMouseLeave={endDrag}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={endDrag}
        width={width ? width : '100%'}
        height={height ? height : isActiveView ? '500px' : 'unset'}
        viewBox={`0 0 ${boundingBox[3].x + 20} ${boundingBox[3].y + 20}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="10"
              dy="10"
              stdDeviation="10"
              flood-color="rgba(0,0,0,1)"
            />
          </filter>

          <clipPath id={uid + '_' + props.view.id}>
            <path d={base} />
          </clipPath>
          {printLocations &&
            printLocations.map(
              (
                printLocationCoordinateMap: TPrintLocationCoordinateMap,
                index: number
              ) => {
                const x1 = printLocationCoordinateMap.coordinateMap[0].x
                const x2 = printLocationCoordinateMap.coordinateMap[3].x
                const y1 = printLocationCoordinateMap.coordinateMap[0].y
                const y2 = printLocationCoordinateMap.coordinateMap[3].y
                return (
                  <clipPath
                    key={uid + '_' + index}
                    id={`printLocation${uid + '_' + index}`}
                  >
                    <path d={`M${x1} ${y1} H${x2} V${y2} H${x1} V${y1}`} />
                  </clipPath>
                )
              }
            )}
        </defs>
        <g
          style={{
            filter: 'url(#shadow)',
          }}
        >
          <path fill={'rgba(0,0,0,0.3)'} d={base} />
        </g>
        <g
          ref={SVGref}
          style={{
            clipPath: `url(#${uid + '_' + props.view.id}`,
          }}
        >
          <rect width="100%" height="100%" fill={color} />
          {printLocations &&
            printLocations
              .filter(pL => {
                if (props.addOnsByLocation) {
                  const allAvailablePrintLocations =
                    props.addOnsByLocation.flatMap(aOBL => aOBL.location.id)
                  return (
                    allAvailablePrintLocations.includes(pL.printLocation.id) &&
                    pL.printLocation.available
                  )
                } else {
                  return true
                }
              })
              .map(
                (
                  printLocationCoordinateMap: TPrintLocationCoordinateMap,
                  index: number
                ) => {
                  const x1 = printLocationCoordinateMap.coordinateMap[0].x
                  const x2 = printLocationCoordinateMap.coordinateMap[3].x
                  const y1 = printLocationCoordinateMap.coordinateMap[0].y
                  const y2 = printLocationCoordinateMap.coordinateMap[3].y

                  const isUploaded =
                    (tempDesigns?.length &&
                      tempDesigns?.findIndex(
                        (design: TTempDesign) =>
                          design.printLocation.id ===
                          printLocationCoordinateMap.printLocation.id
                      ) !== -1) ||
                    (designs?.length &&
                      designs?.findIndex(
                        (design: TDesign) =>
                          design.printLocation.id ===
                          printLocationCoordinateMap.printLocation.id
                      ) !== -1)

                  return (
                    <Fragment key={index}>
                      {isDesignStudio &&
                        tempDesigns &&
                        !isUploaded &&
                        isActiveView &&
                        printLocationCoordinateMap.printLocation.name !==
                          SLEEVE_CUSTOMIZATION && (
                          <g>
                            <rect
                              id={`upload${index}`}
                              className="upload"
                              x={x1}
                              y={y1}
                              width={x2 - x1}
                              height={y2 - y1}
                              fill="transparent"
                              cursor="pointer"
                              onClick={() =>
                                setModalOpen({
                                  id: `upload-click-me-${props.view.id}-${index}`,
                                  pL: printLocationCoordinateMap.printLocation,
                                  pLI: index,
                                })
                              }
                              style={
                                isActiveView && showPrintLocations
                                  ? {
                                      stroke: `${
                                        lightOrDark(color) === 'light'
                                          ? 'black'
                                          : 'white'
                                      }`,
                                      strokeWidth: '2px',
                                      strokeDasharray:
                                        index === selectedPrintLocationIndex
                                          ? 'none'
                                          : '7, 7',
                                    }
                                  : { display: 'none' }
                              }
                            ></rect>
                            {x2 - x1 > 200 ? (
                              <text
                                style={{
                                  fill:
                                    lightOrDark(color) === 'light'
                                      ? 'black'
                                      : 'white',
                                }}
                                x={x1 + 20}
                                y={y2 - 20}
                                fontFamily={'Helvetica'}
                                fontSize={30}
                              >
                                Click to Upload
                              </text>
                            ) : (
                              <text
                                style={{
                                  fill:
                                    lightOrDark(color) === 'light'
                                      ? 'black'
                                      : 'white',
                                }}
                                x={x1 + 20}
                                y={y2 - 20}
                                fontFamily={'Helvetica'}
                                fontSize={30}
                              >
                                Click
                              </text>
                            )}
                          </g>
                        )}
                      {isActiveView &&
                        isSleeveCustomization &&
                        printLocationCoordinateMap.printLocation.name ===
                          SLEEVE_CUSTOMIZATION && (
                          <text
                            x={x1}
                            y={y2}
                            fontSize="xxx-large"
                            id={`sleeveText`}
                            style={{ transformBox: 'fill-box' }}
                            transform={`rotate(${printLocationCoordinateMap.rotate})`}
                          >
                            Name
                          </text>
                        )}
                      {tempDesigns &&
                        tempDesigns.map(
                          (design: TTempDesign, designIndex: number) =>
                            design.printLocation.id ===
                              printLocationCoordinateMap.printLocation.id &&
                            designIndex !== selectedDesign?.designIndex && (
                              <g
                                key={designIndex}
                                style={{
                                  clipPath: `url(#printLocation${index}`,
                                }}
                              >
                                <image
                                  id={`image${designIndex}`}
                                  width={design.w}
                                  height={design.h}
                                  className={
                                    isActiveView && isDesignStudio
                                      ? 'draggable'
                                      : ''
                                  }
                                  x={x1 + design.origin.x}
                                  y={y1 + design.origin.y}
                                  href={
                                    design.file.thumbnail || design.file.url
                                  }
                                  cursor="pointer"
                                  data-designindex={designIndex}
                                  data-printlocationindex={index}
                                />
                              </g>
                            )
                        )}
                      {selectedDesign &&
                        tempDesigns &&
                        tempDesigns.map(
                          (design: TTempDesign, designIndex: number) =>
                            design.printLocation.id ===
                              printLocationCoordinateMap.printLocation.id && (
                              <g
                                key={design.file.id}
                                style={{
                                  clipPath: `url(#printLocation${index}`,
                                }}
                                display={
                                  selectedDesign.designIndex === designIndex
                                    ? 'block'
                                    : 'none'
                                }
                              >
                                <image
                                  id={`selectedimage${designIndex}`}
                                  width={design.w}
                                  height={design.h}
                                  className={'draggable'}
                                  x={x1 + design.origin.x}
                                  y={y1 + design.origin.y}
                                  href={
                                    design.file.thumbnail || design.file.url
                                  }
                                  cursor="move"
                                  data-designindex={design.designIndex}
                                  data-printlocationindex={index}
                                />
                                <rect
                                  id={`nw${design.designIndex}`}
                                  className="resize"
                                  width={cornerWidth}
                                  height={cornerWidth}
                                  fill="black"
                                  stroke="white"
                                  cursor="nw-resize"
                                  x={x1 + design.origin.x}
                                  y={y1 + design.origin.y}
                                />
                                <rect
                                  id={`ne${design.designIndex}`}
                                  className="resize"
                                  width={cornerWidth}
                                  height={cornerWidth}
                                  fill="black"
                                  stroke="white"
                                  cursor="ne-resize"
                                  x={
                                    x1 +
                                    design.origin.x +
                                    design.w -
                                    cornerWidth
                                  }
                                  y={y1 + design.origin.y}
                                />
                                <rect
                                  id={`sw${design.designIndex}`}
                                  className="resize"
                                  width={cornerWidth}
                                  height={cornerWidth}
                                  fill="black"
                                  stroke="white"
                                  cursor="sw-resize"
                                  x={x1 + design.origin.x}
                                  y={
                                    y1 +
                                    design.origin.y +
                                    design.h -
                                    cornerWidth
                                  }
                                />
                                <rect
                                  id={`se${design.designIndex}`}
                                  className="resize"
                                  width={cornerWidth}
                                  height={cornerWidth}
                                  fill="black"
                                  stroke="white"
                                  cursor="se-resize"
                                  x={
                                    x1 +
                                    design.origin.x +
                                    design.w -
                                    cornerWidth
                                  }
                                  y={
                                    y1 +
                                    design.origin.y +
                                    design.h -
                                    cornerWidth
                                  }
                                />
                              </g>
                            )
                        )}
                      {designs &&
                        designs.map(
                          (design: TDesign, designIndex: number) =>
                            design.printLocation.id ===
                              printLocationCoordinateMap.printLocation.id && (
                              <g key={designIndex}>
                                <image
                                  width={design.w}
                                  height={design.h}
                                  x={x1 + design.origin.x}
                                  y={y1 + design.origin.y}
                                  href={
                                    design.file.thumbnail || design.file.url
                                  }
                                />
                              </g>
                            )
                        )}
                      {previewURL &&
                        index ===
                          printLocations.findIndex(
                            pL =>
                              props.addOnsByLocation &&
                              pL.printLocation.id ===
                                (previewAddOnByLocation?.location.id ||
                                  props.addOnsByLocation[0].location.id)
                          ) && (
                          <g>
                            <image
                              width={x2 - x1}
                              height={y2 - y1}
                              x={x1}
                              y={y1}
                              href={previewURL}
                            />
                          </g>
                        )}
                    </Fragment>
                  )
                }
              )}
          <image
            width={boundingBox[3].x}
            height={boundingBox[3].y}
            href={texture}
            pointerEvents="none"
          />
        </g>
      </svg>
    </>
  )
}

const DesignModal = ({
  theme,
  open,
  setOpen,
  selectedDesign,
  setSelectedDesign,
  tempDesigns,
  setTempDesigns,
  product,
  view,
  selectedAddOn,
  selectedAddOnIndex,
}: {
  theme: ITheme
  open: { id: string; pL: TPrintLocation; pLI: number } | undefined
  setOpen: (
    open: { id: string; pL: TPrintLocation; pLI: number } | undefined
  ) => void
  selectedDesign: TTempDesign
  setSelectedDesign: (selectedDesign: TTempDesign | null) => void
  tempDesigns: TTempDesign[]
  setTempDesigns: (tempDesigns: TTempDesign[]) => void
  product: TProduct
  view: TView
  selectedAddOn?: TAddOn
  selectedAddOnIndex?: number
}) => {
  const containsPrintLocation = (
    printLocation: TPrintLocation,
    location: TPrintLocation
  ) => {
    return location.id === printLocation.id
  }
  const { data: userImages, refetch, loading } = useQuery(GET_FILES)

  const handleUpload = (
    view: TView,
    printLocation: TPrintLocation,
    printLocationIndex: number,
    File: any
  ) => {
    console.log('handleUpload', File, view, printLocation, printLocationIndex)
    const designIndex =
      selectedDesign.file.id !== '' && selectedDesign.designIndex >= 0
        ? selectedDesign.designIndex + 1
        : tempDesigns.length
    const firstAddOnIndex = product?.addOnsByLocation.findIndex(
      (addOnByLocation: TAddOnByLocation) =>
        containsPrintLocation(printLocation, addOnByLocation.location)
    )
    const firstAddOn = product?.addOnsByLocation[firstAddOnIndex]?.addOn
    // console.log(product.addOnsByLocation, firstAddOn, firstAddOnIndex)

    const designData: TTempDesign = {
      designIndex: designIndex,
      addOn: selectedAddOn || firstAddOn,
      addOnIndex: selectedAddOnIndex || firstAddOnIndex,
      file: File,
      view: view,
      printLocation: printLocation,
      printLocationIndex: printLocationIndex,
      origin: { x: 0, y: 0 },
      w:
        view.printLocations[printLocationIndex].coordinateMap[3]?.x -
        view.printLocations[printLocationIndex].coordinateMap[0]?.x,
      h:
        view.printLocations[printLocationIndex].coordinateMap[3]?.y -
        view.printLocations[printLocationIndex].coordinateMap[0]?.y,
    }
    setSelectedDesign(designData)

    const tempDesignsData = tempDesigns.splice(0)
    tempDesignsData.splice(designIndex, 0, designData)
    for (let i = designIndex; i < tempDesignsData.length; i++) {
      tempDesignsData[i].designIndex = i
    }
    setTempDesigns(tempDesignsData)
  }

  return (
    <>
      {open ? (
        <M.Modal>
          <Heading element="h4" color={theme.colors.text.default}>
            Upload or Select an Image
          </Heading>
          <B.Exit onClick={() => setOpen(undefined)}>X</B.Exit>
          <div style={{ paddingTop: '20px' }}>
            <B.SubtleButton
              onClick={() => {
                setOpen(undefined)
                document.getElementById(open.id)?.click()
              }}
            >
              Upload
            </B.SubtleButton>
          </div>

          {!loading ? (
            userImages && (
              <MiniGallery
                numImages={6}
                handleImage={(File: TFile) => {
                  handleUpload(view, open.pL, open.pLI, File)
                  setOpen(undefined)
                }}
                userImages={userImages.me.files}
              />
            )
          ) : (
            <div style={{ paddingTop: '20px' }}>
              <Loader />
            </div>
          )}
        </M.Modal>
      ) : null}
      <button hidden id="refetchGallery" onClick={() => refetch()} />
    </>
  )
}

export default withTheme(Mockup)
