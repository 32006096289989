import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

// Styles
import { Text } from '@sweaterplanet/nucleus-style'
import {
  Nav,
  NavLinks,
  NavLink,
  NavLogo,
  VerticalNavLinks,
  NavLogoImg,
} from 'styles/lib/nav'

// Routes
import * as USER_ROUTES from 'routes/user'
import * as ADMIN_ROUTES from 'routes/admin'

// Types
import { AppState } from 'store/store'
import { UserState } from 'store/reducers/userReducer'
import { Role } from 'types/User'

// Components
import CartNav from './CartNav'
import { SignOut } from 'components/Auth'
import DarkModeSwitch from 'components/DarkModeSwitch'

// Icons
import { faBars, faUser, faTimes } from '@fortawesome/free-solid-svg-icons'
import Logo from 'static/logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Title from 'static/title'

interface NavProps {
  user: UserState
  whitelabelNav?: boolean
  whitelabelWebsite?: string
  whitelabelLogo?: string
  whitelabelStoreId?: string
}

const MobileNav: React.FC<NavProps> = props => {
  const [hamburgerToggledOn, setHamburgerToggledOn] = useState<boolean>(false)
  const {
    user: { authenticated, credentials },
    whitelabelNav,
    whitelabelWebsite,
    whitelabelLogo,
    whitelabelStoreId,
  } = props

  return (
    <>
      <Nav>
        <NavLinks>
          <NavLink>
            {whitelabelNav && whitelabelLogo ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={whitelabelWebsite}
              >
                <NavLogoImg
                  src={whitelabelLogo}
                  alt={whitelabelWebsite}
                  width={'100px'}
                  height={'100px'}
                />
              </a>
            ) : (
              <NavLogo>
                <Logo />
              </NavLogo>
            )}
          </NavLink>
        </NavLinks>
        <NavLinks>
          {hamburgerToggledOn ? (
            <NavLink onClick={() => setHamburgerToggledOn(false)}>
              <FontAwesomeIcon size={'3x'} icon={faTimes as any} />
            </NavLink>
          ) : (
            <NavLink onClick={() => setHamburgerToggledOn(true)}>
              <FontAwesomeIcon size={'3x'} icon={faBars as any} />
            </NavLink>
          )}
        </NavLinks>
      </Nav>
      {hamburgerToggledOn && (
        <DropdownNav
          authenticated={authenticated}
          credentials={credentials}
          whitelabelNav={whitelabelNav}
          whitelabelStoreId={whitelabelStoreId}
        />
      )}
    </>
  )
}

interface IDropdownNavProps {
  authenticated: boolean
  credentials?: { name?: string; role: Role; retailLocation?: any }
  whitelabelWebsite?: string
  whitelabelNav?: boolean
  whitelabelStoreId?: string
  isStore?: boolean
}

const DropdownNav: React.FC<IDropdownNavProps> = props => {
  const history = useHistory()
  return (
    <VerticalNavLinks>
      {(props.credentials?.role === Role.ADMIN ||
        props.credentials?.role === Role.MANAGER) && (
        <NavLink>
          <Text>
            <Link to={ADMIN_ROUTES.ADMIN}>Admin</Link>
          </Text>
        </NavLink>
      )}
      <NavLink>
        <Text>
          <Link
            to={
              props.whitelabelStoreId
                ? `${USER_ROUTES.STORE}/${props.whitelabelStoreId}`
                : USER_ROUTES.PRODUCTS
            }
          >
            Products
          </Link>
        </Text>
      </NavLink>
      {!(
        history.location.pathname.includes(USER_ROUTES.STORE) ||
        props.whitelabelStoreId
      ) && (
        <NavLink>
          <Text>
            <a rel="noopener noreferrer" href={USER_ROUTES.GALLERY}>
              Gallery
            </a>
          </Text>
        </NavLink>
      )}
      {/* {!(
        history.location.pathname.includes(USER_ROUTES.STORE) ||
        props.whitelabelStoreId
      ) && (
        <NavLink>
          <Text>
            <Link to={USER_ROUTES.ABOUT}>About</Link>
          </Text>
        </NavLink>
      )} */}
      {!!props.credentials?.retailLocation && (
        <NavLink>
          <Text>
            <a rel="noopener noreferrer" href={USER_ROUTES.COLLECTIONS}>
              Collections
            </a>
          </Text>
        </NavLink>
      )}
      {/*<NavLink>*/}
      {/*  <Text>*/}
      {/*    <Link to={USER_ROUTES.CATALOG}>Catalog</Link>*/}
      {/*  </Text>*/}
      {/*</NavLink>*/}
      {props.authenticated &&
      (props.credentials?.role === Role.ADMIN ||
        props.credentials?.role === Role.USER ||
        props.credentials?.role === Role.MANAGER) ? (
        <>
          <NavLink>
            <Text>
              <Link to={USER_ROUTES.ACCOUNT}>
                <FontAwesomeIcon icon={faUser as any} />{' '}
                {props.credentials?.name}
              </Link>
            </Text>
          </NavLink>
          <NavLink>
            <SignOut />
          </NavLink>
        </>
      ) : (
        <>
          <NavLink>
            <Text>
              <Link to={USER_ROUTES.SIGN_IN}>Sign In</Link>
            </Text>
          </NavLink>
          <NavLink>
            <Text>
              <Link to={USER_ROUTES.SIGN_UP}>Sign Up</Link>
            </Text>
          </NavLink>
        </>
      )}
      {props.authenticated && (
        <NavLink>
          <CartNav />
        </NavLink>
      )}
      <NavLink>
        <DarkModeSwitch />
      </NavLink>
    </VerticalNavLinks>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

export default connect(mapStateToProps)(MobileNav)
