import { TUser } from './User'

// User Actions
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const SET_GUEST = 'SET_GUEST'
export const SET_ADMIN = 'SET_ADMIN'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SET_CART_NUMBER = 'SET_CART_NUMBER'

export interface SET_AUTHENTICATED {
  type: typeof SET_AUTHENTICATED
}

export interface SET_UNAUTHENTICATED {
  type: typeof SET_UNAUTHENTICATED
}

export interface SET_USER {
  type: typeof SET_USER
  payload: TUser
}

export interface SET_GUEST {
  type: typeof SET_GUEST
  payload: TUser
}

export interface SET_ADMIN {
  type: typeof SET_ADMIN
}

export interface ADD_TO_CART {
  type: typeof ADD_TO_CART
}

export interface REMOVE_FROM_CART {
  type: typeof REMOVE_FROM_CART
}

export interface SET_CART_NUMBER {
  type: typeof SET_CART_NUMBER
  payload: number
}

export type UserActionTypes =
  | SET_AUTHENTICATED
  | SET_UNAUTHENTICATED
  | SET_USER
  | SET_ADMIN
  | ADD_TO_CART
  | REMOVE_FROM_CART
  | SET_CART_NUMBER

// Data Actions

export const EXAMPLE = 'EXAMPLE'

export interface EXAMPLE {
  type: typeof EXAMPLE
}

export type DataActionTypes = EXAMPLE

// Dark Mode Actions

export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SET_LIGHT_MODE = 'SET_LIGHT_MODE'

export interface SET_DARK_MODE {
  type: typeof SET_DARK_MODE
}

export interface SET_LIGHT_MODE {
  type: typeof SET_LIGHT_MODE
}

export type ThemeActionTypes = SET_LIGHT_MODE | SET_DARK_MODE

// App Actions
export type AppActions = UserActionTypes | DataActionTypes | ThemeActionTypes
