import { ITheme } from 'types/Theme'

const theme: ITheme = {
  dark: true,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    contrast: '#E2E6E9',
    purple: {
      default: '#765ED4',
      alt: '#432AA2',
    },
    page: {
      background: '#0F1215',
    },
    text: {
      default: 'paper',
      danger: '#FF5555',
      warning: '#FFEE55',
      info: '#55FFFF',
    },
    nav: {
      background: '#0F1215',
      text: '#DDD',
    },
    button: {
      background: '#05998C',
      text: '#FFF',
    },
    card: {
      background: 'slate',
      background2: '#36414a',
      text: '#DDD',
    },
    link: {
      text: '#CCC',
      hover: '#888',
    },
    table: {
      background: '#36414a',
      text: '#E2E6E9',
      alt: '#222',
    },
    loader: '#FFF',
    modal: {
      background: '#36414a',
      text: '#E2E6E9',
    },
    tooltip: {
      background: '#222',
      text: '#EEE',
    },
  },
  fonts: {
    primary: 'Helvetica, sans-serif',
    secondary: 'Freight, serif',
    monospaced: 'Courier, monospaced',
  },
  sizing: {
    xs: {
      padding: 15,
      h1: 20,
      h2: 15,
      h3: 10,
      p: 10,
      a: 12,
    },
    sm: {
      padding: 20,
      h1: 25,
      h2: 20,
      h3: 15,
      p: 11,
      a: 13,
    },
    md: {
      padding: 20,
      h1: 30,
      h2: 20,
      h3: 15,
      p: 11,
      a: 13,
    },
    lg: {
      padding: 30,
      h1: 20,
      h2: 18,
      h3: 15,
      p: 12,
      a: 14,
    },
    xl: {
      padding: 30,
      h1: 25,
      h2: 20,
      h3: 18,
      p: 13,
      a: 15,
    },
  },
}

export default theme
