import { gql } from 'apollo-boost'

export default gql`
  query getSomeStores($searches: [String!], $offset: Int, $limit: Int) {
    getSomeStores(searches: $searches, offset: $offset, limit: $limit) {
      id
      name
      description
      isPublic
      managers {
        id
      }
      logo {
        id
        filename
        url
      }
      bannerImg {
        id
        filename
        url
      }
      moodboard {
        id
        filename
        url
      }
      products {
        id
        OrderItem {
          subtotal
          createdAt
          Order {
            id
            paid
            payInStoreLocation {
              id
            }
          }
        }
      }
    }
  }
`
