import styled from 'styled-components'

interface ICardProps {
  width?: string
  height?: string
  minWidth?: string
  minHeight?: string
  maxWidth?: string
  maxHeight?: string
}

export const card = styled.div<ICardProps>`
  width: ${p => (p.width ? p.width : 'unset')};
  height: ${p => (p.height ? p.height : 'unset')};
  min-width: ${p => (p.minWidth ? p.minWidth : '200px')};
  min-height: ${p => (p.minHeight ? p.minHeight : 'unset')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : 'unset')};
  max-height: ${p => (p.maxHeight ? p.maxHeight : 'unset')};
  border-radius: 25px;
  background: ${p => p.theme.colors.card.background};
  color: ${p => p.theme.colors.card.text};
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    margin: ${p => p.theme.sizing.xs.padding / 2}px;
    padding: ${p => p.theme.sizing.xs.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    margin: ${p => p.theme.sizing.sm.padding / 2}px;
    padding: ${p => p.theme.sizing.sm.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    margin: ${p => p.theme.sizing.md.padding / 2}px;
    padding: ${p => p.theme.sizing.md.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    margin: ${p => p.theme.sizing.lg.padding / 2}px;
    padding: ${p => p.theme.sizing.lg.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    margin: ${p => p.theme.sizing.xl.padding / 2}px;
    padding: ${p => p.theme.sizing.xl.padding}px;
  }
`
