import React from 'react'
import { ADMIN_COUPONS } from 'routes/admin'
import DELETE_COUPON from 'gql/DELETE_COUPON'
import GET_COUPONS from 'gql/GET_SOME_COUPONS'
import CouponCreateForm from './CouponCreateForm'
import DataTable from '../Admin/lib/DataTable'

interface ICouponTable {}

const CouponTable: React.FC<ICouponTable> = props => {
  return (
    <>
      <DataTable
        getDataQuery={GET_COUPONS}
        getDataQueryName="getSomeCoupons"
        deleteDataQuery={DELETE_COUPON}
        headers={[
          'Code',
          'Name',
          'Status',
          'Type',
          'Value',
          'Uses',
          'Valid Until',
        ]}
        properties={[
          'id',
          'name',
          'available',
          'type',
          'value',
          'maxUses',
          'validUntil',
        ]}
        ROUTE={ADMIN_COUPONS}
        title="Coupons"
      >
        {createFormProps => <CouponCreateForm {...createFormProps} />}
      </DataTable>
    </>
  )
}

export default CouponTable
