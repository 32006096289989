import React, { useState } from 'react'
import { TTag } from 'types'
import { TTagID } from 'types/Tag'
import { ButtonStyle as B, DivLayout as D, FormStyle as F } from 'styles'
import { useMutation } from '@apollo/react-hooks'
import UPSERT_TAG_BY_CONTENT from './gql/UPSERT_TAG_BY_CONTENT'
import { Error } from 'utils'

// props
interface ITagViewerProps {
  tags: TTag[]
  deleteTag?: (arg0: TTagID) => void
  newTag?: (arg0: TTagID) => void
}

const TagViewer: React.FC<ITagViewerProps> = props => {
  // state
  const [newTagActive, setNewTagActive] = useState<boolean>(false)
  const [newTagContent, setNewTagContent] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  // mutation
  const [UpsertTagByContent] = useMutation(UPSERT_TAG_BY_CONTENT, {
    variables: {
      content: newTagContent,
    },
  })

  // handles delete tag button click
  const handleOnTagDelete: (arg0: number) => void = index => {
    props.deleteTag && props.deleteTag(props.tags[index].id)
  }

  // handles new tag button click
  const handleOnNewTag = () => {
    if (props.newTag && newTagContent) {
      UpsertTagByContent()
        .then(tag => {
          props.newTag && props.newTag(tag.data.upsertTagByContent.id)
        })
        .catch(e => setError(e.message))
    }
  }

  // validation
  const newTagIsInvalid = newTagContent === ''

  // JSX
  return (
    <>
      <D.flex>
        {props.tags.length > 0 &&
          props.tags.map((tag, index) => (
            <B.Tag color={tag.color}>
              {tag.icon}
              {tag.content}
              {props.deleteTag && (
                <span
                  style={{ color: 'red', fontSize: 'larger' }}
                  onClick={() => handleOnTagDelete(index)}
                >
                  {' '}
                  ⓧ
                </span>
              )}
            </B.Tag>
          ))}
        {newTagActive ? (
          <D.flex>
            <F.Input
              placeholder={'some tag'}
              value={newTagContent}
              onChange={e => setNewTagContent(e.target.value)}
            />
            <B.SubtleButton disabled={newTagIsInvalid} onClick={handleOnNewTag}>
              Add
            </B.SubtleButton>
            <B.SubtleButton onClick={() => setNewTagActive(false)}>
              x
            </B.SubtleButton>
          </D.flex>
        ) : (
          props.newTag && (
            <B.SubtleButton onClick={() => setNewTagActive(true)}>
              +
            </B.SubtleButton>
          )
        )}
      </D.flex>
      {error && <Error message={error} />}
    </>
  )
}

export default TagViewer
