import { gql } from 'apollo-boost'

export default gql`
  query getSomeAvailableProducts($limit: Int, $withStores: Boolean) {
    getSomeAvailableProducts(
      limit: $limit
      withStores: $withStores
      featured: true
    ) {
      id
      name
      basePrice
      available
      isDesignable
      defaultColor
      tags {
        id
        content
        color
        icon
      }
      variantGroups {
        id
        name
        variants {
          value
        }
      }
      inventoryGroup {
        id
        name
        views {
          id
          name
          boundingBox
          base
          texture
          printLocations {
            id
            printLocation {
              id
              name
              available
              width
              height
            }
            coordinateMap
          }
        }
      }
      store {
        id
        name
      }
      thumbnail {
        url
      }
    }
  }
`
