import { gql } from 'apollo-boost'

export default gql`
  mutation confirmPayment(
    $redirect_status: String!
    $payment_intent: String!
    $orderId: ID!
    $saveCard: Boolean
    $orderNotes: String
  ) {
    confirmPayment(
      redirect_status: $redirect_status
      payment_intent: $payment_intent
      orderId: $orderId
      saveCard: $saveCard
      orderNotes: $orderNotes
    ) {
      status
      message
    }
  }
`
