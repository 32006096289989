import React from 'react'

// Types
import { ITheme } from 'types/Theme'

// Styles
import { Page, Content } from 'styles/lib/page'
import { withTheme } from 'styled-components'
import { Flex, Box, Text, Heading, Image } from '@sweaterplanet/nucleus-style'

// Components
import Navigation from 'components/Navigation'
import { YoutubeEmbed } from 'components/Section'
import { UserState } from 'store/reducers/userReducer'
import CompanyStory from 'components/Section/lib/CompanyStory'
import FoundersAndTeam from 'components/Section/lib/FoundersAndTeam'
import ContactUs from 'components/Section/lib/ContactUs'
import AboutImage from 'static/Store.jpg'

interface IAboutUsPageProps {
  user: UserState
  darkMode: boolean
  theme: ITheme
}

const AboutUs: React.FC<IAboutUsPageProps> = props => {
  const { darkMode } = props
  const { colors } = props.theme

  return (
    <Page>
      <Navigation />
      <Content>
        <Box>
          <Box padding={'0 0 20px 0'}>
            <Heading element="h2" color={colors.text.default}>
              Bossy Panda
            </Heading>
            <Text color={colors.text.default}>Made with love in Montreal</Text>
          </Box>
          <Box maxWidth="800px" padding="20px 0">
            <Image width={'100%'} src={AboutImage} />
          </Box>
          <CompanyStory
            theme={props.theme}
            darkMode={darkMode}
            title={'Quality Custom Merch'}
          />

          <ContactUs
            theme={props.theme}
            darkMode={darkMode}
            title={'Get In Touch'}
            description={
              'Want to collab? Have an issue with the website? Get in touch!'
            }
          />
        </Box>
      </Content>
    </Page>
  )
}

export default withTheme(AboutUs)
