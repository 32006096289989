import React, { useState } from 'react'
// import ManualOrderForm from './OrderForm'
import * as ADMIN_ROUTES from 'routes/admin'
import GET_SOME_ORDERS from 'gql/GET_SOME_ORDERS'
import DataTable from 'components/Admin/lib/DataTable'
import { useMutation, useQuery } from '@apollo/react-hooks'
import GET_ORDER from 'gql/GET_ORDER'
import UPDATE_FULFILLMENT_STATUS from 'gql/UPDATE_FULFILLMENT_STATUS'
import { ITheme, TAddress, TOrder, TOrderItem, TUser } from 'types'
import Mockup from '../../Mockup'
import { Error, Loader } from '../../../utils'
import { DateTime } from 'luxon'
import { Line } from 'styles/lib/hr'
import { Link } from 'react-router-dom'

import { Table } from 'styles/lib/table'

import {
  Flex,
  Heading,
  Box,
  Text,
  Card,
  Button,
} from '@sweaterplanet/nucleus-style'
// import { BILLING_STATUS, TOrderID } from '../../../types/Order'
import { withTheme } from 'styled-components'
import FULFILL_ORDER from '../../../gql/FULFILL_ORDER'
import { TOrderID } from '../../../types/Order'

interface IOrdersTable {}

export const OrdersTable: React.FC<IOrdersTable> = props => {
  return (
    <DataTable
      getDataQuery={GET_SOME_ORDERS}
      getDataQueryName="getSomeOrders"
      headers={['Mockup', 'Date', 'Customer', 'Status', 'Order', 'Total']}
      properties={[
        'mockup',
        'date',
        'owner',
        'fulfillmentStatus',
        'id',
        'price',
      ]}
      ROUTE={ADMIN_ROUTES.ADMIN_ORDERS}
      title="Orders"
    />
  )
}

interface IOrderItemViewProps {
  orderId: TOrderID
  orderItem: TOrderItem
  theme: ITheme
}

export const OrderItemView: React.FC<IOrderItemViewProps> = ({
  orderId,
  orderItem,
  theme,
}) => {
  const selectedColors = orderItem.variantSelection.map(v =>
    v.variants.filter(v => v.variantGroup.name === 'Color')
  )

  const handlePrintProviderClick = () => {}

  return (
    <Card
      margin={'1rem 0'}
      bg={theme.colors.card.background}
      padding={'1rem'}
      round
    >
      <Flex>
        <Box>
          <Heading element={'h3'} color={theme.colors.text.default}>
            {orderItem.product.name}
          </Heading>

          {/* Order Price Information */}
          <Flex margin="1rem 0" flexDirection="column">
            <Heading element="h4">Price Breakdown</Heading>
            <Text color={theme.colors.text.default}>
              Subtotal: ${orderItem.subtotal}
            </Text>
            <Text color={theme.colors.text.default}>Tax: ${orderItem.tax}</Text>
            <Text color={theme.colors.text.default}>
              Total: ${orderItem.totalPrice}
            </Text>
          </Flex>

          {/* Quantity */}
          <Flex marginBottom="1rem">
            <Text color={theme.colors.text.default}>
              Total Quantity:{' '}
              {orderItem.variantSelection
                .map(i => i.quantity)
                .reduce((total, num) => total + num, 0)}
            </Text>
          </Flex>

          {/* Inventory Id */}
          <Flex marginBottom="1rem">
            <Text color={theme.colors.text.default}>
              Inventory Group: {orderItem.product.inventoryGroup.id}
            </Text>
          </Flex>
        </Box>

        {/* Mockups */}
        <Box padding={'0 1rem'}>
          <Flex>
            {orderItem.product.inventoryGroup.views.map(v => (
              <Mockup
                view={v}
                color={
                  selectedColors && selectedColors.length > 0
                    ? selectedColors[0][0]?.value
                    : 'slate'
                }
                width="8rem"
                height="8rem"
                designs={orderItem.designs}
                isActiveView={true}
                isSleeveCustomization={
                  !!orderItem?.variantSelection[0]?.customization
                }
              />
            ))}
          </Flex>
        </Box>
      </Flex>
      <Line />
      {/* Variant Selections */}
      <Box margin={'1rem 0'}>
        <Table width={'100%'}>
          <tr>
            <th>Size</th>
            <th>Color</th>
            <th>Customization</th>
            <th>Quantity</th>
          </tr>
          {orderItem.variantSelection.map(variantSelection => (
            <tr>
              <td>
                {
                  variantSelection.variants.filter(
                    v => v.variantGroup.name === 'Size'
                  )[0]?.name
                }
              </td>
              <td>
                {
                  variantSelection.variants.filter(
                    v => v.variantGroup.name === 'Color'
                  )[0]?.name
                }
              </td>
              <td>
                {variantSelection.customization?.name
                  ? variantSelection.customization?.name
                  : 'N/A'}
              </td>
              <td>{variantSelection.quantity}</td>
            </tr>
          ))}
        </Table>
      </Box>
      <Flex margin={'1rem 0'} justifyContent={'space-between'}>
        <Link to={`${ADMIN_ROUTES.ADMIN_LOGO_MODS}/${orderItem.id}`}>
          <Button size="xs" bg={'aqua'} color={'white'}>
            Logo Mods
          </Button>
        </Link>
        <Button
          size="xs"
          bg={'blue'}
          color={'white'}
          onClick={handlePrintProviderClick}
        >
          Print Provider
        </Button>
      </Flex>
    </Card>
  )
}

interface IOrderViewProps {
  order: TOrder
  theme: ITheme
  refetch: () => any
}

export const OrderView: React.FC<IOrderViewProps> = ({
  order,
  theme,
  refetch,
}) => {
  const [fulfillmentStatus, setFulfillmentStatus] = useState<
    string | undefined
  >()

  const [
    FulfillOrder,
    { loading: fulfilledOrderLoading, error: fulfilledOrderError },
  ] = useMutation(FULFILL_ORDER, {
    variables: {
      orderId: order.id,
    },
  })

  const [
    updateFulfillmentStatus,
    { loading: fulfillmentLoading, error: fulfillmentError },
  ] = useMutation(UPDATE_FULFILLMENT_STATUS, {
    variables: {
      orderId: order.id,
      fulfillmentStatus: fulfillmentStatus,
    },
  })

  // const handleCapture = async () => {
  //   const { data } = await captureAuthPayment()
  //   data && refetch()
  // }

  const handleChangeStatus = async () => {
    const { data } = await updateFulfillmentStatus()
    data && refetch()
  }
  const handleFulfillOrder = async () => {
    const { data } = await FulfillOrder()
    data && refetch()
  }

  return (
    <Card>
      {fulfillmentError && <Error message={fulfillmentError.message} />}

      {fulfillmentLoading && <Loader />}
      <Flex marginBottom="2rem">
        <Heading element={'h2'} color={theme.colors.text.default}>
          Order Details
        </Heading>
      </Flex>
      <Flex marginBottom="2rem">
        <Text color={theme.colors.text.default}>
          Send order to Bossy Panda{' '}
          <Button
            color={'white'}
            bg={'green'}
            size={'xs'}
            onClick={handleFulfillOrder}
          >
            Fulfill Order
          </Button>
          {fulfilledOrderLoading && <Loader />}
          {fulfilledOrderError && (
            <Error message={fulfilledOrderError.message} />
          )}
        </Text>
      </Flex>
      <Flex marginBottom="2rem">
        <Text color={theme.colors.text.default}>
          Fulfillment:{' '}
          {order.fulfillmentStatus === 'APPROVED'
            ? 'Sent to Bossy Panda '
            : order.fulfillmentStatus}{' '}
          <select onChange={e => setFulfillmentStatus(e.target.value)}>
            <option value="" selected>
              Change Status
            </option>
            <option value={'PROCESSING'}>Processing</option>
            <option value={'APPROVED'}>Sent to Bossy Panda</option>
            <option value={'PRINTING'}>Printing</option>
            <option value={'PRINTED'}>Printed</option>
            <option value={'SHIPPED'}>Shipped</option>
            <option value={'DELIVERED'}>Delivered</option>
          </select>
          {fulfillmentStatus &&
            fulfillmentStatus !== order.fulfillmentStatus && (
              <Button
                onClick={handleChangeStatus}
                size={'xs'}
                color={'white'}
                bg={'red'}
              >
                Change
              </Button>
            )}
        </Text>
      </Flex>
      <Flex marginBottom="2rem">
        {order.pickupLocation && (
          <Text color={theme.colors.text.default}>
            Pickup Location: {order.pickupLocation.name}
          </Text>
        )}
      </Flex>
      <Flex marginBottom="2rem">
        {order.payInStoreLocation && (
          <Text color={theme.colors.text.default}>
            Pay In Store Location: {order.payInStoreLocation.name}
          </Text>
        )}
      </Flex>
      <Flex marginBottom="2rem">
        {order.coupon ? (
          <Text color={theme.colors.text.default}>
            Coupon: {order.coupon.id} for {order.coupon.value}% off
          </Text>
        ) : (
          <Text color={theme.colors.text.default}>Coupon: N/A</Text>
        )}
      </Flex>
      <Flex marginBottom="2rem">
        <Text bold color={theme.colors.text.default}>
          Order Total: ${order.price}
        </Text>
      </Flex>
      {order.orderItems.map((item: TOrderItem) => {
        return (
          <OrderItemView orderItem={item} theme={theme} orderId={order.id} />
        )
      })}
    </Card>
  )
}

interface ICustomerInfoProps {
  customer: TUser
  address: TAddress
  theme: ITheme
}

export const CustomerInfo: React.FC<ICustomerInfoProps> = ({
  customer,
  address,
  theme,
}) => {
  return (
    <Card padding={'2rem'} bg={theme.colors.card.background} round>
      <Heading element={'h2'} color={theme.colors.text.default}>
        Customer
      </Heading>
      <Text color={theme.colors.text.default}>{customer.name}</Text>
      <Text color={theme.colors.text.default}>{customer.role}</Text>
      <Line />
      <Heading element={'h3'} color={theme.colors.text.default}>
        Order Contact
      </Heading>
      <Text color={theme.colors.text.default}>{customer.email}</Text>
      <Text color={theme.colors.text.default}>{address.phone}</Text>
      <Line />
      <Heading element={'h3'} color={theme.colors.text.default}>
        Shipping Address
      </Heading>
      <Text color={theme.colors.text.default}>
        {address.firstName} {address.lastName}
      </Text>
      <Text color={theme.colors.text.default}>{address.address1}</Text>
      <Text color={theme.colors.text.default}>{address.address2}</Text>
      <Text color={theme.colors.text.default}>
        {address.city} {address.state} {address.postCode}
      </Text>
      <Text color={theme.colors.text.default}>{address.country}</Text>
    </Card>
  )
}

interface IOrderDashboardProps {
  match: {
    params: {
      orderId: string
    }
  }
  theme: ITheme
}

const OrderDashboard: React.FC<IOrderDashboardProps> = ({ match, theme }) => {
  const {
    loading: orderLoading,
    data: orderData,
    error: getOrderError,
    refetch: refetchOrder,
  } = useQuery(GET_ORDER, {
    variables: {
      id: match.params.orderId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const orderDateTime =
    orderData &&
    DateTime.fromMillis(parseInt(orderData.getOrder.createdAt)).toFormat('ff')

  return (
    <>
      {orderLoading && <Loader />}
      {getOrderError && <Error message={getOrderError.message} />}
      {orderData && (
        <>
          <Flex
            padding={'2rem'}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Heading element={'h4'} color={theme.colors.text.default}>
              Order # {orderData.getOrder.id}
            </Heading>
            <Heading element={'h3'} color={theme.colors.text.default}>
              {orderDateTime}
            </Heading>
          </Flex>
          <Flex justifyContent={'space-between'} width={'100%'}>
            <OrderView
              order={orderData.getOrder}
              theme={theme}
              refetch={refetchOrder}
            />
            <CustomerInfo
              customer={orderData.getOrder.User}
              address={orderData.getOrder.shipping[0]}
              theme={theme}
            />
          </Flex>
        </>
      )}
    </>
  )
}

export default withTheme(OrderDashboard)
