import { gql } from 'apollo-boost'

export default gql`
  query getSomeCoupons($searches: [String!], $offset: Int, $limit: Int) {
    getSomeCoupons(searches: $searches, offset: $offset, limit: $limit) {
      id
      name
      available
      type
      value
      maxUses
      uses
      validFrom
      validUntil
    }
  }
`
