import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import UPSERT_SHPPING_OPTION from '../../../gql/UPSERT_SHPPING_OPTION'
import {
  ButtonStyle as B,
  FormStyle as F,
  ModalLayout as M,
} from '../../../styles'
import { Error } from '../../../utils'

interface IShippingOptionForm {
  visible: boolean
  toggle: any
  refetch?: () => any
  shippingOption?: any
}

const ShippingOptionForm: FC<IShippingOptionForm> = props => {
  const { shippingOption } = props
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [price, setPrice] = useState<number>(0)
  const [countries, setCountries] = useState<string>('["CA", "US"]')
  const [error, setError] = useState<string | null>(null)
  const [countriesValid, setCountriesValue] = useState(true)
  const [UpsertShippingOption] = useMutation(UPSERT_SHPPING_OPTION)

  const resetForm = () => {
    setName('')
    setDescription('')
    setPrice(0)
    setCountries('["CA", "US"]')
  }

  const IsJsonString = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (shippingOption) {
      setName(shippingOption.name)
      setDescription(shippingOption.description)
      setPrice(shippingOption.price)
      setCountries(JSON.stringify(shippingOption.countries))
    }
  }, [shippingOption])

  useEffect(() => {
    setCountriesValue(IsJsonString(countries))
  }, [countries])

  const handleShippingOptionCreate = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    console.log(name, description, price, JSON.parse(countries))
    event.preventDefault()
    if (shippingOption) {
      UpsertShippingOption({
        variables: {
          id: shippingOption.id,
          name,
          description,
          price,
          countries: countries !== '' && JSON.parse(countries),
        },
      })
        .then(response => {
          if (!response.errors && props.refetch) {
            props.toggle(false)
            props.refetch()
            resetForm()
          }
        })
        .catch(e => setError(e.message))
    } else {
      UpsertShippingOption({
        variables: {
          name,
          description,
          price,
          countries: countries !== '' && JSON.parse(countries),
        },
      })
        .then(response => {
          if (!response.errors && props.refetch) {
            props.toggle(false)
            props.refetch()
            resetForm()
          }
        })
        .catch(e => setError(e.message))
    }
  }
  const invalid = name === '' || !countriesValid
  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>
            {shippingOption ? 'Update Shipping Option' : 'New Shipping Option'}
          </h2>
          <B.Exit
            onClick={() => {
              resetForm()
              props.toggle(false)
            }}
          >
            X
          </B.Exit>
          <F.Form>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Description
              <F.Input
                value={description}
                type={'text'}
                placeholder={'description'}
                onChange={e => setDescription(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Price
              <F.Input
                value={price}
                type={'number'}
                placeholder={'price'}
                onChange={e => setPrice(parseInt(e.target.value))}
              />
            </F.Label>
            <text color={'red'}>
              {!countriesValid && 'countries are invalid'}
            </text>
            <F.Label>
              Countries
              <F.Input
                type={'textarea'}
                value={countries}
                placeholder={'["CA", "US"]'}
                onChange={e => setCountries(e.target.value)}
              />
            </F.Label>
            <B.Button
              disabled={invalid}
              onClick={e => handleShippingOptionCreate(e)}
            >
              {shippingOption ? 'Update' : 'Create'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
      {error && <Error message={error} />}
    </>
  )
}

export default ShippingOptionForm
