import { gql } from 'apollo-boost'

export default gql`
  query getPrintLocation($id: ID!) {
    getPrintLocation(id: $id) {
      id
      name
      width
      height
      price
      updatedAt
      tags {
        id
        color
        icon
        content
      }
      available
    }
  }
`
