import { gql } from 'apollo-boost'

export default gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      object
      billing_details
      card
      created
      customer
      livemode
      metadata
    }
  }
`
