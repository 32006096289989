import { PageLayout as P, FormStyle as F } from 'styles'
import React from 'react'

interface IOrderFromScratchProps {}

const CustomOrderScratch: React.FC<IOrderFromScratchProps> = () => {
  return (
    <P.Page>
      <P.Content>
        <h1>Custom Order</h1>
        <F.Form>
          <F.Label>
            Employee email
            <F.Input type="email" name="employee_email" />
          </F.Label>
        </F.Form>
      </P.Content>
    </P.Page>
  )
}

export default CustomOrderScratch
