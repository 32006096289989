import { gql } from 'apollo-boost'

export default gql`
  {
    me {
      id
      role
      email
      name
      phone
      company
      managerPermissions
      retailLocation {
        id
        name
      }
      orders {
        id
        createdAt
        billingStatus
        price
        paymentToken
        shippingPrice
        paid
        shipping {
          address1
          address2
          city
          state
          postCode
          country
          firstName
          lastName
          email
        }
        pickupLocation {
          id
          name
        }
        payInStoreLocation {
          id
        }
        fulfillmentStatus
        orderItems {
          subtotal
          id
          product {
            id
            name
            basePrice
            priceBreaks
            inventoryGroup {
              id
              views {
                id
                boundingBox
                texture
                base
                printLocations {
                  id
                  printLocation {
                    id
                    name
                  }
                  coordinateMap
                }
              }
            }
          }
          variantSelection {
            id
            quantity
            variants {
              id
              name
              value
              available
              price
              variantGroup {
                id
                name
              }
            }
          }
          designs {
            id
            printLocation {
              id
              name
              price
            }
            addOn {
              id
              name
              publicName
              basePrice
              priceBreaks
            }
            file {
              id
              url
              thumbnail
            }
            origin
            w
            h
          }
        }
      }
      files {
        id
        filename
        Design {
          id
        }
        StoreLogo {
          id
        }
        StoreBanner {
          id
        }
        Product {
          id
        }
        Promotion {
          id
        }
      }
      billingAccount {
        id
        address {
          id
          firstName
          lastName
          business
          email
          address1
          address2
          city
          state
          country
          postCode
          phone
        }
        name
        cc
        expMonth
        expYear
      }
      shippingAddresses {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      defaultShippingAddress {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      billingAddresses {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      defaultBillingAddress {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
    }
  }
`
