import React, { useState, useEffect } from 'react'
import { UtiliesStyle as U, FormStyle as F } from 'styles'
import { Flex, Box } from '@sweaterplanet/nucleus-style'
import { TVariant } from 'types'
import { constructColor, sortColorsByHue, TColorObj } from './color'

type TColor = { name?: string; value: string; id: string } | TVariant

interface ColorPickerProps {
  colors: any[]
  width?: string
  round?: boolean
  setColor?: React.Dispatch<React.SetStateAction<any>>
  selectedColorIndexes?: number[]
  setSelectedColorIndexes?: (colorIndexes: number[]) => void
  isMultiple?: boolean
  variantSelections?: Record<
    string,
    Record<string, { quantity: number; customizations: { name: string }[] }>
  >[]
  setVariantSelections?: (
    variantSelections: Record<
      string,
      Record<string, { quantity: number; customizations: { name: string }[] }>
    >[]
  ) => void
  initialSizeIdsWithQuantity?: Record<
    string,
    { quantity: number; customizations: { name: string }[] }
  >
  setAddToCartError?: (error: string) => void
  unavailableVariants?: TVariant[]
}

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const [tooltipVisibility, setTooltipVisibility] = useState<number>(-1)
  const {
    width,
    round,
    setColor,
    selectedColorIndexes,
    setSelectedColorIndexes,
    isMultiple,
    variantSelections,
    setVariantSelections,
    initialSizeIdsWithQuantity,
    setAddToCartError,
  } = props

  const [colors, setColors] = useState<TVariant[]>(props.colors)

  useEffect(() => {
    if (props.colors.length > 1) {
      const colorObjects: TColorObj[] = []
      props.colors.forEach((color: TVariant) => {
        colorObjects.push(constructColor(color))
      })
      setColors(
        // colorObjects.map((colorObject: TColorObj) => colorObject.variantFields)
        sortColorsByHue(colorObjects).map(
          (colorObject: TColorObj) => colorObject.variantFields
        )
      )
    } else if (props.colors.length === 1) {
      setColors(props.colors)
    }
  }, [props.colors])

  const handleColorClick = (color: any, index: number) => {
    if (setColor) setColor(color)
    if (setAddToCartError) setAddToCartError('')
    if (
      selectedColorIndexes &&
      setSelectedColorIndexes &&
      variantSelections &&
      setVariantSelections &&
      initialSizeIdsWithQuantity
    ) {
      const tempSelectedColorIndexes = [...selectedColorIndexes]
      if (isMultiple) {
        if (selectedColorIndexes.includes(index)) {
          const Index = tempSelectedColorIndexes.findIndex(
            colorIndex => colorIndex === index
          )

          tempSelectedColorIndexes.splice(Index, 1)
          setSelectedColorIndexes(tempSelectedColorIndexes)

          const tempVariantSelections = [...variantSelections]
          tempVariantSelections.splice(Index, 1)
          setVariantSelections(tempVariantSelections)
        } else {
          setSelectedColorIndexes([...tempSelectedColorIndexes, index])
          setVariantSelections([
            ...variantSelections,
            {
              [`${color.id}/${color.name}/${color.value}`]: {
                ...initialSizeIdsWithQuantity,
              },
            },
          ])
        }
      } else {
        if (selectedColorIndexes.includes(index)) {
          setSelectedColorIndexes([])
          setVariantSelections([])
        } else {
          setSelectedColorIndexes([index])
          setVariantSelections([
            {
              [`${color.id}/${color.name}/${color.value}`]: {
                ...initialSizeIdsWithQuantity,
              },
            },
          ])
        }
      }
    }
  }

  return (
    <Flex flexWrap="wrap" flexDirection="row" justifyContent="flex-start">
      {colors.map((color: TColor, index: number) => (
        <>
          {!props.unavailableVariants?.map(uV => uV.id)?.includes(color.id) && (
            <Box
              key={index}
              padding="3px"
              margin="2px"
              display="flex"
              border={
                selectedColorIndexes && selectedColorIndexes.includes(index)
                  ? `1px solid ${color.value}`
                  : '1px solid transparent'
              }
            >
              <F.Color
                width={width}
                round={round}
                onMouseEnter={() => setTooltipVisibility(index)}
                onMouseLeave={() => setTooltipVisibility(-1)}
                type="button"
                color={color.value}
                onClick={() => handleColorClick(color, index)}
              />
              {color.name && (
                <U.Tooltip visible={tooltipVisibility === index}>
                  {color.name}
                </U.Tooltip>
              )}
            </Box>
          )}
        </>
      ))}
    </Flex>
  )
}

export default ColorPicker
