import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const Label = styled.label`
  color: ${p => p.theme.colors.contrast};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Input = styled.input<{ width?: string }>`
  width: ${p => (p.width ? p.width : 'unset')};
  outline: none;
  background: none;
  color: ${p => p.theme.colors.contrast};
  border: none;
  border-bottom: 1px solid ${p => p.theme.colors.contrast};
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    font-size: ${p => p.theme.sizing.xs.a}px;
    padding: ${p => p.theme.sizing.xs.a}px;
    margin: ${p => p.theme.sizing.xs.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    font-size: ${p => p.theme.sizing.sm.a}px;
    padding: ${p => p.theme.sizing.sm.a}px;
    margin: ${p => p.theme.sizing.sm.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    font-size: ${p => p.theme.sizing.md.a}px;
    padding: ${p => p.theme.sizing.md.a}px;
    margin: ${p => p.theme.sizing.md.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    font-size: ${p => p.theme.sizing.lg.a}px;
    padding: ${p => p.theme.sizing.lg.a}px;
    margin: ${p => p.theme.sizing.lg.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    font-size: ${p => p.theme.sizing.xl.a}px;
    padding: ${p => p.theme.sizing.xl.a}px;
    margin: ${p => p.theme.sizing.xl.padding / 4}px 0;
  }
`

interface ICheckoutInputProps {
  width?: string
  error?: boolean
}

export const checkoutInput = styled.input<ICheckoutInputProps>`
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  background-color: ${p =>
    p.error
      ? p.theme.colors.card.background2
      : p.theme.colors.button.background};
  color: ${p => 'white'};
  width: ${p => (p.width ? p.width : 'unset')};
  height: 35px;
  padding-left: 10px;
  outline: none;
`

interface ICheckoutSelectProps {
  width?: string
  isForm?: boolean
  error?: boolean
}

export const checkoutSelect = styled.select<ICheckoutSelectProps>`
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  background-color: ${p =>
    p.error
      ? p.theme.colors.card.background2
      : p.theme.colors.button.background};
  color: ${p => 'white'};
  width: ${p => p.width};
  height: 35px;
  padding-left: 10px;
  outline: none;
  appearance: none;
`

export const checkoutCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${p => p.theme.colors.contrast};
  margin: 0 10px 0 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0px;
    &:checked + .check {
      color: ${p => p.theme.colors.contrast};
    }
  }
  .check {
    cursor: pointer;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    color: transparent;
    &:before {
      color: transparent;
    }
  }
`

export const Swatch = styled.input<{ color: string }>`
  background-color: ${p => p.color};
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`

export const Color = styled.input<{
  color: string
  width?: string
  round?: boolean
}>`
  background-color: ${p => p.color};
  width: ${p => (p.width ? p.width : '30px')};
  height: ${p => (p.width ? p.width : '30px')};
  border: none;
  ${p => (p.round ? 'border-radius: 10px;' : null)}
  outline: none;
  cursor: pointer;
`

export const size = styled.div<{ index: number; selectedIndex: number }>`
  max-width: 40px;
  max-height: 40px;
  border: 3px solid ${p => p.theme.colors.contrast};
  margin: 5px 10px 0 0;
  cursor: pointer;
  background: ${p =>
    p.index === p.selectedIndex ? p.theme.colors.table.alt : 'unset'};
  h4 {
    margin: 11px;
    text-align: center;
  }
`

export const quantity = styled.div`
  width: 50px;
  height: 40px;
  border: 3px solid ${p => p.theme.colors.contrast};
  margin: 10px 10px 0 0;
  padding: 0 15px;
  display: flex;
  input {
    width: 100%;
    border: none;
    border-bottom: none;
    background: none;
    font-size: 20px;
    padding: 5px 0;
    color: ${p => p.theme.colors.contrast};
    outline: none;
  }
`

export const checkbox = styled.input``

export const select = styled.select``

export const upload = styled.input`
  outline: none;
  background: none;
  border: none;
  width: 200px;
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    font-size: ${p => p.theme.sizing.xs.a}px;
    padding: ${p => p.theme.sizing.xs.a}px;
    margin: ${p => p.theme.sizing.xs.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    font-size: ${p => p.theme.sizing.sm.a}px;
    padding: ${p => p.theme.sizing.sm.a}px;
    margin: ${p => p.theme.sizing.sm.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    font-size: ${p => p.theme.sizing.md.a}px;
    padding: ${p => p.theme.sizing.md.a}px;
    margin: ${p => p.theme.sizing.md.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    font-size: ${p => p.theme.sizing.lg.a}px;
    padding: ${p => p.theme.sizing.lg.a}px;
    margin: ${p => p.theme.sizing.lg.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    font-size: ${p => p.theme.sizing.xl.a}px;
    padding: ${p => p.theme.sizing.xl.a}px;
    margin: ${p => p.theme.sizing.xl.padding / 4}px 0;
  }
`

export const Textarea = styled.textarea`
  width: -webkit-fill-available;
  min-height: 300px;
  background-color: ${p => p.theme.colors.card.background2};
  border: 2px solid ${p => p.theme.colors.contrast};
  color: ${p => p.theme.colors.contrast};
  font-size: 20px;
  padding: 10px;
  overflow: auto;
  resize: none;
`
