import { gql } from 'apollo-boost'

export default gql`
  mutation addToCart(
    $product: JSONObject!
    $tempDesigns: [JSONObject]
    $isStore: Boolean
    $variantSelections: [JSONObject]!
  ) {
    addToCart(
      product: $product
      tempDesigns: $tempDesigns
      isStore: $isStore
      variantSelections: $variantSelections
    ) {
      auth {
        token
      }
      cart {
        id
        cartItems {
          designs {
            file {
              id
            }
          }
        }
      }
    }
  }
`
