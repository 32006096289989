export const terms = `
Bossy Panda Terms of Use
March 1, 2022
 
Welcome to the Bossy Panda Terms of Use agreement. For purposes of this agreement, “Site” refers to the Company’s website, which can be accessed at www.bossypanda.com. “Service” refers to the Company’s services accessed via the Site, in which users can design and purchase custom merchandise and create stores. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site or our Service. 

The following Terms of Use apply when you view or use the Service via our website, email or personal interaction with a Bossy Panda employee. 

Please review the following terms carefully. By accessing or using the Service, you signify your agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in their entirety, you may not access or use the Service or Site.  

PRIVACY POLICY

The Company respects the privacy of its Service users. Please refer to the Company’s Privacy Policy (found here: [insert Link to Privacy Policy]) which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Service, you signify your agreement to the Privacy Policy as well as these Terms of Use.

ABOUT THE SERVICE

The Service allows you to:
Design and develop custom merchandise 
Purchase your custom merchandise 
Develop a clothing line - for non-profit use

REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE

You need to be at least 13 years old to register for and use the Service.
If you are a user who signs up for the Service, you will create a personalized account which includes a unique username and a password to access the Service and to receive messages from the Company. You agree to notify us immediately of any unauthorized use of your password and/or account. The Company will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your member name, password and/or account.

USE RESTRICTIONS

Your permission to use the Site is conditioned upon the following use, posting and conduct restrictions: 
You agree that you will not under any circumstances:
·    access the Service for any reason other than your personal, non-commercial use solely as permitted by the normal functionality of the Service,
\tWhile our website may not be used for commercial use, the products we fulfill - once delivered to you, may be used for commercial use.
·    collect or harvest any personal data of any user of the Site or the Service 
·    use the Site or the Service for the solicitation of business in the course of trade or in connection with a commercial enterprise;
\tOur site may be used in full to create and order products for commercial use, however individual functions may not be used for solicitation of other clients.
·    distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly-available searchable indices but retain the right to revoke this permission at any time on a general or specific basis);
·    use the Service for any unlawful purpose or for the promotion of illegal activities;
·    attempt to, or harass, abuse or harm another person or group;
·    use another user’s account without permission;
·    intentionally allow another user to access your account; 
·    provide false or inaccurate information when registering an account;
·    interfere or attempt to interfere with the proper functioning of the Service;
·    make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;
·    bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data; 
·    circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service; or 
·    publish or link to malicious content of any sort, including that intended to damage or disrupt another user’s browser or computer.


POSTING AND CONDUCT RESTRICTIONS

 You agree that we are only acting as a passive conduct for your online distribution and publication of your User Content. The Company, however, reserves the right to remove any User Content from the Service at its sole discretion.
We grant you permission to use and access the Service, subject to the following express conditions surrounding User Content. You agree that failure to adhere to any of these conditions constitutes a material breach of these Terms. 
If we deem a design to be malicious or inappropriate, we reserve the right to refuse fulfilling it, and to delete your account.
You are responsible for uploading your own logo. While we review all orders through the Site, we are not responsible for the quality of the uploaded logo.
You are to refrain from posting copyrighted material to our site. If we determine that your logo is owned by another entity, we retain the ability to refuse to fulfill it. However, we are not held responsible for determining if your logo is already owned by another entity.
We reserve the right to refuse fulfillment of an order that is a “knock off” or “imitation” of another company's products. 
You are personally and solely responsible for the creation and editing of your logo on merchandise via the website. Unless expressly stated otherwise, the product you upload will be fulfilled and produced on an ‘as is’ basis.
If a quote for an order is not paid within 3 days of its original offering, the offer is no longer valid, unless otherwise stated.
No cancellations of orders are allowed. This includes, artwork changes, size changes, brand changes, or colour changes.
This option may be allowed if our “exceptional guarantee” is bought.
Bossy Panda is not responsible for delays or damages caused by third party shipping or couriers. 

You understand and agree that any liability, loss or damage that occurs as a result of the use of any User Content that you make available or access through your use of the Service is solely your responsibility. The Site is not responsible for any public display or misuse of your User Content. 
The Site does not, and cannot, pre-screen or monitor all User Content. However, at our discretion, we, or technology we employ, may monitor and/or record your interactions with the Service or with other Users.


LINKS TO OTHER SITES AND/OR MATERIALS

As part of the Service, we may provide you with convenient links to third party website(s) (“Third Party Sites”) as well as content or items belonging to or originating from third parties (the “Third Party Applications, Software or Content”). These links are provided as a courtesy to Service subscribers. We have no control over Third Party Sites or Third Party Applications, Software or Content or the promotions, materials, information, goods or services available on these Third Party Sites or Third Party Applications, Software or Content. Such Third Party Sites and Third Party Applications, Software or Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness, and we are not responsible for any Third Party Sites accessed through the Site or any Third Party Applications, Software or Content posted on, available through or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other policies of or contained in the Third Party Sites or the Third Party Applications, Software or Content. Inclusion of, linking to or permitting the use or installation of any Third Party Site or any Third Party Applications, Software or Content does not imply our approval or endorsement. If you decide to leave the Site and access the Third Party Sites or to use or install any Third Party Applications, Software or Content, you do so at your own risk and you should be aware that our terms and policies, including these Terms of Use, no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any Third Party Site to which you navigate from the Site or relating to any applications you use or install from the Third Party Site.

COPYRIGHT COMPLAINTS AND COPYRIGHT AGENT

(a) Termination of Repeat Infringer Accounts. We respect the intellectual property rights of others and requires that the users do the same. Pursuant to R.S.C., 1985, c. C-42. of the Canadian Copyright Act, we have adopted and implemented a policy that provides for the termination in appropriate circumstances of users of the Service who are repeat infringers. We may terminate access for participants or users who are found repeatedly to provide or post protected third party content without necessary rights and permissions.
(b) DMCA Take-Down Notices.  If you are a copyright owner or an agent thereof and believe, in good faith, that any materials provided on the Service infringe upon your copyrights, you may submit a notification pursuant to the Canadian Copyright Act (see R.S.C., 1985, c. C-42) copyright agent at [Insert Address]:2.) (“DMCA”) by sending the following information in writing to our designated employee.
 
1.   The date of your notification;
2.   A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
3.   A description of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;
4.   A description of the material that is claimed to be infringing or to be the subject of infringing activity and information sufficient to enable us to locate such work;
5.   Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and/or email address;
6.   A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
7.   A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
(c) Counter-Notices. If you believe that your User Content that has been removed from the Site is not infringing, or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to the law, to post and use the content in your User Content, you may send a counter-notice containing the following information to our copyright agent using the contact information set forth above:
 
1.   Your physical or electronic signature;
2.   A description of the content that has been removed and the location at which the content appeared before it was removed;
3.   A statement that you have a good faith belief that the content was removed as a result of mistake or a misidentification of the content; and
4.   Your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court in [insert state of residence or incorporation] and a statement that you will accept service of process from the person who provided notification of the alleged infringement.
If a counter-notice is received by our copyright agent, we may send a copy of the counter-notice to the original complaining party informing such person that it may reinstate the removed content in ten (10) business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may (in our sole discretion) be reinstated on the Site in ten (10) to fourteen (14) business days or more after receipt of the counter-notice.

LICENSE GRANT

By posting any User Content via the Service, you expressly grant, and you represent and warrant that you have a right to grant, to the Company a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness as contained in your User Content, if applicable, in whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use in connection with the Service.

INTELLECTUAL PROPERTY

You acknowledge and agree that we and our licensors retain ownership of all intellectual property rights of any kind related to the Service, including applicable copyrights, trademarks and other proprietary rights. Other product and company names that are mentioned on the Service may be trademarks of their respective owners. We reserve all rights that are not expressly granted to you under these Terms of Use.

EMAIL MAY NOT BE USED TO PROVIDE NOTICE

Communications made through the Service’s email and messaging system will not constitute legal notice to the Site, the Service, or any of its officers, employees, agents or representatives in any situation where legal notice is required by contract or any law or regulation.

USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM

For contractual purposes, you: (a) consent to receive communications from us in an electronic form via the email address you have submitted; and (b) agree that all Terms of Use, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. The foregoing does not affect your non-waivable rights.

We may also use your email address to send you other messages, including information about the Site or the Service and special offers. You may opt out of such email by changing your account settings, using the “opt out” link in the message, or by sending an email to info@bossypanda.com or mail to the following postal address:
Customer Support,
Address
Opting out may prevent you from receiving messages regarding the Site, the Service or special offers.

WARRANTY DISCLAIMER

THE SERVICE, IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SERVICE INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED FROM THE SERVICE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU.

LIMITATION OF DAMAGES; RELEASE

TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SITE, THE SERVICE, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE,  OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM: (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY TO USE THE SERVICE; (C) THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH USE OR WITH ANY OTHER USER OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.

If you have a dispute with one or more users, a merchant of a product or service that you review using the Service, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. 

MODIFICATION OF TERMS OF USE

We can amend these Terms of Use at any time and will update these Terms of Use in the event of any such amendments. It is your sole responsibility to check the Site from time to time to view any such changes in this agreement. Your continued use of the Site or the Service signifies your agreement to our revisions to these Terms of Use. We will endeavor to notify you of material changes to the Terms by posting a notice on our homepage and/or sending an email to the email address you provided to us upon registration. For this additional reason, you should keep your contact and profile information current. Any changes to these Terms (other than as set forth in this paragraph) or waiver of our rights hereunder shall not be valid or effective except in a written agreement bearing the physical signature of one of our officers. No purported waiver or modification of this agreement on our part via telephonic or email communications shall be valid.

GENERAL TERMS

If any part of this Terms of Use agreement is held or found to be invalid or unenforceable, that portion of the agreement will be construed as to be consistent with applicable law while the remaining portions of the agreement will remain in full force and effect. Any failure on our part to enforce any provision of this agreement will not be considered a waiver of our right to enforce such provision. Our rights under this agreement survive any transfer or termination of this agreement.

You agree that any cause of action related to or arising out of your relationship with the Company must commence within ONE year after the cause of action accrues. Otherwise, such cause of action is permanently barred.

These Terms of Use and your use of the Site are governed by the federal laws of Canada and the laws of the State of Quebec, without regard to conflict of law provisions.

We may assign or delegate these Terms of Service and/or our Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without our prior written consent, and any unauthorized assignment or delegation by you is void.

YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS AND CONDITIONS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER WITH THE PRIVACY POLICY AT [INSERT LINK TO PRIVACY POLICY] REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.
  `
