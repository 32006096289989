import styled from 'styled-components'

const btn = `
    border: none;
    cursor: pointer;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 5px;
    transition: ease 0.3s;
    &:disabled{
      cursor: default;
    }
`

export const Button = styled.button`
  ${btn};
  border: none;
  border-radius: 5px;
  background: ${p => p.theme.colors.button.background};
  color: ${p => p.theme.colors.button.text};
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    font-size: ${p => p.theme.sizing.xs.a}px;
    padding: ${p => p.theme.sizing.xs.a}px;
    margin: ${p => p.theme.sizing.xs.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    font-size: ${p => p.theme.sizing.sm.a}px;
    padding: ${p => p.theme.sizing.sm.a}px;
    margin: ${p => p.theme.sizing.sm.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    font-size: ${p => p.theme.sizing.md.a}px;
    padding: ${p => p.theme.sizing.md.a}px;
    margin: ${p => p.theme.sizing.md.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    font-size: ${p => p.theme.sizing.lg.a}px;
    padding: ${p => p.theme.sizing.lg.a}px;
    margin: ${p => p.theme.sizing.lg.padding / 4}px 0;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    font-size: ${p => p.theme.sizing.xl.a}px;
    padding: ${p => p.theme.sizing.xl.a}px;
    margin: ${p => p.theme.sizing.xl.padding / 4}px 0;
  }
  &:disabled {
    opacity: 0.5;
  }
`

export const SubtleButton = styled.button`
  ${btn};
  background: ${(p: { bg?: string }) => p.bg || 'none'};
  color: ${(p: { color?: string; theme: any }) =>
    p.color || p.theme.colors.contrast};
  border-radius: 10px;
  padding: 5px 10px;
  border: 2px solid
    ${(p: { bg?: string; theme: any }) =>
      p.bg ? p.bg : p.theme.colors.contrast};
  &:disabled {
    opacity: 0.2;
  }
`

export const DangerButton = styled.button`
  ${btn};
  color: ${p => p.theme.colors.contrast};
  background: ${p => p.theme.colors.text.danger};
  border-radius: 5px;
  padding: 10px;
  border: none;
`

interface ITagProps {
  color?: string
}

export const Tag = styled.button<ITagProps>`
  ${btn};
  font-size: 12px;
  background: none;
  border-radius: 5px;
  padding: 1px 6px;
  color: ${p => p.color || p.theme.colors.contrast};
  border: 1px solid ${p => p.color || p.theme.colors.contrast};
`
export const Remove = styled.a`
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

export const Exit = styled.a`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`
