import React, { useState } from 'react'
import Mockup from 'components/Mockup'
import { TView } from 'types/View'
import { TDesign } from 'types/Design'
import { Loader } from 'utils'
import { TTempDesign } from 'types/Design'
import { Flex } from '@sweaterplanet/nucleus-style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { withTheme } from 'styled-components'
import { ITheme, TAddOn, TAddOnByLocation, TProduct } from 'types'
import { useMediaQuery } from 'react-responsive'

interface DesignViewerProps {
  views: TView[]
  activeView: TView | null
  color: string
  setActiveView: (view: TView) => void

  tempDesigns?: TTempDesign[]
  setTempDesigns?: (designs: TTempDesign[]) => void
  selectedDesign?: TTempDesign
  setSelectedDesign?: (design: TTempDesign) => void
  designs?: TDesign[]
  showPrintLocations?: boolean
  selectedPrintLocationIndex?: number
  setSelectedPrintLocationIndex?: (index: number) => void
  isDesignStudio?: boolean
  isSleeveCustomization?: boolean
  isStore?: boolean
  addOnsByLocation?: TAddOnByLocation[]
  theme: ITheme
  product?: TProduct
  selectedAddOn?: TAddOn
  selectedAddOnIndex?: number
}

const DesignViewer: React.FC<DesignViewerProps> = props => {
  const {
    views,
    activeView,
    color,
    setActiveView,
    tempDesigns,
    setTempDesigns,
    selectedDesign,
    setSelectedDesign,
    designs,
    showPrintLocations,
    selectedPrintLocationIndex,
    setSelectedPrintLocationIndex,
    isDesignStudio,
    isSleeveCustomization,
    addOnsByLocation,
    theme: { colors },
    product,
    selectedAddOn,
    selectedAddOnIndex,
  } = props

  const [viewIndex, setViewIndex] = useState(0)

  const handleArrowClick = (side: string) => {
    let index: number
    if (side === 'left')
      index = viewIndex - 1 <= -1 ? views.length - 1 : viewIndex - 1
    else index = viewIndex + 1 >= views.length ? 0 : viewIndex + 1

    setActiveView(views[index])
    setViewIndex(index)
  }

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  return (
    <>
      {activeView ? (
        <Flex width={'100%'} maxWidth={'600px'}>
          {!isMobile && (
            <Flex flexDirection="column" justifyContent="center">
              <FontAwesomeIcon
                icon={faChevronLeft as any}
                size="2x"
                color={colors.contrast}
                onClick={() => handleArrowClick('left')}
                style={{ cursor: 'pointer' }}
              />
            </Flex>
          )}
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={'100%'}
          >
            {views.map(
              (view: TView) =>
                activeView &&
                view.id === activeView.id && (
                  <Mockup
                    view={view}
                    key={view.id}
                    color={color}
                    width="100%"
                    tempDesigns={tempDesigns}
                    setTempDesigns={setTempDesigns}
                    designs={designs}
                    selectedDesign={selectedDesign}
                    setSelectedDesign={setSelectedDesign}
                    showPrintLocations={showPrintLocations}
                    selectedPrintLocationIndex={selectedPrintLocationIndex}
                    setSelectedPrintLocationIndex={
                      setSelectedPrintLocationIndex
                    }
                    isDesignStudio={isDesignStudio}
                    isSleeveCustomization={isSleeveCustomization}
                    isActiveView={true}
                    addOnsByLocation={addOnsByLocation}
                    product={product}
                    selectedAddOn={selectedAddOn}
                    selectedAddOnIndex={selectedAddOnIndex}
                  />
                )
            )}
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItem="center"
              margin="20px"
            >
              {views.map((view: TView, index: number) => (
                <Flex
                  key={view.id}
                  onClick={() => {
                    setActiveView(view)
                    setViewIndex(index)
                  }}
                  style={{ cursor: 'pointer' }}
                  alignItems="center"
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                >
                  <Mockup
                    view={view}
                    color={color}
                    width="70%"
                    tempDesigns={tempDesigns}
                    designs={designs}
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
          {!isMobile && (
            <Flex flexDirection="column" justifyContent="center">
              <FontAwesomeIcon
                icon={faChevronRight as any}
                size="2x"
                color={colors.contrast}
                onClick={() => handleArrowClick('right')}
                style={{ cursor: 'pointer' }}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default withTheme(DesignViewer)
