import { TVariant } from 'types'

export type TColorObj = {
  chroma: number
  hue: number
  sat: number
  val: number
  luma: number
  red: number
  green: number
  blue: number
  variantFields: TVariant
}

export const constructColor = (color: TVariant) => {
  const hex = color.value.substring(1)
  /* Get the RGB values to calculate the Hue. */
  const r = parseInt(hex.substring(0, 2), 16) / 255
  const g = parseInt(hex.substring(2, 4), 16) / 255
  const b = parseInt(hex.substring(4, 6), 16) / 255

  /* Getting the Max and Min values for Chroma. */
  const max = Math.max.apply(Math, [r, g, b])
  const min = Math.min.apply(Math, [r, g, b])

  /* Variables for HSV value of hex color. */
  const chr = max - min
  let hue = 0
  const val = max
  let sat = 0

  if (val > 0) {
    /* Calculate Saturation only if Value isn't 0. */
    sat = chr / val
    if (sat > 0) {
      if (r === max) {
        hue = 60 * ((g - min - (b - min)) / chr)
        if (hue < 0) {
          hue += 360
        }
      } else if (g === max) {
        hue = 120 + 60 * ((b - min - (r - min)) / chr)
      } else if (b === max) {
        hue = 240 + 60 * ((r - min - (g - min)) / chr)
      }
    }
  }
  let colorObj: TColorObj = {
    chroma: 0,
    hue: 0,
    sat: 0,
    val: 0,
    luma: 0,
    red: 0,
    green: 0,
    blue: 0,
    variantFields: color,
  }
  colorObj.chroma = chr
  colorObj.hue = hue
  colorObj.sat = sat
  colorObj.val = val
  colorObj.luma = 0.3 * r + 0.59 * g + 0.11 * b
  colorObj.red = parseInt(hex.substring(0, 2), 16)
  colorObj.green = parseInt(hex.substring(2, 4), 16)
  colorObj.blue = parseInt(hex.substring(4, 6), 16)
  return colorObj
}

export const sortColorsByHue = (colorObjects: TColorObj[]) => {
  return colorObjects.sort(function (a, b) {
    return a.hue - b.hue
  })
}

export const lightOrDark = (color: any) => {
  let r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light'
  } else {
    return 'dark'
  }
}
