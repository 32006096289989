import { gql } from 'apollo-boost'

export default gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      createdAt
      updatedAt
      fulfillmentStatus
      billingStatus
      price
      paid
      pickupLocation {
        id
        name
      }
      payInStoreLocation {
        id
        name
      }
      User {
        name
        email
        role
      }
      orderItems {
        id
        subtotal
        tax
        totalPrice
        product {
          id
          name
          inventoryGroup {
            id
            name
            views {
              id
              boundingBox
              texture
              base
              printLocations {
                id
                printLocation {
                  id
                  name
                  price
                }
                coordinateMap
                rotate
              }
            }
          }
        }
        variantSelection {
          id
          variants {
            id
            name
            value
            available
            price
            variantGroup {
              id
              name
            }
          }
          quantity
          customization
        }
        designs {
          id
          printLocation {
            id
            name
            price
          }
          addOn {
            id
            name
            publicName
            basePrice
            priceBreaks
          }
          file {
            id
            url
            thumbnail
          }
          origin
          w
          h
        }
      }
      shipping {
        city
        state
        postCode
        country
        firstName
        lastName
        address1
        address2
      }
      coupon {
        id
        value
      }
    }
  }
`
