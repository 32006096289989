import { gql } from 'apollo-boost'

export default gql`
  mutation createPromotion(
    $id: ID
    $name: String
    $coupon: String!
    $couponDescription: String
    $description: String
    $products: [String!]!
    $bannerImg: Upload
  ) {
    createPromotion(
      id: $id
      name: $name
      coupon: $coupon
      couponDescription: $couponDescription
      description: $description
      products: $products
      bannerImg: $bannerImg
    ) {
      id
    }
  }
`
