import React from 'react'
import CheckoutInput from './CheckoutInput'

import { TPickupData } from '../../../types/Pickup'

import { Formik } from 'formik'

import * as Yup from 'yup'

import { Flex } from '@sweaterplanet/nucleus-style'

interface PickupFormProps {
  data: TPickupData
  setData: (data: TPickupData) => void
  step: number
  handleNextStep?: () => void
}

const PickupForm: React.FC<PickupFormProps> = props => {
  const { data, setData, step, handleNextStep } = props

  const handleOnInputChange = (name: string, value: string) => {
    const pickupData = { ...data }
    setData({ ...pickupData, [name]: value })
  }

  const formSubmit = () => {
    if (handleNextStep) handleNextStep()
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      validationSchema={Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().required('Email is required'),
        phone: Yup.string().required('Phone is required'),
      })}
      onSubmit={formSubmit}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="on">
          <Flex width="100%" flexDirection="column" justifyContent="center">
            <Flex
              width="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <CheckoutInput
                title="First Name"
                name="firstName"
                type="text"
                width="50%"
                value={values.firstName}
                error={errors.firstName}
                autoComplete="given-name"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('firstName', event.target.value)
                  handleChange(event)
                }}
              />
              <CheckoutInput
                title="Last Name"
                name="lastName"
                type="text"
                width="50%"
                value={values.lastName}
                error={errors.lastName}
                autoComplete="family-name"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('lastName', event.target.value)
                  handleChange(event)
                }}
              />
            </Flex>
            <CheckoutInput
              title="Email"
              name="email"
              type="text"
              width="100%"
              value={values.email}
              error={errors.email}
              autoComplete="email"
              onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleOnInputChange('email', event.target.value)
                handleChange(event)
              }}
            />
            <CheckoutInput
              title="Phone"
              name="phone"
              type="text"
              width="100%"
              value={values.phone}
              error={errors.phone}
              autoComplete="tel"
              onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleOnInputChange('phone', event.target.value)
                handleChange(event)
              }}
            />
          </Flex>
          <button id={`form${step}`} type="submit" hidden={true}>
            Submit
          </button>
        </form>
      )}
    </Formik>
  )
}

export default PickupForm
