import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import {
  ButtonStyle as B,
  CardStyle as C,
  GridLayout as G,
  DivLayout as D,
} from '../../../styles'
import * as ADMIN_ROUTES from 'routes/admin'
import StoreForm from './StoreForm'
import GET_MANAGED_STORE from './gql/GET_MANAGED_STORE'
import { TOrder, TOrderItem, TProduct, TVariantSelection } from 'types'
import ProductCard from 'components/Product/lib/ProductCard'
import ProductForm from 'components/Product/lib/ProductForm'
import { Image } from '@sweaterplanet/nucleus-style'
import * as USER_ROUTES from 'routes/user'
import BulkProductForm from '../../Product/lib/BulkProductForm'
import DUPLICATE_STORE from './gql/DUPLICATE_STORE'
import { useHistory } from 'react-router-dom'
import { Tag } from 'styles/lib/button'

interface IAdminStoreProps {
  match: {
    params: {
      storeId: string
    }
  }
}

const AdminStore: React.FC<IAdminStoreProps> = props => {
  const { loading, data, error, refetch } = useQuery(GET_MANAGED_STORE, {
    variables: {
      id: props.match.params.storeId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [modalOpen, toggleModalOpen] = useState<boolean>(false)
  const history = useHistory()

  const [productModalOpen, toggleProductModalOpen] = useState<boolean>(false)
  const [bulkProductModalOpen, toggleBulkProductModalOpen] =
    useState<boolean>(false)
  const [DuplicateStore] = useMutation(DUPLICATE_STORE, {
    variables: {
      id: props.match.params.storeId,
    },
  })
  const handleDuplicateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    DuplicateStore()
      .then(response => {
        console.log(response)
        history.push(
          `${ADMIN_ROUTES.ADMIN_STORES}/${response.data.duplicateStore.id}`
        )
      })
      .catch(e => {
        return <Error message={e.message} />
      })
  }

  if (error) {
    return <Error message={error.message} />
  }
  if (loading && !data) {
    return <Loader />
  }

  return (
    <>
      {data && (
        <>
          <h1>{data.getManagedStore.store.name}</h1>
          <G.column>
            <C.card>
              <D.flex justify={'space-around'}>
                <h1 style={{ paddingRight: '20px' }}>
                  {data.getManagedStore.store.name}
                </h1>
                <B.SubtleButton onClick={handleDuplicateClick}>
                  Duplicate
                </B.SubtleButton>
              </D.flex>
              <G.row>
                <h3>Store Info</h3>
                <B.Button onClick={() => toggleModalOpen(!modalOpen)}>
                  Edit
                </B.Button>
                <StoreForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetch}
                  store={data.getManagedStore.store}
                />
              </G.row>
              <G.row>
                <h4>Revenue: </h4>
                <p>
                  $
                  {data.getManagedStore.store.products
                    .flatMap((p: { OrderItem: any[] }) =>
                      p.OrderItem.filter(
                        oI => oI.Order.paid || oI.Order.payInStoreLocation?.id
                      ).flatMap((oI: { subtotal: number }) => oI.subtotal)
                    )
                    .reduce(
                      (partialSum: number, a: number) => partialSum + a,
                      0
                    )
                    .toFixed(2)}
                </p>
              </G.row>
              <G.row>
                <h4>Items Sold:</h4>
                <p>
                  {Array.from(
                    new Set(
                      data.getManagedStore.store.products.flatMap(
                        (p: { OrderItem: any[] }) =>
                          p.OrderItem.filter(
                            oI =>
                              oI.Order.paid || oI.Order.payInStoreLocation?.id
                          ).map((oI: { Order: { id: string } }) => oI.Order.id)
                      )
                    )
                  )
                    .map(o => {
                      return data.getManagedStore.orders
                        .find((oI: { id: string }) => oI.id === o)
                        .orderItems.reduce(
                          (partialSum: number, a: TOrderItem) =>
                            partialSum +
                            a.variantSelection.reduce(
                              (partialSum: number, b: TVariantSelection) =>
                                partialSum + b.quantity,
                              0
                            ),
                          0
                        )
                    })
                    .reduce(
                      (partialSum: number, a: number) => partialSum + a,
                      0
                    )}
                </p>
              </G.row>
              <G.row>
                <h4>Orders: </h4>
                <p>
                  {
                    data.getManagedStore.orders.filter(
                      (o: any) => o.paid || o.payInStoreLocation?.id
                    ).length
                  }{' '}
                </p>
              </G.row>
              <G.row>
                <h4>Name: </h4>
                <p>{data.getManagedStore.store.name}</p>
              </G.row>
              <G.row>
                <h4>Url: </h4>
              </G.row>
              <G.row>
                <a
                  href={`${USER_ROUTES.STORE}/${
                    data.getManagedStore.store.slug ||
                    data.getManagedStore.store.id
                  }`}
                >{`${
                  process.env.REACT_APP_BE_URL?.includes('localhost')
                    ? 'http://localhost:3000'
                    : process.env.REACT_APP_BE_URL?.includes(
                        'nucleus-backend-dev'
                      )
                    ? 'https://dev.bossypanda.com'
                    : 'https://bossypanda.com'
                }${USER_ROUTES.STORE}/${
                  data.getManagedStore.store.slug ||
                  data.getManagedStore.store.id
                }`}</a>
              </G.row>
              <h4>Description: </h4>
              <p>{data.getManagedStore.store.description}</p>
              <h4>Logo: </h4>
              <Image
                width={'400px'}
                src={data.getManagedStore.store.logo?.url}
                alt="N/A"
              />
              <h4>Banner: </h4>
              {data.getManagedStore.store.bannerImg ? (
                <Image
                  width={'400px'}
                  src={data.getManagedStore.store.bannerImg.url}
                  alt="banner"
                />
              ) : (
                'N/A'
              )}
              <h4>Moodboard: </h4>
              {data.getManagedStore.store.moodboard ? (
                <Image
                  width={'400px'}
                  src={data.getManagedStore.store.moodboard.url}
                  alt="moodboard"
                />
              ) : (
                'N/A'
              )}
            </C.card>
            <G.row>
              <h1>Products</h1>
              <B.Button
                onClick={() => toggleProductModalOpen(!productModalOpen)}
              >
                Add
              </B.Button>
              <B.Button
                style={{ marginLeft: '10px' }}
                onClick={() =>
                  toggleBulkProductModalOpen(!bulkProductModalOpen)
                }
              >
                Bulk Add
              </B.Button>
              <ProductForm
                visible={productModalOpen}
                toggle={toggleProductModalOpen}
                refetch={refetch}
                store={data.getManagedStore.store}
              />
              <BulkProductForm
                visible={bulkProductModalOpen}
                toggle={toggleBulkProductModalOpen}
                refetch={refetch}
                store={data.getManagedStore.store}
              />
            </G.row>
            <G.row wrap>
              {data.getManagedStore.store.products.map(
                (
                  product: TProduct & { orderItems: TOrderItem[] },
                  index: number
                ) => (
                  <G.column key={index}>
                    <ProductCard
                      showOrderQuantity={data.getManagedStore.orders
                        .filter((o: any) => o.paid || o.payInStoreLocation?.id)
                        .flatMap((order: TOrder) =>
                          order.orderItems
                            .filter(
                              orderItem => orderItem.product.id === product.id
                            )
                            .flatMap((orderItem: any) =>
                              orderItem.variantSelection.flatMap(
                                (variantSelection: any) =>
                                  variantSelection.quantity
                              )
                            )
                        )
                        .reduce(
                          (partialSum: number, a: number) => partialSum + a,
                          0
                        )}
                      product={product}
                      isAdmin={true}
                      refetch={refetch}
                    />
                  </G.column>
                )
              )}
            </G.row>
          </G.column>
        </>
      )}
    </>
  )
}

export default AdminStore
