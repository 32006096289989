import styled from 'styled-components'

interface ICircleProps {
  width: string
  height: string
}

export const circle = styled.div<ICircleProps>`
  width: ${p => p.width};
  height: ${p => p.height};
  border-radius: 50%;
  text-align: center;
  line-height: ${p => p.height};
  border: 2px solid ${p => p.theme.colors.contrast};
`

interface IQuestionMarkProps {}

export const questionMark = styled.div<IQuestionMarkProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  font-weight: bold;
  background-color: ${p => p.theme.colors.contrast};
  color: ${p => p.theme.colors.page.background};
`
