import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import * as ADMIN_ROUTES from 'routes/admin'
import * as USER_ROUTES from 'routes/user'
import { AppState } from 'store/store'
import { UserState } from 'store/reducers/userReducer'

import DarkModeSwitch from 'components/DarkModeSwitch'

import { Nav, NavLink, NavLogo, NavSublink } from 'styles/lib/nav'
import Logo from 'static/logo'
import { Role } from '../../../types'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

interface NavProps {
  user: UserState
}

const user = gql`
  query me {
    me {
      storeManager {
        id
        name
      }
    }
  }
`

const AdminNav: React.FC<NavProps> = props => {
  const { data } = useQuery(user)
  return (
    <Nav vertical>
      {props.user.credentials.role === Role.ADMIN ? (
        <>
          <NavLink>
            <NavLogo>
              <Logo />
            </NavLogo>
          </NavLink>
          <NavLink>
            <Link to={ADMIN_ROUTES.ADMIN}>Dashboard</Link>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_WEBSITE_SETTINGS}>Settings</Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_USERS}>Users</Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_FILES}>Files</Link>
            </NavSublink>
          </NavLink>
          <NavLink>
            <Link to={ADMIN_ROUTES.ADMIN_PRODUCTS}>Products</Link>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_ADD_ONS}>Decoration Types</Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_PRINT_PROVIDERS}>
                Decoration Locations
              </Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_TAGS}>Tags</Link>
            </NavSublink>
          </NavLink>
          <NavLink>
            <Link to={ADMIN_ROUTES.ADMIN_ORDERS}>Orders</Link>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_SHIPPING}>Shipping</Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}>Inventory</Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_INVENTORY_PROVIDERS}>
                Warehouses
              </Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_RETAIL_LOCATIONS}>
                Retail Locations
              </Link>
            </NavSublink>
          </NavLink>
          <NavLink>
            <Link to={ADMIN_ROUTES.ADMIN_STORES}>Stores</Link>

            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_COUPONS}>Coupons</Link>
            </NavSublink>
            <NavSublink>
              <Link to={ADMIN_ROUTES.ADMIN_PROMOTIONS}>Promotions</Link>
            </NavSublink>
            {data?.me.storeManager &&
              data.me.storeManager.map(
                (store: { id: string; name: string }) => (
                  <NavSublink>
                    <Link to={USER_ROUTES.STORE_MANAGER + '/' + store.id}>
                      {store.name}
                    </Link>
                  </NavSublink>
                )
              )}
          </NavLink>
          <NavLink>
            <DarkModeSwitch />
          </NavLink>
        </>
      ) : props.user.credentials.role === Role.MANAGER ? (
        <>
          <NavLink>
            <NavLogo>
              <Logo />
            </NavLogo>
          </NavLink>
          <NavLink>
            <Link to={ADMIN_ROUTES.ADMIN}>Dashboard</Link>
            {props.user.credentials.managerPermissions.includes(
              'WEBSITE_SETTINGS'
            ) && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_WEBSITE_SETTINGS}>Settings</Link>
              </NavSublink>
            )}
            {/*<NavSublink>*/}
            {/*  <Link to={ADMIN_ROUTES.ADMIN_USERS}>Users</Link>*/}
            {/*</NavSublink>*/}
            {props.user.credentials.managerPermissions.includes('FILES') && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_FILES}>Files</Link>
              </NavSublink>
            )}
          </NavLink>
          <NavLink>
            {data?.me.storeManager &&
              data.me.storeManager.map(
                (store: { id: string; name: string }) => (
                  <NavSublink>
                    <Link to={USER_ROUTES.STORE_MANAGER + '/' + store.id}>
                      {store.name}
                    </Link>
                  </NavSublink>
                )
              )}
          </NavLink>
          <NavLink>
            {props.user.credentials.managerPermissions.includes('PRODUCTS') && (
              <Link to={ADMIN_ROUTES.ADMIN_PRODUCTS}>Products</Link>
            )}
            {props.user.credentials.managerPermissions.includes(
              'DECORATIONS'
            ) && (
              <>
                <NavSublink>
                  <Link to={ADMIN_ROUTES.ADMIN_ADD_ONS}>Decoration Types</Link>
                </NavSublink>
                <NavSublink>
                  <Link to={ADMIN_ROUTES.ADMIN_PRINT_PROVIDERS}>
                    Decoration Locations
                  </Link>
                </NavSublink>
              </>
            )}
            {props.user.credentials.managerPermissions.includes('TAGS') && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_TAGS}>Tags</Link>
              </NavSublink>
            )}
          </NavLink>
          <NavLink>
            {props.user.credentials.managerPermissions.includes('ORDERS') && (
              <Link to={ADMIN_ROUTES.ADMIN_ORDERS}>Orders</Link>
            )}
            {props.user.credentials.managerPermissions.includes(
              'CUSTOM_ORDER'
            ) && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_CUSTOM_ORDER}>Custom Order</Link>
              </NavSublink>
            )}
            {props.user.credentials.managerPermissions.includes('SHIPPING') && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_SHIPPING}>Shipping</Link>
              </NavSublink>
            )}
            {props.user.credentials.managerPermissions.includes(
              'INVENTORY'
            ) && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}>Inventory</Link>
              </NavSublink>
            )}
            {props.user.credentials.managerPermissions.includes(
              'WAREHOUSES'
            ) && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_INVENTORY_PROVIDERS}>
                  Warehouses
                </Link>
              </NavSublink>
            )}
            {props.user.credentials.managerPermissions.includes(
              'RETAIL_LOCATIONS'
            ) && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_RETAIL_LOCATIONS}>
                  Retail Locations
                </Link>
              </NavSublink>
            )}
          </NavLink>
          <NavLink>
            {props.user.credentials.managerPermissions.includes('STORES') && (
              <Link to={ADMIN_ROUTES.ADMIN_STORES}>Stores</Link>
            )}
            {props.user.credentials.managerPermissions.includes('COUPONS') && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_COUPONS}>Coupons</Link>
              </NavSublink>
            )}
            {props.user.credentials.managerPermissions.includes(
              'PROMOTIONS'
            ) && (
              <NavSublink>
                <Link to={ADMIN_ROUTES.ADMIN_PROMOTIONS}>Promotions</Link>
              </NavSublink>
            )}
          </NavLink>
          <NavLink>
            <DarkModeSwitch />
          </NavLink>
        </>
      ) : (
        <></>
      )}
    </Nav>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AdminNav)
