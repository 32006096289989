import { gql } from 'apollo-boost'

export default gql`
  mutation upsertView(
    $id: ID!
    $name: String!
    $boundingBox: [JSONObject]!
    $base: String!
    $texture: String!
    $inventoryGroupId: ID!
  ) {
    upsertView(
      id: $id
      name: $name
      boundingBox: $boundingBox
      base: $base
      texture: $texture
      inventoryGroupId: $inventoryGroupId
    ) {
      id
    }
  }
`
