import React, { useState, useEffect } from 'react'
import {
  ModalLayout as M,
  ButtonStyle as B,
  FormStyle as F,
  GridLayout as G,
  DivLayout,
} from 'styles'
import { useMutation } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import { TAddOn } from 'types/AddOn'
import CREATE_ADD_ON from 'gql/CREATE_ADD_ON'
import EDIT_ADD_ON from 'gql/EDIT_ADD_ON'

interface AddOnFormProps {
  visible: boolean
  toggle: (value: boolean) => void
  refetch: () => void
  addOn?: TAddOn
}

const AddOnForm: React.FC<AddOnFormProps> = props => {
  const { addOn, toggle, refetch, visible } = props

  const [name, setName] = useState<string>('')
  const [publicName, setPublicName] = useState<string>('')
  const [available, setAvailable] = useState<boolean>(true)
  const [basePrice, setBasePrice] = useState<number>(0)
  const [description, setDescription] = useState<string>('')
  const [priceBreaks, setPriceBreaks] = useState<[number, number[]][]>([])

  const [CreateAddOn, { loading }] = useMutation(CREATE_ADD_ON)
  const [EditAddOn] = useMutation(EDIT_ADD_ON)

  useEffect(() => {
    if (addOn) {
      setName(addOn.name)
      setPublicName(addOn.publicName ? addOn.publicName : '')
      setBasePrice(addOn.basePrice)
      setAvailable(addOn.available)
      setDescription(addOn.description ? addOn.description : '')

      if (addOn.priceBreaks) {
        const priceBreaks: [number, number[]][] = []
        Object.entries(addOn.priceBreaks).forEach(
          (priceBreak: [string, number | number[]]) => {
            if (Array.isArray(priceBreak[1])) {
              if (priceBreak[1].length === 4) {
                priceBreaks.push([
                  parseInt(priceBreak[0]),
                  [...priceBreak[1], 0, 0, 0, 0],
                ])
              } else {
                priceBreaks.push([parseInt(priceBreak[0]), priceBreak[1]])
              }
            } else {
              priceBreaks.push([
                parseInt(priceBreak[0]),
                [priceBreak[1], 0, 0, 0, 0, 0, 0, 0],
              ])
            }
          }
        )
        setPriceBreaks(priceBreaks)
      }
    }
  }, [addOn])

  // const handleCreateClick = () => {
  //   CreateAddOn()
  //     .then(() => {
  //       toggle(false)
  //       refetch()
  //     })
  //     .catch(error => <Error message={error.message} />)
  // }

  const handleSubmitClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()

    let priceBreaksData: Record<string, number[]> | null = null

    if (priceBreaks.length > 0) {
      const priceBreaksObject: Record<string, number[]> = {}
      priceBreaks.forEach((priceBreak: [number, number[]]) => {
        priceBreaksObject[priceBreak[0].toString()] = priceBreak[1]
      })

      const sortedPriceBreaks: Record<string, number[]> = {}
      Object.keys(priceBreaksObject)
        .sort()
        .forEach((quantity: string) => {
          sortedPriceBreaks[quantity] = priceBreaksObject[quantity]
        })

      priceBreaksData = sortedPriceBreaks
    }

    try {
      if (addOn) {
        await EditAddOn({
          variables: {
            id: addOn.id,
            name,
            publicName,
            basePrice,
            available,
            description,
            priceBreaks: priceBreaksData,
          },
        })
      } else {
        await CreateAddOn({
          variables: {
            name,
            publicName,
            basePrice,
            available,
            description,
            priceBreaks: priceBreaksData,
          },
        })
      }

      toggle(false)
      refetch()
    } catch (error: unknown) {
      return <Error message={(error as { message: string }).message} />
    }
  }

  const handleAddPriceBreak = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    const temp: [number, number[]][] = [
      ...priceBreaks,
      [1, [0, 0, 0, 0, 0, 0, 0, 0]],
    ]
    setPriceBreaks(temp)
  }

  const handleDeletePriceBreak = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault()
    const temp = [...priceBreaks]
    temp.splice(index, 1)
    setPriceBreaks(temp)
  }

  const handleQuantityChange = (
    newQuantity: string,
    price: number[],
    index: number
  ) => {
    let num = parseInt(newQuantity)
    if (num === 0) num = 1

    const temp = [...priceBreaks]
    temp[index][0] = num
    temp[index][1] = price
    setPriceBreaks(temp)
  }

  const handlePriceChange = (
    newPrice: string,
    quantity: number,
    index: number,
    secondIndex: number // This is for the multi side price discounts
  ) => {
    const temp = [...priceBreaks]
    temp[index][0] = quantity
    temp[index][1][secondIndex] = parseFloat(newPrice)
    // temp[index] = [quantity, parseFloat(newPrice)]
    setPriceBreaks(temp)

    console.log(newPrice, quantity, index, temp)

    const newTemp = temp

    // @ts-ignore
    const newThing = [
      quantity,
      [
        parseFloat(newPrice),
        parseFloat(newPrice),
        parseFloat(newPrice),
        parseFloat(newPrice),
      ],
    ]

    console.log('newTemp', newTemp)
    console.log('newThing', newThing)
  }

  const isPriceBreakInvalid = (priceBreaks: any[][]) => {
    for (const priceBreak of priceBreaks) {
      for (const price of priceBreak[1]) {
        if (isNaN(priceBreak[0]) || isNaN(price)) return true
      }
    }
    return false
  }

  const invalid =
    name === '' ||
    basePrice === 0 ||
    isNaN(basePrice) ||
    isPriceBreakInvalid(priceBreaks)

  if (loading) return <Loader />

  return (
    <>
      {visible && (
        <M.Modal>
          <h2>{addOn ? 'Edit Decoration Type' : 'New Decoration Type'}</h2>
          <B.Exit onClick={() => toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Public Name
              <F.Input
                value={publicName}
                type={'text'}
                placeholder={'public name'}
                onChange={e => setPublicName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Description
              <F.Input
                value={description}
                type={'text'}
                placeholder={'description'}
                onChange={e => setDescription(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Available
              <F.Input
                value={available.toString()}
                checked={available}
                type={'checkbox'}
                placeholder={'available'}
                onChange={e => setAvailable(e.target.checked)}
              />
            </F.Label>
            <F.Label>
              Base Price
              <F.Input
                value={basePrice}
                type={'number'}
                placeholder={'price'}
                step="0.01"
                min="0"
                onChange={e => setBasePrice(parseFloat(e.target.value))}
              />
            </F.Label>
            <F.Label>
              Price Breaks
              <B.Button onClick={handleAddPriceBreak}>Add Price Break</B.Button>
            </F.Label>
            <G.row width="100%" justify="space-between">
              <F.Label>Quantity</F.Label>
              <F.Label style={{ marginRight: '13em' }}>Price Per Side</F.Label>
            </G.row>
            {priceBreaks.map((priceBreak, index) => (
              <G.row key={index}>
                <F.Input
                  value={priceBreak[0]}
                  type={'number'}
                  placeholder={'Quantity'}
                  min="1"
                  onChange={e =>
                    handleQuantityChange(e.target.value, priceBreak[1], index)
                  }
                  width={'50px'}
                  style={{ marginRight: '100px' }}
                />
                <DivLayout.flex>
                  {priceBreak[1].map((price, secondIndex) => (
                    <F.Input
                      key={secondIndex}
                      value={price as number}
                      type={'number'}
                      placeholder={`${secondIndex + 1} sides`}
                      step="0.01"
                      min="0"
                      onChange={e =>
                        handlePriceChange(
                          e.target.value,
                          priceBreak[0],
                          index,
                          secondIndex
                        )
                      }
                      width={'65px'}
                      style={{ paddingLeft: '2px', paddingRight: '2px' }}
                    />
                  ))}
                </DivLayout.flex>
                <B.Button
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleDeletePriceBreak(event, index)}
                >
                  -
                </B.Button>
              </G.row>
            ))}
            {/* <pre>
              {JSON.stringify(priceBreaks)}
              {basePrice}
            </pre> */}
            <B.Button disabled={invalid} onClick={handleSubmitClick}>
              {addOn ? 'Update' : 'Create Decoration Type'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default AddOnForm
