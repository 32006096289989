import React from 'react'

import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import App from 'components/App'
import { store } from 'store/store'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// https://www.apollographql.com/docs/react/networking/authentication/
// https://github.com/jaydenseric/graphql-upload/issues/170
// Issue with apollo client upload and node version, check package.json -> "resolutions": {}
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// Error Handling for any error on the site
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link: ApolloLink.from([
    authLink,
    errorLink,
    createUploadLink({ uri: process.env.REACT_APP_BE_URL }),
  ]),
  cache: new InMemoryCache(),
})

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
  plugins: [new BugsnagPluginReact()],
})

// let ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
// client intialized with apollo-boost

// const client = new ApolloClient({
//     uri: 'http://localhost:4000/',
//     request: (operation => {
//         const token = localStorage.getItem('token')
//         operation.setContext({
//             headers: {
//                 authorization: token ? `Bearer ${token}` : '',
//             },
//         })
//     }),r
// })

const tagManagerArgs = {
  gtmId: 'GTM-KHS66MK',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
