import React from 'react'
// types
import { ITheme } from 'types/Theme'

// Styles
import { Flex, Link } from '@sweaterplanet/nucleus-style'
import { FooterLinkTable } from 'styles/lib/table'

// Routes
import * as USER_ROUTES from 'routes/user'

// Icons
// import { IconProp } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFacebook,
//   faYoutube,
//   faInstagram,
//   faLinkedin,
// } from '@fortawesome/free-brands-svg-icons'

interface FooterProps {
  theme: ITheme
  authenticated: boolean
  history: any
}

const Footer: React.FC<FooterProps> = props => {
  const {
    theme: { colors },
    authenticated,
    history,
  } = props
  return (
    <Flex
      bg={colors.card.background}
      width="100%"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <div style={{ padding: '20px' }}>
        <FooterLinkTable>
          <tbody>
            <tr>
              <td onClick={() => history.push(USER_ROUTES.HOME)}>
                <Link color={colors.text.default}>Home</Link>
              </td>
              <td onClick={() => history.push(USER_ROUTES.PRODUCTS)}>
                <Link color={colors.text.default}>Products</Link>
              </td>
              <td
                onClick={() =>
                  authenticated
                    ? history.push(USER_ROUTES.CART)
                    : history.push(USER_ROUTES.SIGN_IN)
                }
              >
                <Link color={colors.text.default}>Cart</Link>
              </td>
            </tr>
            <tr>
              <td
                onClick={() =>
                  authenticated
                    ? history.push(USER_ROUTES.ACCOUNT)
                    : history.push(USER_ROUTES.SIGN_IN)
                }
              >
                <Link color={colors.text.default}>Account</Link>
              </td>
              <td onClick={() => history.push(USER_ROUTES.TERMS)}>
                <Link color={colors.text.default}>Terms</Link>
              </td>
              <td>
                <a
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  target={'_blank'}
                  href={USER_ROUTES.CONTACT}
                >
                  <Link color={colors.text.default}>Contact</Link>
                </a>
              </td>
            </tr>
          </tbody>
        </FooterLinkTable>
      </div>
      {/*<Box width={'100%'} bg={colors.card.background2}>*/}
      {/*    <Heading element="h3">*/}
      {/*        © Sweater Planet 2020*/}
      {/*    </Heading>*/}
      {/*</Box>*/}
    </Flex>
  )
}

export default Footer
