import React, { useEffect } from 'react'

// Types
import { TProduct } from 'types/Product'
import { TAddOn } from 'types/AddOn'
import { TAddOnByLocation } from 'types/AddOnByLocation'
import { TPrintLocation } from 'types/PrintLocation'
import { TView } from 'types/View'
import { TTempDesign } from 'types'
import { ITheme } from 'types/Theme'

// Styles
import {
  Card,
  Flex,
  Heading,
  Box,
  Text,
  // QuestionMark,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'

// Components
import formatter from 'utils/lib/Formatter'

interface AddOnPickerProps {
  product: TProduct
  availableAddOns: TAddOn[] | undefined
  handleSetSelectedAddOn: (addOn: TAddOn, index: number) => void
  activeView: TView | null
  selectedDesign: TTempDesign
  selectedAddOn: TAddOn | undefined
  selectedAddOnIndex: number | undefined
  tempDesigns: TTempDesign[]
  theme: ITheme
  darkMode: boolean
  tempDesignLength?: number
  totalPrintCost?: number
  quantity?: number
  bulkAddOnPrices?: any
}

const AddOnPicker: React.FC<AddOnPickerProps> = props => {
  const containsPrintLocation = (
    printLocation: TPrintLocation,
    location: TPrintLocation
  ) => {
    return location.id === printLocation.id
  }

  const {
    activeView,
    product: { addOnsByLocation },
    // selectedDesign,
    handleSetSelectedAddOn,
    theme: { colors },
  } = props

  const selectAddOn = (addOn: TAddOn, index: number) => {
    handleSetSelectedAddOn(addOn, index)
  }

  const getPrice = (addOn: any, index: number) => {
    let bulkAddOnPrice = addOn.basePrice
    for (const x of Object.keys(addOn.priceBreaks)) {
      if ((props.quantity || 1) >= parseInt(x)) {
        bulkAddOnPrice = addOn.priceBreaks[x][index] || bulkAddOnPrice
      }
    }

    return bulkAddOnPrice
  }

  return (
    <Card bg={colors.card.background} round data-tut="tour_7" width={'100%'}>
      <Box padding="10px 0 0 0">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="100%"
          >
            <Box margin="20px 0">
              <Heading
                element="h4"
                color={colors.text.default}
                style={{
                  overflow: 'hidden',
                }}
              >
                Decoration
              </Heading>
            </Box>
            <Box width="-webkit-fill-available" overflowY="scroll">
              {props.availableAddOns &&
                props.availableAddOns
                  .filter(aO => aO.available)
                  .map((addOn, index: number) => {
                    const isAddOnIncluded = activeView
                    // &&
                    // containsPrintLocation(
                    //   selectedDesign.printLocation,
                    //   addOnByLocation.location
                    // )

                    return (
                      // isAddOnIncluded &&
                      <Box
                        key={index}
                        className="addOn"
                        padding="5px 20px"
                        onClick={() =>
                          isAddOnIncluded
                            ? // && selectedDesign.designIndex >= 0
                              selectAddOn(addOn, index)
                            : null
                        }
                        cursor="pointer"
                        style={{
                          backgroundColor:
                            index === props.selectedAddOnIndex
                              ? colors.button.background
                              : 'unset',
                        }}
                      >
                        <Flex
                          className="addOn"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          margin="5px 0"
                        >
                          {isAddOnIncluded && (
                            <>
                              <Text
                                className="addOn"
                                color={colors.text.default}
                              >
                                {addOn.publicName || addOn.name}
                              </Text>
                              {/* <Text
                                className="addOn"
                                color={colors.text.default}
                              >
                                {props.totalPrintCost && props.tempDesignLength
                                  ? addOn.basePrice === 0
                                    ? 'FREE'
                                    : '$' +
                                      formatter.format(getPrice(addOn, index))
                                  : ''}
                              </Text> */}
                            </>
                          )}
                        </Flex>
                      </Box>
                    )
                  })}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Card>
  )
}

export default withTheme(AddOnPicker)
