import { gql } from 'apollo-boost'

export default gql`
  query getOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      createdAt
      updatedAt
      Order {
        id
        fulfillmentStatus
        billingStatus
        User {
          name
          email
          role
        }
      }
      product {
        id
        name
        inventoryGroup {
          id
          name
          views {
            id
            boundingBox
            texture
            base
            printLocations {
              id
              printLocation {
                id
                name
                price
              }
              coordinateMap
              rotate
            }
          }
        }
      }
      variantSelection {
        id
        variants {
          id
          name
          value
          available
          price
          variantGroup {
            id
            name
          }
        }
        quantity
        customization
      }
      designs {
        id
        printLocation {
          id
          name
          price
        }
        addOn {
          id
          name
          publicName
          basePrice
          priceBreaks
        }
        file {
          id
          url
          filename
          thumbnail
        }
        printFile {
          id
          url
          filename
        }
        origin
        w
        h
      }
    }
  }
`
