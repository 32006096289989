export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin-details'
export const ADMIN_INVENTORY_GROUPS = '/admin/inventory/groups'
export const ADMIN_INVENTORY_PROVIDERS = '/admin/inventory/providers'
export const ADMIN_PRODUCTS = '/admin/products'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_ADD_ONS = '/admin/addons'
export const ADMIN_PRINT_PROVIDERS = '/admin/printing/providers'
export const ADMIN_PRINT_LOCATION_EDITOR = '/admin/views'
export const ADMIN_TAGS = '/admin/tags'
export const ADMIN_COUPONS = '/admin/coupons'
export const ADMIN_STORES = '/admin/stores'
export const ADMIN_EMAIL_BOT = '/admin/email'
export const ADMIN_EMAIL_BOT_TEMPLATES = '/admin/email/templates'
export const ADMIN_OAUTH = '/admin/oauth'
export const ADMIN_PROMOTIONS = '/admin/promotions'
export const ADMIN_ORDERS = '/admin/orders'
export const ADMIN_LOGO_MODS = '/admin/design'
export const ADMIN_SHIPPING = '/admin/shipping'
export const ADMIN_WEBSITE_SETTINGS = '/admin/settings'
export const ADMIN_PRINT_LOCATIONS = '/admin/printlocations'
export const ADMIN_FILES = '/admin/files'
export const ADMIN_CUSTOM_ORDER = '/admin/custom-order'
export const ADMIN_RETAIL_LOCATIONS = '/admin/retail-locations'

export const ADMIN_OAUTH_REDIRECT_URI = `${
  process.env.REACT_APP_FE_URL || 'http://localhost:3000'
}/admin/oauth`
