import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { withTheme } from 'styled-components'

// Redux
import { UserState } from 'store/reducers/userReducer'

// Components
import Navigation from 'components/Navigation'
// Types
import { ITheme } from 'types/Theme'

import { Page, Content } from 'styles/lib/page'
import { AppState } from 'store/store'
import Terms from './lib/TermsContent'
import { terms } from 'constants/terms'

interface HomeProps {
  user: UserState
  darkMode: boolean
  theme: ITheme
}

const TermsPage: React.FC<HomeProps & RouteComponentProps> = props => {
  const { theme } = props

  return (
    <Page>
      <Navigation />
      <Content>
        <Terms
          theme={theme}
          title={'Terms & Conditions'}
          description={'2022 - Bossy Panda'}
          terms={terms}
        />
      </Content>
    </Page>
  )
}
const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withTheme(TermsPage))
