import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withTheme } from 'styled-components'

// Store
import { AppState } from 'store/store'
import { UserState } from 'store/reducers/userReducer'

// Components
import {
  Hero,
  Ideas,
  Moodboards,
  ProductsPreview,
  Message,
} from 'components/Home'
import Navigation from 'components/Navigation'
import Footer from 'components/Footer'

// Types
import { ITheme } from 'types/Theme'

// Styles
import { Page, Content } from 'styles/lib/page'

// Images
import FirstImage from 'static/Table.png'
import { useQuery } from '@apollo/react-hooks'
import GET_WEBSITE_SETTINGS from '../gql/GET_WEBSITE_SETTINGS'
import Promotion from '../components/Promotion/lib/Promotion'

interface HomeProps {
  user: UserState
  darkMode: boolean
  theme: ITheme
}

const HomePage: React.FC<HomeProps & RouteComponentProps> = props => {
  const {
    user: { authenticated },
    darkMode,
    theme,
    history,
  } = props

  const [isPromotionOpen, setIsPromotionOpen] = useState(false)
  const [isMessageOpen, setIsMessageOpen] = useState(false)

  const { data: websiteSettingsData } = useQuery(GET_WEBSITE_SETTINGS)
  useEffect(() => {
    if (
      websiteSettingsData &&
      websiteSettingsData.getWebsiteSettings.displayCoupon
    ) {
      console.log(websiteSettingsData.getWebsiteSettings.displayCoupon)
      setIsPromotionOpen(true)
    }
    if (
      websiteSettingsData &&
      websiteSettingsData.getWebsiteSettings.homePageMessage
    ) {
      console.log(websiteSettingsData.getWebsiteSettings.homePageMessage)
      setIsMessageOpen(true)
    }
  }, [websiteSettingsData])

  return (
    <Page>
      {isMessageOpen && (
        <Message
          message={
            props.user.credentials.retailLocation
              ? `Welcome to Bossy Panda ${props.user.credentials.retailLocation.name}`
              : websiteSettingsData.getWebsiteSettings.homePageMessage
          }
          onClose={() => setIsMessageOpen(false)}
        />
      )}
      {isPromotionOpen && (
        <Promotion
          coupon={websiteSettingsData.getWebsiteSettings.displayCoupon}
          couponDescription="Use code: "
          onClose={() => setIsPromotionOpen(false)}
        />
      )}
      <Navigation />
      <Content>
        <Hero
          theme={theme}
          history={history}
          darkMode={darkMode}
          title={'Bossy Panda'}
          description={
            'Welcome to Bossy Panda. A jungle where you can create custom merch that stands out.'
          }
          img={FirstImage}
        />
        {!props.user.credentials.retailLocation && (
          <>
            <Moodboards theme={theme} history={history} darkMode={darkMode} />
            <Ideas
              theme={theme}
              history={history}
              darkMode={darkMode}
              title={'Your ideas, our attention to detail'}
              description={
                "Enthusiast or pro, we've got your back. Every order placed is uprezzed and hand touched by our team of pandas and approved by you."
              }
            />
            <ProductsPreview
              theme={theme}
              history={history}
              darkMode={darkMode}
              title={"Don't compromise, it's the future"}
              description={
                'Pick from our massive selection of products, and get the print you deserve.'
              }
            />
          </>
        )}
      </Content>
      <Footer
        theme={props.theme}
        history={history}
        authenticated={authenticated}
      />
    </Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withRouter(withTheme(HomePage)))
