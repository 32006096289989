import {
  TProduct,
  TView,
  TVariant,
  TAddOn,
  TPrintLocationCoordinateMap,
  TDesign,
  ITheme,
  TTempDesign,
} from 'types'

import { Flex, Box } from '@sweaterplanet/nucleus-style'

import * as ADMIN_ROUTES from 'routes/admin'

import { useMutation } from '@apollo/react-hooks'
import EDIT_PRODUCT_DESIGNS from '../../Product/lib/gql/EDIT_PRODUCT_DESIGNS'

import { Designer } from './Designer'
import DesignViewer from './DesignViewer'

import { Error } from 'utils'

import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { AppState } from '../../../store/store'
import { connect } from 'react-redux'

interface DesignStudioPageProps {
  match: {
    params: {
      productId: string
    }
  }
  location: {
    state: {
      product: TProduct
      color: TVariant
      colorIndex: number
      isStore: boolean
    }
  }
  theme: ITheme
  history: any
  darkMode: boolean
}

const DesignStudioPage: React.FC<DesignStudioPageProps> = props => {
  const { color, colorIndex, product, isStore } = props.location.state
  const { theme } = props

  const [selectedColor, setSelectedColor] = useState<TVariant>(color)
  const [activeView, setActiveView] = useState<TView | null>(
    product.inventoryGroup.views[0]
  )
  const [addOnError, setAddOnError] = useState(false)
  const [tempDesigns, setTempDesigns] = useState<TTempDesign[]>([])
  const [selectedDesign, setSelectedDesign] = useState<TTempDesign>({
    designIndex: -2,
    file: { id: '', encoding: '', filename: '', mimetype: '', url: '' },
    addOn: { available: false, id: '', name: '', publicName: '', basePrice: 0 },
    addOnIndex: -1,
    view: {
      base: '',
      boundingBox: [],
      id: '',
      name: '',
      printLocations: [],
      texture: '',
    },
    printLocation: {
      available: false,
      height: 0,
      id: '',
      name: '',
      width: 0,
    },
    printLocationIndex: -1,
    origin: { x: 0, y: 0 },
    w: 0,
    h: 0,
  })
  const [selectedPrintLocationIndex, setSelectedPrintLocationIndex] =
    useState(-1)
  const defaultImageWidth = 50
  const defaultImageHeight = 50

  useEffect(() => {
    if (product) {
      if (isStore) {
        const tempDesigns: TTempDesign[] = []
        let tempDesign: any
        product.designs.forEach((design: TDesign, index: number) => {
          tempDesign = {
            designIndex: index,
            file: design.file,
            addOn: design.addOn,
            printLocation: design.printLocation,
            origin: design.origin,
            w: design.w,
            h: design.h,
          }

          for (const view of product.inventoryGroup.views) {
            const printLocationIndex = view.printLocations.findIndex(
              (printLocationCoordinateMap: TPrintLocationCoordinateMap) =>
                printLocationCoordinateMap.printLocation.id ===
                design.printLocation.id
            )
            if (printLocationIndex !== -1) {
              tempDesign.view = view
              tempDesign.printLocationIndex = printLocationIndex
              break
            }
          }

          for (let i = 0; i < product.addOnsByLocation.length; i++) {
            if (product.addOnsByLocation[i].addOn.id === design.addOn.id) {
              tempDesign.addOnIndex = i
              break
            }
          }

          if (
            tempDesign.view === undefined ||
            tempDesign.printLocationIndex === undefined ||
            tempDesign.addOnIndex === undefined
          )
            return null
          else tempDesigns.push(tempDesign)
        })

        setTempDesigns(tempDesigns)
      }
    }
    console.log('HERE ', product.addOnsByLocation)
  }, [product, isStore])

  const handleSetActiveView = (view: TView) => {
    setActiveView(view)
  }

  const handleSetSelectedAddOn = (addOn: TAddOn | null, index: number) => {
    setAddOnError(false)

    const selectedDesignData = {
      ...selectedDesign,
      addOn: addOn,
      addOnIndex: index,
    }
    setSelectedDesign(selectedDesignData)
    const tempDesignsData = [...tempDesigns]
    tempDesignsData[selectedDesignData.designIndex] = selectedDesignData
    setTempDesigns(tempDesignsData)
  }

  const [editProductDesigns] = useMutation(EDIT_PRODUCT_DESIGNS)

  const isAddOnError = (tempDesigns: TTempDesign[]) => {
    for (const design of tempDesigns) {
      if (design.addOnIndex < 0) return true
    }
    return false
  }

  const handleUpdateProduct = () => {
    if (isAddOnError(tempDesigns)) {
      setAddOnError(true)
    } else {
      editProductDesigns({
        variables: {
          id: product.id,
          tempDesigns: tempDesigns,
        },
      })
        .then(() => {
          props.history.push({
            pathname: `${ADMIN_ROUTES.ADMIN_PRODUCTS}/${product.id}`,
            state: {
              isStore: true,
            },
          })
        })
        .catch(error => {
          return <Error message={error.message} />
        })
    }
  }

  return (
    <>
      <a
        style={{ position: 'absolute', left: '200px' }}
        href={`${ADMIN_ROUTES.ADMIN_PRODUCTS}/${props.match.params.productId}`}
      >
        {'<'} Back to Product
      </a>
      <Flex flexDirection="row" justifyContent="center" alignItems="flex-start">
        <Box margin="0 20px">
          <DesignViewer
            setActiveView={handleSetActiveView}
            activeView={activeView}
            views={product.inventoryGroup.views}
            color={selectedColor.value}
            tempDesigns={tempDesigns}
            setTempDesigns={setTempDesigns}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            showPrintLocations={true}
            selectedPrintLocationIndex={selectedPrintLocationIndex}
            setSelectedPrintLocationIndex={setSelectedPrintLocationIndex}
            isDesignStudio={true}
            isStore={isStore}
            addOnsByLocation={product.addOnsByLocation}
          />
        </Box>
        <Designer
          theme={theme}
          isStore={isStore}
          selectedColors={product.variantGroups[colorIndex].variants}
          product={product}
          activeView={activeView}
          setActiveView={setActiveView}
          defaultImageWidth={defaultImageWidth}
          defaultImageHeight={defaultImageHeight}
          tempDesigns={tempDesigns}
          setTempDesigns={setTempDesigns}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          handleSetSelectedAddOn={handleSetSelectedAddOn}
          addOnError={addOnError}
          setAddOnError={setAddOnError}
          setSelectedColor={setSelectedColor}
          selectedPrintLocationIndex={selectedPrintLocationIndex}
          handleUpdateProduct={handleUpdateProduct}
          history={props.history}
          darkMode={props.darkMode}
        />
      </Flex>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withTheme(DesignStudioPage))
