import React from 'react'
import { ADMIN_ADD_ONS } from 'routes/admin'
import AddOnForm from './AddOnForm'
import { DataTable } from 'components/Admin'
import GET_ADD_ONS from 'components/Product/lib/gql/GET_ADD_ONS'
import DELETE_ADD_ON from 'gql/DELETE_ADD_ON'

interface IAddOnTable {}

const AddOnTable: React.FC<IAddOnTable> = props => {
  return (
    <DataTable
      getDataQuery={GET_ADD_ONS}
      getDataQueryName="getSomeAddOns"
      deleteDataQuery={DELETE_ADD_ON}
      headers={['Name', 'Public Name', 'Status', 'Tags', 'BasePrice']}
      properties={['name', 'publicName', 'available', 'tags', 'basePrice']}
      ROUTE={ADMIN_ADD_ONS}
      title="Decoration Types"
    >
      {createFormProps => <AddOnForm {...createFormProps} />}
    </DataTable>
  )
}

export default AddOnTable
