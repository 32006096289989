import { gql } from 'apollo-boost'

export default gql`
  mutation updateFulfillmentStatus($orderId: ID!, $fulfillmentStatus: String!) {
    updateFulfillmentStatus(
      orderId: $orderId
      fulfillmentStatus: $fulfillmentStatus
    ) {
      fulfillmentStatus
      id
    }
  }
`
