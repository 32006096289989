import React, { useState } from 'react'
import GoogleAuthorization from './GoogleAuthorization'

import { useMutation, useQuery } from '@apollo/react-hooks'
import GET_GOOGLE_CREDENTIAL from './gql/GET_GOOGLE_CREDENTIAL'
import { Error, Loader } from 'utils'
import SEND_EMAIL from './gql/SEND_EMAIL'
import GET_EMAIL_TEMPLATES from './gql/GET_EMAIL_TEMPLATES'
import { FormStyle as F } from 'styles'

interface IEmailBotPageProps {}

interface IEmailBotProps {
  authToken: string
  refreshToken?: string
}

const EmailBot: React.FC<IEmailBotProps> = props => {
  const [to, setTo] = useState<string>('')
  const [template, setTemplate] = useState<string>('')
  const [fromName, setFromName] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName] = useState<string>('')
  const [groupName, setGroupName] = useState<string>('')
  const [organizationName] = useState<string>('')
  const [mockupId] = useState<string>('')
  const [primaryColor] = useState<string>('')
  const [sendAtTime] = useState<string>('')
  const [customLink] = useState<string>('')
  const [customHTML] = useState<string>('')

  const {
    loading: templateLoading,
    data: templateData,
    error: templateError,
    // refetch: templateRefetch,
  } = useQuery(GET_EMAIL_TEMPLATES)

  const [SendEmail] = useMutation(SEND_EMAIL, {
    variables: {
      to,
      template,
      fromName,
      firstName,
      lastName,
      groupName,
      organizationName,
      mockupId,
      primaryColor,
      sendAtTime,
      customLink,
      customHTML,
    },
  })

  const submitForm = () => {
    SendEmail().then(data => {})
  }
  const disabled =
    to === '' ||
    template === '' ||
    fromName === '' ||
    firstName === '' ||
    groupName === ''

  if (templateLoading) return <Loader />

  if (templateError) return <Error message={templateError.message} />

  if (templateData.getEmailTemplates) {
    return (
      <F.Form>
        <label>
          From Name
          <input
            placeholder={'Your Name'}
            name={'from'}
            type={'text'}
            value={fromName}
            onChange={e => setFromName(e.target.value)}
          />
        </label>
        <label>
          To Email
          <input
            placeholder={'example@sweaterplanet.com'}
            name={'to'}
            type={'text'}
            value={to}
            onChange={e => setTo(e.target.value)}
          />
        </label>
        <label>
          Template
          <select name={'template'} onChange={e => setTemplate(e.target.value)}>
            <option disabled selected value={''}>
              select
            </option>
            {templateData.getEmailTemplates.map((t: any) => (
              <option value={t.id}>{t.name}</option>
            ))}
          </select>
        </label>
        <label>
          First Name
          <input
            placeholder={'John Doe'}
            name={'firstName'}
            type={'text'}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </label>
        <label>
          groupName
          <input
            placeholder={'Sweater Planet'}
            name={'groupName'}
            type={'text'}
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
          />
        </label>

        <input
          disabled={disabled}
          type={'button'}
          onClick={() => submitForm()}
        />
      </F.Form>
    )
  } else {
    return <p>Please add templates</p>
  }
}

const EmailBotPage: React.FC<IEmailBotPageProps> = props => {
  const { loading, data, error } = useQuery(GET_GOOGLE_CREDENTIAL)

  if (loading) return <Loader />

  if (error) return <Error message={error.message} />

  if (data && data.getGoogleCredential) {
    return (
      <EmailBot
        authToken={data.getGoogleCredential.authToken}
        refreshToken={data.getGoogleCredential.refreshToken}
      />
    )
  } else {
    return <GoogleAuthorization />
  }
}

export default EmailBotPage
