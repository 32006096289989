import styled from 'styled-components'

export const Table = styled.table`
  border-collapse: collapse;
  background-color: ${p => p.theme.colors.table.background};
  color: ${p => p.theme.colors.table.text};
  th {
    text-align: left;
    @media (min-width: ${p => p.theme.breakpoints.xs}px) {
      font-size: ${p => p.theme.sizing.xs.h3}px;
      padding: ${p => p.theme.sizing.xs.a}px 0;
    }
    @media (min-width: ${p => p.theme.breakpoints.sm}px) {
      font-size: ${p => p.theme.sizing.sm.h3}px;
      padding: ${p => p.theme.sizing.sm.a}px 0;
    }
    @media (min-width: ${p => p.theme.breakpoints.md}px) {
      font-size: ${p => p.theme.sizing.md.h3}px;
      padding: ${p => p.theme.sizing.md.a}px 0;
    }
    @media (min-width: ${p => p.theme.breakpoints.lg}px) {
      font-size: ${p => p.theme.sizing.lg.h3}px;
      padding: ${p => p.theme.sizing.lg.a}px 0;
    }
    @media (min-width: ${p => p.theme.breakpoints.xl}px) {
      font-size: ${p => p.theme.sizing.xl.h3}px;
      padding: ${p => p.theme.sizing.xl.a}px 0;
    }
  }
  // tr{
  //   &:nth-child(even){
  //     background-color: ${p => p.theme.colors.table.alt};
  //   }
  // };
  td {
    text-align: left;
    @media (min-width: ${p => p.theme.breakpoints.xs}px) {
      font-size: ${p => p.theme.sizing.xs.a}px;
      padding: ${p => p.theme.sizing.xs.a}px;
    }
    @media (min-width: ${p => p.theme.breakpoints.sm}px) {
      font-size: ${p => p.theme.sizing.sm.a}px;
      padding: ${p => p.theme.sizing.sm.a}px;
    }
    @media (min-width: ${p => p.theme.breakpoints.md}px) {
      font-size: ${p => p.theme.sizing.md.a}px;
      padding: ${p => p.theme.sizing.md.a}px;
    }
    @media (min-width: ${p => p.theme.breakpoints.lg}px) {
      font-size: ${p => p.theme.sizing.lg.a}px;
      padding: ${p => p.theme.sizing.lg.a}px;
    }
    @media (min-width: ${p => p.theme.breakpoints.xl}px) {
      font-size: ${p => p.theme.sizing.xl.a}px;
      padding: ${p => p.theme.sizing.xl.a}px;
    }
  }
  tr {
    .alt {
      background-color: ${p => p.theme.colors.table.alt};
    }
  }
`

export const FooterLinkTable = styled.table`
  color: ${p => p.theme.colors.table.text};
  width: 100%;
  td {
    padding: 20px 30px;
    text-align: center;
    cursor: pointer;
  }
`

export const CartTable = styled.table`
  width: 100%;
  color: ${p => p.theme.colors.table.text};
  margin: 20px 0;
  thead {
    margin: 20px 0;
  }
  td {
    font-weight: bold;
    font-size: large;
    padding: 20px;
  }
  th {
    font-weight: initial;
    font-size: large;
    text-align: initial;
    padding: 20px;
  }
  h5 {
    margin: 5px 0;
  }
`

export const ShippingTable = styled.table`
  color: ${p => p.theme.colors.table.text};
  width: 100%;
  td {
    padding: 10px 30px;
    font-size: initial;
    font-weight: initial;
  }
  th {
    padding: 10px 20px;
    font-size: large;
    text-align: initial;
  }
  tbody tr {
    cursor: pointer;
  }
`

export const VariantSelectionsTable = styled.table`
  color: ${p => p.theme.colors.table.text};
  td {
    padding: 2px 0;
    font-size: initial;
    font-weight: initial;
  }
  th {
    padding: 20px 0;
    font-size: large;
    font-weight: initial;
  }
`

export const PrintPricesTable = styled.table`
  color: ${p => p.theme.colors.table.text};
  width: fit-content;
  td {
    padding: 10px 60px 10px 0;
    font-size: initial;
    font-weight: initial;
  }
  th {
    padding: 10px 60px 10px 0;
    font-size: large;
    font-weight: initial;
  }
`
