import React, { useState } from 'react'
import GET_SHIPPING_OPTIONS from '../../../gql/GET_SHIPPING_OPTIONS'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from '../../../utils'
import { ButtonStyle as B, TableLayout as T } from '../../../styles'
import { Flex, Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import ShippingOptionForm from './ShippingOptionForm'
import DELETE_SHIPPING_OPTION from '../../../gql/DELETE_SHIPPING_OPTION'

interface IAdminShippingTableProps {
  theme: any
}

const AdminShippingTable: React.FC<IAdminShippingTableProps> = props => {
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)
  const [option, setOption] = useState(undefined)

  const {
    loading: ShippingOptionsLoading,
    data: ShippingOptionsData,
    error: ShippingOptionsError,
    refetch,
  } = useQuery(GET_SHIPPING_OPTIONS)

  const [DeleteShippingOption] = useMutation(DELETE_SHIPPING_OPTION)
  const {
    theme: { colors },
  } = props

  const handleEdit = (i: number) => {
    setOption(ShippingOptionsData.getShippingOptions[i])
    toggleModalOpen(true)
  }
  const handleDelete = (i: number) => {
    DeleteShippingOption({
      variables: {
        id: ShippingOptionsData.getShippingOptions[i].id,
      },
    }).then(() => refetch())
  }

  const handleNew = () => {
    setOption(undefined)
    toggleModalOpen(!modalOpen)
  }
  return (
    <>
      {ShippingOptionsError && <Error message={ShippingOptionsError.message} />}
      {ShippingOptionsLoading ? (
        <Loader />
      ) : (
        ShippingOptionsData && (
          <>
            <B.Button onClick={() => handleNew()}>New</B.Button>
            <ShippingOptionForm
              visible={modalOpen}
              toggle={toggleModalOpen}
              refetch={refetch}
              shippingOption={option}
            />
            <T.ShippingTable>
              <thead>
                <tr>
                  <th>Shipping Method</th>
                  <th>Description</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {ShippingOptionsData.getShippingOptions.map(
                  (shippingOption: any, i: number) => (
                    <tr>
                      <td>
                        <Flex flexDirection="column">
                          <Text bold color={colors.text.default}>
                            {shippingOption.name}
                          </Text>
                        </Flex>
                      </td>
                      <td>
                        <Flex flexDirection="column">
                          <Text bold color={colors.text.default}>
                            {shippingOption.description}
                          </Text>
                        </Flex>
                      </td>
                      <td>
                        <Text bold color={colors.text.default}>
                          {shippingOption.price}
                        </Text>
                      </td>
                      <td>
                        <button onClick={e => handleEdit(i)}>Edit</button>
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                            outline: 'none',
                            border: 'none',
                          }}
                          onClick={e => handleDelete(i)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </T.ShippingTable>
          </>
        )
      )}
    </>
  )
}

export default withTheme(AdminShippingTable)
