import React, { useState, useEffect, FC } from 'react'
import {
  ModalLayout as M,
  ButtonStyle as B,
  FormStyle as F,
  GridLayout as G,
  DivLayout as D,
  DivStyle as DS,
} from 'styles'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import GET_ALL_INVENTORY_PROVIDERS from '../../../gql/GET_ALL_INVENTORY_PROVIDERS'
import GENERATE_INVENTORY from '../../../gql/GENERATE_INVENTORY'
import GET_INVENTORY_GROUP from './gql/GET_INVENTORY_GROUP'
import EDIT_INVENTORY from '../../../gql/EDIT_INVENTORY'
import * as ADMIN_ROUTES from 'routes/admin'
import { useHistory } from 'react-router-dom'
import MultiSelectCheckbox from '../../../utils/lib/MultiSelectCheckbox'
import GET_SOME_ADD_ONS from '../../../gql/GET_SOME_ADD_ONS'
import { TAddOn } from '../../../types'

import Markdown, { MarkdownTextArea } from '../../Markdown'

interface IInventoryFormProps {
  visible: boolean
  toggle: any
  refetch: () => void
  updateItemCode?: string
}

const InventoryForm: FC<IInventoryFormProps> = props => {
  let history = useHistory()
  const { visible, toggle, refetch, updateItemCode } = props
  const [itemCode, setItemCode] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState(`
  # hello hello hello hello hello hello hello hello

> ## hi [click me](https://bossypanda.com)

| hi | hi | hi |
|-|-|-|
| Hi | Hi | Hi |
`)
  const [styleId, setStyleId] = useState(0)
  const [sizes, setSizes] = useState(`[
    {"name": "Small", "value": "S"},
    {"name": "Medium", "value": "M"},
    {"name": "Large", "value": "L"}
    ]`)
  const [colors, setColors] = useState(`[
    {"name": "White", "value": "#FFFFFF"},
    {"name": "Black", "value": "#000000"}
    ]`)
  const [sizesValid, setSizesValid] = useState(false)
  const [colorsValid, setColorsValid] = useState(false)

  const [templateAddOnIds, setTemplateAddOnIds] = useState<String[]>([])

  const [inventoryProviderId, setInventoryProviderId] = useState('')
  const [inventoryProviders, setInventoryProviders] = useState([])

  const [priceBreaks, setPriceBreaks] = useState<number[][]>([])
  const [basePrice, setBasePrice] = useState<number>(0)

  const { loading, error, data } = useQuery(GET_ALL_INVENTORY_PROVIDERS, {
    variables: {
      searches: [],
    },
    fetchPolicy: 'cache-and-network',
  })
  const { data: inventoryGroupData } = useQuery(GET_INVENTORY_GROUP, {
    variables: { id: updateItemCode },
  })

  const { data: AddOnData } = useQuery(GET_SOME_ADD_ONS, {
    variables: {
      searches: [],
    },
  })

  useEffect(() => {
    if (data && data.getAllInventoryProviders) {
      setInventoryProviders(
        data.getAllInventoryProviders.map((i: { id: string }) => i.id)
      )
    }
  }, [data])

  useEffect(() => {
    if (updateItemCode && inventoryGroupData) {
      setItemCode(inventoryGroupData.getInventoryGroup.id)
      setName(inventoryGroupData.getInventoryGroup.name)
      setDescription(inventoryGroupData.getInventoryGroup.description)
      setStyleId(inventoryGroupData.getInventoryGroup.styleId)
      setInventoryProviderId(
        inventoryGroupData.getInventoryGroup.inventory[0]?.inventoryProvider.id
      )
      setTemplateAddOnIds(
        inventoryGroupData.getInventoryGroup.templateAddOns.map(
          (i: { id: string }) => i.id
        )
      )
      setBasePrice(inventoryGroupData.getInventoryGroup.templateBasePrice)
      if (inventoryGroupData.getInventoryGroup.templatePriceBreaks) {
        const priceBreaks: number[][] = []
        Object.entries(
          inventoryGroupData.getInventoryGroup.templatePriceBreaks
        ).forEach((pB: [string, any]) => {
          priceBreaks.push([parseInt(pB[0]), pB[1] as number])
        })
        setPriceBreaks(priceBreaks)
      }
      const sizes = inventoryGroupData.getInventoryGroup.variantGroups
        .find((vg: any) => vg.id === `${updateItemCode}-size`)
        .variants.map((v: any) => ({ name: v.name, value: v.value }))
      const colors = inventoryGroupData.getInventoryGroup.variantGroups
        .find((vg: any) => vg.id === `${updateItemCode}-color`)
        .variants.map((v: any) => ({ name: v.name, value: v.value }))
      console.log(colors, sizes)
      setSizes(JSON.stringify(sizes))
      setColors(JSON.stringify(colors))
    }
  }, [inventoryGroupData, updateItemCode])

  const [GenerateInventory] = useMutation(GENERATE_INVENTORY)
  const [editInventory] = useMutation(EDIT_INVENTORY)

  const handleSubmitClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()

    let priceBreaksData: Record<string, number> | null = null

    if (priceBreaks.length > 0) {
      const priceBreaksObject: Record<string, number> = {}
      priceBreaks.forEach((priceBreak: number[]) => {
        priceBreaksObject[priceBreak[0].toString()] = priceBreak[1]
      })

      const sortedPriceBreaks: Record<string, number> = {}
      Object.keys(priceBreaksObject)
        .sort()
        .forEach((quantity: string) => {
          sortedPriceBreaks[quantity] = priceBreaksObject[quantity]
        })

      priceBreaksData = sortedPriceBreaks
    }

    if (updateItemCode) {
      try {
        await editInventory({
          variables: {
            id: updateItemCode,
            itemCode,
            name,
            description,
            styleId,
            sizes: JSON.parse(sizes),
            colors: JSON.parse(colors),
            inventoryProviderId,
            templateAddOnIds,
            templateBasePrice: basePrice,
            templatePriceBreaks: priceBreaksData,
          },
        })
        toggle(false)
        refetch()
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const resp = await GenerateInventory({
          variables: {
            itemCode,
            name,
            description,
            styleId,
            sizes: JSON.parse(sizes),
            colors: JSON.parse(colors),
            inventoryProviderId,
            templateBasePrice: basePrice,
            templatePriceBreaks: priceBreaksData,
          },
        })
        console.log('here', resp)
        toggle(false)
        // refetch()
        history.push(
          `${ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}/${resp.data.generateInventory.id}`
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const IsJsonString = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  useEffect(() => {
    setSizesValid(IsJsonString(sizes))
  }, [sizes])
  useEffect(() => {
    setColorsValid(IsJsonString(colors))
  }, [colors])

  const handleAddPriceBreak = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    const temp = [...priceBreaks, [1, 0]]
    setPriceBreaks(temp)
  }

  const handleDeletePriceBreak = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault()
    const temp = [...priceBreaks]
    temp.splice(index, 1)
    setPriceBreaks(temp)
  }

  const handleQuantityChange = (
    newQuantity: string,
    price: number,
    index: number
  ) => {
    let num = parseInt(newQuantity)
    if (num === 0) num = 1

    const temp = [...priceBreaks]
    temp[index] = [num, price]
    setPriceBreaks(temp)
  }

  const handlePriceChange = (
    newPrice: string,
    quantity: number,
    index: number
  ) => {
    const temp = [...priceBreaks]
    temp[index] = [quantity, parseFloat(newPrice)]
    setPriceBreaks(temp)
  }

  const isPriceBreakInvalid = (priceBreaks: any[][]) => {
    if (priceBreaks?.length) {
      for (const priceBreak of priceBreaks) {
        if (isNaN(priceBreak[0]) || isNaN(priceBreak[1])) return true
      }
    }
    return false
  }

  const invalid =
    itemCode === '' ||
    name === '' ||
    styleId === 0 ||
    !sizesValid ||
    !colorsValid ||
    inventoryProviderId === '' ||
    isPriceBreakInvalid(priceBreaks)

  if (loading) return <Loader />

  if (error) return <Error message={error.message} />
  return (
    <>
      {visible && AddOnData && (
        <M.Modal>
          <h2>
            {updateItemCode ? 'Edit Inventory Group' : 'New Inventory Group'}
          </h2>
          <B.Exit onClick={() => toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Inventory Provider
              <F.select
                value={inventoryProviderId}
                onChange={e => {
                  setInventoryProviderId(e.target.value)
                }}
              >
                <option disabled selected />
                {inventoryProviders &&
                  inventoryProviders.map(inventoryProvider => (
                    <option value={inventoryProvider}>
                      {inventoryProvider}
                    </option>
                  ))}
              </F.select>
            </F.Label>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'Gildan Hoodie'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Description
              <MarkdownTextArea
                markdown={description}
                setMarkdown={setDescription}
              />
            </F.Label>
            <F.Label>
              Item Code
              <F.Input
                value={itemCode}
                type={'text'}
                placeholder={'g-18500'}
                onChange={e => setItemCode(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Style Id (integer)
              <F.Input
                value={styleId}
                type={'text'}
                placeholder={'2000'}
                onChange={e => setStyleId(parseInt(e.target.value))}
              />
            </F.Label>
            <text color={'red'}>{!sizesValid && 'sizes are invalid'}</text>
            <F.Label>
              Size Object (JSON)
              <F.Textarea
                value={sizes}
                onChange={e => setSizes(e.target.value)}
              />
            </F.Label>
            <text color={'red'}>{!colorsValid && 'colors are invalid'}</text>
            <F.Label>
              Color Object (JSON)
              <F.Textarea
                value={colors}
                onChange={e => setColors(e.target.value)}
              />
            </F.Label>
            {updateItemCode && (
              <F.Label>
                Template AddOns
                <MultiSelectCheckbox
                  options={AddOnData.getSomeAddOns.map((addOn: TAddOn) => ({
                    label: addOn.name,
                    checked: inventoryGroupData.getInventoryGroup.templateAddOns
                      .map((i: any) => i.id)
                      .includes(addOn.id),
                  }))}
                  onChange={(as: { label: string }[]) => {
                    setTemplateAddOnIds(
                      as.map(
                        a =>
                          AddOnData.getSomeAddOns.find(
                            (ad: TAddOn) => ad.name === a.label
                          ).id
                      )
                    )
                  }}
                />
              </F.Label>
            )}
            <F.Label>
              Base Price
              <F.Input
                value={basePrice}
                type={'number'}
                placeholder={'price'}
                step="0.01"
                min="0"
                onChange={e => setBasePrice(parseFloat(e.target.value))}
              />
            </F.Label>
            <F.Label>
              Template Price Breaks
              <B.Button onClick={handleAddPriceBreak}>Add Price Break</B.Button>
            </F.Label>
            <G.row width="100%" justify="space-around">
              <F.Label>Quantity</F.Label>
              <F.Label>Price</F.Label>
            </G.row>
            {priceBreaks?.map((priceBreak: number[], index: number) => (
              <G.row key={index}>
                <F.Input
                  value={priceBreak[0]}
                  type={'number'}
                  placeholder={'Quantity'}
                  min="1"
                  onChange={e =>
                    handleQuantityChange(e.target.value, priceBreak[1], index)
                  }
                />
                <F.Input
                  value={priceBreak[1]}
                  type={'number'}
                  placeholder={'Price'}
                  step="0.01"
                  min="0"
                  onChange={e =>
                    handlePriceChange(e.target.value, priceBreak[0], index)
                  }
                />
                <B.Button
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleDeletePriceBreak(event, index)}
                >
                  -
                </B.Button>
              </G.row>
            ))}
            {/* <pre>
                        {JSON.stringify(priceBreaks)}
                        {basePrice}
                    </pre> */}
            <B.Button disabled={invalid} onClick={handleSubmitClick}>
              {updateItemCode
                ? 'Edit Inventory Group'
                : 'Create Inventory Group'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default InventoryForm
