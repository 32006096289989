import React from 'react'
import styled from 'styled-components'

// Routes
import * as USER_ROUTES from 'routes/user'

// Styles
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@sweaterplanet/nucleus-style'

// Types
import { ITheme } from 'types'

// Titles
import Title from 'static/title'

// Constants
import { NAV_HEIGHT } from 'constants/styleConfig'

interface IHeroSectionSectionProps {
  theme: ITheme
  darkMode: boolean
  title: string
  description?: string
  img: string
  history: any
}

const HeroBox = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    flex-direction: column-reverse;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    flex-direction: row;
  }
`

const ImageBox = styled(Box)`
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    width: 100%;
    padding-top: 2rem;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    width: 50%;
  }
`

const CTABox = styled(Flex)`
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    width: 100%;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    width: 45%;
    margin-left: 5%;
  }
`

const ButtonsBox = styled(Flex)`
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  width: 100%;
`

const HeadingBox = styled(Box)`
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding: 0 1rem;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding: 0 2rem;
  }
`

const Hero: React.FC<IHeroSectionSectionProps> = props => {
  const {
    darkMode,
    theme: { colors },
    history,
  } = props
  return (
    <Flex
      width="100%"
      minHeight={`calc(100vh - ${2 * NAV_HEIGHT}px)`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <HeroBox>
        <ImageBox>
          <Image width="90%" src={props.img} />
        </ImageBox>
        <CTABox flexDirection="column">
          <Heading element="h1" color={colors.text.default}>
            <Title fill={darkMode ? 'white' : 'slate'} />
          </Heading>
          <Box padding="2rem 0">
            <Text color={colors.text.default}>{props.description}</Text>
          </Box>
          <ButtonsBox>
            <Button
              maxWidth
              bg="green"
              color={'white'}
              onClick={() => history.push(USER_ROUTES.PRODUCTS)}
            >
              Start Designing
            </Button>
          </ButtonsBox>
        </CTABox>
      </HeroBox>
    </Flex>
  )
}

export default Hero
