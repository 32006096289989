import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import { DivLayout as D } from 'styles'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import GET_RETAIL_LOCATION from '../../../gql/GET_RETAIL_LOCATION'
import RetailLocationCreateForm from './RetailLocationCreateForm'
import { TUser } from '../../../types'

interface RetailLocationsPageProps {
  theme: ITheme
  match: {
    params: {
      retailLocationId: string
    }
  }
}

const RetailLocationsPage: React.FC<RetailLocationsPageProps> = props => {
  const {
    theme: { colors },
  } = props
  const {
    loading,
    data,
    error: getRetailLocationsError,
    refetch: refetchRetailLocations,
  } = useQuery(GET_RETAIL_LOCATION, {
    variables: {
      id: props.match.params.retailLocationId,
    },
  })

  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  return (
    <>
      {getRetailLocationsError && (
        <Error message={getRetailLocationsError.message} />
      )}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getRetailLocation.name}
          </Heading>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Retail Location Info
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <RetailLocationCreateForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchRetailLocations}
                  retailLocationId={props.match.params.retailLocationId}
                />
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Name
                </Heading>
                <Text color={colors.text.default}>
                  {data.getRetailLocation.name}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Description
                </Heading>
                <Text color={colors.text.default}>
                  {data.getRetailLocation.description}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Location Code
                </Heading>
                <Text color={colors.text.default}>
                  {data.getRetailLocation.locationCode}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Auth Token
                </Heading>
                <Text color={colors.text.default}>
                  {data.getRetailLocation.authToken}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Manager
                </Heading>
                <Text color={colors.text.default}>
                  {data.getRetailLocation.managers.map((m: TUser) => (
                    <a href={`/admin/users/${m.id}`}>{m.name}</a>
                  ))}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Auth Url
                </Heading>
                <Text color={colors.text.default}>
                  <a
                    href={`${
                      process.env.REACT_APP_BE_URL?.includes('localhost')
                        ? 'http://localhost:3000'
                        : process.env.REACT_APP_BE_URL?.includes(
                            'nucleus-backend-dev'
                          )
                        ? 'https://dev.bossypanda.com'
                        : 'https://bossypanda.com'
                    }/signup?loc=${data.getRetailLocation.slug}`}
                  >
                    {`${
                      process.env.REACT_APP_BE_URL?.includes('localhost')
                        ? 'http://localhost:3000'
                        : process.env.REACT_APP_BE_URL?.includes(
                            'nucleus-backend-dev'
                          )
                        ? 'https://dev.bossypanda.com'
                        : 'https://bossypanda.com'
                    }/signup?loc=${data.getRetailLocation.slug}`}
                  </a>
                </Text>
              </Flex>
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(RetailLocationsPage)
