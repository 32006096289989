import React from 'react'
import GET_SOME_TAGS from './gql/GET_SOME_TAGS'
import DELETE_TAG from './gql/DELETE_TAG'
import TagCreateForm from './TagCreateForm'
import { DataTable } from '../../Admin'
import * as ADMIN_ROUTES from '../../../routes/admin'

const TagTable: React.FC = () => {
  return (
    <>
      <DataTable
        getDataQuery={GET_SOME_TAGS}
        getDataQueryName="getSomeTags"
        deleteDataQuery={DELETE_TAG}
        headers={['Id', 'Tag', 'Color']}
        properties={['id', 'tagButton', 'color']}
        ROUTE={ADMIN_ROUTES.ADMIN_TAGS}
        title="Tags"
      >
        {createFormProps => <TagCreateForm {...createFormProps} />}
      </DataTable>
    </>
  )
}

export default TagTable
