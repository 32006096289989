import { gql } from 'apollo-boost'

export default gql`
  query getGoogleCredential {
    getGoogleCredential {
      authToken
      refreshToken
    }
  }
`
