import { ITheme } from 'types/Theme'

const theme: ITheme = {
  dark: false,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    contrast: '#21272C',
    purple: {
      default: '#765ED4',
      alt: '#BAAEEA',
    },
    page: {
      background: '#F1F2F4',
    },
    text: {
      default: 'slate',
      danger: '#ff9595',
      warning: '#fffa72',
      info: '#9cffff',
    },
    nav: {
      background: '#F1F2F4',
      text: '#222',
    },
    button: {
      background: '#05998C',
      text: '#FFF',
    },
    card: {
      background: 'paper',
      background2: '#c5cdd3',
      text: '#222',
    },
    link: {
      text: '#222',
      hover: '#555',
    },
    table: {
      background: '#C5Cdd3',
      text: '#21272c',
      alt: '#e2f4ff',
    },
    loader: '#000',
    modal: {
      background: '#C5Cdd3',
      text: '#21272c',
    },
    tooltip: {
      background: '#daefff',
      text: '#333',
    },
  },
  fonts: {
    primary: 'sans-serif',
    secondary: 'Freight, serif',
    monospaced: 'Courier, monospaced',
  },
  sizing: {
    xs: {
      padding: 15,
      h1: 20,
      h2: 15,
      h3: 10,
      p: 10,
      a: 12,
    },
    sm: {
      padding: 20,
      h1: 25,
      h2: 20,
      h3: 15,
      p: 11,
      a: 13,
    },
    md: {
      padding: 20,
      h1: 30,
      h2: 20,
      h3: 15,
      p: 11,
      a: 13,
    },
    lg: {
      padding: 30,
      h1: 20,
      h2: 18,
      h3: 15,
      p: 12,
      a: 14,
    },
    xl: {
      padding: 30,
      h1: 25,
      h2: 20,
      h3: 18,
      p: 13,
      a: 15,
    },
  },
}

export default theme
