import React, { useState } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import GET_EMAIL_TEMPLATES from './gql/GET_EMAIL_TEMPLATES'
import UPSERT_EMAIL_TEMPLATE from './gql/UPSERT_EMAIL_TEMPLATE'
import {
  ButtonStyle as B,
  DivLayout as D,
  FormStyle as F,
  ModalLayout as M,
  TableLayout as T,
} from '../../../styles'

import { TEmailTemplate } from 'types'

interface IEmailTemplateCreateFormProps {
  visible: boolean
  toggle: any
  refetch: () => void
}

// Pop up modal for creating email templates
const EmailTemplateCreateForm: React.FC<
  IEmailTemplateCreateFormProps
> = props => {
  const [name, setName] = useState<string>('')
  const [html, setHtml] = useState<string>('')
  const [
    CreateTemplate,
    { called: createTemplateCalled, error: createTemplateError },
  ] = useMutation(UPSERT_EMAIL_TEMPLATE, {
    variables: {
      name,
      html,
    },
  })

  const disabled = name === '' || html === ''

  if (createTemplateError)
    return <Error message={createTemplateError.message} />

  const handleCreateClick = () => {
    CreateTemplate().then(() => {
      props.toggle(false)
      props.refetch()
    })
  }

  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>Create New Email Template</h2>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              EJS
              <F.Input
                value={html}
                type={'text'}
                placeholder={'html'}
                onChange={(e: any) => setHtml(e.target.value)}
              />
            </F.Label>
            <B.Button disabled={disabled} onClick={() => handleCreateClick()}>
              Create New Email Template
            </B.Button>
            {createTemplateCalled && <Loader />}
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

interface IEmailTemplateRowProps {
  emailTemplate: TEmailTemplate
}

const EmailTemplateRow: React.FC<IEmailTemplateRowProps> = ({
  emailTemplate,
}) => (
  <tr>
    <td>{emailTemplate.id}</td>
    <td>{emailTemplate.name}</td>
    <td>{emailTemplate.available ? 'available' : 'unavailable'}</td>
    <td>
      <B.SubtleButton>edit</B.SubtleButton>
    </td>
  </tr>
)

const EmailTemplateTable: React.FC = props => {
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(100)
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  const {
    loading: templateLoading,
    data: templateData,
    error: templateError,
    refetch: templateRefetch,
  } = useQuery(GET_EMAIL_TEMPLATES, {
    // variables: {
    //     offset: page * perPage,
    //     limit: perPage,
    // },
    fetchPolicy: 'cache-and-network',
  })

  const handleRefetch = () => {
    templateRefetch({
      // offset: page * perPage,
      // limit: perPage,
    })
  }

  const handleNewTemplateClick = () => {
    toggleModalOpen(!modalOpen)
  }

  if (templateLoading) return <Loader />

  if (templateError) return <Error message={templateError.message} />

  let templates: TEmailTemplate[] = []

  if (templateData && !templateError) {
    templates = templateData.getEmailTemplates
  }

  return (
    <>
      <EmailTemplateCreateForm
        visible={modalOpen}
        toggle={toggleModalOpen}
        refetch={handleRefetch}
      />
      <h1>Email Templates</h1>
      <T.Table>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Available</th>
          <th>
            <B.SubtleButton onClick={handleNewTemplateClick}>+</B.SubtleButton>
          </th>
        </tr>
        {templates.map((template: TEmailTemplate) => (
          <EmailTemplateRow emailTemplate={template} />
        ))}
        <tr>
          <td colSpan={2}>
            <label>
              Limit Per Page:
              <F.Input
                type="number"
                value={perPage}
                onChange={e => setPerPage(parseInt(e.target.value))}
              />
            </label>
          </td>
          <td colSpan={3}>
            <D.flex>
              <p>Page: {page + 1}</p>
              <B.SubtleButton
                disabled={page === 0}
                onClick={() => setPage(page - 1)}
              >
                Previous Page
              </B.SubtleButton>
              <B.SubtleButton
                disabled={templates.length < page * perPage}
                onClick={() => setPage(page + 1)}
              >
                Next Page
              </B.SubtleButton>
            </D.flex>
          </td>
        </tr>
      </T.Table>
    </>
  )
}

export default EmailTemplateTable
