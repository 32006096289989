import React, { useEffect, useState } from 'react'
//Styles
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Text,
} from '@sweaterplanet/nucleus-style'
import { Page } from 'styles/lib/page'
import { Content } from 'styles/lib/page'
import { withTheme } from 'styled-components'
import { Form, Input } from 'styles/lib/form'

// Themes
import { ITheme } from 'types/Theme'

// Components
import Navigation from 'components/Navigation'
import { useMutation } from '@apollo/react-hooks'
import PASSWORD_RESET from '../../../gql/PASSWORD_RESET'
import { Loader, Error } from '../../../utils'
import PASSWORD_RESET_REQUEST from '../../../gql/PASSWORD_RESET_REQUEST'
import { RouteComponentProps } from 'react-router-dom'
import { SIGN_IN } from '../../../routes/user'

interface PasswordResetProps {
  theme: ITheme
  location: {
    search: any
  }
}
const PasswordReset: React.FC<
  PasswordResetProps & RouteComponentProps
> = props => {
  const {
    theme: { colors },
  } = props

  const search = props.location.search // could be '?foo=bar'
  const params = new URLSearchParams(search)
  const token = params.get('token') // bar
  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const resetRequestInvalid = email === ''
  const resetInvalid = newPassword === ''

  const [
    PasswordResetRequest,
    {
      loading: passwordResetRequestLoading,
      data: passwordResetRequestData,
      error: passwordResetRequestError,
    },
  ] = useMutation(PASSWORD_RESET_REQUEST)
  const [
    PasswordReset,
    {
      loading: passwordResetLoading,
      data: passwordResetData,
      error: passwordResetError,
    },
  ] = useMutation(PASSWORD_RESET)

  const handleResetPasswordRequest = (e: any) => {
    e.preventDefault()
    PasswordResetRequest({
      variables: {
        email,
      },
    })
  }

  const handleResetPassword = (e: any) => {
    e.preventDefault()
    PasswordReset({
      variables: {
        token,
        password: newPassword,
      },
    })
  }

  useEffect(() => {
    if (passwordResetData) {
      props.history.push(SIGN_IN)
    }
  }, [passwordResetData, props.history])
  return (
    <>
      {!token ? (
        <Page>
          <Navigation />
          <Content>
            {passwordResetRequestLoading && <Loader />}
            {passwordResetRequestError && (
              <Error message={passwordResetRequestError.message} />
            )}
            {!passwordResetRequestData ? (
              <Card round bg={colors.card.background}>
                <Box padding="30px">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    style={{ textAlign: 'center' }}
                  >
                    <Heading element="h1" color={colors.text.default}>
                      Reset Password
                    </Heading>
                    <Box margin="20px 0">
                      <Text bold color={colors.text.default}>
                        We will send you an email with a link to reset your
                        password.
                      </Text>
                    </Box>
                  </Flex>
                  <Form>
                    <Box margin="0 20px">
                      <Input
                        value={email}
                        type={'text'}
                        placeholder={'Email'}
                        onChange={e => setEmail(e.target.value)}
                        style={{ width: '95%' }}
                      />
                    </Box>
                    <Box margin="40px 0 20px 0">
                      <Button
                        bg="green"
                        color="white"
                        maxWidth
                        disabled={resetRequestInvalid}
                        onClick={(e: any) => handleResetPasswordRequest(e)}
                      >
                        Send Password Reset Link
                      </Button>
                    </Box>
                  </Form>
                </Box>
              </Card>
            ) : (
              <Card round bg={colors.card.background}>
                <Box padding="30px">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    style={{ textAlign: 'center' }}
                  >
                    <Heading element="h1" color={colors.text.default}>
                      Reset Link Sent!
                    </Heading>
                    <Box margin="20px 0">
                      <Text bold color={colors.text.default}>
                        Check your email. If we find an account with your email,
                        we will send you a link to reset it.
                      </Text>
                    </Box>
                    <Box margin="20px 0">
                      <Text bold color={colors.text.default}>
                        Make sure to check your spam folder!
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Card>
            )}
          </Content>
        </Page>
      ) : (
        <Page>
          <Navigation />
          <Content>
            {passwordResetLoading && <Loader />}
            {passwordResetError && (
              <Error message={passwordResetError.message} />
            )}
            {!passwordResetData && (
              <Card round bg={colors.card.background}>
                <Box padding="30px">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    style={{ textAlign: 'center' }}
                  >
                    <Heading element="h1" color={colors.text.default}>
                      Create Password
                    </Heading>

                    <Box margin="20px 0">
                      <Text bold color={colors.text.default}>
                        Enter a new password for your account.
                      </Text>
                    </Box>
                  </Flex>
                  <Form>
                    <Box margin="0 20px">
                      <Input
                        value={newPassword}
                        type={'password'}
                        placeholder={'New Password'}
                        onChange={e => setNewPassword(e.target.value)}
                        style={{ width: '95%' }}
                      />
                    </Box>
                    <Box margin="40px 0 20px 0">
                      <Button
                        bg="green"
                        color="white"
                        maxWidth
                        disabled={resetInvalid}
                        onClick={(e: any) => handleResetPassword(e)}
                      >
                        Create Password
                      </Button>
                    </Box>
                  </Form>
                </Box>
              </Card>
            )}
          </Content>
        </Page>
      )}
    </>
  )
}

export default withTheme(PasswordReset)
