import React, { useState } from 'react'
import * as ADMIN_ROUTES from 'routes/admin'
import { Link } from 'react-router-dom'
import { Heading } from '@sweaterplanet/nucleus-style'

import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'

import {
  TableLayout as T,
  ButtonStyle as B,
  GridLayout as G,
  FormStyle as F,
  DivLayout as D,
} from 'styles'
import GET_INVENTORY_GROUPS from './gql/GET_INVENTORY_GROUPS'
import InventoryForm from './InventoryForm'
import { withTheme } from 'styled-components'

interface IInventoryGroupTable {
  theme?: any
}

interface IInventoryGroupRow {
  inventoryGroup: any
}

const InventoryGroupRow: React.FC<IInventoryGroupRow> = ({
  inventoryGroup,
}) => {
  return (
    <tr>
      <td>
        <F.checkbox type="checkbox" />
      </td>
      <td>
        <Link
          to={`${ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}/${inventoryGroup.id}`}
        >
          {inventoryGroup.name} ({inventoryGroup.id})
        </Link>
      </td>
      <td>{inventoryGroup.inventoryProvider}</td>
      {/*<td>*/}
      {/*  <F.select value={available} onChange={handleSelect}>*/}
      {/*    <option value={'false'}>Unavailable</option>*/}
      {/*    <option value={'true'}>Available</option>*/}
      {/*  </F.select>*/}
      {/*</td>*/}
      {/*<td>*/}
      {/*  {inventoryGroup.tags.map((tag: any) => (*/}
      {/*    <B.Tag key={tag.id} color={tag.color}>*/}
      {/*      {tag.content}*/}
      {/*    </B.Tag>*/}
      {/*  ))}*/}
      {/*  {showTagEditor && (*/}
      {/*    <>*/}
      {/*      <F.Input value={newTag} onChange={e => setNewTag(e.target.value)} />*/}
      {/*      <B.SubtleButton*/}
      {/*        onClick={() =>*/}
      {/*          UpsertTag().catch(error => <Error message={error.message} />)*/}
      {/*        }*/}
      {/*      >*/}
      {/*        add*/}
      {/*      </B.SubtleButton>*/}
      {/*      <B.Tag onClick={() => toggleTagEditor(!showTagEditor)}>-</B.Tag>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*  {!showTagEditor && (*/}
      {/*    <B.Tag onClick={() => toggleTagEditor(!showTagEditor)}>+</B.Tag>*/}
      {/*  )}*/}
      {/*</td>*/}
    </tr>
  )
}

const InventoryGroupTable: React.FC<IInventoryGroupTable> = props => {
  const [search, setSearch] = useState('')
  const [searches, setSearches] = useState<string[]>([])
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(100)
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)
  const { colors } = props.theme

  const { loading, error, data, refetch } = useQuery(GET_INVENTORY_GROUPS, {
    variables: {
      searches: searches,
      offset: page * perPage,
      limit: perPage,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <Loader />

  let inventoryGroups: any[] = []

  if (data && !error) {
    inventoryGroups = data.getSomeInventoryGroups
  }

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const searchClick = () => {
    if (search !== '') {
      setSearches([...searches, search])
      setSearch('')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchClick()
    }
  }

  const handleOnSearchRemove = (index: number) => {
    const searchesData = searches.splice(0)
    searchesData.splice(index, 1)
    setSearches(searchesData)
    refetch({
      searches: searches,
      offset: page * perPage,
      limit: perPage,
    }).catch(error => <Error message={error.message} />)
  }

  if (error) return <Error message={error.message} />

  return (
    <>
      {/* <DataTable
                getDataQuery={GET_INVENTORY_GROUPS}
                getDataQueryName="getSomeInventoryGroups"
                headers={["Name", "Status", "Tags"]}
                properties={["name", "available", "tags"]}
                ROUTE={ROUTES.ADMIN_INVENTORY_GROUPS}
                title="Inventory Groups"
            /> */}
      <G.row>
        <G.column>
          <Heading element="h2" color={colors.text.default}>
            Inventory
          </Heading>
          <InventoryForm
            visible={modalOpen}
            toggle={toggleModalOpen}
            refetch={refetch}
          />
        </G.column>
        <G.column>
          <F.Input
            value={search}
            onChange={searchChange}
            onKeyDown={handleKeyDown}
            type="text"
            placeholder="Search"
          />
        </G.column>
        <G.column>
          <B.Button onClick={searchClick}>Search</B.Button>
        </G.column>
        <G.column>
          {searches.length > 0 &&
            searches.map((tag, index) => (
              <B.Tag onClick={() => handleOnSearchRemove(index)}>{tag}</B.Tag>
            ))}
        </G.column>
      </G.row>
      <T.Table>
        <tr>
          <th>
            <F.checkbox type="checkbox" />
          </th>
          <th>Name</th>
          <th />
          <th>
            {' '}
            <B.SubtleButton onClick={() => toggleModalOpen(!modalOpen)}>
              +
            </B.SubtleButton>
          </th>
          {/*<th>Status</th>*/}
          {/*<th>Tags</th>*/}
        </tr>
        {inventoryGroups &&
          inventoryGroups.map((inventoryGroup: any) => (
            <InventoryGroupRow inventoryGroup={inventoryGroup} />
          ))}
        <tr>
          <td colSpan={2}>
            <label>
              Limit Per Page:
              <F.Input
                type="number"
                value={perPage}
                onChange={e => setPerPage(parseInt(e.target.value))}
              />
            </label>
          </td>
          <td colSpan={2}>
            <D.flex>
              <p>Page: {page + 1}</p>
              <B.SubtleButton
                disabled={page === 0}
                onClick={() => setPage(page - 1)}
              >
                Previous Page
              </B.SubtleButton>
              <B.SubtleButton
                disabled={inventoryGroups.length < page * perPage}
                onClick={() => setPage(page + 1)}
              >
                Next Page
              </B.SubtleButton>
            </D.flex>
          </td>
        </tr>
      </T.Table>
    </>
  )
}

export default withTheme(InventoryGroupTable)
