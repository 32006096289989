import { gql } from 'apollo-boost'

export default gql`
  query getPublicStores {
    getPublicStores {
      id
      name
      description
      slug
      logo {
        id
        filename
        url
      }
      bannerImg {
        id
        filename
        url
      }
      moodboard {
        id
        filename
        url
      }
    }
  }
`
