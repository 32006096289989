import { gql } from 'apollo-boost'

export default gql`
  query getSomeAddOns($searches: [String!], $offset: Int, $limit: Int) {
    getSomeAddOns(searches: $searches, offset: $offset, limit: $limit) {
      id
      name
      publicName
      updatedAt
      basePrice
      priceBreaks
      tags {
        id
        color
        icon
        content
      }
      available
      templateInventoryGroups {
        id
      }
    }
  }
`
