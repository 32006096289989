import { gql } from 'apollo-boost'

export default gql`
  mutation bulkCreateProducts(
    $inventoryGroupItemCodes: [ID!]!
    $name: String!
    $storeId: ID!
    $isDesignable: Boolean
    $file: Upload
  ) {
    bulkCreateProducts(
      inventoryGroupItemCodes: $inventoryGroupItemCodes
      name: $name
      storeId: $storeId
      isDesignable: $isDesignable
      file: $file
    ) {
      id
    }
  }
`
