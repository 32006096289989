import styled from 'styled-components'
import { NAV_HEIGHT, NAV_WIDTH } from 'constants/styleConfig'

interface IStyledNavProps {
  vertical?: boolean
}

export const Nav = styled.div<IStyledNavProps>`
  background: ${p => p.theme.colors.nav.background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${NAV_HEIGHT}px;
  color: ${p => p.theme.colors.nav.text};
  padding: 0 10px;
  ${p =>
    p.vertical
      ? `
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: inherit;
    align-items: flex-start;
    width: ${NAV_WIDTH}px;
    `
      : `
    width: -webkit-fill-available;
    `};
`

export const NavLogo = styled.svg`
  max-width: 120px;
  margin-top: 4px;
  max-height: ${NAV_HEIGHT}px;
  svg {
    fill: ${p => p.theme.colors.nav.text};
  }
`

export const NavLogoImg = styled.img`
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding: 0 ${p => p.theme.sizing.xs.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    padding: 0 ${p => p.theme.sizing.sm.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    padding: 0 ${p => p.theme.sizing.md.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding: 0 ${p => p.theme.sizing.lg.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    padding: 0 ${p => p.theme.sizing.xl.padding}px;
  }
  max-width: 100px;
  width: auto;
  max-height: calc(${NAV_HEIGHT}px - 20px);
  margin: 10px 0;
`

export const NavLinks = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: inherit;
  align-items: center;
`

export const VerticalNavLinks = styled.ul`
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NavLink = styled.li`
  list-style-type: none;
  a {
    font-family: ${p => p.theme.fonts.primary};
    text-decoration: none;
    color: ${p => p.theme.colors.nav.text};
    :hover {
      opacity: 0.5;
    }
  }
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    font-size: ${p => p.theme.sizing.xs.a}px;
    padding: ${p => p.theme.sizing.xs.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    font-size: ${p => p.theme.sizing.sm.a}px;
    padding: ${p => p.theme.sizing.sm.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    font-size: ${p => p.theme.sizing.md.a}px;
    padding: ${p => p.theme.sizing.md.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    font-size: ${p => p.theme.sizing.lg.a}px;
    padding: ${p => p.theme.sizing.lg.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    font-size: ${p => p.theme.sizing.xl.a}px;
    padding: ${p => p.theme.sizing.xl.padding}px;
  }
`
export const NavSublink = styled.li`
  font-size: 10px;
  padding: 10px 0;
  opacity: 0.8;
`
