import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_SOME_RETAIL_LOCATIONS } from 'gql/GET_SOME_RETAIL_LOCATIONS'
import { Card, Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types'
const RetailLocations = (props: { theme: ITheme }) => {
  const { data } = useQuery(GET_SOME_RETAIL_LOCATIONS)
  return (
    <>
      {data?.getSomeRetailLocations?.map((r: any) => (
        <Card
          bg={props.theme.colors.card.background}
          round
          margin="20px 0"
          padding="20px"
        >
          <div style={{ paddingBottom: '20px' }}>
            <Text color={props.theme.colors.text.default}>{r.name}</Text>
          </div>
          <Text color={props.theme.colors.text.default}>{r.description}</Text>
        </Card>
      ))}
    </>
  )
}

export default withTheme(RetailLocations)
