import { ITheme } from 'types'
import { Box, Button, Flex, Heading, Text } from '@sweaterplanet/nucleus-style'
import React from 'react'

import * as USER_ROUTES from 'routes/user'
import RetailLocations from './RetailLocations'

// Styles

interface ICompanyStoryProps {
  darkMode: boolean
  theme: ITheme
  title: string
  description?: string
}

const CompanyStory: React.FC<ICompanyStoryProps> = props => {
  const {
    theme: { colors },
  } = props
  return (
    <Box>
      <Box padding={'10px 0'}>
        <Heading element="h2" color={colors.text.default}>
          {props.title}
        </Heading>
        <Text color={colors.text.default}>{props.description}</Text>
      </Box>
      <Box padding={'10px 0'}>
        <Text color={colors.text.default}>
          Bossy Panda specializes in high quality custom printed merch
        </Text>
      </Box>
      <Box padding={'10px 0'}>
        <Text color={colors.text.default}>
          Whether you are a business, sports team, or brand looking to sell,
          Bossy Panda has your back.
        </Text>
      </Box>
    </Box>
  )
}

export default CompanyStory
