import { GET_SOME_RETAIL_LOCATIONS } from '../../../gql/GET_SOME_RETAIL_LOCATIONS'
import { useQuery } from '@apollo/react-hooks'
import { withTheme } from 'styled-components'
import { ITheme } from 'types'
import { Error, Loader } from '../../../utils'
import { TableLayout as T } from 'styles'

import React, { useEffect } from 'react'
import GET_USER from 'gql/GET_USER'
import { select } from 'styles/lib/form'

interface IPickupLocationProps {
  selectedPickupLocation: number
  setSelectedPickupLocation: (index: number) => void

  theme: ITheme
  darkMode: boolean
  user?: any
  pickupOptionsData: any
}

const PickupLocation: React.FC<IPickupLocationProps> = props => {
  const {
    setSelectedPickupLocation,
    theme: { colors },
  } = props

  const handleSelectPickupLocation = (index: number) => {
    setSelectedPickupLocation(index)
  }

  return (
    <>
      <T.ShippingTable>
        <thead>
          <tr>
            <th>Pickup Location</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.pickupOptionsData.getSomeRetailLocations.map(
            (location: any, index: any) => (
              <tr
                key={index}
                onClick={() => handleSelectPickupLocation(index)}
                style={{
                  backgroundColor:
                    props.selectedPickupLocation === index
                      ? colors.button.background
                      : 'unset',
                }}
              >
                <td>{location.name}</td>
                <td>{location.description}</td>
              </tr>
            )
          )}
        </tbody>
      </T.ShippingTable>
    </>
  )
}

export default withTheme(PickupLocation)
