import styled from 'styled-components'

export const Modal = styled.div`
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background: ${p => p.theme.colors.modal.background};
  color: ${p => p.theme.colors.modal.text};

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding: ${p => p.theme.sizing.xs.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    padding: ${p => p.theme.sizing.sm.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    padding: ${p => p.theme.sizing.md.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding: ${p => p.theme.sizing.lg.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    padding: ${p => p.theme.sizing.xl.padding}px;
  }
  overflow: auto;
`
