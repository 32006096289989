import React, { useEffect, useState } from 'react'
import Navigation from 'components/Navigation'
import { PageLayout as P } from 'styles'
import { Link, useHistory } from 'react-router-dom'
import { ACCOUNT, CART } from 'routes/user'
import { Heading, Box, Flex } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types'
import { useMutation } from '@apollo/react-hooks'
import CONFIRM_PAYMENT from '../../../gql/CONFIRM_PAYMENT'
import { useLocation } from 'react-router-dom'
import { Loader } from '../../../utils'
import { setCartNumber } from 'store/actions/userActions'
import { AppState } from '../../../store/store'
import { connect } from 'react-redux'

interface IConfirmationProps {
  theme: ITheme
  setCartNumber: (value: number) => void
  history: any
  match: {
    params: {
      orderId: string
    }
  }
}

const ConfirmationPage: React.FC<IConfirmationProps> = props => {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const history = useHistory()
  const payInStore = queryParams.get('pay_in_store')
  const paymentIntent = queryParams.get('payment_intent')
  const redirectStatus = queryParams.get('redirect_status')
  const [confirmed, setConfirmed] = useState(payInStore === 'true')

  const [
    ConfirmPayment,
    { loading: ConfirmPaymentLoading, error: ConfirmPaymentError },
  ] = useMutation(CONFIRM_PAYMENT)
  useEffect(() => {
    if (!confirmed) {
      if (paymentIntent && redirectStatus) {
        ConfirmPayment({
          variables: {
            redirect_status: redirectStatus,
            payment_intent: paymentIntent,
            orderId: props.match.params.orderId,
          },
        }).then(() => {
          props.setCartNumber(0)
          queryParams.delete('payment_intent')
          queryParams.delete('redirect_status')
          queryParams.delete('payment_intent_client_secret')
          setConfirmed(true)
          history.replace({
            search: queryParams.toString(),
          })
        })
      } else {
        props.history.push({
          pathname: ACCOUNT,
        })
      }
    } else if (payInStore) {
      props.setCartNumber(0)
      queryParams.delete('payment_intent')
      queryParams.delete('redirect_status')
      queryParams.delete('pay_in_store')
      queryParams.delete('payment_intent_client_secret')
      history.replace({
        search: queryParams.toString(),
      })
    }
    // eslint-disable-next-line
  }, [])

  const {
    theme: { colors },
  } = props

  return (
    <P.Page>
      <Navigation />
      <P.Content>
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {ConfirmPaymentLoading ? (
            <Loader />
          ) : (
            <>
              {confirmed ? (
                <>
                  <Box margin="50px 0 10px 0">
                    <Heading element="h1" color={colors.text.default}>
                      {payInStore === 'true' ? <>Thank You!</> : <>Approved!</>}
                      !
                    </Heading>
                  </Box>
                  <Box margin="10px 0">
                    <Heading element="h2" color={colors.text.default}>
                      {payInStore === 'true' ? (
                        <>Please proceed to the front desk to pay.</>
                      ) : (
                        <>Thanks for your order!</>
                      )}
                    </Heading>
                  </Box>
                  <Box margin="10px 0">
                    <Heading element="h3" color={colors.text.default}>
                      Check your email for updates!
                    </Heading>
                  </Box>
                  {/*<Box margin="70px 0 20px 0">*/}
                  {/*  <Heading element="h2" color={colors.text.default}>*/}
                  {/*    In the meantime...*/}
                  {/*  </Heading>*/}
                  {/*</Box>*/}
                  {/*<iframe*/}
                  {/*  title="Youtube Video"*/}
                  {/*  width="560"*/}
                  {/*  height="315"*/}
                  {/*  src="https://www.youtube.com/embed/ujU9d3Z4Y-g"*/}
                  {/*  frameBorder="0"*/}
                  {/*  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                  {/*  allowFullScreen*/}
                  {/*/>*/}
                </>
              ) : (
                <>
                  {ConfirmPaymentError && (
                    <>
                      <Box margin="50px 0 10px 0">
                        <Heading element="h1" color={colors.text.default}>
                          Something went wrong...
                        </Heading>
                      </Box>
                      <Box margin="10px 0">
                        <Heading element="h2" color={colors.text.default}>
                          Status: {redirectStatus}
                        </Heading>
                      </Box>
                      <Box margin="10px 0 50px 0">
                        <Heading element="h3" color={colors.text.default}>
                          Message: {redirectStatus}
                        </Heading>
                      </Box>
                      <Link to={CART}>
                        <Heading element="h2" color={colors.text.default}>
                          Click here to try again
                        </Heading>
                      </Link>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      </P.Content>
    </P.Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
})

const mapActionsToProps = {
  setCartNumber,
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTheme(ConfirmationPage))
