import { gql } from 'apollo-boost'

export default gql`
  {
    me {
      role
      id
      billingAccount {
        id
        address {
          id
          firstName
          lastName
          business
          email
          address1
          address2
          city
          state
          country
          postCode
          phone
        }
        name
        cc
        expMonth
        expYear
      }
      shippingAddresses {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      defaultShippingAddress {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      billingAddresses {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      defaultBillingAddress {
        id
        firstName
        lastName
        business
        email
        address1
        address2
        city
        state
        country
        postCode
        phone
      }
      cart {
        id
        cartItems {
          id
          product {
            id
            name
            basePrice
            priceBreaks
            inventoryGroup {
              id
              views {
                id
                boundingBox
                texture
                base
                printLocations {
                  id
                  printLocation {
                    id
                    name
                    price
                  }
                  coordinateMap
                  rotate
                }
              }
            }
          }
          variantSelection {
            id
            variants {
              id
              name
              value
              available
              price
              variantGroup {
                id
                name
              }
            }
            quantity
            customization
          }
          designs {
            id
            printLocation {
              id
              name
              price
            }
            addOn {
              id
              name
              publicName
              basePrice
              priceBreaks
            }
            file {
              id
              url
              thumbnail
            }
            origin
            w
            h
          }
        }
      }
    }
  }
`
