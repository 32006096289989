import React, { useState, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useMediaQuery } from 'react-responsive'
// Components
import { Loader, Error } from 'utils'
// Styles
// gql
import * as USER_ROUTES from 'routes/user'

import {
  Card,
  Flex,
  Tag,
  Heading,
  Button,
  Box,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'

import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import GET_PUBLIC_STORES from './gql/GET_PUBLIC_STORES'
import { TStore } from 'types'

interface ICollectionsListProps {
  theme: ITheme
  location: {
    search: any
  }
  history: any
}

const CollectionsList: React.FC<
  ICollectionsListProps & RouteComponentProps
> = props => {
  const { loading, error, data, refetch } = useQuery(GET_PUBLIC_STORES)

  const { theme } = props

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 750px)' })

  return (
    <Flex flexDirection={isTabletOrMobile ? 'column' : 'row'} width="100%">
      {error && <Error message={error.message} />}
      <Box>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow="1"
        >
          {loading && <Loader />}
          <Flex
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="center"
            padding="20px"
          >
            {data &&
              data.getPublicStores &&
              data.getPublicStores.length > 0 &&
              data.getPublicStores.map((store: TStore, index: number) => (
                <Box margin="20px" key={index}>
                  <a href={`${USER_ROUTES.STORE}/${store.slug}`}>
                    <Card
                      round
                      bg={theme.colors.card.background}
                      width="400px"
                      padding="20px"
                    >
                      <Box margin="20px" key={index}>
                        <Flex
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box margin=" 0 20px 0">
                            <Heading
                              element="h3"
                              color={theme.colors.text.default}
                            >
                              {store.name}
                            </Heading>
                          </Box>
                          <img
                            src={store.logo?.url || store.bannerImg?.url || ''}
                            alt={store.name}
                            style={{ width: '200px' }}
                          />
                        </Flex>
                      </Box>
                    </Card>
                  </a>
                </Box>
              ))}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default withRouter(withTheme(CollectionsList))
