import React from 'react'
import WarehouseCreateForm from './WarehouseCreateForm'
import { DataTable } from '../../Admin'
import GET_ALL_INVENTORY_PROVIDERS from '../../../gql/GET_ALL_INVENTORY_PROVIDERS'
import DELETE_INVENTORY_PROVIDER from '../../../gql/DELETE_INVENTORY_PROVIDER'
import * as ADMIN_ROUTES from '../../../routes/admin'

const WarehouseTable: React.FC = () => {
  return (
    <>
      <DataTable
        getDataQuery={GET_ALL_INVENTORY_PROVIDERS}
        getDataQueryName="getAllInventoryProviders"
        deleteDataQuery={DELETE_INVENTORY_PROVIDER}
        headers={['Name', 'Id', 'Value']}
        properties={['name', 'id', 'value']}
        ROUTE={ADMIN_ROUTES.ADMIN_INVENTORY_PROVIDERS}
        title="Warehouses"
      >
        {createFormProps => <WarehouseCreateForm {...createFormProps} />}
      </DataTable>
    </>
  )
}

export default WarehouseTable
