import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { DocumentNode } from 'graphql'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import * as ADMIN_ROUTES from 'routes/admin'

import {
  TableLayout as T,
  ButtonStyle as B,
  GridLayout as G,
  FormStyle as F,
  DivLayout as D,
  ModalLayout as M,
} from 'styles'
import { Flex, Heading } from '@sweaterplanet/nucleus-style'

import { TTag } from 'types/Tag'
import { ProductTags } from 'components/Tag'
import Mockup from '../../Mockup'
import TruncateString from '../../../utils/lib/TruncateString'
import { withTheme } from 'styled-components'
import { TUser } from '../../../types'

const row = (
  property: string,
  title: string,
  ROUTE: string,
  item: any,
  refetch?: () => void,
  upgradeUser?: any,
  downgradeUser?: any
) => {
  if (title === 'Coupons') {
    if (property === 'id') {
      return (
        <td>
          <Link to={`${ROUTE}/${item.id}`}>{item.id}</Link>
        </td>
      )
    } else if (property === 'name') {
      return <td>{item.name}</td>
    } else if (property === 'value') {
      return (
        <td>
          {item.value} {item.type.includes('PERCENT') ? '%' : '$'}
        </td>
      )
    } else if (property === 'maxUses') {
      return (
        <td>
          {item.uses} / {item.maxUses}
        </td>
      )
    } else if (property === 'validUntil') {
      return <td>{new Date(item.validUntil).toDateString()}</td>
    }
  } else if (title === 'Tags') {
    if (property === 'id') {
      return (
        <td>
          <Link to={`${ROUTE}/${item.id}`}>{item.id}</Link>
        </td>
      )
    }
  } else if (title === 'Stores') {
    if (property === 'logo') {
      return <td>{item.logo ? item.logo.filename : 'N/A'}</td>
    } else if (property === 'bannerImg') {
      return <td>{item.bannerImg ? item.bannerImg.filename : 'N/A'}</td>
    } else if (property === 'moodboard') {
      return <td>{item.moodboard ? item.moodboard.filename : 'N/A'}</td>
    } else if (property === '#products') {
      return <td>{item.products.length}</td>
    } else if (property === 'revenue') {
      return (
        <td>
          $
          {item.products
            .flatMap((p: { OrderItem: any[] }) =>
              p.OrderItem.filter(
                oI => oI.Order.paid || oI.Order.payInStoreLocation?.id
              ).flatMap((oI: { subtotal: number }) => oI.subtotal)
            )
            .reduce((partialSum: number, a: number) => partialSum + a, 0)
            .toFixed(2)}
        </td>
      )
    } else if (property === 'orders') {
      return (
        <td>
          {
            Array.from(
              new Set(
                item.products.flatMap((p: { OrderItem: any[] }) =>
                  p.OrderItem.filter(
                    oI => oI.Order.paid || oI.Order.payInStoreLocation?.id
                  ).flatMap((oI: { Order: { id: number } }) => oI.Order.id)
                )
              )
            ).length
          }
        </td>
      )
    } else if (property === 'purchased') {
      return (
        <td>
          {item.products
            .flatMap((p: { OrderItem: { createdAt: string }[] }) =>
              p.OrderItem.map((oI: { createdAt: string }) => oI.createdAt)
            )
            .reduce(
              (maxDateString: string, currDateString: string) =>
                maxDateString > currDateString ? maxDateString : currDateString,
              ''
            )
            ? new Date(
                item.products
                  .flatMap((p: { OrderItem: { createdAt: string }[] }) =>
                    p.OrderItem.map((oI: { createdAt: string }) => oI.createdAt)
                  )
                  .reduce(
                    (maxDateString: string, currDateString: string) =>
                      maxDateString > currDateString
                        ? maxDateString
                        : currDateString,
                    ''
                  ) * 1
              )
                .toISOString()
                .split('T')[0]
            : 'N/A'}
        </td>
      )
    }
  } else if (title === 'Products') {
    if (property === 'tags' && refetch) {
      return (
        <td>
          <ProductTags product={item} refetch={refetch} />
        </td>
      )
    }
  } else if (title === 'Users') {
    if (property === 'googleId') {
      return <td>{item.googleId ? '✅' : ''}</td>
    }
  }
  if (title === 'Files') {
    if (property === 'filename') {
      return (
        <td>
          <a target={'_blank'} rel="noopener noreferrer" href={item.url}>
            {TruncateString(item.filename, 30)}
          </a>
        </td>
      )
    }
    if (property === 'url') {
      return (
        <td>
          <img width={'100px'} src={item.url} alt={item.url} />
        </td>
      )
    } else if (property === 'owner') {
      return (
        <td>
          <Link to={`${ADMIN_ROUTES.ADMIN_USERS}/${item.owner.id}`}>
            {item.owner.name}
          </Link>
        </td>
      )
    }
  }

  if (title === 'Orders') {
    if (property === 'mockup') {
      return (
        <td>
          {item.orderItems[0] && (
            <Mockup
              view={item.orderItems[0]?.product.inventoryGroup.views[0]}
              color={
                item.orderItems[0]?.variantSelection &&
                item.orderItems[0]?.variantSelection.length > 0
                  ? item.orderItems[0]?.variantSelection[0].value
                  : 'slate'
              }
              width="4rem"
              height="4rem"
              designs={item.orderItems[0]?.designs}
              isActiveView={true}
              isSleeveCustomization={
                !!item.orderItems[0]?.variantSelection[0]?.customization
              }
            />
          )}
        </td>
      )
    } else if (property === 'owner') {
      return <td>{item.User.name}</td>
    } else if (property === 'fulfillmentStatus') {
      return <td>{item.fulfillmentStatus}</td>
    } else if (property === 'id') {
      return (
        <td>
          <Link to={`${ROUTE}/${item.id}`}>{item.id}</Link>
        </td>
      )
    } else if (property === 'date') {
      return <td>{new Date(item.createdAt * 1).toLocaleDateString()}</td>
    }
  }

  if (title === 'Retail Locations') {
    if (property === 'managers.name') {
      return <td>{item.managers.map((m: TUser) => m.name + ', ')}</td>
    } else if (property === 'managers.email') {
      return <td>{item.managers.map((m: TUser) => m.email + ', ')}</td>
    }
  }

  if (property === 'name') {
    return (
      <td>
        <Link to={`${ROUTE}/${item.id}`}>{item.name}</Link>
      </td>
    )
  } else if (property === 'available') {
    return <td>{item.available ? 'Available ✅' : 'Unavailable ❌'}</td>
  } else if (property === 'price' || property === 'basePrice') {
    return <td>$ {item[property]}</td>
  } else if (property === 'tags') {
    return (
      <td>
        {item.tags.map((tag: TTag) => (
          <B.Tag key={tag.id} color={tag.color}>
            {tag.content}
          </B.Tag>
        ))}
      </td>
    )
  } else if (property === 'inventoryGroup') {
    return <td>{item.inventoryGroup.name}</td>
  } else if (property === 'tagButton') {
    return (
      <td>
        <B.Tag color={item.color}>
          {item.icon} {item.content}
        </B.Tag>
      </td>
    )
  } else if (property === 'coupon') {
    return <td>{item.coupon.name}</td>
  } else {
    return <td>{item[property]}</td>
  }
}

interface DataRowProps {
  item: any
  title: string
  properties: string[]
  ROUTE: string
  refetch?: () => void
}

const DataRow: React.FC<DataRowProps> = props => {
  const { item, properties, ROUTE, refetch, title } = props
  return (
    <tr>
      <td>
        <F.checkbox type="checkbox" />
      </td>
      {properties.map((property: string, index: number) => (
        <Fragment key={index}>
          {row(property, title, ROUTE, item, refetch)}
        </Fragment>
      ))}
    </tr>
  )
}

interface DataRowWithDeleteProps {
  item: any
  title: string
  properties: string[]
  ROUTE: string
  refetch?: () => void
  deleteDataQuery: DocumentNode
}

const DataRowWithDelete: React.FC<DataRowWithDeleteProps> = props => {
  const { item, properties, ROUTE, refetch, deleteDataQuery, title } = props

  const [showModal, setShowModal] = useState(false)

  const [DeleteItem, { error }] = useMutation(deleteDataQuery, {
    variables: {
      id: item.id,
    },
  })

  const handleDeleteItem = async () => {
    const status = await DeleteItem()
    if (status && refetch) refetch()
  }

  return (
    <>
      <tr>
        <td>
          <F.checkbox type="checkbox" />
        </td>
        {properties.map((property: string, index: number) => (
          <Fragment key={index}>
            {row(property, title, ROUTE, item, refetch)}
          </Fragment>
        ))}
        <td>
          <B.DangerButton onClick={() => setShowModal(true)}>
            Delete
          </B.DangerButton>

          {showModal && (
            <M.Modal style={{ background: 'yellow', color: 'black' }}>
              <h2>Are you sure you want to delete this?</h2>
              <p>
                Once this is deleted, it'll be wiped from our database and gone
                forever.
              </p>
              <B.Exit
                style={{ color: 'black' }}
                onClick={() => setShowModal(false)}
              >
                X
              </B.Exit>

              <Flex flexDirection="row" justifyContent="space-evenly">
                <B.DangerButton onClick={() => handleDeleteItem()}>
                  Delete
                </B.DangerButton>
              </Flex>
            </M.Modal>
          )}
        </td>
      </tr>
      {error && <Error message={error.message} />}
    </>
  )
}

interface DataTableProps {
  getDataQuery: DocumentNode
  getDataQueryName: string
  deleteDataQuery?: DocumentNode
  headers: string[]
  properties: string[]
  ROUTE: string
  title: string
  children?: (props: any) => React.ReactChild
  theme?: any
  hideInactiveProducts?: boolean
}

const DataTable: React.FC<DataTableProps> = props => {
  const {
    getDataQuery,
    getDataQueryName,
    deleteDataQuery,
    headers,
    properties,
    ROUTE,
    title,
    theme,
    hideInactiveProducts,
  } = props
  const { colors } = theme

  const [search, setSearch] = useState('')
  const [searches, setSearches] = useState<string[]>([])
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(100)
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  const { loading, error, data, refetch } = useQuery(getDataQuery, {
    variables: {
      searches: searches,
      offset: page * perPage,
      limit: perPage,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleRefetch = () => {
    refetch({
      searches: [],
      offset: page * perPage,
      limit: perPage,
    }).catch(error => <Error message={error.message} />)
  }

  if (loading) return <Loader />

  let dataList: any[] = []

  if (data && !error) {
    dataList = data[getDataQueryName]
    if (hideInactiveProducts) {
      dataList = dataList.filter((item: any) => item.available)
    }
  }

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const searchClick = () => {
    if (search !== '') {
      setSearches([...searches, search])
      setSearch('')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchClick()
    }
  }

  const handleOnSearchRemove = (index: number) => {
    const searchesData = searches.splice(0)
    searchesData.splice(index, 1)
    setSearches(searchesData)
    refetch().catch(error => <Error message={error.message} />)
  }

  const handleNewClick = () => {
    toggleModalOpen(!modalOpen)
  }

  if (error) return <Error message={error.message} />

  return (
    <>
      {props.children &&
        props.children({
          visible: modalOpen,
          toggle: toggleModalOpen,
          refetch: handleRefetch,
        })}
      {/* <AddOnCreateForm visible={modalOpen} toggle={toggleModalOpen} refetch={handleRefetch}/> */}
      <G.row>
        <G.column>
          <Heading element="h2" color={colors.text.default}>
            {title}
          </Heading>
        </G.column>
        <G.column>
          <F.Input
            value={search}
            onChange={searchChange}
            onKeyDown={handleKeyDown}
            type="text"
            placeholder="Search"
          />
        </G.column>
        <G.column>
          <B.Button onClick={searchClick}>Search</B.Button>
        </G.column>
        <G.row>
          {searches.length > 0 &&
            searches.map((tag, index) => (
              <B.Tag onClick={() => handleOnSearchRemove(index)}>{tag}</B.Tag>
            ))}
        </G.row>
      </G.row>
      <T.Table>
        <thead>
          <tr>
            <th>
              <F.checkbox type="checkbox" />
            </th>
            {headers.map((header: string, index: number) => (
              <th key={index}>{header}</th>
            ))}
            {props.children && (
              <th>
                <B.SubtleButton onClick={handleNewClick}>+</B.SubtleButton>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {dataList &&
            dataList.map((item: any, index: number) => (
              <>
                {deleteDataQuery ? (
                  <DataRowWithDelete
                    key={index}
                    item={item}
                    refetch={handleRefetch}
                    properties={properties}
                    deleteDataQuery={deleteDataQuery}
                    ROUTE={ROUTE}
                    title={title}
                  />
                ) : (
                  <DataRow
                    key={index}
                    item={item}
                    refetch={handleRefetch}
                    properties={properties}
                    ROUTE={ROUTE}
                    title={title}
                  />
                )}
              </>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>
              <label>
                Limit Per Page:
                <F.Input
                  type="number"
                  value={perPage}
                  onChange={e => setPerPage(parseInt(e.target.value))}
                />
              </label>
            </td>
            <td colSpan={3}>
              <D.flex>
                <p>Page: {page + 1}</p>
                <B.SubtleButton
                  disabled={page === 0}
                  onClick={() => setPage(page - 1)}
                >
                  Previous Page
                </B.SubtleButton>
                <B.SubtleButton
                  disabled={dataList && dataList.length < page * perPage}
                  onClick={() => setPage(page + 1)}
                >
                  Next Page
                </B.SubtleButton>
              </D.flex>
            </td>
          </tr>
        </tfoot>
      </T.Table>
    </>
  )
}

export default withTheme(DataTable)
