import { PageLayout as P, ButtonStyle as B } from 'styles'
import React from 'react'
import * as ADMIN_ROUTES from '../../routes/admin'
import { useHistory } from 'react-router-dom'

interface ICustomOrderPageProps {}

const CustomOrderPage: React.FC<ICustomOrderPageProps> = () => {
  const history = useHistory()
  return (
    <P.Page>
      <P.Content>
        <h1>Custom Order Page</h1>
        <B.Button
          onClick={() =>
            history.push(`${ADMIN_ROUTES.ADMIN_CUSTOM_ORDER}/scratch}`)
          }
        >
          Create from Scratch
        </B.Button>
        <B.Button disabled>Create from Order</B.Button>
        <B.Button disabled>Create from Cart Item</B.Button>
        <B.Button disabled>Create from Store</B.Button>
      </P.Content>
    </P.Page>
  )
}

export default CustomOrderPage
