import React, { useEffect, useState } from 'react'
import { AppState } from 'store/store'
import { connect } from 'react-redux'
import { UserState } from 'store/reducers/userReducer'
import * as USER_ROUTES from 'routes/user'
import { Redirect } from 'react-router-dom'
import Navigation from 'components/Navigation'
import { useQuery } from '@apollo/react-hooks'
// import Upload from 'components/Upload'
import {
  PageLayout as P,
  TableLayout as T,
  // ButtonStyle as B,
  HrStyle as Hr,
} from 'styles'
import { Error, Loader } from 'utils'
// import DELETE_FILE from 'gql/DELETE_FILE'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheck } from '@fortawesome/free-solid-svg-icons'
import AccountDetail from './AccountDetails'
import { Flex, Heading, Box, Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import GET_USER from 'gql/GET_USER'
import { TVariant } from 'types/Variant'
import { TOrder } from 'types/Order'
import { TVariantSelection } from 'types/VariantSelection'
import Order from './Order'

interface AccountProps {
  user: UserState
  theme: ITheme
}

const AccountPage: React.FC<AccountProps> = props => {
  const {
    user: { authenticated },
    theme: { colors },
  } = props
  const { loading, data, error, refetch } = useQuery(GET_USER, {
    errorPolicy: 'all',
  })
  // const [removeFile] = useMutation(DELETE_FILE)

  const [selectedColorsByOrder, setSelectedColorsByOrder] = useState<
    TVariant[][]
  >([])

  useEffect(() => {
    refetch().catch(error => <Error message={error.message} />)
  })

  useEffect(() => {
    if (data && data.me.orders) {
      let selectedColorIds: string[] = []
      let selectedColors: TVariant[] = []
      const selectedColorsByOrder: TVariant[][] = []

      data.me.orders.forEach((order: TOrder) => {
        order.orderItems[0]?.variantSelection.forEach(
          (variantSelection: TVariantSelection) => {
            // Get Colors of Order for the first OrderItem
            for (let variant of variantSelection.variants) {
              if (
                variant.variantGroup.name === 'Color' &&
                !selectedColorIds.includes(variant.id)
              ) {
                selectedColorIds.push(variant.id)
                selectedColors.push(variant)
                break
              }
            }
          }
        )

        selectedColorsByOrder.push(selectedColors)
      })

      setSelectedColorsByOrder(selectedColorsByOrder)
    }
  }, [data])

  // const handleAddFile = () => {
  //   const fileInput = document.getElementById('imageInput')
  //   if (fileInput !== null) fileInput.click()
  // }
  //
  // const handleRemoveFile = async (id: string) => {
  //   try {
  //     const status = await removeFile({ variables: { id: id } })
  //     if (status.data.deleteFile) await refetch()
  //   } catch (error) {
  //     return <Error message={error.message} />
  //   }
  // }

  const [showDetails, setShowDetails] = useState<boolean[]>([])

  return (
    <P.Page>
      <Navigation />
      <P.Content>
        {authenticated ? (
          <>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              alignItems="flex-start"
              width="100%"
            >
              {error && <Error message={error.message} />}
              <Box minWidth="400px" maxWidth="100%" marginRight="4rem">
                <AccountDetail
                  user={data ? data.me : undefined}
                  refetch={refetch}
                />
              </Box>
              <Box
                width="fit-content"
                float="right"
                maxWidth="100%"
                marginTop="2rem"
                style={{ overflow: 'hidden' }}
              >
                <Box marginBottom="2rem">
                  <Heading element="h2" color={colors.text.default}>
                    Orders
                  </Heading>
                </Box>
                <Hr.Line thickness="1px" />
                <Box width="min-content" maxWidth="100%" whiteSpace="nowrap">
                  <T.CartTable>
                    <thead>
                      <tr>
                        <Text bold color={props.theme.colors.contrast}>
                          Order Details
                        </Text>
                        {/* <th>Status</th>
                        <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <tr>
                          <td colSpan={1}>
                            <Loader />
                          </td>
                        </tr>
                      )}
                      {data &&
                        data.me.orders &&
                        data.me.orders
                          .filter(
                            (order: TOrder) =>
                              order.paid || order.payInStoreLocation
                          )
                          .map((order: TOrder, index: number) => (
                            <div>
                              <Order
                                key={index}
                                order={order}
                                selectedColors={selectedColorsByOrder[index]}
                                showDetails={showDetails[index]}
                                setShowDetails={showDetails =>
                                  setShowDetails(prevShowDetails => {
                                    const newShowDetails = [
                                      ...prevShowDetails,
                                    ].map(() => false)
                                    newShowDetails[index] = showDetails
                                    return newShowDetails
                                  })
                                }
                              />
                            </div>
                          ))}
                    </tbody>
                  </T.CartTable>
                </Box>
              </Box>
            </Flex>
          </>
        ) : (
          <Redirect to={USER_ROUTES.SIGN_IN} />
        )}
      </P.Content>
    </P.Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTheme(AccountPage))
