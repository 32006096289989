import React from 'react'

// Types
import { ITheme } from 'types/Theme'

// Styles
import { Page, Content } from 'styles/lib/page'
import { withTheme } from 'styled-components'
import {
  Flex,
  Box,
  Text,
  Heading,
  Image,
  Button,
} from '@sweaterplanet/nucleus-style'

// Components
import Navigation from 'components/Navigation'
import { UserState } from 'store/reducers/userReducer'
import CompanyStory from 'components/Section/lib/CompanyStory'
import ContactUsSection from 'components/Section/lib/ContactUs'
import AboutImage from 'static/Store.jpg'
import RetailLocations from 'components/Section/lib/RetailLocations'

interface IContactUsPageProps {
  user: UserState
  darkMode: boolean
  theme: ITheme
}

const ContactUs: React.FC<IContactUsPageProps> = props => {
  const { darkMode } = props
  const { colors } = props.theme

  return (
    <Page>
      <Navigation />
      <Content>
        <Box>
          <Box padding={'10px 0'}>
            <Heading element="h2" color={colors.text.default}>
              Get in touch
            </Heading>
          </Box>
          <Box padding={'10px 0'}>
            <Text color={colors.text.default}>
              Bossy Panda is reachable via email, phone or in person at our
              retail locations.
            </Text>
          </Box>
          <Box padding={'10px 0'}>
            <Text color={colors.text.default}>
              <a href="mailto:info@bossypanda.com">info@bossypanda.com</a>
            </Text>
            <Text color={colors.text.default}>
              <a href="Tel:+1 438-299-5909">+1 438-299-5909</a>
            </Text>
          </Box>
          <Box padding={'10px 0'}>
            <Flex width={'100%'} padding={'10px 0'}>
              <a
                href={'https://koalendar.com/e/meet-with-bossy-panda'}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                <Button bg="green" color="white">
                  Book a meeting
                </Button>
              </a>
            </Flex>
          </Box>
          <Box paddingTop="40px">
            <Heading element="h2" color={colors.text.default}>
              Visit Us
            </Heading>
            <Text color={colors.text.default}>
              Visit us in store and we will help you design your merch at one of
              our retail locations!
            </Text>
            <RetailLocations />
          </Box>
        </Box>

        <Box>
          <ContactUsSection
            theme={props.theme}
            darkMode={darkMode}
            title={'Get In Touch'}
            description={
              'Want to collab? Have an issue with the website? Get in touch!'
            }
          />
        </Box>
      </Content>
    </Page>
  )
}

export default withTheme(ContactUs)
