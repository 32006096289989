import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Loader, Error, setToken } from 'utils'
import { ApolloQueryResult } from 'apollo-boost'
import { TView } from 'types/View'
import SINGLE_UPLOAD from 'gql/SINGLE_UPLOAD'
import { AppState } from '../../store/store'
import { signIn } from '../../store/actions/userActions'
import { connect } from 'react-redux'
import { TUser } from '../../types/User'
import { useDropzone } from 'react-dropzone'
import { ModalLayout as M, ButtonStyle as B } from 'styles'
import {
  Flex,
  // Tag,
  Heading,
  // Button,
  // Box,
  // Image,
  Text,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from '../../types/Theme'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'styles/lib/button'
import MiniGallery from 'components/Gallery/lib/MiniGallery'

interface uploadProps {
  refetch?:
    | ((
        variables?: Record<string, any> | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | ((
        variables?:
          | {
              searches: string[]
            }
          | undefined
      ) => Promise<ApolloQueryResult<any>>)

  view?: TView
  printLocationIndex?: number
  handleDesignStudioUpload?: (File: any) => void

  handleProductsPagePreview?: (file: any) => void

  signIn: (user: TUser) => void
  theme: ITheme
  hidden?: boolean
  fullWidth?: boolean
}

const Upload: React.FC<uploadProps> = props => {
  const [singleUpload, { loading, error }] = useMutation(SINGLE_UPLOAD)
  const [modalOpen, setModalOpen] = useState(false)

  const {
    refetch,
    view,
    printLocationIndex,
    handleDesignStudioUpload,
    handleProductsPagePreview,
    theme: { colors },
  } = props
  const onDrop = useCallback(
    async acceptedFiles => {
      try {
        const FILE = await singleUpload({
          variables: { file: acceptedFiles[0] },
        })
        if (FILE.data) {
          // Guest Account
          if (FILE.data.singleUpload.auth) {
            setToken(FILE.data.singleUpload.auth.token)
            props.signIn(FILE.data.singleUpload.auth.user)
          }

          // Products Page
          if (handleProductsPagePreview) {
            handleProductsPagePreview(FILE.data.singleUpload.file)
            console.log(FILE.data.singleUpload.file)
          }

          // Design Studio
          if (handleDesignStudioUpload) {
            handleDesignStudioUpload(FILE.data.singleUpload.file)
            document.getElementById('refetchGallery')?.click()
          }

          // Account Page & Products Page
          if (refetch) {
            refetch().catch(error => <Error message={error.message} />)
          }
        }
      } catch (error: any) {
        return <Error message={error.message} />
      }
    },
    [
      handleDesignStudioUpload,
      handleProductsPagePreview,
      props,
      refetch,
      singleUpload,
    ]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  })
  const [isShown, setIsShown] = useState(false)

  if (loading) return <Loader />
  if (error) return <Error message={error.message} />

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        height={props.hidden ? '0px' : '100px'}
        width={props.fullWidth ? '100%' : props.hidden ? '0px' : '100px'}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          border: `2px ${isDragActive ? 'solid' : 'dashed'} ${
            isDragActive || isShown ? colors.button.background : colors.contrast
          }`,
          cursor: 'pointer',
          visibility: props.hidden && 'hidden',
        }}
        {...getRootProps()}
      >
        <input
          {...getInputProps()}
          accept=".jpeg, .jpg, .png, .svg, .ico, .pdf"
          id={`upload-click-me-${view?.id}-${printLocationIndex}`}
        />
        {isDragActive || isShown ? (
          <FontAwesomeIcon
            icon={faCloudUploadAlt as any}
            size="3x"
            color={colors.button.background}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCloudUploadAlt as any}
            size="3x"
            color={colors.contrast}
          />
        )}
      </Flex>
    </>
    // <input
    //   id={
    //     printLocationIndex !== undefined && view
    //       ? `imageInput${view.id}/${printLocationIndex}`
    //       : 'imageInput'
    //   }
    //   type="file"
    //   accept=".jpeg, .jpg, .png, .svg, .ico"
    //   required
    //   hidden={true}
    //   onChange={onChange}
    //   onClick={onInputClick}
    // />
  )
}

const mapStateToProps = (state: AppState) => ({})

const mapActionsToProps = {
  signIn,
}

export default connect(mapStateToProps, mapActionsToProps)(withTheme(Upload))
