import { gql } from 'apollo-boost'

export default gql`
  mutation editAddOn(
    $id: ID!
    $name: String!
    $publicName: String
    $basePrice: Float!
    $available: Boolean!
    $description: String!
    $priceBreaks: JSONObject
  ) {
    editAddOn(
      id: $id
      name: $name
      publicName: $publicName
      basePrice: $basePrice
      available: $available
      description: $description
      priceBreaks: $priceBreaks
    ) {
      id
    }
  }
`
