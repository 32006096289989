import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader, ColorPicker } from 'utils'
import { VariantPicker } from 'components/Design'
import Mockup from 'components/Mockup'
import { Heading } from '@sweaterplanet/nucleus-style'
import { Card, Flex, Button, Text, Box } from '@sweaterplanet/nucleus-style'
import {
  ButtonStyle as B,
  FormStyle as F,
  DivLayout as D,
  CardStyle as C,
  ModalLayout as M,
} from 'styles'
import { TVariant, TView } from 'types'
import * as ADMIN_ROUTES from 'routes/admin'
import { Link } from 'react-router-dom'
import GET_INVENTORY_GROUP from './gql/GET_INVENTORY_GROUP'
import UPSERT_VIEW from './gql/UPSERT_VIEW'
import SINGLE_UPLOAD from '../../../gql/SINGLE_UPLOAD'
import InventoryForm from './InventoryForm'
import { withTheme } from 'styled-components'
import DUPLICATE_INVENTORY from 'gql/DUPLICATE_INVENTORY'
import DELETE_INVENTORY_GROUP from './gql/DELETE_INVENTORY_GROUP'
import DELETE_VIEW from './gql/DELETE_VIEW'

interface IInventoryGroupPageProps {
  match: {
    params: {
      inventoryGroupId: string
    }
  }
  history: any
  theme?: any
}

const InventoryGroup: React.FC<IInventoryGroupPageProps> = props => {
  const { colors } = props.theme

  const { loading, data, error, refetch } = useQuery(GET_INVENTORY_GROUP, {
    variables: { id: props.match.params.inventoryGroupId },
  })
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  const [selectedColor, setSelectedColor] = useState<TVariant>({
    id: '',
    name: '',
    value: '#808080',
    available: true,
    variantGroup: {
      id: '',
      name: 'Color',
      variants: [],
    },
  })

  // Duplicate Inventory
  const [duplicateInventory] = useMutation(DUPLICATE_INVENTORY, {
    variables: {
      id: props.match.params.inventoryGroupId,
    },
  })

  const [isWarningOpen, setIsWarningOpen] = useState(false)

  const [deleteInventory] = useMutation(DELETE_INVENTORY_GROUP, {
    variables: {
      itemCode: props.match.params.inventoryGroupId,
    },
    onCompleted: () => {
      props.history.push(ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS)
    },
  })

  const [setViewWarningOpen, setSetViewWarningOpen] = useState<
    string | undefined
  >(undefined)

  const [deleteView] = useMutation(DELETE_VIEW, {
    variables: {
      id: setViewWarningOpen,
    },
    onCompleted: () => {
      refetch()
      setSetViewWarningOpen(undefined)
    },
  })

  const [upsertView] = useMutation(UPSERT_VIEW, {
    onCompleted: () => {
      refetch()
    },
  })

  const updateView = (view: TView) => {
    upsertView({
      variables: {
        id: view.id,
        name: view.name,
        boundingBox: view.boundingBox,
        base: view.base,
        texture: view.texture,
        inventoryGroupId: props.match.params.inventoryGroupId,
      },
    })
  }

  if (error) {
    return <Error message={error.message} />
  }
  if (loading && !data) {
    return <Loader />
  }

  return (
    <>
      {data && (
        <>
          <D.flex justify={'space-between'}>
            <h1 style={{ paddingRight: '20px' }}>
              {data.getInventoryGroup.name}
            </h1>

            <D.flex justify={'space-around'}>
              <B.SubtleButton
                onClick={(e: any) => {
                  e.preventDefault()
                  duplicateInventory()
                    .then((res: any) => {
                      console.log(res)
                      props.history.push(
                        `${ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}/${res.data.duplicateInventoryGroup.id}`
                      )
                    })
                    .catch((err: any) => {
                      console.log(err)
                    })
                }}
              >
                Duplicate
              </B.SubtleButton>
              <div style={{ marginLeft: '20px' }}>
                <Box
                  margin="10px 0 10px 0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsWarningOpen(true)}
                >
                  <Text color="red">Delete Inventory Group</Text>
                </Box>
              </div>
              {isWarningOpen && (
                <M.Modal>
                  <Box marginBottom="20px">
                    <Heading element="h4" color={'red'}>
                      Are you sure you want to delete this inventory group?
                    </Heading>
                  </Box>
                  <Flex flexDirection="row" justifyContent="space-evenly">
                    <Button
                      bg="red"
                      color="paper"
                      onClick={() => deleteInventory()}
                    >
                      Delete Inventory Group
                    </Button>
                    <Button onClick={() => setIsWarningOpen(false)}>
                      Cancel
                    </Button>
                  </Flex>
                </M.Modal>
              )}
            </D.flex>
          </D.flex>
          <B.Button onClick={() => toggleModalOpen(!modalOpen)}>Edit</B.Button>
          <InventoryForm
            visible={modalOpen}
            toggle={toggleModalOpen}
            refetch={refetch}
            updateItemCode={props.match.params.inventoryGroupId}
          />
          {/*<U.status color={data.getInventoryGroup.available ? 'green' : 'red'}>*/}
          {/*  <span>●</span>*/}
          {/*  {data.getInventoryGroup.available ? 'available' : 'unavailable'}*/}
          {/*</U.status>*/}
          <D.flex wrap={'wrap'} align={'flex-start'} justify={'center'}>
            <C.card>
              <Heading element="h2" color={colors.text.default}>
                Variant Management
              </Heading>

              {data.getInventoryGroup.variantGroups.map((variantGroup: any) => (
                <>
                  <h4>{variantGroup.name}</h4>
                  <D.iv maxWidth={'400px'}>
                    {variantGroup.name === 'Color' ? (
                      <ColorPicker
                        colors={variantGroup.variants}
                        setColor={setSelectedColor}
                      />
                    ) : (
                      <VariantPicker variants={variantGroup.variants} />
                    )}
                  </D.iv>
                </>
              ))}
            </C.card>
            <C.card maxWidth={'600px'} minWidth={'300px'}>
              <h3>Create View</h3>
              <ViewCreateForm inventoryGroupId={data.getInventoryGroup.id} />
            </C.card>
            <C.card maxWidth={'600px'}>
              <h3>Manage Views</h3>
              <D.flex>
                {data.getInventoryGroup.views.map(
                  (view: TView, index: number) => {
                    return (
                      <D.pad v={'20px'} h={'20px'}>
                        <D.flex>
                          <h4>{view.name}</h4>
                          {index !== 0 && (
                            <Box
                              margin="10px 0 10px 0"
                              style={{ cursor: 'pointer' }}
                              onClick={() => updateView(view)}
                            >
                              <Text color="blue">⬆</Text>
                            </Box>
                          )}
                          <Link
                            to={`${ADMIN_ROUTES.ADMIN_PRINT_LOCATION_EDITOR}/${view.id}`}
                          >
                            Edit
                          </Link>
                          <Box
                            margin="10px 0 10px 0"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSetViewWarningOpen(view.id)}
                          >
                            <Text color="red">Delete</Text>
                          </Box>
                          {setViewWarningOpen === view.id && (
                            <M.Modal>
                              <Box marginBottom="20px">
                                <Heading element="h4" color={'red'}>
                                  Are you sure you want to delete this view?
                                </Heading>
                              </Box>
                              <Flex
                                flexDirection="row"
                                justifyContent="space-evenly"
                              >
                                <Button
                                  bg="red"
                                  color="paper"
                                  onClick={() => deleteView()}
                                >
                                  Delete View
                                </Button>
                                <Button
                                  onClick={() =>
                                    setSetViewWarningOpen(undefined)
                                  }
                                >
                                  Cancel
                                </Button>
                              </Flex>
                            </M.Modal>
                          )}
                        </D.flex>
                        <Mockup
                          key={view.id}
                          view={view}
                          color={selectedColor.value}
                        />
                      </D.pad>
                    )
                  }
                )}
              </D.flex>
            </C.card>
          </D.flex>
        </>
      )}
    </>
  )
}

interface IViewCreateProps {
  inventoryGroupId: string
}

const ViewCreateForm: React.FC<IViewCreateProps> = props => {
  const [name, setName] = useState<string>('')
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [base, setBase] = useState<string>('')
  const [texture, setTexture] = useState<string>('')

  const boundingBox = [
    { x: 0, y: 0 },
    { x: width, y: 0 },
    { x: 0, y: height },
    { x: width, y: height },
  ]

  const [CreateView] = useMutation(UPSERT_VIEW, {
    variables: {
      name,
      boundingBox,
      base,
      texture,
      inventoryGroupId: props.inventoryGroupId,
    },
  })
  const [singleUpload] = useMutation(SINGLE_UPLOAD)

  const invalid =
    name === '' || width === 0 || height === 0 || base === '' || texture === ''

  // // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
  // const onInputClick = (
  //   event: React.MouseEvent<HTMLInputElement, MouseEvent>
  // ) => {
  //   const element = event.target as HTMLInputElement
  //   element.value = ''
  // }

  const onChange = async ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      try {
        const FILE = await singleUpload({ variables: { file } })
        if (FILE.data) {
          console.log(FILE.data)
          setTexture(FILE.data.singleUpload.file.url)
        }
      } catch (error: any) {
        return <Error message={error.message} />
      }
    }
  }

  return (
    <F.Form>
      <F.Label>
        Name
        <F.Input
          value={name}
          type={'text'}
          placeholder={'Gildan 18500 Front'}
          onChange={e => setName(e.target.value)}
        />
      </F.Label>
      <F.Label>
        Width
        <F.Input
          value={width}
          type={'number'}
          placeholder={'500'}
          onChange={e => setWidth(+e.target.value)}
        />
      </F.Label>
      <F.Label>
        Height
        <F.Input
          value={height}
          type={'number'}
          placeholder={'600'}
          onChange={e => setHeight(+e.target.value)}
        />
      </F.Label>
      <F.Label>
        Base Path
        <F.Input
          value={base}
          type={'text'}
          placeholder={'M307.16,865.11c4.95,0'}
          onChange={e => setBase(e.target.value)}
        />
      </F.Label>
      <F.Label>
        Texture Url
        <F.Input
          value={texture}
          type={'text'}
          placeholder={'https://example.com'}
          onChange={e => setTexture(e.target.value)}
        />
      </F.Label>
      <F.Label>
        Upload Texture
        <F.upload
          type="file"
          accept=".png"
          onChange={onChange}
          // onClick={onInputClick}
        />
      </F.Label>
      <B.Button
        disabled={invalid}
        onClick={e => {
          e.preventDefault()
          CreateView().catch(error => <Error message={error.message} />)
        }}
      >
        Create View
      </B.Button>
    </F.Form>
  )
}

export default withTheme(InventoryGroup)
