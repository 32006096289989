import React, { useEffect, useState, Fragment } from 'react'

import { TCartItem } from 'types/CartItem'
import { TVariant } from 'types/Variant'
import { TVariantSelection } from 'types/VariantSelection'
import { TDesign } from 'types/Design'
import { connect } from 'react-redux'
import { ApolloQueryResult } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import Mockup from 'components/Mockup'
import { removeFromCart } from 'store/actions/userActions'
import { HrStyle as Hr, TableLayout as T, FormStyle as F } from 'styles'
import { Error, ColorPicker } from 'utils'
import DELETE_CARTITEM from 'gql/DELETE_CARTITEM'
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

interface ICartItemProps {
  cartItem: TCartItem
  cartItemTotal: number
  printLocationIds: string[]
  quantity: number
  removeFromCart: () => void
  refetch: (
    variables?: Record<string, any> | undefined
  ) => Promise<ApolloQueryResult<any>>
  bulkPrice: number
  selectedColors: TVariant[]
  printCost: number
  addOnBulkPrice: Record<string, number>
  sleeveCustomizationCostByVariantSelection: number[]
  theme: ITheme
}

const CartItem: React.FC<ICartItemProps> = props => {
  const [deleteCartItem] = useMutation(DELETE_CARTITEM)
  const [uniquePrintLocationIndex, setUniquePrintLocationIndex] = useState<
    number[]
  >([])
  const [clicked, setClicked] = useState(false)

  const {
    cartItem: { id, product, variantSelection, designs },
    cartItemTotal,
    printLocationIds,
    quantity,
    bulkPrice,
    selectedColors,
    printCost,
    addOnBulkPrice,
    sleeveCustomizationCostByVariantSelection,
    theme: { colors },
  } = props

  console.log(selectedColors)

  useEffect(() => {
    // Save the index of the unique PrintLocations which will be used to display when clicked
    // Array.from(new Set(printLocationIds)) returns a list without duplicates
    const uniquePrintLocationIndex: number[] = []
    Array.from(new Set(printLocationIds)).forEach((uniqueId: string) => {
      const index = printLocationIds.findIndex((id: string) => id === uniqueId)
      if (index !== -1) uniquePrintLocationIndex.push(index)
    })
    setUniquePrintLocationIndex(uniquePrintLocationIndex)
  }, [printLocationIds, props.cartItem])

  const handleDeleteClick = async (id: string) => {
    try {
      const status = await deleteCartItem({ variables: { id: id } })
      if (status.data) props.removeFromCart()

      // Need 2 refetch() for FE to render properly
      await props.refetch()
      await props.refetch()
    } catch (error: any) {
      return <Error message={error.message} />
    }
  }

  return (
    <Fragment key={id}>
      <tr>
        <td>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            minWidth="80%"
            width="fill-available"
            maxWidth="100%"
            whiteSpace="nowrap"
          >
            <Box marginRight="2rem">
              <FontAwesomeIcon
                icon={clicked ? faChevronDown : (faChevronRight as any)}
                color={colors.contrast}
                cursor="pointer"
                onClick={() => setClicked(!clicked)}
              />
            </Box>
            <Box marginRight="2rem">
              <Mockup
                view={product.inventoryGroup.views[0]}
                color={
                  selectedColors && selectedColors.length > 0
                    ? selectedColors[0].value
                    : '#808080'
                }
                width="4rem"
                height="4rem"
                designs={designs}
                isActiveView={true}
                isSleeveCustomization={!!variantSelection[0]?.customization}
              />
            </Box>
            <Flex flexDirection="column">
              <Box margin="5px 0">
                <Heading element="h4" color={colors.text.default}>
                  {product.name}
                </Heading>
              </Box>
              <Box margin="5px 0">
                <ColorPicker
                  colors={
                    selectedColors && selectedColors.length > 0
                      ? selectedColors
                      : []
                  }
                />
              </Box>
              <Box margin="5px 0">
                <Text color={colors.text.default}>
                  Variants: {variantSelection.length}
                </Text>
              </Box>
              <Flex flexDirection="row">
                <Box margin="5px 10px 5px 0" width="fit-content">
                  <Link
                    size="xs"
                    color="blue"
                    onClick={() => setClicked(!clicked)}
                  >
                    View
                  </Link>
                </Box>
                <Box margin="5px 0" width="fit-content">
                  <Link
                    size="xs"
                    color="red"
                    onClick={() => handleDeleteClick(id)}
                  >
                    Remove
                  </Link>
                </Box>
              </Flex>
              <Box
                margin="5px 0"
                width="fit-content"
                style={{ visibility: clicked ? 'hidden' : 'visible' }}
              ></Box>
            </Flex>
          </Flex>
        </td>
        <td>{quantity}</td>
        <td>${formatter.format(bulkPrice + printCost)}</td>
        <td>
          <Flex flexDirection="column" position="absolute">
            ${formatter.format(cartItemTotal)}
            {/*<Box marginTop="1rem">*/}
            {/*  <Button bg="slate" color="paper" size="xs">*/}
            {/*    Edit*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </Flex>
        </td>
      </tr>
      {clicked && (
        <>
          <tr>
            <td colSpan={5}>
              <Flex flexDirection="column">
                <Box margin="40px 0 0 0">
                  <Text bold color={colors.text.default}>
                    Base Prices
                  </Text>
                </Box>
                <T.VariantSelectionsTable>
                  <thead>
                    <tr>
                      <th>Color</th>
                      <th>Size</th>
                      <th>Base Price</th>
                      <th>Decoration ($)</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variantSelection.map(
                      (variantSelection: TVariantSelection, index: number) => {
                        const colorIndex = variantSelection.variants.findIndex(
                          (variant: TVariant) =>
                            variant.variantGroup.name === 'Color'
                        )
                        const color = variantSelection.variants[colorIndex]
                        const sizeIndex = variantSelection.variants.findIndex(
                          (variant: TVariant) =>
                            variant.variantGroup.name === 'Size'
                        )
                        const size = variantSelection.variants[sizeIndex]

                        const basePrice =
                          bulkPrice +
                          (color.price ? color.price : 0) +
                          (size.price ? size.price : 0)
                        return (
                          <tr key={index}>
                            <td>
                              <Flex flexDirection="row" alignItems="center">
                                <F.Color type="button" color={color.value} />
                                <Box marginLeft="10px">
                                  <Text color={colors.text.default}>
                                    {color.name}
                                  </Text>
                                </Box>
                              </Flex>
                            </td>
                            <td>
                              <Text color={colors.text.default}>
                                {size.name}
                              </Text>
                            </td>
                            <td>
                              <Text color={colors.text.default}>
                                ${formatter.format(basePrice)}
                              </Text>
                            </td>
                            <td>
                              <Text color={colors.text.default}>
                                {printCost +
                                  sleeveCustomizationCostByVariantSelection[
                                    index
                                  ] ===
                                0
                                  ? 'FREE'
                                  : '+' +
                                    formatter.format(
                                      printCost +
                                        sleeveCustomizationCostByVariantSelection[
                                          index
                                        ]
                                    )}
                              </Text>
                            </td>
                            <td>
                              <Text color={colors.text.default}>
                                {variantSelection.quantity}
                              </Text>
                            </td>
                            <td>
                              <Text color={colors.text.default}>
                                $
                                {formatter.format(
                                  basePrice +
                                    printCost +
                                    sleeveCustomizationCostByVariantSelection[
                                      index
                                    ]
                                )}
                              </Text>
                            </td>
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </T.VariantSelectionsTable>
                {designs && designs.length > 0 && (
                  <>
                    <Box margin="40px 0 20px 0">
                      <Text bold color={colors.text.default}>
                        Decoration Prices
                      </Text>
                    </Box>
                    <T.PrintPricesTable>
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Decoration Location</th>
                          <th>Decoration Method</th>
                        </tr>
                      </thead>
                      <tbody>
                        {designs.map((design: TDesign, index: number) => (
                          <tr key={index}>
                            <td>
                              <Image
                                src={design.file.thumbnail || design.file.url}
                                width="100px"
                                height="100px"
                                style={{ objectFit: 'contain' }}
                              />
                            </td>
                            <td>
                              <Flex flexDirection="column">
                                <Text bold color={colors.text.default}>
                                  {design.printLocation.name}
                                </Text>
                                <Box>
                                  <Text color={colors.text.default}>
                                    {design.printLocation.price &&
                                    uniquePrintLocationIndex.includes(index)
                                      ? '$' +
                                        formatter.format(
                                          design.printLocation.price
                                        )
                                      : ''}
                                  </Text>
                                </Box>
                              </Flex>
                            </td>
                            <td>
                              <Flex flexDirection="column">
                                <Text bold color={colors.text.default}>
                                  {design.addOn.publicName || design.addOn.name}
                                </Text>
                                <Box>
                                  <Text color={colors.text.default}>
                                    {addOnBulkPrice[design.addOn.id] > 0
                                      ? '$' +
                                        formatter.format(
                                          addOnBulkPrice[design.addOn.id]
                                        )
                                      : 'FREE'}
                                  </Text>
                                </Box>
                              </Flex>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </T.PrintPricesTable>
                  </>
                )}
                {variantSelection[0].customization && (
                  <>
                    <Box margin="40px 0 20px 0">
                      <Text bold color={colors.text.default}>
                        Sleeve Customizations
                      </Text>
                    </Box>
                    <T.VariantSelectionsTable>
                      <thead>
                        <tr>
                          <th>Color</th>
                          <th>Size</th>
                          <th>Price</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {variantSelection.map(
                          (
                            variantSelection: TVariantSelection,
                            index: number
                          ) => {
                            const colorIndex =
                              variantSelection.variants.findIndex(
                                (variant: TVariant) =>
                                  variant.variantGroup.name === 'Color'
                              )
                            const color = variantSelection.variants[colorIndex]
                            const sizeIndex =
                              variantSelection.variants.findIndex(
                                (variant: TVariant) =>
                                  variant.variantGroup.name === 'Size'
                              )
                            const size = variantSelection.variants[sizeIndex]

                            return (
                              variantSelection.customization && (
                                <tr key={index}>
                                  <td>
                                    <Flex
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <F.Color
                                        type="button"
                                        color={color.value}
                                      />
                                      <Box marginLeft="10px">
                                        <Text color={colors.text.default}>
                                          {color.name}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </td>
                                  <td>
                                    <Text color={colors.text.default}>
                                      {size.name}
                                    </Text>
                                  </td>
                                  <td>
                                    {sleeveCustomizationCostByVariantSelection[
                                      index
                                    ] === 0
                                      ? 'FREE'
                                      : '$' +
                                        formatter.format(
                                          sleeveCustomizationCostByVariantSelection[
                                            index
                                          ]
                                        )}
                                  </td>
                                  <td>
                                    <Text color={colors.text.default}>
                                      {variantSelection.customization.name}
                                    </Text>
                                  </td>
                                </tr>
                              )
                            )
                          }
                        )}
                      </tbody>
                    </T.VariantSelectionsTable>
                  </>
                )}
              </Flex>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <Hr.Line />
            </td>
          </tr>
        </>
      )}
    </Fragment>
  )
}

const mapStateToProps = () => ({})

const mapActionsToProps = {
  removeFromCart,
}

export default connect(mapStateToProps, mapActionsToProps)(withTheme(CartItem))
