import { gql } from 'apollo-boost'

export default gql`
  mutation upsertRetailLocation(
    $id: ID
    $name: String!
    $description: String
    $locationCode: String
    $authToken: String
    $slug: String
  ) {
    upsertRetailLocation(
      id: $id
      name: $name
      description: $description
      locationCode: $locationCode
      authToken: $authToken
      slug: $slug
    ) {
      id
      name
      description
      locationCode
      slug
      authToken
      managers {
        id
        name
        email
      }
    }
  }
`
