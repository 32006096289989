import styled from 'styled-components'

interface ILineProps {
  color?: string
  thickness?: string
  marginTop?: string
  marginBottom?: string
}
export const Line = styled.hr<ILineProps>`
  width: 100%;
  background-color: ${p => p.theme.colors.card.text};
  border: 1px solid ${p => p.theme.colors.card.text};
  margin-bottom: ${p => p.marginBottom || '0px'};
  margin-top: ${p => p.marginTop || '0px'};
  height: ${p => p.thickness || '2px'};
`
