import { DataActionTypes } from 'types/actions'

export interface DataState {}

const initialState: DataState = {}

export default (state = initialState, action: DataActionTypes): DataState => {
  switch (action.type) {
    default:
      return state
  }
}
