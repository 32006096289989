import { gql } from 'apollo-boost'

export default gql`
  query getManagedStore($id: ID!) {
    getManagedStore(id: $id) {
      orders {
        id
        createdAt
        updatedAt
        fulfillmentStatus
        billingStatus
        price
        paid
        shippingPrice
        payInStoreLocation {
          id
          name
        }
        User {
          name
        }
        pickupLocation {
          id
          name
        }
        orderItems {
          subtotal
          id
          product {
            id
            name
            inventoryGroup {
              id
              views {
                id
                boundingBox
                texture
                base
                printLocations {
                  id
                  printLocation {
                    id
                    name
                    price
                  }
                  coordinateMap
                  rotate
                }
              }
            }
          }
          variantSelection {
            id
            variants {
              id
              name
              value
              available
              price
              variantGroup {
                id
                name
              }
            }
            quantity
            customization
          }
          designs {
            id
            printLocation {
              id
              name
              price
            }
            addOn {
              id
              name
              publicName
              basePrice
              priceBreaks
            }
            file {
              id
              url
              thumbnail
            }
            origin
            w
            h
          }
        }
        shipping {
          address1
          address2
          city
          state
          postCode
          country
          firstName
          lastName
          email
        }
      }
      store {
        id
        name
        slug
        description
        whitelabelNav
        isPublic
        managers {
          id
          name
          email
        }
        logo {
          id
          url
          filename
        }
        bannerImg {
          id
          url
          filename
        }
        moodboard {
          id
          url
          filename
        }
        products {
          id
          name
          basePrice
          priceBreaks
          available
          description
          isDesignable
          defaultColor
          orderCount
          createdAt
          tags {
            id
            content
            color
          }
          variantGroups {
            id
            name
            variants {
              id
              name
              value
            }
          }
          addOnsByLocation {
            id
            addOn {
              id
              name
              publicName
              available
              basePrice
              priceBreaks
            }
            location {
              id
              name
              width
              height
            }
          }
          inventoryGroup {
            id
            name
            variantGroups {
              id
              name
              variants {
                id
                value
                name
              }
            }
            views {
              id
              name
              boundingBox
              base
              texture
              printLocations {
                id
                printLocation {
                  id
                  name
                  available
                  width
                  height
                }
                coordinateMap
              }
            }
          }
          designs {
            id
            file {
              id
              url
              filename
              thumbnail
            }
            addOn {
              id
              name
              publicName
              basePrice
              priceBreaks
            }
            printLocation {
              id
              name
              price
            }
            rotate
            flip
            vectorize
            origin
            w
            h
          }
          thumbnail {
            url
          }
          OrderItem {
            subtotal
            createdAt
            Order {
              id
              paid
              payInStoreLocation {
                id
              }
            }
          }
        }
      }
    }
  }
`
