import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { signIn, setCartNumber, signOut } from 'store/actions/userActions'

// Components
import { Loader, Error, destroyToken } from 'utils'
import { setToken } from 'utils'

// Types
import { TUser } from 'types/User'
import { connect } from 'react-redux'
import { AppState } from 'store/store'
import { ITheme } from 'types/Theme'

// Routes
import * as USER_ROUTES from 'routes/user'

// Styles
import { Flex, Button, Box, Text } from '@sweaterplanet/nucleus-style'

import { Form, Input } from 'styles/lib/form'

import LOGIN from 'gql/LOGIN'
import SignInGoogle from './SignInGoogle'
import { useMediaQuery } from 'react-responsive'

interface SignInFormProps {
  signIn: (user: TUser) => void
  signOut: () => void
  setCartNumber: (num: number) => void
  theme: ITheme
  state?: AppState
  storeId?: string
}

const SignInForm: React.FC<SignInFormProps> = props => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const {
    theme: { colors },
  } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [SignIn, { called, loading, data, error }] = useMutation(LOGIN, {
    variables: { email, password },
  })

  const invalid = password === '' || email === ''

  if (called && loading) return <Loader />

  if (data) {
    setToken(data.login.token)
    props.signIn(data.login.user)
    if (data.login.user.cart)
      props.setCartNumber(data.login.user.cart.cartItems.length)
    return (
      <Redirect
        to={
          props.storeId
            ? `${USER_ROUTES.STORE}/${props.storeId}`
            : USER_ROUTES.HOME
        }
      />
    )
  }

  const handleSignUp = async () => {
    try {
      await SignIn()
    } catch (error: any) {
      destroyToken()
      signOut()
      return <Error message={error.message} />
    }
  }

  return (
    <>
      {error && <Error message={error.message} />}
      {(!data || error) && (
        <Form>
          {isTabletOrMobile ? (
            <>
              <Box marginRight="10px">
                <Input
                  value={email}
                  type={'text'}
                  placeholder={'Email'}
                  onChange={e => setEmail(e.target.value)}
                />
              </Box>
              <Box marginLeft="10px">
                <Input
                  value={password}
                  type={'password'}
                  placeholder={'Password'}
                  onChange={e => setPassword(e.target.value)}
                />
              </Box>
            </>
          ) : (
            <Flex width="100%" justifyContent="space-between">
              <Box marginRight="10px">
                <Input
                  value={email}
                  type={'text'}
                  placeholder={'Email'}
                  onChange={e => setEmail(e.target.value)}
                />
              </Box>
              <Box marginLeft="10px">
                <Input
                  value={password}
                  type={'password'}
                  placeholder={'Password'}
                  onChange={e => setPassword(e.target.value)}
                />
              </Box>
            </Flex>
          )}
          <Box margin="40px 0 20px 0">
            <Button
              bg="green"
              color="white"
              maxWidth
              disabled={invalid}
              onClick={() => handleSignUp()}
            >
              Sign In
            </Button>
          </Box>
          {/* <SignInGoogle theme={props.theme} storeId={props.storeId} /> */}
          <Flex width="100%" justifyContent="space-around" margin="40px 0 0 0">
            <Text color={colors.text.default}>
              <a
                href={USER_ROUTES.PASSWORD_RESET}
                target="_blank"
                rel="noopener noreferrer"
              >
                Password Reset
              </a>
            </Text>
          </Flex>
          <Flex width="100%" justifyContent="center" margin="20px 0 0 0">
            <Text color={colors.text.default}>
              <a
                href={USER_ROUTES.TERMS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </Text>
          </Flex>
        </Form>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({})

const mapActionsToProps = {
  signIn,
  signOut,
  setCartNumber,
}

export default connect(mapStateToProps, mapActionsToProps)(SignInForm)
