import { gql } from 'apollo-boost'

export default gql`
  mutation editProduct(
    $id: ID!
    $name: String!
    $basePrice: Float!
    $description: String!
    $priceBreaks: JSONObject
    $defaultColor: String
    $thumbnail: Upload
    $deleteThumbnail: Boolean
    $customField1: String
    $customField2: String
    $isDesignable: Boolean
  ) {
    editProduct(
      id: $id
      name: $name
      basePrice: $basePrice
      description: $description
      priceBreaks: $priceBreaks
      defaultColor: $defaultColor
      thumbnail: $thumbnail
      deleteThumbnail: $deleteThumbnail
      customField1: $customField1
      customField2: $customField2
      isDesignable: $isDesignable
    ) {
      id
    }
  }
`
