import React, { useState, useEffect } from 'react'

import { HrStyle as Hr, FormStyle as F } from 'styles'
import { CART, CHECKOUT, CONFIRMATION, SIGN_UP } from 'routes/user'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import APPLY_COUPON from 'gql/APPLY_COUPON'
import { useMutation } from '@apollo/react-hooks'
import { Error } from 'utils'
import { TCoupon } from 'types/Coupon'
import {
  Card,
  Flex,
  Heading,
  Button,
  Text,
  Box,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import 'react-credit-cards/es/styles-compiled.css'
import { CreditCard } from 'components/Checkout/lib/CreditCardForm'
import * as USER_ROUTES from 'routes/user'
import { Role, TAddress } from '../../../types'
import { UserState } from '../../../store/reducers/userReducer'
import { AppState } from '../../../store/store'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import PAY_IN_STORE from '../../../gql/PAY_IN_STORE'
import CONFIRM_PAYMENT from '../../../gql/CONFIRM_PAYMENT'

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const round = (num: number) => {
  return Math.round(num * 100) / 100
}

interface CartCheckoutProps {
  me?: any
  subTotal: number
  cartItemTotals?: number[]

  coupon: TCoupon | null
  setCoupon: (coupon: TCoupon | null) => void
  referralCode: string | null
  setReferralCode: (referralCode: string | null) => void
  discount: number
  setDiscount: (discount: number) => void
  tax?: number
  address?: TAddress
  setTax?: (tax: number) => void
  shippingPrice?: number
  shippingSpeed?: string
  total: number
  setTotal: (total: number) => void
  step?: number
  setStep?: (step: number) => void
  handleNextStep?: () => void
  creditCard?: CreditCard
  disabled?: boolean
  deliveryType?: string | null
  paymentType?: string | null
  orderId?: string
  setOrderId?: (orderId: string) => void
  selectedPayInStoreLocation?: any
  orderNotes?: string

  theme: ITheme
  user: UserState
  storeId?: string
}

const CartCheckout: React.FC<
  CartCheckoutProps & RouteComponentProps
> = props => {
  const {
    me,
    subTotal,
    cartItemTotals,
    coupon,
    setCoupon,
    referralCode,
    setReferralCode,
    discount,
    setDiscount,
    tax,
    address,
    setTax,
    shippingPrice,
    // shippingSpeed,
    total,
    setTotal,
    step,
    setStep,
    handleNextStep,
    creditCard,
    disabled,
    theme: { colors },
  } = props

  const [error, setError] = useState<string | null>(null)
  const [applyCoupon] = useMutation(APPLY_COUPON)
  const [couponInput, setCouponInput] = useState(coupon?.name || '')
  const [referralInput, setReferralInput] = useState(referralCode || '')
  const [couponError, setCouponError] = useState('')

  // const [total, setTotal] = useState(0)

  useEffect(() => {
    if (referralCode) {
      setReferralInput(referralCode)
    }
  }, [referralCode])

  useEffect(() => {
    if (coupon) {
      setCouponInput(coupon?.name)
    }
  }, [coupon])

  useEffect(() => {
    if (setTax) {
      const taxRate =
        props.deliveryType === 'Pickup'
          ? 0.14975
          : address?.country === 'CA'
          ? address.state === 'AB'
            ? 0.05
            : address.state === 'BC'
            ? 0.12
            : address.state === 'MB'
            ? 0.12
            : address.state === 'NB'
            ? 0.15
            : address.state === 'NF'
            ? 0.15
            : address.state === 'NT'
            ? 0.05
            : address.state === 'NS'
            ? 0.15
            : address.state === 'NU'
            ? 0.05
            : address.state === 'ON'
            ? 0.13
            : address.state === 'PE'
            ? 0.15
            : address.state === 'QC'
            ? 0.14975
            : address.state === 'SK'
            ? 0.11
            : address.state === 'YT'
            ? 0.05
            : 0
          : 0
      if (coupon) {
        setTotal(
          (subTotal +
            (shippingPrice || 0) -
            (coupon.type === 'SHIPPING_PERCENT'
              ? (shippingPrice || 0) * coupon.value * 0.01
              : coupon.type === 'CART_PERCENT'
              ? subTotal * coupon.value * 0.01
              : coupon.type === 'CART_AMOUNT'
              ? coupon.value > subTotal + (shippingPrice || 0)
                ? subTotal + (shippingPrice || 0)
                : coupon.value
              : 0)) *
            (1 + taxRate)
        )
        setTax(
          (subTotal +
            (shippingPrice || 0) -
            (coupon.type === 'SHIPPING_PERCENT'
              ? (shippingPrice || 0) * coupon.value * 0.01
              : coupon.type === 'CART_PERCENT'
              ? subTotal * coupon.value * 0.01
              : coupon.type === 'CART_AMOUNT'
              ? coupon.value > subTotal + (shippingPrice || 0)
                ? subTotal + (shippingPrice || 0)
                : coupon.value
              : 0)) *
            taxRate
        )
        setDiscount(
          coupon.type === 'SHIPPING_PERCENT'
            ? (shippingPrice || 0) * coupon.value * 0.01
            : coupon.type === 'CART_PERCENT'
            ? subTotal * coupon.value * 0.01
            : coupon.type === 'CART_AMOUNT'
            ? coupon.value > subTotal + (shippingPrice || 0)
              ? subTotal + (shippingPrice || 0)
              : coupon.value
            : 0
        )
      } else {
        setTotal((subTotal + (shippingPrice || 0)) * (1 + taxRate))
        setTax((subTotal + (shippingPrice || 0)) * taxRate)
        setDiscount(0)
      }
    } else {
      if (coupon) {
        setTotal(
          subTotal +
            (shippingPrice || 0) -
            (coupon.type === 'SHIPPING_PERCENT'
              ? (shippingPrice || 0) * coupon.value * 0.01
              : coupon.type === 'CART_PERCENT'
              ? subTotal * coupon.value * 0.01
              : coupon.type === 'CART_AMOUNT'
              ? coupon.value > subTotal
                ? subTotal
                : coupon.value
              : 0)
        )
        setDiscount(
          coupon.type === 'SHIPPING_PERCENT'
            ? (shippingPrice || 0) * coupon.value * 0.01
            : coupon.type === 'CART_PERCENT'
            ? subTotal * coupon.value * 0.01
            : coupon.type === 'CART_AMOUNT'
            ? coupon.value > subTotal
              ? subTotal
              : coupon.value
            : 0
        )
      } else {
        setTotal(subTotal + (shippingPrice || 0))
        setDiscount(0)
      }
    }
  }, [
    subTotal,
    coupon,
    address,
    setTax,
    setTotal,
    shippingPrice,
    setDiscount,
    props.deliveryType,
  ])

  const handleCheckoutClick = () => {
    if (me && me.cart && me.cart.cartItems.length > 0) {
      // todo: change for next JS [WEIRD FLOW]

      if (me.role === Role.GUEST) {
        props.history.push(
          `${SIGN_UP}${
            props.storeId ? `?store=${props.storeId}` : `?redirect=${CART}`
          }`
        )
      } else {
        props.history.push({
          pathname: CHECKOUT,
          state: {
            cart: me.cart,
            subTotal: round(subTotal),
            shippingAddresses: me.shippingAddresses,
            billingAddresses: me.billingAddresses,
            billingAccount: me.billingAccount,
            defaultShippingAddress: me.defaultShippingAddress,
            defaultBillingAddress: me.defaultBillingAddress,
            coupon: coupon,
            discount: discount,
            cartItemTotals: cartItemTotals,
          },
        })
      }
    }
  }

  const handleCouponInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCouponInput(event.target.value)
    setCouponError('')
  }

  const handleCouponInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleApplyCoupon()
    }
  }

  const handleApplyCoupon = () => {
    setCouponInput('')

    applyCoupon({
      variables: {
        code: couponInput,
        subTotal: subTotal,
        shipping: 0,
      },
    })
      .then(response => {
        if (response.data.applyCoupon.error) {
          setCouponError(response.data.applyCoupon.error)
        } else {
          setCouponError('')
          setCoupon(response.data.applyCoupon.coupon)
          setDiscount(
            response.data.applyCoupon.coupon.type === 'SHIPPING_PERCENT'
              ? (shippingPrice || 0) *
                  response.data.applyCoupon.coupon.value *
                  0.01
              : response.data.applyCoupon.coupon.type === 'CART_PERCENT'
              ? subTotal * response.data.applyCoupon.coupon.value * 0.01
              : response.data.applyCoupon.coupon.type === 'CART_AMOUNT'
              ? response.data.applyCoupon.coupon.value
              : 0
          )
        }
      })
      .catch(error => setError(error.message))
  }

  const handleReferralInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferralInput(event.target.value)
  }

  const handleReferralInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleApplyReferralCode()
    }
  }

  const handleApplyReferralCode = () => {
    setReferralCode(referralInput)
    setReferralInput('')
  }

  const handleFormClick = () => {
    if (props.deliveryType === 'Pickup' && setStep) {
      setStep(1)
    }
    if (step !== undefined) {
      const formButton = document.getElementById(`form${step}`)
      if (formButton) formButton.click()
    }
  }
  const [payInStore] = useMutation(PAY_IN_STORE)
  const [ConfirmPayment] = useMutation(CONFIRM_PAYMENT)

  const handlePayClick = () => {
    console.log(props.paymentType)
    if (props.paymentType === 'Pay In Store') {
      payInStore({
        variables: {
          orderId: props.orderId,
          payInStoreLocationId: props.selectedPayInStoreLocation.id,
          orderNotes: props.orderNotes,
        },
      }).then((response: any) => {
        console.log(response)
      })
      props.history.push({
        pathname: `${CONFIRMATION}/store`,
        search: `?pay_in_store=true`,
      })
    } else {
      if (total > 0) {
        const payButton = document.getElementById('creditCardFormButton')
        if (payButton) payButton.click()
      } else {
        ConfirmPayment({
          variables: {
            redirect_status: 'succeeded',
            payment_intent: '',
            orderId: props.orderId,
            orderNotes: props.orderNotes,
          },
        }).then((response: any) => {
          console.log(response)
        })
        props.history.push({
          pathname: `${CONFIRMATION}/store`,
          search: `?pay_in_store=true`,
        })
      }
    }
  }

  const handleButtonClick = () => {
    if (step === undefined) handleCheckoutClick()
    else {
      if (step === 0) {
        handleFormClick()
      } else if (step === 1 && handleNextStep) {
        handleNextStep()
      } else if (step === 2) {
        handlePayClick()
      }
    }
  }

  const buttonString = () => {
    if (step === undefined) {
      return 'Checkout'
    } else {
      switch (step) {
        case 0:
          return props.deliveryType === 'Pickup' ? 'Pickup' : 'Shipping'
        case 1:
          return 'Pay'
        case 2:
          return 'Confirm'
        default:
          return ''
      }
    }
  }

  const breakpoint = useMediaQuery({ maxWidth: 1000 })

  return (
    <Box>
      {breakpoint && (
        <Flex
          width="100%"
          justifyContent="space-around"
          marginBottom={'60px'}
          marginTop={'20px'}
        >
          {/*{step !== undefined && setStep && step > 0 && (*/}
          {/*  <Button*/}
          {/*    bg="purple"*/}
          {/*    color="white"*/}
          {/*    size="sm"*/}
          {/*    onClick={() => setStep(step - 1)}*/}
          {/*    disabled={!!disabled}*/}
          {/*    width={'100%'}*/}
          {/*  >*/}
          {/*    <FontAwesomeIcon icon={faChevronLeft} /> {' Back'}*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*{buttonString() === 'Checkout' &&*/}
          {/*  props.user.credentials.managerPermissions.includes(*/}
          {/*    'PAY_IN_STORE'*/}
          {/*  ) && (*/}
          {/*    <Button*/}
          {/*      bg="purple"*/}
          {/*      color="white"*/}
          {/*      size="sm"*/}
          {/*      onClick={handlePayInStore}*/}
          {/*    >*/}
          {/*      <FontAwesomeIcon icon={faCreditCard} /> {' Pay In Store'}*/}
          {/*    </Button>*/}
          {/*  )}*/}
          <Button
            bg="green"
            color="white"
            size="sm"
            maxWidth={true}
            onClick={() => handleButtonClick()}
            disabled={!!disabled}
            width={'100%'}
          >
            {buttonString() + '  '}{' '}
            <FontAwesomeIcon icon={faChevronRight as any} />
          </Button>
        </Flex>
      )}
      <Card
        bg={colors.card.background}
        round
        maxWidth="100%"
        padding="20px"
        justifyContent="center"
        margin="0"
        marginBottom="2rem"
        whiteSpace="nowrap"
      >
        {error && <Error message={error} />}
        <Flex flexDirection="column">
          {(step === undefined || (step !== undefined && step < 3)) && (
            <>
              <Box marginBottom="20px">
                <Heading element="h3" color={colors.text.default}>
                  Order Summary
                </Heading>
              </Box>
              <Hr.Line />
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                margin="20px 0 20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Subtotal
                </Heading>
                <Heading element="h4" color={colors.text.default}>
                  ${formatter.format(subTotal)}
                </Heading>
              </Flex>
              {coupon && discount !== 0 && (
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  margin="20px 0 20px 0"
                >
                  <Heading element="h4" color={colors.text.default}>
                    Discount
                  </Heading>
                  <Heading element="h4" color={colors.text.default}>
                    - ${formatter.format(discount)}
                  </Heading>
                </Flex>
              )}
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                margin="10px 0"
              >
                <Flex flexDirection="column">
                  <Heading element="h4" color={colors.text.default}>
                    Shipping
                  </Heading>
                  <Text color={colors.text.default}>
                    Calculated at Checkout
                  </Text>
                </Flex>
                <Heading element="h4" color={colors.text.default}>
                  {shippingPrice !== undefined && shippingPrice > -1
                    ? '$' + formatter.format(shippingPrice)
                    : '—'}
                </Heading>
              </Flex>
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                margin="10px 0"
              >
                <Flex flexDirection="column">
                  <Heading element="h4" color={colors.text.default}>
                    Tax
                  </Heading>
                  <Text color={colors.text.default}>
                    Calculated at Checkout
                  </Text>
                </Flex>
                <Heading element="h4" color={colors.text.default}>
                  {tax !== undefined && tax > -1
                    ? '$' + formatter.format(tax)
                    : '—'}
                </Heading>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Heading element="h4" color={colors.text.default}></Heading>
                <F.Input
                  type="text"
                  value={couponInput}
                  placeholder="Promo Code"
                  onChange={handleCouponInputChange}
                  onKeyDown={handleCouponInputKeyDown}
                />

                <Box margin="10px 0">
                  <Button
                    size="xs"
                    bg={colors.text.default}
                    color={colors.text.default === 'slate' ? 'white' : 'black'}
                    onClick={() => handleApplyCoupon()}
                  >
                    Apply
                  </Button>
                </Box>
              </Flex>
              {couponError !== '' && (
                <Flex justifyContent="center">
                  <Text color="red">{couponError}</Text>
                </Flex>
              )}

              {step !== undefined && (
                <Flex flexDirection="row" justifyContent="space-between">
                  <F.Input
                    type="text"
                    value={referralInput}
                    placeholder="Referral Code"
                    onChange={handleReferralInputChange}
                    onKeyDown={handleReferralInputKeyDown}
                  />
                  <Box margin="10px 0">
                    <Button
                      size="xs"
                      bg={colors.text.default}
                      color={
                        colors.text.default === 'slate' ? 'white' : 'black'
                      }
                      onClick={() => handleApplyReferralCode()}
                    >
                      Apply
                    </Button>
                  </Box>
                </Flex>
              )}

              <Box margin="20px 0 20px 0">
                <Text color={colors.text.default}>
                  <a
                    href={USER_ROUTES.TERMS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms Of Service
                  </a>
                </Text>
                <Text color={colors.text.default}>
                  *All prices in CAD{' '}
                  <span role="img" aria-label="canada-flag">
                    🇨🇦
                  </span>
                </Text>
              </Box>
            </>
          )}
          {step !== undefined && step === 3 && creditCard && (
            <>
              <Box marginBottom="30px">
                <Heading element="h2" color={colors.text.default}>
                  Confirm Order
                </Heading>
              </Box>
              <Hr.Line />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="30px 0"
              >
                <Text color={colors.text.default}>Have a question?</Text>
                <Button
                  bg={colors.text.default}
                  color={colors.text.default === 'slate' ? 'paper' : 'slate'}
                >
                  Contact Us
                </Button>
              </Flex>
              <Box margin="10px 0">
                <Text color={colors.text.default}>
                  By placing this order, you agree to our{' '}
                  <a
                    href={USER_ROUTES.TERMS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms Of Service
                  </a>
                </Text>
              </Box>
            </>
          )}
          <Hr.Line />
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            margin="20px 0"
          >
            <Heading element="h4" color={colors.text.default}>
              Total
            </Heading>
            <Heading element="h4" color={colors.text.default}>
              ${formatter.format(total)}
            </Heading>
          </Flex>
          <Flex width="100%" justifyContent="space-around">
            {step !== undefined && setStep && step > 0 && (
              <Button
                bg="green"
                color="white"
                size="sm"
                onClick={() => setStep(step - 1)}
              >
                <FontAwesomeIcon icon={faChevronLeft as any} /> {' Back'}
              </Button>
            )}
            {/*{buttonString() === 'Checkout' &&*/}
            {/*  props.user.credentials.managerPermissions.includes(*/}
            {/*    'PAY_IN_STORE'*/}
            {/*  ) && (*/}
            {/*    <Button*/}
            {/*      bg="purple"*/}
            {/*      color="white"*/}
            {/*      size="sm"*/}
            {/*      onClick={handlePayInStore}*/}
            {/*    >*/}
            {/*      <FontAwesomeIcon icon={faCreditCard} /> {' Pay In Store'}*/}
            {/*    </Button>*/}
            {/*  )}*/}
            <Button
              bg="green"
              color="white"
              size="sm"
              maxWidth={step === 0 || !step ? true : false}
              onClick={() => handleButtonClick()}
              disabled={!!disabled}
            >
              {buttonString() + '  '}{' '}
              <FontAwesomeIcon icon={faChevronRight as any} />
            </Button>
          </Flex>
        </Flex>
      </Card>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

export default withRouter(
  withTheme(connect(mapStateToProps, mapActionsToProps)(CartCheckout))
)
