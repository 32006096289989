import React, { useEffect, useState } from 'react'
import { ModalLayout as M, ButtonStyle as B, FormStyle as F } from 'styles'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import { TPrintLocation } from '../../../types'

const UPSERT_PRINT_LOCATION = gql`
  mutation upsertPrintLocation(
    $id: ID
    $available: Boolean
    $name: String
    $width: Int
    $height: Int
    $price: Int
  ) {
    upsertPrintLocation(
      id: $id
      available: $available
      name: $name
      width: $width
      height: $height
      price: $price
    ) {
      id
    }
  }
`

interface IPrintLocationCreateFormProps {
  visible: boolean
  toggle: any
  refetch: () => void
  printLocation?: TPrintLocation
}

const PrintLocationCreateForm: React.FC<
  IPrintLocationCreateFormProps
> = props => {
  const { printLocation } = props
  const [name, setName] = useState<string>('')
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [price, setPrice] = useState<number>(0)
  const [available, setAvailable] = useState<boolean>(
    printLocation?.available || false
  )

  const [UpsertPrintLocation, { loading }] = useMutation(UPSERT_PRINT_LOCATION)

  const handleCreateClick = async () => {
    try {
      if (printLocation) {
        await UpsertPrintLocation({
          variables: {
            id: printLocation.id,
            name,
            available,
            width,
            height,
            price,
          },
        })
      } else {
        await UpsertPrintLocation({
          variables: {
            name,
            available,
            width,
            height,
            price,
          },
        })
      }
      props.toggle(false)
      props.refetch()
    } catch (e: any) {
      return <Error message={e.message} />
    }
  }

  useEffect(() => {
    if (printLocation) {
      setName(printLocation.name)
      setWidth(printLocation.width)
      setHeight(printLocation.height)
      setPrice(printLocation.price || 0)
      setAvailable(printLocation.available)
    }
  }, [printLocation])

  const invalid = name === '' || width === 0 || height === 0

  if (loading) return <Loader />

  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>
            {printLocation
              ? 'Edit Decoration Location'
              : 'New Decoration Location'}
          </h2>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Available
              <F.Input
                checked={available}
                type={'checkbox'}
                placeholder={'on'}
                onChange={() => setAvailable(!available)}
              />
            </F.Label>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>

            <F.Label>
              Width
              <F.Input
                value={width}
                type={'number'}
                placeholder={'width'}
                onChange={e => setWidth(+e.target.value)}
              />
            </F.Label>
            <F.Label>
              Height
              <F.Input
                value={height}
                type={'number'}
                placeholder={'height'}
                onChange={e => setHeight(+e.target.value)}
              />
            </F.Label>
            <F.Label>
              Price
              <F.Input
                value={price}
                type={'number'}
                placeholder={'price'}
                onChange={e => setPrice(+e.target.value)}
              />
            </F.Label>
            <B.Button disabled={invalid} onClick={() => handleCreateClick()}>
              {printLocation ? 'Update' : 'Create Decoration Location'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default PrintLocationCreateForm
