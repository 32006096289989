export default [
  'WEBSITE_SETTINGS',
  'FILES',
  'PRODUCTS',
  'DECORATIONS',
  'TAGS',
  'ORDERS',
  'SHIPPING',
  'INVENTORY',
  'WAREHOUSES',
  'STORES',
  'COUPONS',
  'PROMOTIONS',
  'RETAIL_LOCATIONS',
  // 'MANUAL_FULFILLMENT',
  // 'CHARGE_CREDIT_CARD',

  'DISABLE_ONLINE_PAYMENT',
  'PAY_IN_STORE',

  // 'ENABLE_PICKUP',
  'DISABLE_SHIPPING',

  'ENABLE_CHECKOUT_USER_CREATION',
  'ENFORCE_CHECKOUT_USER_CREATION',

  // 'IS_RETAIL_LOCATION',
]
