import { gql } from 'apollo-boost'

export default gql`
  mutation upsertShippingOption(
    $id: ID
    $name: String!
    $description: String
    $countries: [String]
    $price: Int!
  ) {
    upsertShippingOption(
      id: $id
      name: $name
      description: $description
      countries: $countries
      price: $price
    ) {
      id
    }
  }
`
