import React from 'react'
import { DataTable } from '../../Admin'
import PromotionCreateForm from './PromotionCreateForm'

import GET_SOME_PROMOTIONS from 'gql/GET_SOME_PROMOTIONS'
import DELETE_PROMOTION from 'gql/DELETE_PROMOTION'
import { PROMOTION } from 'routes/user'

interface ICouponTable {}

const PromotionTable: React.FC<ICouponTable> = props => {
  return (
    <>
      <DataTable
        getDataQuery={GET_SOME_PROMOTIONS}
        getDataQueryName="getSomePromotions"
        deleteDataQuery={DELETE_PROMOTION}
        headers={['Url', 'Name', 'Coupon']}
        properties={['id', 'name', 'couponDescription']}
        ROUTE={PROMOTION}
        title="Promotions"
      >
        {createFormProps => <PromotionCreateForm {...createFormProps} />}
      </DataTable>
    </>
  )
}

export default PromotionTable
