import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import { DivLayout as D, UtiliesStyle as U } from 'styles'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import GET_PRINT_LOCATION from 'gql/GET_PRINT_LOCATION'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import PrintLocationCreateForm from './PrintLocationCreateForm'

interface AddOnPageProps {
  match: {
    params: {
      printLocationId: string
    }
  }
  theme: ITheme
}

const PrintLocationPage: React.FC<AddOnPageProps> = props => {
  const {
    theme: { colors },
  } = props
  const {
    loading,
    data,
    error: getPrintLocationError,
    refetch: refetchPrintLocation,
  } = useQuery(GET_PRINT_LOCATION, {
    variables: {
      id: props.match.params.printLocationId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  return (
    <>
      {getPrintLocationError && (
        <Error message={getPrintLocationError.message} />
      )}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getPrintLocation.name}
          </Heading>
          <Box margin="30px 0">
            <U.status
              color={data.getPrintLocation.available ? 'green' : 'red'}
              style={{ cursor: 'pointer' }}
              // onClick={() =>
              //   data.getAddOn.available ? handleMakeUnavailableClick() : null
              // }
            >
              <span>●</span>
              {data.getPrintLocation.available ? 'available' : 'unavailable'}
            </U.status>
            {/* {data.getAddOn.available === false && (
              <U.status>
                <B.subtle onClick={handleMakeAvailableClick}>
                Make Available
              </B.subtle>
              </U.status>
            )} */}
          </Box>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Decoration Location Info
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <PrintLocationCreateForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchPrintLocation}
                  printLocation={data.getPrintLocation}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Name:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getPrintLocation.name}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Dimensions:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getPrintLocation.width}
                  {' x '}
                  {data.getPrintLocation.height}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Price:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  ${data.getPrintLocation.price}
                </Text>
              </Flex>
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(PrintLocationPage)
