import React from 'react'
import { GET_SOME_RETAIL_LOCATIONS } from '../../../gql/GET_SOME_RETAIL_LOCATIONS'
import * as ADMIN_ROUTES from '../../../routes/admin'
import { DataTable } from '../../Admin'
import RetailLocationCreateForm from './RetailLocationCreateForm'
import DELETE_RETAIL_LOCATION from '../../../gql/DELETE_RETAIL_LOCATION'

const RetailLocationsTable: React.FC = () => {
  return (
    <>
      <DataTable
        getDataQuery={GET_SOME_RETAIL_LOCATIONS}
        getDataQueryName="getSomeRetailLocations"
        deleteDataQuery={DELETE_RETAIL_LOCATION}
        headers={[
          'Name',
          'Location Code',
          'Slug',
          'Auth Token',
          'Station Names',
          'Station Emails',
        ]}
        properties={[
          'name',
          'locationCode',
          'slug',
          'authToken',
          'managers.name',
          'managers.email',
        ]}
        ROUTE={ADMIN_ROUTES.ADMIN_RETAIL_LOCATIONS}
        title="Retail Locations"
      >
        {createFormProps => <RetailLocationCreateForm {...createFormProps} />}
      </DataTable>
    </>
  )
}

export default RetailLocationsTable
