import React from 'react'
import * as USER_ROUTES from 'routes/user'
import * as ADMIN_ROUTES from 'routes/admin'

interface ProductLinkProps {
  productID: string
  isAdmin?: boolean
  color?: { name?: string; value: string }
  previewID?: string
  previewAddOnByLocationId?: string
  dec?: string
}

const ProductLink: React.FC<ProductLinkProps> = props => {
  const url = React.useMemo(() => {
    if (props.isAdmin) {
      return `${ADMIN_ROUTES.ADMIN_PRODUCTS}/${props.productID}`
    } else {
      return `${USER_ROUTES.PRODUCT}/${props.productID}?${
        props.color ? `&color=${props.color.name}` : ''
      }${props.previewID ? `&previewID=${props.previewID}` : ''}${
        props.previewAddOnByLocationId
          ? `&previewLoc=${props.previewAddOnByLocationId}`
          : ''
      }
      ${props.dec ? `&dec=${props.dec}` : ''}
      `
    }
  }, [
    props.isAdmin,
    props.productID,
    props.color,
    props.previewID,
    props.previewAddOnByLocationId,
    props.dec,
  ])

  return (
    <a
      style={{ textDecoration: 'none' }}
      // href={
      //   props.isAdmin
      //     ? `${ADMIN_ROUTES.ADMIN_PRODUCTS}/${props.productID}`
      //     : props.color
      //     ? props.previewID
      //       ? `${USER_ROUTES.PRODUCT}/${props.productID}?color=${props.color.name}&previewID=${props.previewID}`
      //       : `${USER_ROUTES.PRODUCT}/${props.productID}?color=${props.color.name}`
      //     : props.previewID
      //     ? `${USER_ROUTES.PRODUCT}/${props.productID}?previewID=${props.previewID}`
      //     : `${USER_ROUTES.PRODUCT}/${props.productID}`
      // }
      href={url}
    >
      {props.children}
    </a>
  )
}

export default ProductLink
