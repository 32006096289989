import { ITheme, TDesign, TOrderItem } from 'types'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import GET_ORDER_ITEM from 'gql/GET_ORDER_ITEM'
import { withTheme } from 'styled-components'
import { Error, Loader } from 'utils'
import {
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Box,
} from '@sweaterplanet/nucleus-style'
import * as ADMIN_ROUTES from 'routes/admin'
import { Link } from 'react-router-dom'
import Mockup from 'components/Mockup'
import { Line } from 'styles/lib/hr'
import { Table } from '../../../styles/lib/table'
import { ButtonStyle as B, FormStyle as F } from '../../../styles'
import ADD_PRINT_FILE_TO_DESIGN from 'gql/ADD_PRINT_FILE_TO_DESIGN'

interface ILogoModsMockupsProps {
  orderItem: TOrderItem
}

export const LogoModsMockups: React.FC<ILogoModsMockupsProps> = ({
  orderItem,
}) => {
  const selectedColors = orderItem.variantSelection.map(v =>
    v.variants.filter(v => v.variantGroup.name === 'Color')
  )

  return (
    <>
      {orderItem.product.inventoryGroup.views.map(v => (
        <Mockup
          view={v}
          color={
            selectedColors && selectedColors.length > 0
              ? selectedColors[0][0].value
              : 'slate'
          }
          width="15rem"
          height="15rem"
          designs={orderItem.designs}
          isActiveView={true}
          isSleeveCustomization={!!orderItem.variantSelection[0].customization}
        />
      ))}
    </>
  )
}

interface ILogoModsDesignRowProps {
  design: TDesign
  refetch?: () => any
}
const LogoModsDesignRow: React.FC<ILogoModsDesignRowProps> = ({
  design,
  refetch,
}) => {
  const [printFile, setPrintFile] = useState<any>(design.printFile || null)

  React.useEffect(() => {
    setPrintFile(design.printFile)
  }, [design.printFile])

  const [
    AddPrintFileToDesign,
    { loading: addPrintFilesLoading, error: addPrintFilesError },
  ] = useMutation(ADD_PRINT_FILE_TO_DESIGN, {
    variables: {
      designId: design.id,
      printFile,
    },
  })

  const handleSubmitClick = async () => {
    console.log('here')
    try {
      const data = await AddPrintFileToDesign()
      data && refetch && refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      setPrintFile(file)
    }
  }

  const handleRemoveClick = () => {
    setPrintFile(null)
  }

  const invalid = printFile === null || printFile === design.printFile

  return (
    <tr>
      {addPrintFilesError && <Error message={addPrintFilesError.message} />}
      {addPrintFilesLoading && <Loader />}
      <td>
        <Image src={design.file.url} width={'200px'} />
      </td>
      <td>{design.addOn.name}</td>
      <td>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={design.file.url}
          download
        >
          {design.file.filename}
        </a>
      </td>
      <td>
        {printFile ? (
          <Flex alignItems={'center'}>
            <Box padding={'10px'}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                download
                href={printFile.url}
              >
                {printFile.filename}
              </a>
            </Box>
            <B.SubtleButton onClick={() => handleRemoveClick()}>
              x
            </B.SubtleButton>
          </Flex>
        ) : (
          <F.upload
            type="file"
            accept="any"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event)
            }
            onClick={onInputClick}
          />
        )}
      </td>
      <td>
        {!invalid && (
          <Button size={'xs'} color={'red'} onClick={handleSubmitClick}>
            Save
          </Button>
        )}
      </td>
    </tr>
  )
}

interface ILogoModsProps {
  match: {
    params: {
      orderItemId: string
    }
  }
  theme: ITheme
}

const LogoMods: React.FC<ILogoModsProps> = ({ match, theme }) => {
  // const [originalFiles, setOriginalFiles] = useState<[any?]>([]);
  const {
    loading: orderItemLoading,
    data: orderItemData,
    error: getOrderItemError,
    refetch: refetchOrderItem,
  } = useQuery(GET_ORDER_ITEM, {
    variables: {
      id: match.params.orderItemId,
    },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      {orderItemLoading && <Loader />}
      {getOrderItemError && <Error message={getOrderItemError.message} />}

      {orderItemData && (
        <>
          <Flex
            padding={'2rem'}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Link
              to={`${ADMIN_ROUTES.ADMIN_ORDERS}/${orderItemData.getOrderItem.Order.id}`}
            >
              <Heading element={'h4'} color={theme.colors.text.default}>
                {'<'} Order # {orderItemData.getOrderItem.Order.id}
              </Heading>
            </Link>
            <Text color={theme.colors.text.default}>
              {orderItemData.getOrderItem.Order.User.name} <br />
              {orderItemData.getOrderItem.Order.User.email}
            </Text>
          </Flex>
          <Flex width={'100%'} wrap={'wrap'}>
            <LogoModsMockups orderItem={orderItemData.getOrderItem} />
          </Flex>
          <Line />
          <Box margin={'1rem 0'} width={'100%'}>
            <Table width={'100%'}>
              <tr>
                <th>Logo</th>
                <th>Add On</th>
                <th>Download Source</th>
                <th>Print File</th>
              </tr>
              {orderItemData.getOrderItem.designs.map((design: TDesign) => (
                <LogoModsDesignRow
                  key={design.id}
                  design={design}
                  refetch={refetchOrderItem}
                />
              ))}
            </Table>
          </Box>
        </>
      )}
    </>
  )
}

export default withTheme(LogoMods)
