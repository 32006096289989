import { gql } from 'apollo-boost'

export default gql`
  {
    me {
      id
      files {
        id
        filename
        url
        thumbnail
        Design {
          id
        }
        StoreLogo {
          id
        }
        StoreBanner {
          id
        }
        Product {
          id
        }
        Promotion {
          id
        }
      }
    }
  }
`
