import * as React from 'react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import SET_WEBSITE_SETTINGS from '../../../gql/SET_WEBSITE_SETTINGS'
import GET_SOME_TAGS from '../../Tag/lib/gql/GET_SOME_TAGS'
import GET_WEBSITE_SETTINGS from '../../../gql/GET_WEBSITE_SETTINGS'
import { ButtonStyle as B, FormStyle as F } from 'styles'
import { Error, Loader } from '../../../utils'
import { TCoupon, TTag } from '../../../types'
import GET_SOME_COUPONS from '../../../gql/GET_SOME_COUPONS'
import { withTheme } from 'styled-components'
import { Heading } from '@sweaterplanet/nucleus-style'

interface IWebsiteSettingsProps {
  theme?: any
}

const WebsiteSettings: React.FC<IWebsiteSettingsProps> = ({ theme }) => {
  const { colors } = theme
  const [colorsValid, setColorsValid] = useState<boolean>(false)
  const [popularSearches, setPopularSearches] = useState<string[]>([])
  const [displayCoupon, setDisplayCoupon] = useState<string | undefined>(
    undefined
  )
  const [homePageMessage, setHomePageMessage] = useState<string | undefined>(
    undefined
  )

  const {
    loading: tagsLoading,
    error: tagsError,
    data: tagsData,
  } = useQuery(GET_SOME_TAGS, { variables: { searches: [] } })
  const {
    loading: websiteSettingsLoading,
    error: websiteSettingsError,
    data: websiteSettingsData,
  } = useQuery(GET_WEBSITE_SETTINGS)
  const { data: couponsData } = useQuery(GET_SOME_COUPONS, {
    variables: { searches: [] },
  })

  useEffect(() => {
    if (websiteSettingsData) {
      console.log(websiteSettingsData)
      setPreviewColors(
        JSON.stringify(websiteSettingsData.getWebsiteSettings.previewColors)
      )
      setPopularSearches(
        websiteSettingsData.getWebsiteSettings.popularSearches.map(
          (s: TTag) => s.id
        )
      )
      setDisplayCoupon(websiteSettingsData.getWebsiteSettings.displayCoupon?.id)
      setHomePageMessage(websiteSettingsData.getWebsiteSettings.homePageMessage)
    }
  }, [websiteSettingsData])

  useEffect(() => {
    if (couponsData) {
      console.log(couponsData)
    }
  }, [couponsData])

  const [SetWebsiteSettings] = useMutation(SET_WEBSITE_SETTINGS)
  const [previewColors, setPreviewColors] = useState(`[
        {"name": "White", "value": "#FFFFFF"},
        {"name": "Black", "value": "#000000"}
    ]`)
  const IsJsonString = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  const invalid = !popularSearches.length || !colorsValid
  const handleSetWebstiteSettings = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    await SetWebsiteSettings({
      variables: {
        previewColors: JSON.parse(previewColors),
        popularSearches: popularSearches,
        displayCoupon: displayCoupon,
        homePageMessage: homePageMessage,
      },
    })
  }

  useEffect(() => {
    setColorsValid(IsJsonString(previewColors))
  }, [previewColors])

  const handleCheckTag = (tag: TTag) => {
    let newArr = popularSearches
    if (newArr.includes(tag.id)) {
      let index = newArr.indexOf(tag.id)
      if (index !== -1) {
        newArr.splice(index, 1)
      }
    } else {
      newArr.push(tag.id)
    }
    console.log(newArr)
    console.log(popularSearches)
    setPopularSearches(newArr)
    console.log(popularSearches)
  }

  return (
    <>
      <Heading element="h2" color={colors.text.default}>
        Website Settings
      </Heading>
      {!tagsLoading &&
      !websiteSettingsLoading &&
      websiteSettingsData &&
      tagsData ? (
        <F.Form>
          <p color={'red'}>{!colorsValid && 'colors are invalid'}</p>
          <F.Label>
            Preview Colors Object (JSON)
            <F.Textarea
              value={previewColors}
              onChange={e => setPreviewColors(e.target.value)}
            />
          </F.Label>
          <br />
          <F.Label>Popular Searches Tag</F.Label>
          {tagsData.getSomeTags.map((tag: TTag) => (
            <F.Label>
              {tag.icon} {tag.content}
              <input
                key={tag.id}
                type="checkbox"
                checked={popularSearches.includes(tag.id)}
                onChange={() => handleCheckTag(tag)}
              />
            </F.Label>
          ))}
          <br />
          <F.Label>
            Display Coupon
            <select
              value={displayCoupon}
              onChange={e => setDisplayCoupon(e.target.value)}
            >
              <option value={undefined}></option>
              {couponsData &&
                couponsData.getSomeCoupons.map((c: TCoupon) => (
                  <option value={c.id}>{c.name}</option>
                ))}
            </select>
          </F.Label>
          <br />
          <F.Label>
            Home Page Message
            <input
              type="text"
              value={homePageMessage}
              onChange={e => setHomePageMessage(e.target.value)}
            />
          </F.Label>

          {/*Front Page Tag*/}
          {/*<F.select*/}
          {/*    value={popularSearches}*/}
          {/*    onChange={e => {*/}
          {/*        setPopularSearches(e.target.value)*/}
          {/*    }}*/}
          {/*>*/}
          {/*    {tagsData.getSomeTags.map((t: TTag) => (*/}
          {/*        <option value={t.id}>*/}
          {/*            {t.icon} {t.content}*/}
          {/*        </option>*/}
          {/*    ))}*/}
          {/*</F.select>*/}
          <F.Label>
            Featured Tag: <input value={'featured'} disabled />
          </F.Label>
          <B.Button disabled={invalid} onClick={handleSetWebstiteSettings}>
            Set Website Settings
          </B.Button>
        </F.Form>
      ) : (
        <Loader />
      )}
      {websiteSettingsError && <Error message={websiteSettingsError.message} />}
      {tagsError && <Error message={tagsError.message} />}
    </>
  )
}

export default withTheme(WebsiteSettings)
