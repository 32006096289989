// graphql mutation to pay in store
import { gql } from 'apollo-boost'

export default gql`
  mutation PayInStore(
    $orderId: ID!
    $payInStoreLocationId: ID!
    $orderNotes: String
  ) {
    payInStore(
      orderId: $orderId
      payInStoreLocationId: $payInStoreLocationId
      orderNotes: $orderNotes
    ) {
      message
      status
    }
  }
`
