import React, { useEffect, useState } from 'react'
import { ModalLayout as M, ButtonStyle as B, FormStyle as F } from 'styles'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import { CouponType } from 'types/Coupon'
import { TCoupon } from '../../types'

const CREATE_COUPON = gql`
  mutation createCoupon(
    $id: String!
    $name: String!
    $available: Boolean!
    $type: String!
    $value: Int!
    $maxUses: Int!
    $validFrom: Date!
    $validUntil: Date!
  ) {
    createCoupon(
      id: $id
      name: $name
      available: $available
      type: $type
      value: $value
      maxUses: $maxUses
      validFrom: $validFrom
      validUntil: $validUntil
    ) {
      id
    }
  }
`

const EDIT_COUPON = gql`
  mutation editCoupon(
    $id: String!
    $name: String!
    $available: Boolean!
    $type: String!
    $value: Int!
    $maxUses: Int!
    $validFrom: Date!
    $validUntil: Date!
  ) {
    editCoupon(
      id: $id
      name: $name
      available: $available
      type: $type
      value: $value
      maxUses: $maxUses
      validFrom: $validFrom
      validUntil: $validUntil
    ) {
      id
    }
  }
`

interface ICouponCreateFormProps {
  visible: boolean
  toggle: any
  refetch: () => void
  coupon?: TCoupon
}

const CouponCreateForm: React.FC<ICouponCreateFormProps> = props => {
  const { coupon } = props
  const [code, setCode] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [available, setAvailable] = useState<boolean>(false)
  const [type, setType] = useState<string>(CouponType.CART_AMOUNT)
  const [value, setValue] = useState<number>(0)
  const [maxUses, setMaxUses] = useState<number>(1)

  const [tzoffset] = useState(new Date().getTimezoneOffset() * 60000)
  const [today] = useState(
    new Date(Date.now() - tzoffset).toISOString().substring(0, 16)
  )
  const [nextWeek] = useState(
    new Date(Date.now() - tzoffset + 604800000).toISOString().substring(0, 16)
  )
  const [validFrom, setValidFrom] = useState<string>(today)
  const [validUntil, setValidUntil] = useState<string>(nextWeek)

  const invalid = name === '' || code === '' || value === 0 || validUntil === ''

  const [CreateCoupon, { loading: createLoading }] = useMutation(CREATE_COUPON)
  const [EditCoupon, { loading: editLoading }] = useMutation(EDIT_COUPON)

  useEffect(() => {
    if (coupon) {
      setCode(coupon.id)
      setName(coupon.name)
      setAvailable(coupon.available)
      setType(coupon.type)
      setValue(coupon.value)
      setMaxUses(coupon.maxUses)
      // setValidFrom(coupon.validFrom)
      // setValidUntil(coupon.validUntil)
    }
  }, [coupon])

  const handleCreateClick = () => {
    const UTCValidFrom = new Date(validFrom).toISOString()
    const UTCValidUntil = new Date(validUntil).toISOString()
    if (coupon) {
      EditCoupon({
        variables: {
          id: coupon.id,
          name,
          available,
          type,
          value,
          maxUses,
          validFrom: UTCValidFrom,
          validUntil: UTCValidUntil,
        },
      })
        .then(() => {
          props.toggle(false)
          props.refetch()
        })
        .catch(error => <Error message={error.message} />)
    } else {
      CreateCoupon({
        variables: {
          id: code,
          name,
          available,
          type,
          value,
          maxUses,
          validFrom: UTCValidFrom,
          validUntil: UTCValidUntil,
        },
      })
        .then(() => {
          props.toggle(false)
          props.refetch()
        })
        .catch(error => <Error message={error.message} />)
    }
  }

  if (createLoading) return <Loader />
  if (editLoading) return <Loader />

  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>{coupon ? 'Edit Coupon' : 'New Coupon'}</h2>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Code
              <F.Input
                value={code}
                type={'text'}
                placeholder={'Code'}
                onChange={e => setCode(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'Name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Available
              <F.Input
                checked={available}
                type={'checkbox'}
                placeholder={'available'}
                onChange={e => setAvailable(e.target.checked)}
              />
            </F.Label>
            <F.Label>
              Type
              <F.select value={type} onChange={e => setType(e.target.value)}>
                {Object.keys(CouponType).map((type: string) => (
                  <option value={type}>{type}</option>
                ))}
              </F.select>
            </F.Label>
            <F.Label>
              Value
              <F.Input
                value={value}
                type={'number'}
                placeholder={'value'}
                min="0"
                onChange={e => setValue(+e.target.value)}
              />
            </F.Label>
            <F.Label>
              Max Uses
              <F.Input
                value={maxUses}
                type={'number'}
                placeholder={'value'}
                onChange={e => setMaxUses(+e.target.value)}
              />
            </F.Label>
            <F.Label>
              Valid From
              <F.Input
                value={validFrom}
                type="datetime-local"
                onChange={e => setValidFrom(e.target.value)}
                min={today}
                max={validUntil}
              />
            </F.Label>
            <F.Label>
              Valid Until
              <F.Input
                value={validUntil}
                type="datetime-local"
                onChange={e => setValidUntil(e.target.value)}
                min={validFrom}
              />
            </F.Label>
            <B.Button disabled={invalid} onClick={() => handleCreateClick()}>
              {coupon ? 'Edit Coupon' : 'Create Coupon'}
            </B.Button>
          </F.Form>
          {/* <pre>
                    {JSON.stringify({
                        today: today,
                        validFrom: validFrom,
                        validUntil: validUntil,
                    })}
                </pre> */}
        </M.Modal>
      )}
    </>
  )
}

export default CouponCreateForm
