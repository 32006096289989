import { gql } from 'apollo-boost'

export default gql`
  mutation editStore(
    $id: ID!
    $name: String!
    $description: String
    $logo: Upload
    $bannerImg: Upload
    $moodboard: Upload
    $slug: String
    $removeBanner: Boolean
    $removeLogo: Boolean
    $removeMoodboard: Boolean
    $whitelabelNav: String
    $isPublic: Boolean
  ) {
    editStore(
      id: $id
      name: $name
      description: $description
      logo: $logo
      bannerImg: $bannerImg
      moodboard: $moodboard
      slug: $slug
      removeBanner: $removeBanner
      removeLogo: $removeLogo
      removeMoodboard: $removeMoodboard
      whitelabelNav: $whitelabelNav
      isPublic: $isPublic
    ) {
      id
    }
  }
`
