import { gql } from 'apollo-boost'

export default gql`
  query getPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      name
      coupon {
        id
        name
        available
        type
        value
        maxUses
        uses
        validFrom
        validUntil
      }
      couponDescription
      description
      products {
        id
        name
        basePrice
        available
        isDesignable
        defaultColor
        tags {
          id
          content
          color
          icon
        }
        variantGroups {
          id
          name
          variants {
            id
            value
          }
        }
        inventoryGroup {
          id
          name
          views {
            id
            name
            boundingBox
            base
            texture
            printLocations {
              id
              printLocation {
                id
                name
                available
                width
                height
              }
              coordinateMap
            }
          }
        }
        store {
          id
        }
        designs {
          id
          file {
            id
            url
            filename
          }
          addOn {
            id
            name
            publicName
            available
            basePrice
            priceBreaks
          }
          printLocation {
            id
            name
            price
          }
          rotate
          flip
          vectorize
          origin
          w
          h
        }
      }
      bannerImg {
        id
        url
      }
    }
  }
`
