import { gql } from 'apollo-boost'

export default gql`
  mutation applyCoupon($code: ID!, $subTotal: Float!, $shipping: Float!) {
    applyCoupon(code: $code, subTotal: $subTotal, shipping: $shipping) {
      coupon {
        id
        name
        available
        type
        value
        maxUses
        uses
        validFrom
        validUntil
      }
      discount
      error
    }
  }
`
