import React from 'react'
import { ITheme } from 'types/Theme'
import { Content, Page } from 'styles/lib/page'
import Navigation from 'components/Navigation'
import { withTheme } from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import GET_FILES from '../../../gql/GET_FILES'
import { Loader } from 'utils'

interface IGalleryPageProps {
  theme: ITheme
}

const GalleryPage: React.FC<IGalleryPageProps> = props => {
  const { data, loading } = useQuery(GET_FILES)
  return (
    <Page>
      <Navigation />
      <Content padding={'0'}>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
          {loading ? (
            <Loader />
          ) : data && data.me.files.length ? (
            data.me.files.map((file: any) => (
              <a
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                }}
                href={`/products?previewID=${file.id}`}
              >
                <img
                  src={file.thumbnail || file.url}
                  alt={file.id}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    maxHeight: '100%',
                  }}
                />
              </a>
            ))
          ) : (
            <p>
              You haven't uploaded any images yet.{' '}
              <a href="/products">Upload one now</a>
            </p>
          )}
        </div>
      </Content>
    </Page>
  )
}

export default withTheme(GalleryPage)
