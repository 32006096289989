import { ThemeActionTypes, SET_DARK_MODE, SET_LIGHT_MODE } from 'types/actions'

export interface ThemeState {
  darkMode: boolean
}

const initialState: ThemeState = {
  darkMode: false,
}

export default (state = initialState, action: ThemeActionTypes): ThemeState => {
  switch (action.type) {
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: true,
      }
    case SET_LIGHT_MODE:
      return {
        ...state,
        darkMode: false,
      }
    default:
      return state
  }
}
