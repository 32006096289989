import React from 'react'

// Redux
import { AppState } from 'store/store'
import { connect } from 'react-redux'
import { setDarkMode, setLightMode } from 'store/actions/themeActions'

import { Switch } from '@sweaterplanet/nucleus-style'

interface DarkModeSwitchProps {
  darkMode: boolean
  setLightMode: () => void
  setDarkMode: () => void
}

const DarkModeSwitchBase: React.FC<DarkModeSwitchProps> = props => {
  const { darkMode, setLightMode, setDarkMode } = props
  const handleClick = () => (darkMode ? setLightMode() : setDarkMode())
  return (
    <Switch
      color="green"
      id="checkbox"
      type="checkbox"
      checked={darkMode}
      onChange={handleClick}
    ></Switch>
  )
}
const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
})

const mapActionsToProps = {
  setDarkMode,
  setLightMode,
}

export default connect(mapStateToProps, mapActionsToProps)(DarkModeSwitchBase)
