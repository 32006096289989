import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { FC } from 'react'
import StripePayment from './StripePayment'
import { Loader } from '../../../utils'
import { AppState } from '../../../store/store'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { ITheme } from '../../../types'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')

interface IStripeElementProps {
  total: number
  vars: any
  theme: ITheme
  orderId?: string
  setOrderId?: (orderId: string) => void
  clientSecret?: string
  orderNotes?: string
}

const StripeElement: FC<IStripeElementProps> = props => {
  const {
    theme: { colors },
  } = props

  const { orderId } = props

  // useEffect(() => {
  //   CreatePaymentIntent({ variables: vars })
  //     .then(response => {
  //       // console.log(response.data)
  //       setClientSecret(response.data.createPaymentIntent.paymentToken)
  //       setOrderId && setOrderId(response.data.createPaymentIntent.id)
  //     })
  //     .catch(err => {
  //       alert(err.message)
  //       // console.log(err)
  //     })
  //   // eslint-disable-next-line
  // }, [])
  const options = {
    // passing the client secret obtained from the server
    clientSecret: props.clientSecret,
    appearance: {
      theme: 'flat',
      variables: {
        colorText: colors.text.default === 'paper' ? '#fff' : '#000',
        colorBackground: colors.text.default === 'slate' ? '#DDD' : '#222',
      },
    },
  }

  return (
    <>
      {!props.clientSecret ? (
        props.total > 0 ? (
          <Loader />
        ) : (
          orderId && <></>
        )
      ) : (
        // @ts-ignore
        <Elements stripe={stripePromise} options={options}>
          {orderId && (
            <StripePayment
              orderId={orderId}
              orderNotes={props.orderNotes}
              clientSecret={props.clientSecret}
            />
          )}
        </Elements>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withTheme(StripeElement))
