import { gql } from 'apollo-boost'

export default gql`
  query {
    getSomeInventoryGroups(searches: []) {
      name
      description
      styleId
      id
      templateBasePrice
      templatePriceBreaks
    }
  }
`
