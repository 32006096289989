import React, { useState, useEffect } from 'react'
import {
  ModalLayout as M,
  ButtonStyle as B,
  FormStyle as F,
  GridLayout as G,
} from 'styles'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import GET_INVENTORY_GROUPS from './gql/GET_INVENTORY_GROUPS'
import CREATE_PRODUCT from './gql/CREATE_PRODUCT'
import { TInventoryGroup, TProduct, TStore, TVariant } from 'types'
import EDIT_PRODUCT from './gql/EDIT_PRODUCT'
import * as ADMIN_ROUTES from '../../../routes/admin'
import { useHistory } from 'react-router-dom'
import { MarkdownTextArea } from 'components/Markdown'

interface IProductFormProps {
  visible: boolean
  toggle: any
  refetch: () => void
  product?: TProduct
  store?: TStore
}

const ProductForm: React.FC<IProductFormProps> = props => {
  const history = useHistory()
  const { visible, toggle, refetch, product, store } = props

  const [inventoryGroupItemCode, setInventoryGroupItemCode] = useState<
    string | undefined
  >(undefined)
  const [inventoryGroupStyleId, setInventoryGroupStyleId] = useState<
    number | undefined
  >(undefined)
  const [inventoryGroups, setInventoryGroups] = useState<TInventoryGroup[]>([])

  const [name, setName] = useState<string>('')
  const [basePrice, setBasePrice] = useState<number>(0)
  const [description, setDescription] = useState<string>('')
  const [priceBreaks, setPriceBreaks] = useState<number[][]>([])
  const [defaultColor, setDefaultColor] = useState<string>('')
  const [customField1, setCustomField1] = useState<string>('')
  const [customField2, setCustomField2] = useState<string>('')
  const [thumbnail, setThumbnail] = useState<any | undefined>(undefined)
  const [deleteThumbnail, setDeleteThumbnail] = useState<string>('false')
  const [isDesignable, setIsDesignable] = useState<string>(
    store ? 'false' : 'true'
  )

  const { loading, error, data } = useQuery(GET_INVENTORY_GROUPS, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (product) {
      setName(product.name)
      setBasePrice(product.basePrice)
      setDescription(product.description ? product.description : '')
      setDefaultColor(product.defaultColor ? product.defaultColor : '')
      setCustomField1(product.customField1 ? product.customField1 : '')
      setCustomField2(product.customField2 ? product.customField2 : '')
      setIsDesignable(
        product.isDesignable ? JSON.stringify(product.isDesignable) : 'false'
      )

      if (product.priceBreaks) {
        const priceBreaks: number[][] = []
        Object.entries(product.priceBreaks).forEach(
          (priceBreak: [string, number]) => {
            priceBreaks.push([parseInt(priceBreak[0]), priceBreak[1]])
          }
        )
        setPriceBreaks(priceBreaks)
      }
    }
    console.log(data, loading)
    if (data && data.getSomeInventoryGroups) {
      const inventoryGroups = data.getSomeInventoryGroups.sort(
        (a: TInventoryGroup, b: TInventoryGroup) => a.name.localeCompare(b.name)
      )

      setInventoryGroups(inventoryGroups)
      setInventoryGroupItemCode(inventoryGroups[0].id)
      setInventoryGroupStyleId(inventoryGroups[0].styleId)
    }
  }, [data, product, loading])

  useEffect(() => {
    if (inventoryGroupItemCode && data && !product) {
      const inventoryGroup = data?.getSomeInventoryGroups.find(
        (i: TInventoryGroup) => i.id === inventoryGroupItemCode
      )
      if (inventoryGroup) {
        if (inventoryGroup.templateBasePrice) {
          setBasePrice(inventoryGroup.templateBasePrice)
        } else {
          setBasePrice(0)
        }
        if (inventoryGroup.templatePriceBreaks) {
          setPriceBreaks(
            Object.keys(inventoryGroup.templatePriceBreaks).map(k => [
              k,
              inventoryGroup.templatePriceBreaks[k],
            ])
          )
        } else {
          setPriceBreaks([])
        }
        if (inventoryGroup.description) {
          setDescription(inventoryGroup.description)
        }
      }
    }
  }, [data, inventoryGroupItemCode, product])

  const [CreateProduct] = useMutation(CREATE_PRODUCT)
  const [EditProduct] = useMutation(EDIT_PRODUCT)

  const handleSubmitClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()

    let priceBreaksData: Record<string, number> | null = null

    if (priceBreaks.length > 0) {
      const priceBreaksObject: Record<string, number> = {}
      priceBreaks.forEach((priceBreak: number[]) => {
        priceBreaksObject[priceBreak[0].toString()] = priceBreak[1]
      })

      const sortedPriceBreaks: Record<string, number> = {}
      Object.keys(priceBreaksObject)
        .sort()
        .forEach((quantity: string) => {
          sortedPriceBreaks[quantity] = priceBreaksObject[quantity]
        })

      priceBreaksData = sortedPriceBreaks
    }

    try {
      if (product) {
        await EditProduct({
          variables: {
            id: product.id,
            name,
            basePrice,
            description,
            priceBreaks: priceBreaksData,
            defaultColor,
            thumbnail: thumbnail,
            deleteThumbnail: JSON.parse(deleteThumbnail),
            isDesignable: JSON.parse(isDesignable),
            customField1,
            customField2,
          },
        })
      } else {
        const resp = await CreateProduct({
          variables: {
            inventoryGroupItemCode,
            inventoryGroupStyleId,
            name,
            basePrice,
            available: false,
            description,
            priceBreaks: priceBreaksData,
            storeId: store?.id,
            thumbnail: thumbnail,
            isDesignable: JSON.parse(isDesignable),
            customField1,
            customField2,
          },
        })
        history.push(
          `${ADMIN_ROUTES.ADMIN_PRODUCTS}/${resp.data.createProduct.id}`
        )
      }
      toggle(false)
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddPriceBreak = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    const temp = [...priceBreaks, [1, 0]]
    setPriceBreaks(temp)
  }

  const handleDeletePriceBreak = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault()
    const temp = [...priceBreaks]
    temp.splice(index, 1)
    setPriceBreaks(temp)
  }

  const handleQuantityChange = (
    newQuantity: string,
    price: number,
    index: number
  ) => {
    let num = parseInt(newQuantity)
    if (num === 0) num = 1

    const temp = [...priceBreaks]
    temp[index] = [num, price]
    setPriceBreaks(temp)
  }

  const handlePriceChange = (
    newPrice: string,
    quantity: number,
    index: number
  ) => {
    const temp = [...priceBreaks]
    temp[index] = [quantity, parseFloat(newPrice)]
    setPriceBreaks(temp)
  }

  const isPriceBreakInvalid = (priceBreaks: any[][]) => {
    for (const priceBreak of priceBreaks) {
      if (isNaN(priceBreak[0]) || isNaN(priceBreak[1])) return true
    }
    return false
  }

  const invalid =
    inventoryGroupItemCode === undefined ||
    inventoryGroupStyleId === undefined ||
    name === '' ||
    basePrice === 0 ||
    isNaN(basePrice) ||
    isPriceBreakInvalid(priceBreaks)

  if (loading) return <Loader />

  if (error) return <Error message={error.message} />

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      console.log('here')
      console.log('file: ', file)
      setThumbnail(file)
    }
  }
  // console.log(product?.unavailableVariants)

  return (
    <>
      {visible && (
        <M.Modal>
          <h2>{product ? 'Edit Product' : 'New Product'}</h2>
          <B.Exit onClick={() => toggle(false)}>X</B.Exit>
          <F.Form>
            {!product ? (
              <F.Label>
                Inventory Group
                <F.select
                  value={`${inventoryGroupItemCode}/${inventoryGroupStyleId}`}
                  onChange={e => {
                    setInventoryGroupItemCode(e.target.value.split('/')[0])
                    setInventoryGroupStyleId(
                      parseInt(e.target.value.split('/')[1])
                    )
                  }}
                >
                  {inventoryGroups &&
                    inventoryGroups.map((inventoryGroup: TInventoryGroup) => (
                      <option
                        value={`${inventoryGroup.id}/${inventoryGroup.styleId}`}
                      >
                        {inventoryGroup.name} ({inventoryGroup.id})
                      </option>
                    ))}
                </F.select>
              </F.Label>
            ) : (
              <F.Label>
                Select Default Color
                <select onChange={e => setDefaultColor(e.target.value)}>
                  <option disabled={true} selected={!product.defaultColor}>
                    No Default Configured
                  </option>
                  {product.inventoryGroup.variantGroups
                    .filter(v => v.name === 'Color')[0]
                    .variants.filter(
                      (v: TVariant) =>
                        !product?.unavailableVariants
                          ?.map(uV => uV.id)
                          .includes(v.id)
                    )
                    .sort((a, b) =>
                      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
                    )
                    .map(v => (
                      <option
                        value={v.value}
                        selected={product.defaultColor === v.value}
                      >
                        {v.name}
                      </option>
                    ))}
                </select>
              </F.Label>
            )}

            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Description
              <MarkdownTextArea
                markdown={description}
                setMarkdown={setDescription}
              />
            </F.Label>
            <F.Label>
              Base Price
              <F.Input
                value={basePrice}
                type={'number'}
                placeholder={'price'}
                step="0.01"
                min="0"
                onChange={e => setBasePrice(parseFloat(e.target.value))}
              />
            </F.Label>
            <F.Label>
              {' '}
              Upload Thumbnail (Optional)
              <F.upload
                type="file"
                accept=".png"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event)
                }
              />
            </F.Label>
            <F.Label>
              {' '}
              Delete Thumbnail
              <select
                value={deleteThumbnail}
                onChange={e => setDeleteThumbnail(e.target.value)}
              >
                <option value={'false'}>False</option>
                <option value={'true'}>True</option>
              </select>
            </F.Label>
            <F.Label>
              {' '}
              Is Designable ?
              <select
                value={isDesignable}
                onChange={e => setIsDesignable(e.target.value)}
              >
                <option value={'false'}>False</option>
                <option value={'true'}>True</option>
              </select>
            </F.Label>
            <F.Label>
              Custom Field 1
              <F.Input
                value={customField1}
                type={'text'}
                placeholder={'custom 1'}
                onChange={e => setCustomField1(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Custom Field 2
              <F.Input
                value={customField2}
                type={'text'}
                placeholder={'custom 2'}
                onChange={e => setCustomField2(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Price Breaks
              <B.Button onClick={handleAddPriceBreak}>Add Price Break</B.Button>
            </F.Label>
            <G.row width="100%" justify="space-around">
              <F.Label>Quantity</F.Label>
              <F.Label>Price</F.Label>
            </G.row>
            {priceBreaks.map((priceBreak: number[], index: number) => (
              <G.row key={index}>
                <F.Input
                  value={priceBreak[0]}
                  type={'number'}
                  placeholder={'Quantity'}
                  min="1"
                  onChange={e =>
                    handleQuantityChange(e.target.value, priceBreak[1], index)
                  }
                />
                <F.Input
                  value={priceBreak[1]}
                  type={'number'}
                  placeholder={'Price'}
                  step="0.01"
                  min="0"
                  onChange={e =>
                    handlePriceChange(e.target.value, priceBreak[0], index)
                  }
                />
                <B.Button
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleDeletePriceBreak(event, index)}
                >
                  -
                </B.Button>
              </G.row>
            ))}
            {/* <pre>
                        {JSON.stringify(priceBreaks)}
                        {basePrice}
                    </pre> */}
            <B.Button disabled={invalid} onClick={handleSubmitClick}>
              {product ? 'Update' : 'Create Product'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default ProductForm
