import React, { useState } from 'react'
import { ModalLayout as M, ButtonStyle as B, FormStyle as F } from 'styles'
import CREATE_PROMOTION from 'gql/CREATE_PROMOTION'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import GET_SOME_COUPONS from 'gql/GET_SOME_COUPONS'
import GET_SOME_PRODUCTS from 'components/Product/lib/gql/GET_SOME_PRODUCTS'
import { Box } from '@sweaterplanet/nucleus-style'

interface IPromotionCreateFormProps {
  visible: boolean
  toggle: any
  refetch: () => void
}

const PromotionCreateForm: React.FC<IPromotionCreateFormProps> = props => {
  const [code, setCode] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [coupon, setCoupon] = useState<string>('')
  const [couponDescription, setCouponDescription] = useState<string>('')
  const [bannerImg, setBannerImg] = useState<any | null>(null)
  const [description, setDescription] = useState<string>('')
  const [products, setProducts] = useState<string[]>([])

  const invalid = code === '' || coupon === '' || !products.length

  const { data: couponsData, loading: couponsLoading } = useQuery(
    GET_SOME_COUPONS,
    {
      variables: {
        limit: 1000,
        offset: 0,
        searches: [],
      },
    }
  )

  const { data: productsData, loading: productsLoading } = useQuery(
    GET_SOME_PRODUCTS,
    {
      variables: {
        limit: 1000,
        offset: 0,
        searches: [],
      },
    }
  )

  const [CreatePromotion, { loading }] = useMutation(CREATE_PROMOTION)
  const handleCreateClick = () => {
    CreatePromotion({
      variables: {
        id: code,
        name,
        coupon,
        bannerImg,
        couponDescription,
        description,
        products,
      },
    })
      .then(() => {
        props.toggle(false)
        props.refetch()
      })
      .catch(error => <Error message={error.message} />)
  }

  const handleAddProduct = (id: string) => {
    if (!products.includes(id)) {
      let newProducts = products
      newProducts.push(id)
      setProducts(newProducts)
    }
  }
  const handleRemoveProduct = (id: string) => {
    let index = products.indexOf(id)
    products.splice(index, 1)
  }

  const onUploadInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const onUploadChange = (
    {
      target: {
        validity,
        files: [file],
      },
    }: any,
    type: string
  ) => {
    if (validity.valid) {
      if (type === 'banner') setBannerImg(file)
    }
  }

  if (loading || couponsLoading || productsLoading) return <Loader />

  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>New Promotion</h2>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              slug
              <F.Input
                value={code}
                type={'text'}
                placeholder={'Url Slug'}
                onChange={e => setCode(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'Name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Coupon
              {couponsData && (
                <F.select
                  value={coupon}
                  onChange={e => setCoupon(e.target.value)}
                >
                  <option value={''} key={'0'} disabled>
                    Select
                  </option>
                  {couponsData.getSomeCoupons.map(
                    (c: { id: string; name: string; available: boolean }) =>
                      c.available && (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      )
                  )}
                </F.select>
              )}
            </F.Label>
            <F.Label>
              Coupon Description
              <F.Input
                value={couponDescription}
                type={'text'}
                placeholder={'Coupon Description'}
                onChange={e => setCouponDescription(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Upload Banner Image (2000 x 1000)
              <F.upload
                type="file"
                accept="image/*"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onUploadChange(event, 'banner')
                }
                onClick={onUploadInputClick}
              />
            </F.Label>
            <F.Label>
              Description
              <F.Input
                value={description}
                type={'text'}
                placeholder={'Description'}
                onChange={e => setDescription(e.target.value)}
              />
            </F.Label>
            <h3>Products</h3>
            <Box>
              {productsData?.getSomeProducts.map((p: any) => (
                <F.Label>
                  {p.name}
                  <input
                    key={p.id}
                    type={'checkbox'}
                    onChange={e =>
                      e.target.checked
                        ? handleAddProduct(p.id)
                        : handleRemoveProduct(p.id)
                    }
                  />
                </F.Label>
              ))}
            </Box>
            <B.Button disabled={invalid} onClick={() => handleCreateClick()}>
              Create Promotion
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default PromotionCreateForm
