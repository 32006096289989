import { gql } from 'apollo-boost'

export default gql`
  query getStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      slug
      description
      whitelabelNav
      isPublic
      managers {
        id
      }
      logo {
        id
        url
        filename
      }
      bannerImg {
        id
        url
        filename
      }
      moodboard {
        id
        url
        filename
      }
      products {
        id
        name
        basePrice
        priceBreaks
        available
        description
        isDesignable
        defaultColor
        orderCount
        createdAt
        tags {
          id
          content
          color
        }
        variantGroups {
          id
          name
          variants {
            id
            name
            value
          }
        }
        addOnsByLocation {
          id
          addOn {
            id
            name
            publicName
            available
            basePrice
            priceBreaks
          }
          location {
            id
            name
            width
            height
          }
        }
        inventoryGroup {
          id
          name
          variantGroups {
            id
            name
            variants {
              id
              value
              name
            }
          }
          views {
            id
            name
            boundingBox
            base
            texture
            printLocations {
              id
              printLocation {
                id
                name
                available
                width
                height
              }
              coordinateMap
            }
          }
        }
        designs {
          id
          file {
            id
            url
            filename
            thumbnail
          }
          addOn {
            id
            name
            publicName
            basePrice
            priceBreaks
          }
          printLocation {
            id
            name
            price
          }
          rotate
          flip
          vectorize
          origin
          w
          h
        }
        thumbnail {
          url
        }
        OrderItem {
          subtotal
          createdAt
          Order {
            id
            paid
            payInStoreLocation {
              id
            }
          }
        }
      }
    }
  }
`
