import React, { useEffect, useState } from 'react'
import { HrStyle as Hr, FormStyle as F, ModalLayout as M } from 'styles'
import {
  Card,
  Flex,
  Heading,
  Button,
  Text,
  Box,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { TUser } from 'types/User'
// import { TAddress } from 'types/Address'
import { useMutation, useQuery } from '@apollo/react-hooks'
import EDIT_USER from 'gql/EDIT_USER'
import { Error, destroyToken } from 'utils'
import { ApolloQueryResult } from 'apollo-boost'
import { AppState } from 'store/store'
import { setUser, signOut } from 'store/actions/userActions'
import { connect } from 'react-redux'
import { UserState } from 'store/reducers/userReducer'
import DELETE_USER from 'gql/DELETE_USER'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { HOME } from 'routes/user'
import GET_PAYMENT_METHODS from 'gql/GET_PAYMENT_METHODS'
import REMOVE_PAYMENT_METHOD from 'gql/REMOVE_PAYMENT_METHOD'

interface CartCheckoutProps {
  user: TUser
  credentials: UserState['credentials']
  setUser: (user: TUser) => void
  signOut: () => void
  refetch: (
    variables?: Record<string, any> | undefined
  ) => Promise<ApolloQueryResult<any>>
  theme: ITheme
}

const AccountDetails: React.FC<
  CartCheckoutProps & RouteComponentProps
> = props => {
  const {
    user,
    credentials,
    setUser,
    signOut,
    history,
    refetch,
    theme: { colors },
  } = props
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')

  const [isNameEdit, setIsNameEdit] = useState(false)
  const [isEmailEdit, setIsEmailEdit] = useState(false)
  const [isPhoneEdit, setIsPhoneEdit] = useState(false)
  const [isCompanyEdit, setIsCompanyEdit] = useState(false)

  // const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1)
  const [isWarningOpen, setIsWarningOpen] = useState(false)

  const [editUser] = useMutation(EDIT_USER)
  const [deleteUser] = useMutation(DELETE_USER)

  const handleEditOpen = (setIsEdit: (value: boolean) => void) => {
    if (user) {
      setName(user.name || 'guest user')
      setEmail(user.email || 'guest@guest.com')
      setPhone(user.phone || '')
      setCompany(user.company || '')
    }
    setIsEdit(true)
  }

  const handleKeyDown = (key: string, setIsEdit: (value: boolean) => void) => {
    if (key === 'Enter') {
      handleSave(setIsEdit)
    }
  }

  const handleSave = async (setIsEdit: (value: boolean) => void) => {
    try {
      const response = await editUser({
        variables: {
          id: user.id,
          name: name === '' ? user.name : name,
          email: email === '' ? user.email : email,
          phone: phone,
          company: company,
        },
      })

      if (response.data) {
        setUser({
          ...credentials,
          name: response.data.editUser.name,
          email: response.data.editUser.email,
          phone: response.data.editUser.phone,
          company: response.data.editUser.company,
        })
        await refetch()
        setIsEdit(false)
      }
    } catch (error) {
      // @ts-ignore
      return <Error message={error.message} />
    }
  }

  // const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedAddressIndex(parseInt(event.target.value))
  // }

  const handleDeleteUser = async () => {
    setIsWarningOpen(false)
    if (user) {
      try {
        const response = await deleteUser({
          variables: {
            id: user.id,
          },
        })

        if (response.data) {
          destroyToken()
          signOut()
          history.push(HOME)
        }
      } catch (error) {
        // @ts-ignore
        return <Error message={error.message} />
      }
    }
  }

  // get and delete payment methods use
  const [removePaymentMethod] = useMutation(REMOVE_PAYMENT_METHOD)
  const [paymentMethods, setPaymentMethods] = useState<object[] | undefined>(
    undefined
  )

  const { data: paymentMethodsData, refetch: paymentMethodsRefetch } =
    useQuery(GET_PAYMENT_METHODS)

  useEffect(() => {
    if (paymentMethodsData) {
      setPaymentMethods(paymentMethodsData.getPaymentMethods)
    }
  }, [paymentMethodsData])

  return (
    <Card
      bg={colors.card.background}
      round
      maxWidth="100%"
      padding="2rem 2rem"
      justifyContent="center"
    >
      <Flex flexDirection="column">
        <Box marginBottom="20px">
          <Heading element="h3" color={colors.text.default}>
            Account Details
          </Heading>
        </Box>
        <Hr.Line />
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          margin="40px 0 20px 0"
        >
          <Flex alignItems="center">
            <Text bold color={colors.text.default}>
              Name
            </Text>
            {!isNameEdit && (
              <Box
                marginLeft="20px"
                cursor="pointer"
                onClick={() => handleEditOpen(setIsNameEdit)}
              >
                <Text color={colors.text.default}>Edit</Text>
              </Box>
            )}
          </Flex>
          {!isNameEdit ? (
            user && (
              <Text bold color={colors.text.default}>
                {user.name}
              </Text>
            )
          ) : (
            <Flex alignItems="center">
              <F.Input
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event.key, setIsNameEdit)
                }
              />
              <Flex>
                <Button
                  bg="green"
                  color="paper"
                  size="xs"
                  onClick={() => handleSave(setIsNameEdit)}
                >
                  Save
                </Button>
                <Box marginLeft="10px">
                  <Button
                    bg="red"
                    color="paper"
                    size="xs"
                    onClick={() => setIsNameEdit(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          margin="20px 0"
        >
          <Flex alignItems="center">
            <Text bold color={colors.text.default}>
              Email
            </Text>
            {!isEmailEdit && (
              <Box
                marginLeft="20px"
                cursor="pointer"
                onClick={() => handleEditOpen(setIsEmailEdit)}
              >
                <Text color={colors.text.default}>Edit</Text>
              </Box>
            )}
          </Flex>
          {!isEmailEdit ? (
            user && (
              <Text bold color={colors.text.default}>
                {user.email}
              </Text>
            )
          ) : (
            <Flex alignItems="center">
              <F.Input
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(event.target.value)
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event.key, setIsEmailEdit)
                }
              />
              <Flex>
                <Button
                  bg="green"
                  color="paper"
                  size="xs"
                  onClick={() => handleSave(setIsEmailEdit)}
                >
                  Save
                </Button>
                <Box marginLeft="10px">
                  <Button
                    bg="red"
                    color="paper"
                    size="xs"
                    onClick={() => setIsEmailEdit(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          margin="20px 0"
        >
          <Flex alignItems="center">
            <Text bold color={colors.text.default}>
              Phone Number
            </Text>
            {!isPhoneEdit && (
              <Box
                marginLeft="20px"
                cursor="pointer"
                onClick={() => handleEditOpen(setIsPhoneEdit)}
              >
                <Text color={colors.text.default}>Edit</Text>
              </Box>
            )}
          </Flex>
          {!isPhoneEdit ? (
            user && (
              <Text bold color={colors.text.default}>
                {user.phone}
              </Text>
            )
          ) : (
            <Flex alignItems="center">
              <F.Input
                value={phone}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPhone(event.target.value)
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event.key, setIsPhoneEdit)
                }
              />
              <Flex>
                <Button
                  bg="green"
                  color="paper"
                  size="xs"
                  onClick={() => handleSave(setIsPhoneEdit)}
                >
                  Save
                </Button>
                <Box marginLeft="10px">
                  <Button
                    bg="red"
                    color="paper"
                    size="xs"
                    onClick={() => setIsPhoneEdit(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          margin="20px 0"
        >
          <Flex alignItems="center">
            <Text bold color={colors.text.default}>
              Company
            </Text>
            {!isCompanyEdit && (
              <Box
                marginLeft="20px"
                cursor="pointer"
                onClick={() => handleEditOpen(setIsCompanyEdit)}
              >
                <Text color={colors.text.default}>Edit</Text>
              </Box>
            )}
          </Flex>
          {!isCompanyEdit ? (
            user && (
              <Text bold color={colors.text.default}>
                {user.company}
              </Text>
            )
          ) : (
            <Flex alignItems="center">
              <F.Input
                value={company}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(event.target.value)
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event.key, setIsCompanyEdit)
                }
              />
              <Flex>
                <Button
                  bg="green"
                  color="paper"
                  size="xs"
                  onClick={() => handleSave(setIsCompanyEdit)}
                >
                  Save
                </Button>
                <Box marginLeft="10px">
                  <Button
                    bg="red"
                    color="paper"
                    size="xs"
                    onClick={() => setIsCompanyEdit(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex
          margin="20px 0"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Text bold color={colors.text.default}>
            Payment Methods
          </Text>
          <Box marginTop="20px">
            {paymentMethods?.length
              ? paymentMethods.map((d: any) => (
                  <Flex justifyContent="space-between">
                    <p>{`**** **** **** ${d.card.last4}`}</p>
                    <Button
                      size="medium"
                      onClick={() =>
                        removePaymentMethod({
                          variables: { paymentMethodId: d.id },
                        }).then(() => paymentMethodsRefetch())
                      }
                    >
                      Remove
                    </Button>
                  </Flex>
                ))
              : 'N/A'}
          </Box>
        </Flex>

        <Hr.Line thickness="0px" />
        <Box
          margin="30px 0 10px 0"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsWarningOpen(true)}
        >
          <Text color="red">Delete Account</Text>
        </Box>
        {isWarningOpen && (
          <M.Modal>
            <Box marginBottom="20px">
              <Heading element="h4" color={colors.text.default}>
                Are you sure you want to delete this account?
              </Heading>
            </Box>
            <Flex flexDirection="row" justifyContent="space-evenly">
              <Button bg="red" color="paper" onClick={() => handleDeleteUser()}>
                Delete Account
              </Button>
              <Button onClick={() => setIsWarningOpen(false)}>Cancel</Button>
            </Flex>
          </M.Modal>
        )}
      </Flex>
    </Card>
  )
}

const mapStateToProps = (state: AppState) => ({
  credentials: state.user.credentials,
})

const mapActionsToProps = {
  setUser,
  signOut,
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(withTheme(AccountDetails)))
