import { gql } from 'apollo-boost'

export default gql`
  query getCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      name
      available
      type
      value
      maxUses
      validFrom
      validUntil
    }
  }
`
