import React from 'react'

import { Button } from '@sweaterplanet/nucleus-style'

import { useMutation, useQuery } from '@apollo/react-hooks'

import { Redirect } from 'react-router-dom'

import GOOGLE_AUTHORIZATION from './gql/GOOGLE_AUTHORIZATION'
import { Error, Loader } from 'utils'
import { ADMIN_EMAIL_BOT } from 'routes/admin'
import GET_GOOGLE_AUTH_LINK from './gql/GET_GOOGLE_AUTH_LINK'

interface IGoogleAuthorizationProps {
  history?: any
}

const GoogleAuthorization: React.FC<IGoogleAuthorizationProps> = props => {
  const { data: googleAuthLinkData } = useQuery(GET_GOOGLE_AUTH_LINK)

  const [GoogleAuthorize, { called, loading, data, error }] =
    useMutation(GOOGLE_AUTHORIZATION)

  if (props.history && props.history.location.search && !called) {
    const search = props.history.location.search
    GoogleAuthorize({
      variables: {
        code: search.split('code=')[1].split('&')[0],
      },
    })
  }

  if (error) return <Error message={error.message} />

  if (loading && !data) return <Loader />

  if (data) {
    return <Redirect to={ADMIN_EMAIL_BOT} />
  }

  if (googleAuthLinkData) {
    console.log(googleAuthLinkData)
    return (
      <>
        <Button
          onClick={() =>
            (window.location.href = googleAuthLinkData.getGoogleAuthLink.url)
          }
        >
          Authorize with Google
        </Button>
      </>
    )
  } else {
    return <Loader />
  }
}

export default GoogleAuthorization
