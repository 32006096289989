import React, { useEffect, useState } from 'react'
import ColorPicker from 'components/ColorPicker'
import LayerPicker from './LayerPicker'
import AddOnPicker from './AddOnPicker'
import { TProduct } from 'types/Product'
import { TView } from 'types/View'
import { TVariant } from 'types/Variant'
import { TAddOn } from 'types/AddOn'
import { ITheme } from 'types/Theme'
import { TTempDesign } from 'types/Design'
import {
  Card,
  Flex,
  Heading,
  Button,
  Box,
  Text,
} from '@sweaterplanet/nucleus-style'
import { TAddOnByLocation, TPrintLocation } from 'types'
interface IDesignerProps {
  selectedColors: TVariant[]
  product: TProduct
  isStore?: boolean
  history: any

  activeView: TView | null
  setActiveView: (view: TView) => void
  defaultImageWidth: number
  defaultImageHeight: number
  tempDesigns: TTempDesign[]
  setTempDesigns: (tempDesigns: TTempDesign[]) => void
  selectedDesign: TTempDesign
  setSelectedDesign: (tempDesign: TTempDesign) => void
  handleSetSelectedAddOn: (addOn: TAddOn | null, index: number) => void
  addOnError: boolean
  setAddOnError: (error: boolean) => void
  setSelectedColor: React.Dispatch<React.SetStateAction<TVariant>> | undefined
  handleUpdateProduct?: () => void
  setIsDesignStudio?: (value: boolean) => void
  selectedPrintLocationIndex: number
  isTour?: boolean
  setIsTour?: (value: boolean) => void
  setTourStep?: (value: number) => void

  theme: ITheme
  darkMode: boolean
}

export const Designer: React.FC<IDesignerProps> = props => {
  const {
    selectedColors,
    product,
    isStore,
    activeView,
    setActiveView,
    defaultImageWidth,
    defaultImageHeight,
    tempDesigns,
    setTempDesigns,
    selectedDesign,
    setSelectedDesign,
    // handleSetSelectedAddOn,
    addOnError,
    setAddOnError,
    setSelectedColor,
    handleUpdateProduct,
    setIsDesignStudio,
    isTour,
    setIsTour,
    setTourStep,
    theme,
    darkMode,
  } = props

  const isAddOnError = (tempDesigns: TTempDesign[]) => {
    for (const design of tempDesigns) {
      if (design.addOnIndex < 0) return true
    }
    return false
  }

  const handleBackToProductClick = () => {
    if (tempDesigns.length > 0 && isAddOnError(tempDesigns)) {
      setAddOnError(true)
    } else {
      setSelectedDesign({ ...selectedDesign, designIndex: -2 })
      if (isTour && setTourStep) setTourStep(8)
      setIsDesignStudio && setIsDesignStudio(false)
    }
  }
  const [availableAddOns, setAvailableAddOns] = useState<TAddOn[] | undefined>()

  const [selectedAddOn, setSelectedAddOn] = useState<TAddOn>()
  const [selectedAddOnIndex, setSelectedAddOnIndex] = useState<number>()

  const handleSetSelectedAddOn = (addOn: TAddOn, index: number) => {
    setSelectedAddOn(addOn)
    setSelectedAddOnIndex(index)

    setAddOnError(false)

    const selectedDesignData = {
      ...selectedDesign,
      addOn: addOn,
      addOnIndex: index,
    }

    setSelectedDesign(selectedDesignData)
    // const tempDesignsData = tempDesigns.map((t: TTempDesign) => ({
    //   ...t,
    //   addOn: addOn,
    //   addOnIndex: index,
    // }))
    const tempDesignsData = [...tempDesigns].map((t: TTempDesign) => ({
      ...t,
      addOn: addOn,
      addOnIndex: index,
    }))

    // tempDesignsData[selectedDesignData.designIndex] = selectedDesignData
    setTempDesigns(tempDesignsData)
  }

  ////////

  const containsPrintLocation = (
    printLocation: TPrintLocation,
    location: TPrintLocation
  ) => {
    return location.id === printLocation.id
  }

  useEffect(() => {
    if (product.addOnsByLocation && tempDesigns) {
      const activeLocations = tempDesigns.map(
        tempDesign => tempDesign.printLocation
      )
      const uniqueActiveLocations = Array.from(
        new Set(activeLocations.map(a => a.id))
      ).map(id => {
        return activeLocations.find(a => a.id === id)
      })

      const allAddOns: TAddOn[] = Array.from(
        new Set(
          product.addOnsByLocation.map((aOBL: TAddOnByLocation) => aOBL.addOn)
        )
      )

      const addOnsByLocationFiltered = product.addOnsByLocation.filter(
        (aOBL: TAddOnByLocation) => {
          return uniqueActiveLocations.filter(uAL => {
            console.log('HERE', uAL)
            console.log('HERE1', aOBL.location)
            console.log(
              'HERE2',
              uAL && containsPrintLocation(uAL, aOBL.location)
            )
            return uAL && containsPrintLocation(uAL, aOBL.location)
          })
        }
      )

      const addOnsFiltered = Array.from(
        new Set(
          addOnsByLocationFiltered.map((a: TAddOnByLocation) => a.addOn.id)
        )
      ).map(id => allAddOns.find(a => a.id === id))

      setAvailableAddOns(addOnsFiltered as TAddOn[])
    }
  }, [tempDesigns, product])

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      width={'100%'}
      maxWidth={'600px'}
    >
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <Flex flexDirection="column" width={'100%'}>
          <LayerPicker
            setActiveView={setActiveView}
            tempDesigns={tempDesigns}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setTempDesigns={setTempDesigns}
            product={product}
            defaultImageWidth={defaultImageWidth}
            defaultImageHeight={defaultImageHeight}
          />
          <Text color="red" style={{ margin: '10px 0', visibility: 'hidden' }}>
            Pick a Layer!
          </Text>
        </Flex>
        <Flex flexDirection="column" width={'100%'}>
          <AddOnPicker
            selectedDesign={selectedDesign}
            handleSetSelectedAddOn={handleSetSelectedAddOn}
            selectedAddOn={selectedAddOn}
            availableAddOns={availableAddOns}
            selectedAddOnIndex={selectedAddOnIndex}
            tempDesigns={tempDesigns}
            activeView={activeView}
            product={product}
            darkMode={darkMode}
          />
          {setIsTour ? (
            <Text
              color="red"
              style={{
                margin: '10px 0',
                visibility: addOnError ? 'visible' : 'hidden',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setIsTour(true)}
            >
              Please Pick an AddOn for each Layer. Stuck? Click me
            </Text>
          ) : (
            <Text
              color="red"
              style={{
                margin: '10px 0',
                visibility: addOnError ? 'visible' : 'hidden',
                textAlign: 'center',
              }}
            >
              Pick an AddOn for each Layer!
            </Text>
          )}
        </Flex>
      </Flex>
      <Card
        bg={theme.colors.card.background}
        round
        width={'100%'}
        margin={'20px'}
      >
        <Box padding="20px">
          <Box marginBottom="10px">
            <Heading element="h4" color={theme.colors.text.default}>
              Selected Colors
            </Heading>
          </Box>
          <Box margin="10px">
            <ColorPicker
              colors={selectedColors}
              setColor={setSelectedColor}
              unavailableVariants={product.unavailableVariants}
            />
          </Box>

          {!isStore ? (
            <Button
              bg="green"
              color="white"
              maxWidth
              size="sm"
              onClick={() => handleBackToProductClick()}
              data-tut="tour_8"
            >
              Back To Product
            </Button>
          ) : (
            handleUpdateProduct && (
              <Button
                bg="green"
                color="white"
                maxWidth
                size="sm"
                onClick={() => handleUpdateProduct()}
              >
                Update
              </Button>
            )
          )}
        </Box>
      </Card>
    </Flex>
  )
}

export default Designer
