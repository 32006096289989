import { gql } from 'apollo-boost'

export default gql`
  mutation createPaymentIntent(
    $amount: Int!
    $billingAddress: JSONObject!
    $billingAccount: JSONObject!
    $cart: JSONObject!
    $shipping: JSONObject!
    $splitShipping: Boolean!
    $saveBillingAddress: Boolean!
    $saveBillingAccount: Boolean!
    $coupon: JSONObject
    $cartItemTotals: [Float]!
    $referralCode: String
    $shippingPrice: Float!
    $shippingSpeed: String!
    $deliveryType: String!
    $createNewUser: Boolean
    $pickupLocationID: ID
  ) {
    createPaymentIntent(
      billingAddress: $billingAddress
      billingAccount: $billingAccount
      amount: $amount
      cart: $cart
      shipping: $shipping
      splitShipping: $splitShipping
      saveBillingAddress: $saveBillingAddress
      saveBillingAccount: $saveBillingAccount
      coupon: $coupon
      cartItemTotals: $cartItemTotals
      referralCode: $referralCode
      shippingPrice: $shippingPrice
      shippingSpeed: $shippingSpeed
      deliveryType: $deliveryType
      createNewUser: $createNewUser
      pickupLocationID: $pickupLocationID
    ) {
      paymentToken
      id
    }
  }
`
