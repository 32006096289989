import { gql } from 'apollo-boost'

export default gql`
  query getAllInventoryProviders(
    $searches: [String!]
    $offset: Int
    $limit: Int
  ) {
    getAllInventoryProviders(
      searches: $searches
      offset: $offset
      limit: $limit
    ) {
      id
      name
      value
    }
  }
`
