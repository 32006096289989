import React, { useState, useEffect, useMemo } from 'react'
import { TProduct } from 'types/Product'
import { TTag } from 'types/Tag'
import { useQuery } from '@apollo/react-hooks'
import { useMediaQuery } from 'react-responsive'
// Components
import { Loader, Error } from 'utils'
import ProductCard from './ProductCard'
import ColorPicker from 'components/ColorPicker'
// Styles
import { Input } from 'styles/lib/form'
// gql
import GET_SOME_PRODUCTS from './gql/GET_SOME_PRODUCTS'
import {
  Flex,
  Tag,
  Heading,
  Text,
  Button,
  Box,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import Upload from 'components/Upload'
import GET_WEBSITE_SETTINGS from '../../../gql/GET_WEBSITE_SETTINGS'
import GET_FILE from '../../../gql/GET_FILE'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import MiniGallery from '../../Gallery/lib/MiniGallery'
import { TAddOn, TAddOnByLocation, TVariant, TVariantGroup } from 'types'
import GET_FILES from 'gql/GET_FILES'
import GET_SOME_ADD_ONS from 'gql/GET_SOME_ADD_ONS'
import { DangerButton, SubtleButton } from 'styles/lib/button'

interface IProductsListPageProps {
  theme: ITheme
  location: {
    search: any
  }
  history: any
}

const ProductsList: React.FC<
  IProductsListPageProps & RouteComponentProps
> = props => {
  const {
    theme: { colors },
  } = props

  // MOBILE
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const sr = props.location.search // could be '?foo=bar'
  const params = useMemo(() => new URLSearchParams(sr), [sr])
  const colorParam = params.get('color') // bar
  const tagParam = params.get('tag')
  const previewIDParam = params.get('previewID')
  const addOnParam = params.get('dec')

  const [search, setSearch] = useState('')
  const [searches, setSearches] = useState<string[]>(tagParam ? [tagParam] : [])
  const [selectedColor, setSelectedColor] = useState<
    { name?: string; value: string } | undefined
  >(undefined)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [previewURL, setPreviewURL] = useState<string | undefined>(undefined)
  const [previewID, setPreviewID] = useState<string | undefined>(undefined)
  const { data: websiteSettingsData } = useQuery(GET_WEBSITE_SETTINGS)

  const { loading, error, data, refetch } = useQuery(GET_SOME_PRODUCTS, {
    variables: { searches: searches },
  })

  const { data: previewFileData } = useQuery(GET_FILE, {
    variables: {
      id: previewIDParam,
    },
  })

  useEffect(() => {
    if (isTabletOrMobile && previewIDParam) {
      setIsSidebarOpen(false)
    }
  }, [isTabletOrMobile, previewIDParam])

  useEffect(() => {
    refetch().catch(error => <Error message={error.message} />)
  })
  let products: TProduct[] = useMemo(() => [], [])
  if (data && !error) {
    let productArr1: TProduct[] = [],
      productArr2: TProduct[] = []
    data.getSomeProducts.forEach((p: TProduct) => {
      if (p.tags.filter((t: TTag) => t.id === 'featured').length) {
        productArr1.push(p)
      } else {
        productArr2.push(p)
      }
    })
    products = productArr1.concat(productArr2)
  }

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }
  const searchClick = () => {
    if (search !== '') {
      setSearches([search])
      setSearch('')
    }
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchClick()
    }
  }
  const handleOnTagRemove = (index: number) => {
    const tagsData = [...searches]
    tagsData.splice(index, 1)
    if (tagsData.length === 0) {
      params.delete('tag')
      props.history.push({
        pathname: '/products',
        search: params.toString(),
      })
    } else if (
      tagsData.length === 1 ||
      !tagsData.includes(params.get('tag') || '')
    ) {
      params.set('tag', tagsData[0])
      props.history.push({
        pathname: '/products',
        search: params.toString(),
      })
    }
    setSearches(tagsData)
    refetch().catch(error => <Error message={error.message} />)
  }
  const handleTagClick = (tag: TTag) => {
    const tagsData = [...searches]
    if (!tagsData.includes(tag.content)) {
      tagsData.push(tag.content)
    }
    if (tagsData.length === 1) {
      params.set('tag', tag.content)
      props.history.push({
        pathname: '/products',
        search: params.toString(),
      })
    }
    setSearches(tagsData)
    refetch().catch(error => <Error message={error.message} />)
  }
  const handleUpload = (file: any) => {
    // const url = URL.createObjectURL(file)
    // if (previewURL !== undefined) {
    //   URL.revokeObjectURL(previewURL)
    // }
    setPreviewURL(file.thumbnail || file.url)
    setPreviewID(file.id)
    params.set('previewID', file.id)
    props.history.push({
      pathname: '/products',
      search: params.toString(),
    })
    userImagesRefetch()
  }

  console.log(params.toString())

  useEffect(() => {
    if (selectedColor && selectedColor?.name) {
      params.set('color', selectedColor.name)
      props.history.push({
        pathname: '/products',
        search: params.toString(),
      })
    }
  }, [selectedColor, params, props.history])

  useEffect(() => {
    return () => {
      if (previewURL !== undefined) {
        URL.revokeObjectURL(previewURL)
      }
    }
  })

  const [addOns, setAddOns] = useState<TAddOn[]>([])

  const [selectedAddOn, setSelectedAddOn] = useState<TAddOn | null | undefined>(
    undefined
  )

  const { data: addOnsData } = useQuery(GET_SOME_ADD_ONS, {
    variables: { searches: [] },
  })

  const calledOnce = React.useRef(false)

  useEffect(() => {
    if (calledOnce.current) {
      return
    }
    if (addOnsData && products.length && !selectedAddOn) {
      const addOns = addOnsData?.getSomeAddOns.filter((a: TAddOn) =>
        products
          .filter(p => p.available)
          .flatMap(p => p.addOnsByLocation.flatMap(a => a.addOn.id))
          .includes(a.id)
      )
      setAddOns(addOns)
      calledOnce.current = true
    }
  }, [addOnsData, products, selectedAddOn])

  const {
    data: userImages,
    refetch: userImagesRefetch,
    loading: userImagesLoading,
  } = useQuery(GET_FILES)

  useEffect(() => {
    if (selectedAddOn === null) {
      params.delete('dec')
      props.history.push({
        pathname: '/products',
        search: params.toString(),
      })
    }
    if (selectedAddOn && selectedAddOn?.id) {
      params.set('dec', selectedAddOn.id)
      props.history.push({
        pathname: '/products',
        search: params.toString(),
      })
    }
  }, [selectedAddOn, params, props.history])

  useEffect(() => {
    if (websiteSettingsData && addOns) {
      if (colorParam) {
        const findCol =
          websiteSettingsData.getWebsiteSettings.previewColors.find(
            (c: { name: string }) => c.name === `${colorParam}`
          )
        if (findCol) {
          setSelectedColor(findCol)
        }
      }

      if (previewFileData && previewIDParam) {
        setPreviewID(previewIDParam)
        setPreviewURL(
          previewFileData.getFile.thumbnail || previewFileData.getFile.url
        )
      }

      if (addOnParam) {
        const findAddOn = addOns.find((a: TAddOn) => a.id === addOnParam)
        if (findAddOn) {
          setSelectedAddOn(findAddOn)
        }
      }
    }
  }, [
    colorParam,
    previewFileData,
    websiteSettingsData,
    previewIDParam,
    addOnParam,
    addOns,
  ])

  return (
    <Flex flexDirection={isTabletOrMobile ? 'column' : 'row'} width="100%">
      {error && <Error message={error.message} />}
      {isTabletOrMobile ? (
        <Box
          bg={colors.card.background}
          flexGrow={1}
          padding="20px"
          marginBottom={'20px'}
        >
          <Flex width="100%" flexDirection="row" justifyContent="flex-end">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faChevronDown : (faChevronUp as any)}
              size="2x"
              color={colors.contrast}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
          </Flex>
          <Box display={isSidebarOpen ? 'block' : 'none'}>
            <Box margin="30px 0">
              <Heading element="h4" color={colors.text.default}>
                Upload your logo
              </Heading>
            </Box>
            <>
              <Upload
                refetch={refetch}
                handleProductsPagePreview={handleUpload}
                fullWidth
              />
              {/*{previewURL ? (*/}
              {/*  <Image*/}
              {/*    src={previewURL}*/}
              {/*    style={{ maxWidth: '100%', maxHeight: '250px' }}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <></>*/}
              {/*)}*/}
            </>
            {userImages ? (
              <MiniGallery userImages={userImages.me.files} />
            ) : userImagesLoading ? (
              <div style={{ paddingTop: '20px' }}>
                <Loader />
              </div>
            ) : (
              <></>
            )}

            <Box margin="30px 0 10px 0">
              <Heading element="h4" color={colors.text.default}>
                Preview Color
              </Heading>
            </Box>
            {websiteSettingsData ? (
              <ColorPicker
                colors={websiteSettingsData.getWebsiteSettings.previewColors}
                setColor={setSelectedColor}
              />
            ) : (
              <Loader />
            )}

            <Box margin="30px 0 10px 0">
              <Heading element="h4" color={colors.text.default}>
                Popular Searches
              </Heading>
            </Box>
            <Flex flexDirection="row" flexWrap="wrap">
              {websiteSettingsData ? (
                websiteSettingsData.getWebsiteSettings.popularSearches.map(
                  (tag: TTag, index: number) => (
                    <Box key={index} margin="10px 10px 0 0">
                      <Tag
                        onClick={() => handleTagClick(tag)}
                        size="xs"
                        button
                        color={tag.color}
                        text={`${tag.icon ? tag.icon : ''} ${tag.content}`}
                      />
                    </Box>
                  )
                )
              ) : (
                <Loader />
              )}
            </Flex>
            <Box margin="30px 0 10px 0">
              <a href="/products">
                <SubtleButton color={colors.text.default}>
                  <Text color={colors.text.default}>X Reset</Text>
                </SubtleButton>
              </a>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          bg={colors.card.background}
          flexGrow={1}
          maxWidth={isSidebarOpen ? '400px' : 'fit-content'}
          minHeight="100vh"
          padding="20px"
        >
          <Flex
            flexDirection="column"
            width="100%"
            style={{
              position: 'sticky',
              top: '20px',
              alignSelf: 'flex-start',
            }}
            // maxHeight="calc(100vh - 40px)"
            // overflowY="scroll"
            // // show overflowX on mobile
            // overflowX="clip"
            // boxSizing="content-box"
            // paddingRight="17px"
          >
            <Flex width="100%" flexDirection="row" justifyContent="flex-end">
              <FontAwesomeIcon
                icon={isSidebarOpen ? faChevronLeft : (faChevronRight as any)}
                size="2x"
                color={colors.contrast}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              />
            </Flex>
            <Box
              display={isSidebarOpen ? 'block' : 'none'}
              width={'calc(100% - 4px)'}
            >
              <Box margin="30px 0">
                <Heading element="h4" color={colors.text.default}>
                  Upload your logo
                </Heading>
              </Box>
              <>
                <Upload
                  refetch={refetch}
                  handleProductsPagePreview={handleUpload}
                  fullWidth
                />
                {/*{previewURL ? (*/}
                {/*  <Image*/}
                {/*    src={previewURL}*/}
                {/*    style={{ maxWidth: '100%', maxHeight: '250px' }}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <></>*/}
                {/*)}*/}
              </>
              {userImages ? (
                <MiniGallery userImages={userImages.me.files} />
              ) : userImagesLoading ? (
                <div style={{ paddingTop: '20px' }}>
                  <Loader />
                </div>
              ) : (
                <></>
              )}

              <Box margin="30px 0 10px 0">
                <Heading element="h4" color={colors.text.default}>
                  Preview Color
                </Heading>
              </Box>
              {websiteSettingsData ? (
                <ColorPicker
                  colors={websiteSettingsData.getWebsiteSettings.previewColors}
                  setColor={setSelectedColor}
                />
              ) : (
                <Loader />
              )}

              <Box margin="30px 0 10px 0">
                <Heading element="h4" color={colors.text.default}>
                  Popular Searches
                </Heading>
              </Box>
              <Flex flexDirection="row" flexWrap="wrap">
                {websiteSettingsData ? (
                  websiteSettingsData.getWebsiteSettings.popularSearches.map(
                    (tag: TTag, index: number) => (
                      <Box key={index} margin="10px 10px 0 0">
                        <Tag
                          onClick={() => handleTagClick(tag)}
                          size="xs"
                          button
                          color={tag.color}
                          text={`${tag.icon ? tag.icon : ''} ${tag.content}`}
                        />
                      </Box>
                    )
                  )
                ) : (
                  <Loader />
                )}
              </Flex>
              <Box margin="30px 0 10px 0">
                <a href="/products">
                  <SubtleButton color={colors.text.default}>
                    <Text color={colors.text.default}>X Reset Filters</Text>
                  </SubtleButton>
                </a>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
      <Box width="100%">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow="1"
        >
          <Flex
            width="-webkit-fill-available"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box margin="0 20px">
              <Flex>
                {/* list of add ons */}
                {addOns ? (
                  addOns.map((addOn: TAddOn) => (
                    <Box key={addOn.id} margin="0 10px">
                      <Tag
                        size="xs"
                        button
                        style={{
                          backgroundColor:
                            selectedAddOn === addOn ? colors.contrast : '',
                          color:
                            selectedAddOn === addOn ? colors.button.text : '',
                        }}
                        color={colors.contrast}
                        text={selectedAddOn === addOn ? addOn.name : addOn.name}
                        onClick={() =>
                          selectedAddOn === addOn
                            ? setSelectedAddOn(null)
                            : setSelectedAddOn(addOn)
                        }
                      />
                    </Box>
                  ))
                ) : (
                  <Loader />
                )}
              </Flex>
            </Box>
            <Flex flexDirection="row" style={{ width: '50%' }}>
              <Input
                value={search}
                onChange={searchChange}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Search"
                style={{ width: '100%' }}
              />
              <Box marginLeft="20px" marginTop="10px">
                <Button
                  bg="green"
                  color="white"
                  size="xs"
                  onClick={searchClick}
                >
                  Search
                </Button>
              </Box>
            </Flex>
            <Flex flexDirection="row" alignItems="center">
              <Flex flexDirection="row" flexWrap="wrap">
                {searches.length > 0 &&
                  searches.map((search, index) => (
                    <Box key={index} padding="10px 0 10px 15px">
                      <Tag
                        size="xs"
                        button
                        color={colors.contrast}
                        text={search + ' X'}
                        onClick={() => handleOnTagRemove(index)}
                      />
                    </Box>
                  ))}
              </Flex>
            </Flex>
          </Flex>
          {loading && <Loader />}
          <Flex flexWrap="wrap" flexDirection="row" justifyContent="center">
            {products
              .filter(p =>
                selectedAddOn
                  ? p.addOnsByLocation
                      .flatMap(a => a.addOn.id)
                      .includes(selectedAddOn.id)
                  : true
              )
              .map(
                (product: TProduct, index: number) =>
                  product.available &&
                  !product.store && (
                    <Box margin="20px" key={index}>
                      <ProductCard
                        product={product}
                        color={
                          product.variantGroups
                            .find(
                              (variantGroup: TVariantGroup) =>
                                variantGroup.name === 'Color'
                            )
                            ?.variants.find(
                              (variant: TVariant) =>
                                variant.name === selectedColor?.name
                            ) || { name: 'White', value: '#FFF' }
                        }
                        handleTagClick={handleTagClick}
                        previewURL={previewURL}
                        dec={selectedAddOn?.id}
                        previewID={previewID}
                        previewAddOnByLocation={product.addOnsByLocation.find(
                          (addOnByLocation: TAddOnByLocation) =>
                            addOnByLocation.addOn.id === selectedAddOn?.id
                        )}
                      />
                    </Box>
                  )
              )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default withRouter(withTheme(ProductsList))
