import { gql } from 'apollo-boost'

export default gql`
  query getRetailLocation($id: ID!) {
    getRetailLocation(id: $id) {
      id
      name
      description
      locationCode
      authToken
      slug
      managers {
        id
        name
        email
      }
    }
  }
`
