import { gql } from 'apollo-boost'

export const SEARCH_USER = gql`
  mutation searchUser($search: String!) {
    searchUser(search: $search) {
      id
      name
      email
      phone
    }
  }
`
