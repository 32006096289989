import { ITheme } from '../../../types/Theme'
import React, { useEffect } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import GET_FILES from '../../../gql/GET_FILES'
import { Box, Heading } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { TFile } from 'types'
import GET_FILE from 'gql/GET_FILE'
import PING_FILE from 'gql/PING_FILE'
import { Loader } from 'utils'

interface IMiniGalleryProps {
  theme: ITheme
  numImages?: number
  handleImage?: (image: TFile) => void
  userImages: TFile[]
}

const MiniGallery: React.FC<IMiniGalleryProps> = props => {
  const {
    theme: { colors },
    numImages,
    handleImage,
  } = props

  const [pingFile] = useMutation(PING_FILE)

  const handle = (file: TFile) => {
    console.log('FILE', file)
    pingFile({ variables: { id: file.id } })
      .then(async () => {
        console.log('PINGED')
      })
      .catch(err => console.log('PING ERR', err))
    handleImage && handleImage(file)
  }

  if (props.userImages.length) {
    return (
      <Box margin="30px 0 10px 0">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading element="h4" color={colors.text.default}>
            Recent Uploads
          </Heading>
          <a style={{ margin: '4px', textAlign: 'right' }} href={'/gallery'}>
            {'Gallery'}
          </a>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent:
              props.userImages.length > 2 ? 'space-between' : 'flex-start',
            marginTop: '15px',
          }}
        >
          {props.userImages.length &&
            props.userImages.slice(0, numImages || 4).map((file: any) => (
              <>
                {handleImage ? (
                  <a
                    style={{
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '2px',
                    }}
                    onClick={() => handle(file)}
                  >
                    <img
                      src={file.thumbnail || file.url}
                      alt={file.id}
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        maxHeight: '100%',
                      }}
                    />
                  </a>
                ) : (
                  <a
                    style={{
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '2px',
                    }}
                    href={`/products?previewID=${file.id}&color=${
                      window.location.href.split('color=')[1]?.split('tag=')[0]
                    }${
                      window.location.href.split('tag=')[1]?.split('dec=')[0]
                        ? '&tag=' + window.location.href.split('tag=')[1]
                        : ''
                    }${
                      window.location.href.split('dec=')[1]
                        ? '&dec=' + window.location.href.split('dec=')[1]
                        : ''
                    }
                    `}
                  >
                    <img
                      src={file.thumbnail || file.url}
                      alt={file.id}
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        maxHeight: '100%',
                      }}
                    />
                  </a>
                )}
              </>
            ))}
        </div>
      </Box>
    )
  } else return <></>
}

export default withTheme(MiniGallery)
