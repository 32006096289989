import React, { useEffect, useMemo, useState } from 'react'
import { Loader, Error } from 'utils'

import CartCheckout from './CartCheckout'

import { TCartItem } from 'types/CartItem'
import { TVariantSelection } from 'types/VariantSelection'
import { TVariant } from 'types/Variant'
import { TDesign } from 'types/Design'
import { TCoupon } from 'types/Coupon'
import { useMutation, useQuery } from '@apollo/react-hooks'
import CartItem from './CartItem'
import {
  TableLayout as T,
  HrStyle as Hr,
  PageLayout as P,
  ButtonStyle,
} from 'styles'
import GET_CART from 'gql/GET_CART'
import { Flex, Heading, Box } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import Navigation from 'components/Navigation'
import { SLEEVE_CUSTOMIZATION } from 'constants/sleeveCustomization'
import DELETE_CART from '../../../gql/DELETE_CART'
import { setCartNumber } from '../../../store/actions/userActions'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

interface CartProps {
  theme: ITheme
  setCartNumber: any
  location: {
    search: string
  }
}

const Cart: React.FC<CartProps> = props => {
  const {
    theme: { colors },
  } = props
  const { loading, data, error, refetch } = useQuery(GET_CART)
  const [total, setTotal] = useState(0)

  const [cartItemTotals, setCartItemTotals] = useState<number[]>([])
  const [cartTotal, setCartTotal] = useState(0)
  const [printLocationIdsByDesigns, setPrintLocationIdsByDesigns] = useState<
    string[][]
  >([])
  const [bulkPrices, setBulkPrices] = useState<number[]>([])

  const [overallQuantities, setOverallQuantities] = useState<number[]>([])
  const [selectedColorsByCartItem, setSelectedColorsByCartItem] = useState<
    TVariant[][]
  >([])
  const [printCosts, setPrintCosts] = useState<number[]>([])
  const [addOnBulkPrices, setAddOnBulkPrices] = useState<
    Record<string, number>[]
  >([])
  const [
    sleeveCustomizationCostsByCartItem,
    setSleeveCustomizationCostsByCartItem,
  ] = useState<number[][]>([])
  const [coupon, setCoupon] = useState<TCoupon | null>(null)
  const [referralCode, setReferralCode] = useState<string | null>(null)
  const [discount, setDiscount] = useState(0)

  const [DeleteCart] = useMutation(DELETE_CART)

  useEffect(() => {
    refetch().catch(error => <Error message={error.message} />)
  })

  const getSleeveCustomizationCost = (cartItem: TCartItem) => {
    for (let view of cartItem.product.inventoryGroup.views) {
      for (let printLocationCoordinateMap of view.printLocations) {
        if (
          printLocationCoordinateMap.printLocation.name === SLEEVE_CUSTOMIZATION
        ) {
          return printLocationCoordinateMap.printLocation.price
            ? printLocationCoordinateMap.printLocation.price
            : 0
        }
      }
    }
    return 0
  }

  useEffect(() => {
    if (data && data.me.cart) {
      const cartItemTotals: number[] = [] // Stores the totals of each cartItem in a list
      let cartTotal = 0 // Stores the total of the whole cart
      let tempTotal = 0 // Stores the total of a each cartItem

      let overallQuantity = 0 // Variable used to store the quantity purchased of the cartItem
      const overallQuantities: number[] = [] // Stores the quantity each cartItem

      let printLocationIds: string[] = [] // List of printLocationIds from list of designs (duplicates included)
      const printLocationIdsByDesigns: string[][] = [] // List of list of printLocationIds used to give as a prop for CartItem component
      let printLocationWithPrice: { [id: string]: number } = {} // Object with Ids as keys and their corresponding price as value (no duplicates)
      const bulkPrices: number[] = []
      let bulkPrice = 0

      let selectedColorIds: string[] = []
      let selectedColors: TVariant[] = []
      const selectedColorsByCartItem: TVariant[][] = []
      let printCost: number = 0
      const printCosts: number[] = []
      let addOnQuantitiesAndPriceBreaks: Record<
        string,
        {
          quantity: number
          overallQuantity: number
          basePrice: number
          priceBreaks: Record<string, number[]> | null
          bulkPrice: number
        }
      > = {}
      let addOnBulkPrice: Record<string, number> = {}
      const addOnBulkPrices: Record<string, number>[] = []
      let sleeveCustomizationCostByVariantSelection: number[] = []
      const sleeveCustomizationCostsByCartItem: number[][] = []

      data.me.cart.cartItems.forEach((cartItem: TCartItem) => {
        tempTotal = 0
        overallQuantity = 0

        selectedColorIds = []
        selectedColors = []
        sleeveCustomizationCostByVariantSelection = []

        cartItem.variantSelection.forEach(
          (variantSelection: TVariantSelection) => {
            // Adds the prices of each variant
            tempTotal += variantSelection.variants
              .map((variant: TVariant) => (variant.price ? variant.price : 0))
              .reduce((a, b) => a + b, 0)

            // Get Colors of CartItem
            for (let variant of variantSelection.variants) {
              if (
                variant.variantGroup.name === 'Color' &&
                !selectedColorIds.includes(variant.id)
              ) {
                selectedColorIds.push(variant.id)
                selectedColors.push(variant)
                break
              }
            }

            if (variantSelection.customization) {
              const sleeveCost = getSleeveCustomizationCost(cartItem)
              sleeveCustomizationCostByVariantSelection.push(sleeveCost)
              tempTotal += sleeveCost
            } else {
              sleeveCustomizationCostByVariantSelection.push(0)
            }

            // Calculate the overall quantity that was purchased for this product
            overallQuantity += variantSelection.quantity
          }
        )

        // Find bulkPrice of Product
        bulkPrice = cartItem.product.basePrice
        if (cartItem.product.priceBreaks) {
          for (const entry of Object.entries(cartItem.product.priceBreaks)) {
            if (overallQuantity >= parseInt(entry[0])) bulkPrice = entry[1]
            else break
          }
        }
        bulkPrices.push(bulkPrice)

        // Adds the BulkPrice of the product with the Overall Quantity
        tempTotal += bulkPrice * overallQuantity

        printLocationIds = []
        printLocationWithPrice = {}

        addOnQuantitiesAndPriceBreaks = {}

        cartItem.designs?.forEach((design: TDesign) => {
          const addOnId = design.addOn.id
          if (addOnQuantitiesAndPriceBreaks.hasOwnProperty(addOnId)) {
            addOnQuantitiesAndPriceBreaks[addOnId].quantity += 1
            addOnQuantitiesAndPriceBreaks[addOnId].overallQuantity = 1
          } else {
            addOnQuantitiesAndPriceBreaks[addOnId] = {
              quantity: 1,
              overallQuantity: 1,
              basePrice: design.addOn.basePrice,
              priceBreaks: null,
              bulkPrice: design.addOn.basePrice,
            }
          }

          if (
            !addOnQuantitiesAndPriceBreaks[addOnId].priceBreaks &&
            design.addOn.priceBreaks
          ) {
            addOnQuantitiesAndPriceBreaks[addOnId].priceBreaks =
              design.addOn.priceBreaks
          }
          // Set up Object with Id as key and Price as value
          printLocationWithPrice[design.printLocation.id] = design.printLocation
            .price
            ? design.printLocation.price
            : 0

          // Set up array of printLocationIds with their indexes matching the cartItem.designs indexes
          printLocationIds.push(design.printLocation.id)
        })
        Object.keys(addOnQuantitiesAndPriceBreaks).forEach(
          (addOnId: string) => {
            addOnQuantitiesAndPriceBreaks[addOnId].overallQuantity *=
              overallQuantity
          }
        )
        console.log(
          'ADDON QUANTITIES AND PRICE BREAKS',
          addOnQuantitiesAndPriceBreaks
        )
        console.log('PRINT LOCATION WITH PRICE', printLocationWithPrice)

        addOnBulkPrice = {}
        Object.keys(addOnQuantitiesAndPriceBreaks).forEach(
          (addOnId: string) => {
            addOnBulkPrice[addOnId] =
              addOnQuantitiesAndPriceBreaks[addOnId].basePrice
            const priceBreaks =
              addOnQuantitiesAndPriceBreaks[addOnId].priceBreaks
            if (priceBreaks) {
              for (const entry of Object.entries(priceBreaks)) {
                if (
                  addOnQuantitiesAndPriceBreaks[addOnId].overallQuantity >=
                  parseInt(entry[0])
                ) {
                  let locall = entry[1][0]
                  if (cartItem.designs?.length) {
                    let localBulkPrice = 0
                    for (let x = 0; x < cartItem.designs.length; x++) {
                      localBulkPrice += entry[1][x]
                    }
                    locall = localBulkPrice / cartItem.designs.length
                  }
                  addOnQuantitiesAndPriceBreaks[addOnId].bulkPrice = locall
                  addOnBulkPrice[addOnId] = locall
                } else {
                  break
                }
              }
            }
          }
        )

        printCost = 0
        Object.keys(addOnQuantitiesAndPriceBreaks).forEach(
          (addOnId: string) => {
            printCost +=
              addOnQuantitiesAndPriceBreaks[addOnId].bulkPrice *
              addOnQuantitiesAndPriceBreaks[addOnId].quantity
            tempTotal +=
              addOnQuantitiesAndPriceBreaks[addOnId].bulkPrice *
              addOnQuantitiesAndPriceBreaks[addOnId].quantity *
              overallQuantity
          }
        )

        // Calculate price of printLocations making use of how Keys in a Object are unique so only unique PrintLocations are calculated
        const printLocationsPrice = Object.values(
          printLocationWithPrice
        ).reduce((a, b) => a + b, 0)

        printCost += printLocationsPrice
        tempTotal += printLocationsPrice * overallQuantity
        cartTotal += tempTotal
        cartItemTotals.push(tempTotal)
        printLocationIdsByDesigns.push(printLocationIds)

        overallQuantities.push(overallQuantity)
        selectedColorsByCartItem.push(selectedColors)
        printCosts.push(printCost)
        addOnBulkPrices.push(addOnBulkPrice)
        sleeveCustomizationCostsByCartItem.push(
          sleeveCustomizationCostByVariantSelection
        )
      })

      setCartTotal(cartTotal)
      setCartItemTotals(cartItemTotals)
      setPrintLocationIdsByDesigns(printLocationIdsByDesigns)

      setOverallQuantities(overallQuantities)
      setBulkPrices(bulkPrices)
      setSelectedColorsByCartItem(selectedColorsByCartItem)
      setPrintCosts(printCosts)
      setAddOnBulkPrices(addOnBulkPrices)
      setSleeveCustomizationCostsByCartItem(sleeveCustomizationCostsByCartItem)
    }
  }, [data])

  const handleClearClick = () => {
    DeleteCart({ variables: { id: data.me.cart.id } }).then(() => {
      refetch().then(() => props.setCartNumber(0))
    })
  }

  const breakpoint = useMediaQuery({ maxWidth: 1000 })

  const storeId = useMemo(() => {
    return new URLSearchParams(props.location.search).get('store')
  }, [props.location.search])

  return (
    <P.Page>
      <Navigation
        whitelabelStoreId={storeId || undefined}
        whitelabelNav={!!storeId}
      />
      <P.Content>
        <Flex
          flexDirection={breakpoint ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
        >
          {error && <Error message={error.message} />}
          <Box
            // float="left"
            marginRight="2rem"
            // max-width="100%"
            flexGrow={'1'}
            minWidth={breakpoint ? '100%' : '600px'}
          >
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              margin="20px 0"
              float="left"
            >
              <Heading element="h3" color={colors.text.default}>
                Shopping Cart
              </Heading>
              {data && data.me.cart && (
                <Flex>
                  <Heading element="h3" color={colors.text.default}>
                    {data.me.cart.cartItems.length +
                      ' Item' +
                      (data.me.cart.cartItems.length > 1 ? 's' : '')}
                  </Heading>
                  <ButtonStyle.SubtleButton
                    onClick={handleClearClick}
                    style={{ marginLeft: '20px' }}
                  >
                    Clear
                  </ButtonStyle.SubtleButton>
                </Flex>
              )}
            </Flex>
            <Hr.Line thickness="1px" />
            <Box display="block" width="100%" overflowX="auto">
              <T.CartTable>
                <thead>
                  <tr>
                    <th>Product Details</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={5}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {data &&
                    data.me.cart &&
                    data.me.cart.cartItems.map(
                      (cartItem: TCartItem, index: number) => (
                        <CartItem
                          key={index}
                          cartItem={cartItem}
                          cartItemTotal={cartItemTotals[index]}
                          printLocationIds={printLocationIdsByDesigns[index]}
                          quantity={overallQuantities[index]}
                          refetch={refetch}
                          bulkPrice={bulkPrices[index]}
                          selectedColors={selectedColorsByCartItem[index]}
                          printCost={printCosts[index]}
                          addOnBulkPrice={addOnBulkPrices[index]}
                          sleeveCustomizationCostByVariantSelection={
                            sleeveCustomizationCostsByCartItem[index]
                          }
                        />
                      )
                    )}
                </tbody>
              </T.CartTable>
            </Box>
          </Box>
          <CartCheckout
            me={data && data.me ? data.me : {}}
            subTotal={cartTotal}
            cartItemTotals={cartItemTotals}
            coupon={coupon}
            setCoupon={setCoupon}
            referralCode={referralCode}
            setReferralCode={setReferralCode}
            discount={discount}
            setDiscount={setDiscount}
            total={total}
            setTotal={setTotal}
            storeId={storeId || undefined}
          />
        </Flex>
      </P.Content>
    </P.Page>
  )
}

const mapStateToProps = () => ({})

const mapActionsToProps = {
  setCartNumber,
}

export default connect(mapStateToProps, mapActionsToProps)(withTheme(Cart))
