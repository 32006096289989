import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import { DivLayout as D } from 'styles'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import GET_INVENTORY_PROVIDER from './gql/GET_INVENTORY_PROVIDER'
import WarehouseEditForm from './WarehouseEditForm'

interface CouponPageProps {
  match: {
    params: {
      warehouseId: string
    }
  }
  theme: ITheme
}

const WarehousePage: React.FC<CouponPageProps> = props => {
  const {
    theme: { colors },
  } = props
  const {
    loading,
    data,
    error: getCouponError,
    refetch: refetchProduct,
  } = useQuery(GET_INVENTORY_PROVIDER, {
    variables: {
      id: props.match.params.warehouseId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  return (
    <>
      {getCouponError && <Error message={getCouponError.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getInventoryProvider.name}
          </Heading>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Warehouse Info
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <WarehouseEditForm
                  inventoryProvider={data.getInventoryProvider}
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchProduct}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  ID:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getInventoryProvider.id}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Name:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getInventoryProvider.name}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Value:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getInventoryProvider.value}
                </Text>
              </Flex>
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(WarehousePage)
