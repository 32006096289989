import React from 'react'
import styled from 'styled-components'

// Routes
import * as USER_ROUTES from 'routes/user'

// Components
import ColorPicker from 'components/ColorPicker'

// Styles
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@sweaterplanet/nucleus-style'

// Types
import { ITheme } from 'types/Theme'

// Images
import ShirtsImage from 'static/Sweaters-HighRes.png'

// Icons
import DesignIcon1 from 'static/DesignIcon1.svg'
import DesignIcon1Alt from 'static/DesignIcon1Alt.svg'
import DesignIcon2 from 'static/DesignIcon2.svg'
import DesignIcon2Alt from 'static/DesignIcon2Alt.svg'
import DesignIcon3 from 'static/DesignIcon3.svg'
import DesignIcon3Alt from 'static/DesignIcon3Alt.svg'

interface IIdeasProps {
  theme: ITheme
  darkMode: boolean
  title: string
  description: string
  history: any
}

const TextBox = styled(Flex)`
  flex-direction: column;
  width: 80%;
`

const DescriptionBox = styled(Box)`
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    width: 100%;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    width: 60%;
  }
`

const AssetsWrapper = styled(Flex)`
  width: 90%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`

const ShirtsBox = styled(Box)`
  margin: 4rem 0;
  text-align: center;

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    width: 100%;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    width: 50%;
  }
`

const Ideas: React.FC<IIdeasProps> = props => {
  const {
    darkMode,
    theme: { colors },
    history,
  } = props
  return (
    <Flex
      width="100%"
      minHeight={`100vh`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <TextBox>
        <Box width="100%" marginBottom="1rem">
          <Heading element="h2" color={colors.text.default}>
            {props.title}
          </Heading>
        </Box>
        <DescriptionBox>
          <Text color={colors.text.default}>{props.description}</Text>
        </DescriptionBox>
      </TextBox>
      <AssetsWrapper>
        <ShirtsBox>
          <Image width="80%" src={ShirtsImage} />
        </ShirtsBox>
        <Flex flexDirection="column">
          <Box marginBottom="2rem" marginLeft="auto" marginRight="auto">
            <ColorPicker
              round
              width="3rem"
              colors={[
                {
                  value: 'blueviolet',
                  id: '',
                  name: 'Blue Violet',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
                {
                  value: 'deeppink',
                  id: '',
                  name: 'Deep Pink',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
                {
                  value: 'red',
                  id: '',
                  name: 'Red',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
                {
                  value: 'orange',
                  id: '',
                  name: 'Orange',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
                {
                  value: 'yellow',
                  id: '',
                  name: 'Yellow',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
                {
                  value: 'lawngreen',
                  id: '',
                  name: 'Lawn Green',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
                {
                  value: 'turquoise',
                  id: '',
                  name: 'Turquoise',
                  available: true,
                  variantGroup: { id: '', name: '', variants: [] },
                },
              ]}
            />
          </Box>
          <Flex justifyContent={'space-between'} padding="2rem 0">
            <Box padding="0 1rem" alignItems="center">
              <Image
                width="100%"
                src={darkMode ? DesignIcon1 : DesignIcon1Alt}
              />
            </Box>
            <Box padding="0 1rem" alignItems="center">
              <Image
                width="100%"
                src={darkMode ? DesignIcon2 : DesignIcon2Alt}
              />
            </Box>
            <Box padding="0 1rem" alignItems="center">
              <Image
                width="100%"
                src={darkMode ? DesignIcon3 : DesignIcon3Alt}
              />
            </Box>
          </Flex>
          <Button
            bg={colors.text.default}
            color={darkMode ? 'slate' : 'white'}
            onClick={() => history.push(USER_ROUTES.PRODUCTS)}
          >
            Start Designing
          </Button>
        </Flex>
      </AssetsWrapper>
    </Flex>
  )
}

export default Ideas
