import React from 'react'
import { Flex, Heading, Box } from '@sweaterplanet/nucleus-style'
import styled from 'styled-components'
interface MessageProps {
  message: String
  onClose: () => void
}

const Padded = styled.div`
  margin: 0 10px;
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding: 0 ${p => p.theme.sizing.xs.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    padding: 0 ${p => p.theme.sizing.sm.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    padding: 0 ${p => p.theme.sizing.md.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding: 0 ${p => p.theme.sizing.lg.padding}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    padding: 0 ${p => p.theme.sizing.xl.padding}px;
  }
`

const Message: React.FC<MessageProps> = props => {
  const { message, onClose } = props
  return (
    <Box width="100%" bg="green">
      <Padded>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box margin="20px 0 20px 0">
            <Heading element="h4" color={'white'}>
              {message}
            </Heading>
          </Box>
          <Flex margin="" alignItems="center">
            <Box style={{ cursor: 'pointer' }} onClick={() => onClose()}>
              <Heading element="h4" color={'paper'}>
                X
              </Heading>
            </Box>
          </Flex>
        </Flex>
      </Padded>
    </Box>
  )
}

export default Message
