import React from 'react'
import { ITheme } from 'types/Theme'
import { Content, Page } from 'styles/lib/page'
import Navigation from 'components/Navigation'
import { withTheme } from 'styled-components'
import { Flex, Tag, Heading, Button, Box } from '@sweaterplanet/nucleus-style'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/react-hooks'
import GET_PUBLIC_STORES from './gql/GET_PUBLIC_STORES'
import { Loader, Error } from 'utils'
import CollectionsList from './CollectionsList'

interface IProductsPageProps {
  theme: ITheme
}

const ProductsPage: React.FC<IProductsPageProps> = props => {
  const { theme } = props
  return (
    <Page>
      <Navigation />
      <Content padding={'0'}>
        <CollectionsList theme={theme} />
      </Content>
    </Page>
  )
}

export default withTheme(ProductsPage)
