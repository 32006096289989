import { gql } from 'apollo-boost'

export default gql`
  {
    getWebsiteSettings {
      id
      previewColors
      displayCoupon {
        id
        name
        value
        type
      }
      popularSearches {
        id
        color
        icon
        content
      }
      homePageMessage
    }
  }
`
