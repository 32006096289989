import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { signIn } from 'store/actions/userActions'

// Components
import { setToken, Loader, Error } from 'utils'

// Types
import { TUser } from 'types/User'
import { connect } from 'react-redux'
import { AppState } from 'store/store'
import { ITheme } from 'types/Theme'

// Routes
import * as USER_ROUTES from 'routes/user'

// Styles
import { Flex, Button, Box, Text } from '@sweaterplanet/nucleus-style'

import { Form, Input } from 'styles/lib/form'

import SIGN_UP from 'gql/SIGN_UP'
import SignInGoogle from './SignInGoogle'

interface SignUpFormProps {
  signIn: (user: TUser) => void
  theme: ITheme
  redirect?: string
  locationSlug?: string
  storeId?: string
}

const SignUpForm: React.FC<SignUpFormProps> = props => {
  const {
    theme: { colors },
  } = props

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const name = `${firstName} ${lastName}`.trim()

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [company, setCompany] = useState('')

  const [password, setPassword] = useState('')
  const [SignUp, { called, loading, data, error }] = useMutation(SIGN_UP, {
    variables: {
      email,
      phone,
      password,
      name,
      company,
      locationSlug: props.locationSlug,
    },
  })

  const invalid =
    name === '' ||
    password === '' ||
    !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

  if (called && loading) return <Loader />

  if (data) {
    setToken(data.signup.token)
    props.signIn(data.signup.user)

    // The redirect prop allows the user to be redirected to a route after a successful sign up. redirect is passed from the parent sign up page which gets the optional redirect from a query string in the url
    // The purpose of this is to be able to continue a session flow if the user has to sign up. For example, in checkout, a guest user cannot checkout so they must be redirected to sign up, which redirects back to cart.

    if (props.redirect) {
      return <Redirect to={props.redirect} />
    } else {
      return (
        <Redirect
          to={
            props.storeId
              ? `${USER_ROUTES.STORE}/${props.storeId}`
              : USER_ROUTES.HOME
          }
        />
      )
    }
  }

  if (error) {
    if (error.message.includes('A user already exists with that email.')) {
      return (
        <Error message={'A user already exists with that email. Please '}>
          <a style={{ color: 'white' }} href={'/signin'}>
            Sign In
          </a>{' '}
          or{' '}
          <a style={{ color: 'white' }} href={'/signup'}>
            Try Again With a Different Email
          </a>
        </Error>
      )
    }
    return <Error message={error.message} />
  }

  const handleSignUp = async () => {
    try {
      await SignUp()
    } catch (error: any) {
      return <Error message={error.message} />
    }
  }

  return (
    <>
      {!called && (
        <Form>
          <Flex width="100%" justifyContent="space-between">
            <Box marginRight="10px">
              <Input
                value={firstName}
                type={'text'}
                placeholder={'First name *'}
                onChange={e => setFirstName(e.target.value)}
              />
            </Box>
            <Box marginLeft="10px">
              <Input
                value={lastName}
                type={'text'}
                placeholder={'Last name *'}
                onChange={e => setLastName(e.target.value)}
              />
            </Box>
          </Flex>
          <Flex width="100%" justifyContent="space-between">
            <Box marginRight="10px">
              <Input
                value={email}
                type="email"
                placeholder={'Email *'}
                onChange={e => setEmail(e.target.value)}
              />
            </Box>
            <Box marginLeft="10px">
              <Input
                value={password}
                type={'password'}
                placeholder={'Password *'}
                onChange={e => setPassword(e.target.value)}
              />
            </Box>
          </Flex>
          <Flex width="100%" justifyContent="space-between">
            <Box marginRight="10px">
              <Input
                value={phone}
                type={'tel'}
                placeholder={'Phone Number'}
                onChange={e => setPhone(e.target.value)}
              />
            </Box>
            <Box marginLeft="10px">
              <Input
                value={company}
                type={'text'}
                placeholder={'Company'}
                onChange={e => setCompany(e.target.value)}
              />
            </Box>
          </Flex>
          <Box margin="40px 0 20px 0">
            <Button
              bg="green"
              color="white"
              maxWidth
              disabled={invalid}
              onClick={() => handleSignUp()}
            >
              Sign Up
            </Button>
          </Box>
          {/* <SignInGoogle theme={props.theme} storeId={props.storeId} /> */}
          <Flex width="100%" justifyContent="center" margin="40px 0 0 0">
            <Text color={colors.text.default}>
              <a
                href={USER_ROUTES.TERMS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </Text>
          </Flex>
        </Form>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({})

const mapActionsToProps = {
  signIn,
}

export default connect(mapStateToProps, mapActionsToProps)(SignUpForm)
