import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { ButtonStyle as B, FormStyle as F, ModalLayout as M } from 'styles'
import UPSERT_TAG_BY_CONTENT from './gql/UPSERT_TAG_BY_CONTENT'
import { Error } from 'utils'

interface ITagCreateFormProps {
  visible: boolean
  toggle: any
  refetch?: () => any
}

const TagCreateForm: React.FC<ITagCreateFormProps> = props => {
  const [content, setContent] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const [UpsertTagByContent] = useMutation(UPSERT_TAG_BY_CONTENT, {
    variables: {
      content,
      color: color === '' ? undefined : color,
      icon: icon === '' ? undefined : icon,
    },
  })

  const handleOnTagCreate = () => {
    UpsertTagByContent()
      .then(response => {
        if (!response.errors && props.refetch) {
          props.toggle(false)
          props.refetch()
        }
      })
      .catch(e => setError(e.message))
  }

  const invalid = content === ''

  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>New Tag</h2>
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Content
              <F.Input
                value={content}
                type={'text'}
                placeholder={'content'}
                onChange={e => setContent(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Color
              <F.Input
                value={color}
                type={'text'}
                placeholder={'color'}
                onChange={e => setColor(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Icon
              <F.Input
                value={icon}
                type={'text'}
                placeholder={'icon'}
                onChange={e => setIcon(e.target.value)}
              />
            </F.Label>
            <B.Button disabled={invalid} onClick={() => handleOnTagCreate()}>
              Create Tag
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
      {error && <Error message={error} />}
    </>
  )
}

export default TagCreateForm
