import { gql } from 'apollo-boost'

export default gql`
  mutation createAddOnByLocation(
    $addOnId: ID!
    $locations: [ID!]!
    $product: ID!
  ) {
    createAddOnByLocation(
      addOnId: $addOnId
      locations: $locations
      product: $product
    ) {
      id
    }
  }
`
