import { gql } from 'apollo-boost'

export default gql`
  query getSomeOrders($searches: [String!], $offset: Int, $limit: Int) {
    getSomeOrders(searches: $searches, offset: $offset, limit: $limit) {
      id
      createdAt
      updatedAt
      fulfillmentStatus
      billingStatus
      price
      User {
        name
      }
      orderItems {
        id
        product {
          id
          name
          inventoryGroup {
            id
            views {
              id
              boundingBox
              texture
              base
              printLocations {
                id
                printLocation {
                  id
                  name
                  price
                }
                coordinateMap
                rotate
              }
            }
          }
        }
        variantSelection {
          id
          variants {
            id
            name
            value
            available
            price
            variantGroup {
              id
              name
            }
          }
          quantity
          customization
        }
        designs {
          id
          printLocation {
            id
            name
            price
          }
          addOn {
            id
            name
            publicName
            basePrice
            priceBreaks
          }
          file {
            id
            url
            thumbnail
          }
          origin
          w
          h
        }
      }
      shipping {
        city
        state
        postCode
        country
      }
    }
  }
`
