import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import UPSERT_RETAIL_LOCATION from '../../../gql/UPSERT_RETAIL_LOCATION'
import { ModalLayout as M, ButtonStyle as B, FormStyle as F } from 'styles'
import GET_RETAIL_LOCATION from '../../../gql/GET_RETAIL_LOCATION'

interface IRetailLocationCreateFormProps {
  visible: boolean
  toggle: any
  refetch?: () => any
  retailLocationId?: string
}

const RetailLocationCreateForm: React.FC<
  IRetailLocationCreateFormProps
> = props => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [authToken, setAuthToken] = useState<string>('')
  const [locationCode, setLocationCode] = useState<string>('')
  const [slug, setSlug] = useState<string>('')

  const [UpsertRetailLocation] = useMutation(UPSERT_RETAIL_LOCATION, {
    variables: {
      id: props?.retailLocationId,
      name,
      description,
      authToken,
      locationCode,
      slug,
    },
  })
  const { data } = useQuery(GET_RETAIL_LOCATION, {
    variables: {
      id: props?.retailLocationId,
    },
  })

  useEffect(() => {
    if (props.retailLocationId) {
      if (data) {
        setName(data.getRetailLocation.name)
        setDescription(data.getRetailLocation.description)
        setLocationCode(data.getRetailLocation.locationCode)
        setAuthToken(data.getRetailLocation.authToken)
        setSlug(data.getRetailLocation.slug)
      }
    }
  }, [data, props.retailLocationId])

  const handleOnRetailLocationCreate = (e: any) => {
    e.preventDefault()
    console.log(
      'trying to upsert retail location',
      name,
      description,
      locationCode
    )
    UpsertRetailLocation().then(response => {
      console.log('response', response)
      if (!response.errors && props.refetch) {
        props.toggle(false)
        props.refetch()
      }
    })
  }

  const invalid = name === ''

  return (
    <>
      {props.visible && (
        <M.Modal>
          {props.retailLocationId ? (
            <h2>Edit Retail Location</h2>
          ) : (
            <h2>New Retail Location</h2>
          )}
          <B.Exit onClick={() => props.toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Description
              <F.Input
                value={description}
                type={'text'}
                placeholder={'description'}
                onChange={e => setDescription(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Location Code
              <F.Input
                value={locationCode}
                type={'text'}
                placeholder={'locationCode'}
                onChange={e => setLocationCode(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Auth Token
              <F.Input
                value={authToken}
                type={'text'}
                placeholder={'authToken'}
                onChange={e => setAuthToken(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Slug
              <F.Input
                value={slug}
                type={'text'}
                placeholder={'slug'}
                onChange={e => setSlug(e.target.value)}
              />
            </F.Label>
            <B.Button disabled={invalid} onClick={handleOnRetailLocationCreate}>
              {props?.retailLocationId
                ? 'Update Retail Location'
                : 'Create Retail Location'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default RetailLocationCreateForm
