import React, { createRef } from 'react'

// Types
import { TProduct } from 'types/Product'
import { TView } from 'types/View'
import { TPrintLocationCoordinateMap } from 'types/PrintLocationCoordinateMap'
import { TPrintLocation } from 'types/PrintLocation'
import { TTempDesign } from 'types/Design'
import { ITheme } from 'types/Theme'

// Components
import DraggableList from 'react-draggable-list'
import Upload from 'components/Upload'

// Styles
import { Card, Flex, Heading, Box, Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faTimes } from '@fortawesome/free-solid-svg-icons'
import { TAddOnByLocation } from '../../../types/AddOnByLocation'

interface LayerProps {
  item: TTempDesign
  dragHandleProps: object
  commonProps: {
    selectedDesign: TTempDesign
    handleSelectDesign: (design: TTempDesign) => void
    handleRemoveDesign: (index: number) => void
    theme: ITheme
  }
}

interface LayerState {
  isGrabbed: boolean
}

class Layer extends React.Component<LayerProps, LayerState> {
  state = {
    isGrabbed: false,
  }

  render() {
    const {
      item: design,
      dragHandleProps,
      commonProps: {
        selectedDesign,
        handleSelectDesign,
        handleRemoveDesign,
        theme: { colors },
      },
    } = this.props
    const {
      item: { designIndex: index },
    } = this.props

    return (
      <Box
        key={index}
        className="layer"
        padding="5px 20px"
        cursor="pointer"
        maxHeight="100%"
        style={{
          backgroundColor:
            index === selectedDesign.designIndex
              ? colors.button.background
              : 'unset',
        }}
        {...dragHandleProps}
      >
        <Flex
          className="layer"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ cursor: this.state.isGrabbed ? 'grabbing' : 'pointer' }}
          onMouseDown={() => {
            this.setState({ isGrabbed: true })
            handleSelectDesign(design)
          }}
          onMouseUp={() => this.setState({ isGrabbed: false })}
          onDragEnd={() => this.setState({ isGrabbed: false })}
        >
          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            overflowX="hidden"
            className="layer"
          >
            <FontAwesomeIcon
              className="layer"
              icon={faLayerGroup as any}
              style={{ color: colors.contrast, marginRight: '20px' }}
            />
            <Text
              className="layer"
              color={colors.text.default}
              style={{ margin: '5px 0' }}
            >
              {design.file.filename}
            </Text>
          </Flex>
          {index === selectedDesign.designIndex && (
            <Box className="layer" marginLeft="10px">
              <FontAwesomeIcon
                className="layer"
                icon={faTimes as any}
                color={colors.contrast}
                onClick={() => handleRemoveDesign(index)}
              />
            </Box>
          )}
        </Flex>
      </Box>
    )
  }
}

interface LayerPickerProps {
  product: TProduct
  tempDesigns: TTempDesign[]
  setTempDesigns: (designs: TTempDesign[]) => void
  selectedDesign: TTempDesign
  setSelectedDesign: (design: TTempDesign) => void
  setActiveView: (view: TView) => void
  theme: ITheme
  defaultImageWidth?: number
  defaultImageHeight?: number
}

const LayerPicker: React.FC<LayerPickerProps> = props => {
  const {
    selectedDesign,
    tempDesigns,
    product,
    setSelectedDesign,
    setTempDesigns,
    setActiveView,
    theme,
  } = props

  const handleSelectDesign = (design: TTempDesign) => {
    setSelectedDesign(design)
    setActiveView(design.view)
  }

  const handleRemoveDesign = (index: number) => {
    const newTempDesigns = tempDesigns.splice(0)
    newTempDesigns.splice(index, 1)
    newTempDesigns.forEach((_, index: number) => {
      newTempDesigns[index].designIndex = index
    })

    setTempDesigns(newTempDesigns)
    setSelectedDesign({
      ...selectedDesign,
      file: { id: '', encoding: '', filename: '', mimetype: '', url: '' },
      addOn: null,
      addOnIndex: -1,
      designIndex: -2,
    })
  }

  const handleListChange = (
    newList: readonly unknown[],
    movedItem: unknown,
    oldIndex: number,
    newIndex: number
  ) => {
    console.log('newList', newList)
    const newListData = [...newList] as TTempDesign[]
    newListData.forEach((_, index: number) => {
      newListData[index].designIndex = index
      console.log('here', newListData[index].file.id, selectedDesign.file.id)
      if (newListData[index].file.id === selectedDesign.file.id)
        setSelectedDesign({ ...selectedDesign, designIndex: index })
    })

    setTempDesigns(newListData)
  }

  const container = createRef<HTMLDivElement>()

  const containsPrintLocation = (
    printLocation: TPrintLocation,
    location: TPrintLocation
  ) => {
    return location.id === printLocation.id
  }

  const handleUpload = (
    view: TView,
    printLocation: TPrintLocation,
    printLocationIndex: number,
    File: any
  ) => {
    const designIndex =
      selectedDesign.file.id !== '' && selectedDesign.designIndex >= 0
        ? selectedDesign.designIndex + 1
        : tempDesigns.length
    const firstAddOnIndex = product.addOnsByLocation.findIndex(
      (addOnByLocation: TAddOnByLocation) =>
        containsPrintLocation(printLocation, addOnByLocation.location)
    )
    const firstAddOn = product.addOnsByLocation[firstAddOnIndex]?.addOn
    // console.log(product.addOnsByLocation, firstAddOn, firstAddOnIndex)

    const designData: TTempDesign = {
      designIndex: designIndex,
      addOn: firstAddOn,
      addOnIndex: firstAddOnIndex,
      file: File,
      view: view,
      printLocation: printLocation,
      printLocationIndex: printLocationIndex,
      origin: { x: 0, y: 0 },
      w:
        view.printLocations[printLocationIndex].coordinateMap[3]?.x -
        view.printLocations[printLocationIndex].coordinateMap[0]?.x,
      h:
        view.printLocations[printLocationIndex].coordinateMap[3]?.y -
        view.printLocations[printLocationIndex].coordinateMap[0]?.y,
    }
    setSelectedDesign(designData)
    setActiveView(view)

    const tempDesignsData = tempDesigns.splice(0)
    tempDesignsData.splice(designIndex, 0, designData)
    for (let i = designIndex; i < tempDesignsData.length; i++) {
      tempDesignsData[i].designIndex = i
    }
    setTempDesigns(tempDesignsData)
  }

  return (
    <Card
      bg={theme.colors.card.background}
      round
      // width="200px"
      // height="200px"
      data-tut="tour_6"
    >
      <Box padding="10px 0 0 0">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
          height="inherit"
        >
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="inherit"
          >
            <Box margin="20px 0">
              <Heading
                element="h4"
                color={theme.colors.text.default}
                width="-webkit-fill-available"
              >
                Images
              </Heading>
            </Box>
            <Box
              overflowY="scroll"
              overflowX="clip"
              width="-webkit-fill-available"
              white-space="nowrap"
              ref={container}
              maxHeight="100%"
            >
              <DraggableList
                itemKey={(item: any) => {
                  return `${item.file.id}-${item.designIndex}`
                }}
                template={Layer}
                list={tempDesigns}
                onMoveEnd={handleListChange}
                container={() => container.current}
                commonProps={{
                  selectedDesign,
                  handleSelectDesign,
                  handleRemoveDesign,
                  theme,
                }}
              />
            </Box>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              {product.inventoryGroup.views.map(
                (view: TView, viewIndex: number) =>
                  view.printLocations.map(
                    (
                      printLocationCoordinateMap: TPrintLocationCoordinateMap,
                      printLocationIndex: number
                    ) => (
                      <Upload
                        hidden
                        key={`${viewIndex}/${printLocationIndex}`}
                        view={view}
                        printLocationIndex={printLocationIndex}
                        handleDesignStudioUpload={handleUpload.bind(
                          null,
                          view,
                          printLocationCoordinateMap.printLocation,
                          printLocationIndex
                        )}
                      />
                    )
                  )
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Card>
  )
}

export default withTheme(LayerPicker)
