import React, { useState } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
// import GET_EMAIL_TEMPLATES from './gql/GET_EMAIL_TEMPLATES'
import UPSERT_EMAIL_TEMPLATE from './gql/UPSERT_EMAIL_TEMPLATE'
import GET_EMAIL_TEMPLATE from './gql/GET_EMAIL_TEMPLATE'
import {
  ButtonStyle as B,
  // DivLayout as D,
  // FormStyle as F,
  // ModalLayout as M,
  // TableLayout as T,
} from '../../../styles'
// import { DataTable } from 'components/Admin'

// import { TEmailTemplate } from 'types'

interface IEmailTemplateEditorProps {
  match: {
    params: {
      emailTemplateId: string
    }
  }
  history: any
}

// Pop up modal for creating email templates
const EmailTemplateEditor: React.FC<IEmailTemplateEditorProps> = props => {
  const [name, setName] = useState<string>('')
  const [html, setHtml] = useState<string>('')
  const [available, setAvailable] = useState<boolean | undefined>(undefined)

  const {
    loading: getTemplateLoading,
    data: getTemplateData,
    error: getTemplateError,
    refetch: getTemplateRefresh,
  } = useQuery(GET_EMAIL_TEMPLATE, {
    variables: {
      id: props.match.params.emailTemplateId,
    },
    onCompleted: data => {
      setName(getTemplateData.getEmailTemplate.name)
      setHtml(getTemplateData.getEmailTemplate.html)
      setAvailable(getTemplateData.getEmailTemplate.available)
    },
  })
  const [UpdateTemplate] = useMutation(UPSERT_EMAIL_TEMPLATE, {
    variables: {
      id: props.match.params.emailTemplateId,
      name,
      html,
      available,
    },
  })

  const disabled = name === '' || html === ''

  if (getTemplateLoading) return <Loader />

  if (getTemplateError) return <Error message={getTemplateError.message} />

  const handleUpdateClick = () => {
    UpdateTemplate().then(() => {
      getTemplateRefresh()
    })
  }

  return (
    <>
      {getTemplateData && (
        <>
          <h1>{getTemplateData.getEmailTemplate.name}</h1>
          <input
            name={'html'}
            type={'text'}
            value={html}
            onChange={e => setHtml(e.target.value)}
          />
          <B.Button onClick={handleUpdateClick} disabled={disabled}>
            Update
          </B.Button>
        </>
      )}
    </>
  )
}

export default EmailTemplateEditor
