import { gql } from 'apollo-boost'

export default gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      basePrice
      priceBreaks
      customField1
      customField2
      available
      description
      isDesignable
      defaultColor
      unavailableVariants {
        id
        name
        value
        variantGroup {
          name
        }
      }
      thumbnail {
        id
        url
      }
      tags {
        id
        content
        color
        icon
      }
      variantGroups {
        id
        name
        variants {
          id
          name
          value
        }
      }
      addOnsByLocation {
        id
        addOn {
          id
          name
          publicName
          available
          basePrice
          priceBreaks
        }
        location {
          id
          name
          width
          height
        }
      }
      inventoryGroup {
        id
        name
        variantGroups {
          id
          name
          variants {
            id
            value
            name
          }
        }
        templateBasePrice
        templatePriceBreaks
        views {
          id
          name
          boundingBox
          base
          texture
          printLocations {
            id
            printLocation {
              id
              name
              available
              width
              height
            }
            coordinateMap
            rotate
          }
        }
      }
      designs {
        id
        file {
          id
          url
          filename
          thumbnail
        }
        addOn {
          id
          name
          publicName
          available
          basePrice
          priceBreaks
        }
        printLocation {
          id
          name
          price
        }
        rotate
        flip
        vectorize
        origin
        w
        h
      }
      store {
        id
        whitelabelNav
        logo {
          url
        }
      }
    }
  }
`
