import { gql } from 'apollo-boost'

export default gql`
  mutation createProduct(
    $inventoryGroupItemCode: ID!
    $inventoryGroupStyleId: Int!
    $name: String!
    $basePrice: Float!
    $available: Boolean
    $description: String!
    $priceBreaks: JSONObject
    $storeId: ID
    $thumbnail: Upload
    $customField1: String
    $customField2: String
    $isDesignable: Boolean
  ) {
    createProduct(
      inventoryGroupItemCode: $inventoryGroupItemCode
      inventoryGroupStyleId: $inventoryGroupStyleId
      name: $name
      basePrice: $basePrice
      available: $available
      description: $description
      priceBreaks: $priceBreaks
      storeId: $storeId
      thumbnail: $thumbnail
      customField1: $customField1
      customField2: $customField2
      isDesignable: $isDesignable
    ) {
      id
    }
  }
`
