import { gql } from 'apollo-boost'

export default gql`
  mutation editInventoryProvider($id: ID!, $name: String, $value: String) {
    editInventoryProvider(id: $id, name: $name, value: $value) {
      id
      name
      value
    }
  }
`
