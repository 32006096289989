import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle<any>`
  html{
    margin: 0;
    padding: 0;
    background: ${p => p.theme.colors.page.background};
  }
  body {
    margin: 0;
    padding: 0;
  }
  a {
    color: ${p => p.theme.colors.link.text};
    &:hover {
      color: ${p => p.theme.colors.link.hover};
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${p => p.theme.colors.text.default};
  }
  .shadow {
    -webkit-filter: drop-shadow( 4px 4px 3px rgba(0, 0, 0, .7));
    filter: drop-shadow( 4px 4px 3px rgba(0, 0, 0, .7));
  }
`
