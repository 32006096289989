import React from 'react'
import { FormStyle as F } from 'styles'
import { TAddress } from 'types/Address'
import { TBillingAccount } from 'types/BillingAccount'
import { Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { Box } from '@sweaterplanet/nucleus-style'

interface CheckoutInputProps {
  type: string
  width: string
  title?: string
  isSelect?: boolean
  isState?: boolean
  isCountry?: boolean
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelectChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string | number
  name?: string
  onInputFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onInputFocusOut?: (event: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  maxLength?: number
  error?: string
  autoComplete?: string

  addresses?: TAddress[]
  defaultAddress?: TAddress
  billingAccounts?: TBillingAccount[]
  isBillingAddress?: boolean

  theme: ITheme
}

const CheckoutInput: React.FC<CheckoutInputProps> = props => {
  const {
    type,
    width,
    title,
    isSelect,
    isState,
    isCountry,
    onInputChange,
    onSelectChange,
    value,
    name,
    onInputFocus,
    onInputFocusOut,
    placeholder,
    maxLength,
    error,
    autoComplete,
    addresses,
    defaultAddress,
    billingAccounts,
    isBillingAddress,
    theme: { colors },
  } = props

  return (
    <Box width={`calc(${width} - 20px)`} padding="5px 10px">
      {title && (
        <Box marginBottom="5px">
          <Text color={colors.text.default}>{title}</Text>
        </Box>
      )}
      {!isSelect ? (
        <F.checkoutInput
          type={type}
          width={`calc(100% - 10px)`}
          error={
            (error !== undefined && error !== '') ||
            (value !== undefined && value === '')
          }
          name={name}
          value={value ? value : ''}
          autoComplete={autoComplete}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputFocusOut}
        />
      ) : (
        <F.checkoutSelect
          width="100%"
          error={
            (error !== undefined && error !== '') ||
            (value !== undefined && value === '')
          }
          isForm={!addresses && !billingAccounts}
          value={value}
          onChange={onSelectChange}
        >
          {!addresses && !billingAccounts && isCountry && (
            <>
              <option value="" selected></option>
              <option value="CA">Canada</option>
              <option value="US">United States</option>
              <option value="IN">International</option>
            </>
          )}
          {!addresses && !billingAccounts && isState && (
            <>
              <option value="" selected />
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NF">Newfoundland</option>
              <option value="NT">Northwest Territories</option>
              <option value="NS">Nova Scotia</option>
              <option value="NU">Nunavut</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="YT">Yukon Territory</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </>
          )}
          {addresses && !billingAccounts && (
            <>
              {isBillingAddress && <option value={-4}>None</option>}
              {isBillingAddress && <option value={-3}>Same as Shipping</option>}
              <option value={-2}>New Address</option>
              {defaultAddress && <option value={-1}>Default Address</option>}
              {addresses.map((address: TAddress, index: number) => (
                <option key={index} value={index}>
                  Address {index + 1}:{' ' + address.postCode}
                </option>
              ))}
            </>
          )}
          {addresses && billingAccounts && (
            <>
              <option value={-1}>None</option>
              {billingAccounts.map(
                (billingAccount: TBillingAccount, index: number) => (
                  <option value={index}>BillingAccount {index + 1}</option>
                )
              )}
            </>
          )}
          {/* {!addresses && !billingAccounts ? (
            <>
              <option value="Alberta">Alberta</option>
              <option value="British Columbia">British Columbia</option>
              <option value="Manitoba">Manitoba</option>
              <option value="New Brunswick">New Brunswick</option>
              <option value="Newfoundland">Newfoundland</option>
              <option value="Labrador">Labrador</option>
              <option value="Nova Scotia">Nova Scotia</option>
              <option value="Ontario">Ontario</option>
              <option value="Prince Edward Island">Prince Edward Island</option>
              <option value="Quebec">Quebec</option>
              <option value="Saskatchewan">Saskatchewan</option>
            </>
          ) : addresses && !billingAccounts ? (
            <>
              {isBillingAddress && <option value={-4}>None</option>}
              {isBillingAddress && <option value={-3}>Same as Shipping</option>}
              <option value={-2}>New Address</option>
              {defaultAddress && <option value={-1}>Default Address</option>}
              {addresses.map((address: TAddress, index: number) => (
                <option value={index}>
                  Address {index + 1}:{' ' + address.postCode}
                </option>
              ))}
            </>
          ) : (
            billingAccounts && (
              <>
                <option value={-1}>None</option>
                {billingAccounts.map(
                  (billingAccount: TBillingAccount, index: number) => (
                    <option value={index}>BillingAccount {index + 1}</option>
                  )
                )}
              </>
            )
          )} */}
        </F.checkoutSelect>
      )}
      {!addresses && !billingAccounts && (
        <Box margin="2px 0">
          <Text
            color="red"
            style={{
              visibility: error ? 'visible' : 'hidden',
            }}
          >
            {error ? error : 'filler text'}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default withTheme(CheckoutInput)
