import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import { DivLayout as D } from 'styles'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import TagEditForm from './TagEditForm'
import GET_SOME_TAGS from './gql/GET_SOME_TAGS'

interface TagPageProps {
  match: {
    params: {
      tagId: string
    }
  }
  theme: ITheme
}

const TagPage: React.FC<TagPageProps> = props => {
  const {
    theme: { colors },
  } = props
  const {
    loading,
    data,
    error: getTagsError,
    refetch: refetchTags,
  } = useQuery(GET_SOME_TAGS, {
    variables: {
      searches: [props.match.params.tagId],
    },
    fetchPolicy: 'cache-and-network',
  })

  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  return (
    <>
      {getTagsError && <Error message={getTagsError.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getSomeTags[0].id}
          </Heading>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  Tag Info
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <TagEditForm
                  id={props.match.params.tagId || ''}
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchTags}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Content:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getSomeTags[0].content}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Color:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getSomeTags[0].color}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Icon:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getSomeTags[0].icon}
                </Text>
              </Flex>
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(TagPage)
