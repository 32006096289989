import React, { Fragment, useEffect, useState } from 'react'
import { Table } from 'styles/lib/table'
import { Line } from 'styles/lib/hr'
import { withTheme } from 'styled-components'
import { ITheme } from '../../types'
import { Heading } from '@sweaterplanet/nucleus-style'

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

interface BulkPricingTableProps {
  basePrice: number
  priceBreaks: Record<string, number>
  printCost?: number
  tempDesignsLength?: number
  printCostPriceBreaks?: Record<string, number[]>
  isAddOn?: boolean
  quantity?: number
  theme: ITheme
}

const BulkPricingTable: React.FC<BulkPricingTableProps> = props => {
  const {
    basePrice,
    priceBreaks,
    printCost,
    printCostPriceBreaks,
    isAddOn,
    quantity,
    theme,
  } = props

  const [pB, setPB] = useState({ ...priceBreaks })

  useEffect(() => {
    if (printCostPriceBreaks && priceBreaks) {
      let newPriceBreaks = { ...priceBreaks }

      Object.keys(printCostPriceBreaks).forEach(key => {
        if (priceBreaks[key]) {
          newPriceBreaks[key] = priceBreaks[key]
        } else {
          newPriceBreaks[key] = basePrice
          Object.keys(priceBreaks).every(k => {
            if (parseInt(key) > parseInt(k)) {
              newPriceBreaks[key] = priceBreaks[k]
              return true
            } else {
              return false
            }
          })
        }
      })
      if (!newPriceBreaks['1']) {
        newPriceBreaks['1'] = basePrice
      }
      setPB(newPriceBreaks)
    }
  }, [printCostPriceBreaks, priceBreaks, basePrice, printCost])

  const getPrintCostPriceBreak: (priceBreak: string) => number = priceBreak => {
    let validPrintCost = 0

    if (printCost !== undefined) {
      validPrintCost = printCost
    }

    // console.log(printCostPriceBreaks)

    if (
      printCostPriceBreaks &&
      props.tempDesignsLength &&
      printCostPriceBreaks[priceBreak]
    ) {
      let tempPrintCostBreak = 0
      for (let x = 0; x < props.tempDesignsLength; x++) {
        tempPrintCostBreak += printCostPriceBreaks[priceBreak][x]
      }
      return tempPrintCostBreak
    }

    return validPrintCost

    // if (printCostPriceBreaks && props.tempDesignsLength) {
    //   if (printCostPriceBreaks[priceBreak]) {
    //     let localPrintCost = 0
    //     for (let i = 0; i<props.tempDesignsLength; i++) {
    //       localPrintCost += printCostPriceBreaks[priceBreak][i]
    //     }
    //     return localPrintCost / props.tempDesignsLength
    //   } else {
    //     Object.keys(printCostPriceBreaks).forEach(key => {
    //       if (parseInt(key) < parseInt(priceBreak) && props.tempDesignsLength) {
    //         let localPrintCost = 0
    //         Array(props.tempDesignsLength).map((i, j) => {
    //           localPrintCost += printCostPriceBreaks[key][j]
    //         })
    //         validPrintCost = localPrintCost / props.tempDesignsLength
    //       }
    //     })
    //     return validPrintCost
    //   }
    // } else {
    //   return validPrintCost
    // }
  }

  const [activeRow, setActiveRow] = useState(0)

  useEffect(() => {
    if (quantity && pB) {
      let ind = 0

      Object.entries(pB).forEach((entry: [string, number], index: number) => {
        console.log(quantity, entry)
        if (quantity >= parseInt(entry[0])) {
          ind = index
        }
      })
      setActiveRow(ind)
    }
  }, [quantity, pB])

  return (
    <div style={{ width: '100%' }}>
      <Table
        style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}
        className={isAddOn ? 'addOn' : undefined}
      >
        <thead>
          <tr className={isAddOn ? 'addOn' : undefined}>
            <th className={isAddOn ? 'addOn' : undefined}>
              <Heading
                element="h4"
                color={theme.colors.text.default}
                width="-webkit-fill-available"
              >
                Quantity
              </Heading>
            </th>
            <th className={isAddOn ? 'addOn' : undefined}>
              <Heading
                element="h4"
                color={theme.colors.text.default}
                width="-webkit-fill-available"
              >
                Price (Each)
              </Heading>
            </th>
          </tr>
          <tr className={isAddOn ? 'addOn' : undefined}>
            <th colSpan={2} className={isAddOn ? 'addOn' : undefined}>
              <Line />
            </th>
          </tr>
        </thead>
        <tbody>
          {pB &&
            Object.entries(pB).map((entry: [string, number], index: number) => (
              <Fragment key={index}>
                <tr
                  className={isAddOn ? 'addOn' : undefined}
                  style={{
                    backgroundColor:
                      index === activeRow
                        ? theme.colors.table.background
                        : 'rgba(0,0,0,0)',
                  }}
                >
                  <td className={isAddOn ? 'addOn' : undefined}>{entry[0]}</td>
                  <td className={isAddOn ? 'addOn' : undefined}>
                    ${' '}
                    {isAddOn
                      ? JSON.stringify(entry[1])
                      : formatter.format(
                          entry[1] + getPrintCostPriceBreak(entry[0]) || 0
                        )}
                  </td>
                </tr>
              </Fragment>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default withTheme(BulkPricingTable)
