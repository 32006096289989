import { gql } from 'apollo-boost'

export default gql`
  query getEmailTemplates {
    getEmailTemplates {
      id
      name
      available
      html
    }
  }
`
