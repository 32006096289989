import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { CART as CART_ROUTE } from 'routes/user'
import { AppState } from 'store/store'
import { connect } from 'react-redux'
import { UserState } from 'store/reducers/userReducer'
import { setCartNumber } from 'store/actions/userActions'
import { useQuery } from '@apollo/react-hooks'
import GET_CART_NUMBER from 'gql/GET_CART_NUMBER'
import { Error } from 'utils'
import { Text } from '@sweaterplanet/nucleus-style'

interface ICartNavProps {
  user: UserState
  setCartNumber: (num: number) => void
  storeId?: string
}

const CartNav: React.FC<ICartNavProps> = props => {
  const { user, setCartNumber } = props
  const { data, loading, refetch } = useQuery(GET_CART_NUMBER)
  const [error, setError] = useState('')

  useEffect(() => {
    refetch().catch(error => setError(error.message))
  })

  useEffect(() => {
    if (data && data.me.cart && data.me.cart.cartItems.length !== undefined) {
      setCartNumber(data.me.cart.cartItems.length)
    }
  }, [data, loading, setCartNumber])

  return (
    <>
      {error !== '' && <Error message={error} />}
      <Text>
        <Link
          to={`${CART_ROUTE}${props.storeId ? '?store=' + props.storeId : ''}`}
        >
          <FontAwesomeIcon icon={faShoppingCart as any} />
          {' ' + user.cartItems}
        </Link>
      </Text>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {
  setCartNumber,
}

export default connect(mapStateToProps, mapActionsToProps)(CartNav)
