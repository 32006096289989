export const NAV_HEIGHT = 80
export const NAV_WIDTH = 150

export type Spacing = 'none' | 'thin' | 'normal' | 'thick'
export const spacing: any = {
  none: '0',
  thin: '20px',
  normal: '50px',
  thick: '100px',
}
