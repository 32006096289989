import React from 'react'

// Types
import { ITheme } from 'types/Theme'

// Components
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

// Styles
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@sweaterplanet/nucleus-style'
import {
  ButtonBox,
  CarouselBox,
  DescriptionBox,
  TextBox,
  Wrapper,
} from './Moodboards.styles'

// Images
import McMasterGeo from 'static/Moodboards/McMasterGeo.jpg'
import MISN from 'static/Moodboards/MISN.jpg'
import Neat from 'static/Moodboards/Neat.png'
import SoftServe from 'static/Moodboards/SoftServe.jpg'

const slides = [
  {
    img: McMasterGeo,
    alt: 'McMaster Geo Moodboard',
  },
  {
    img: MISN,
    alt: 'McGill International Student Network Moodboard',
  },
  {
    img: Neat,
    alt: 'Neat Moodboard',
  },
  {
    img: SoftServe,
    alt: 'SoftServe Moodboard',
  },
]

// Props
interface IMoodboardsProps {
  theme: ITheme
  darkMode: boolean
  history: any
}

const Moodboards: React.FC<IMoodboardsProps> = props => {
  const {
    darkMode,
    theme: { colors },
  } = props

  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Wrapper>
        <TextBox>
          <Heading element="h2" color={colors.text.default}>
            We design
          </Heading>
          <Heading element="h2" color={darkMode ? 'aqua' : 'green'}>
            custom product lines
          </Heading>
          <Heading element="h2" color={colors.text.default}>
            for your brand
          </Heading>
          <DescriptionBox>
            <Text color={colors.text.default}>
              Whether you're a student group, startup, fortune 500 company or
              just an enthusiast with an idea, we'll work with you to get your
              brand on fabric.
              <br />
              <br />
              Your brand is a point of pride–an extension of yourself that
              you’ve spent days, weeks, months, and years building. That’s why
              you wear it, and why we value each and every client like a
              partner.
              <br />
              <br />
              It starts with a vision and ends with custom printed merch in your
              hands in a flash.
            </Text>
          </DescriptionBox>
          <ButtonBox>
            <Button
              maxWidth
              link
              href="mailto:info@bossypanda.com"
              bg={darkMode ? 'aqua' : 'green'}
              color={darkMode ? 'slate' : 'white'}
            >
              Get in touch
            </Button>
          </ButtonBox>
        </TextBox>
        <CarouselBox>
          <Box width="90%">
            <Carousel
              autoPlay={true}
              // speed={50}
              // transitionMode={'fade'}
              // Infinite carousel
              infiniteLoop={true}
              // Pagination "PREV" and "NEXT" controls
              showStatus={false}
              showIndicators={false}
              // Pagination dots styles
              // defaultControlsConfig={{
              //   pagingDotsStyle: {
              //     fill: 'white',
              //   },
              // }}
            >
              {slides.map((slide, i) => {
                return (
                  <div>
                    <Image
                      width={'100%'}
                      height={'100%'}
                      key={i}
                      src={slide.img}
                      alt={slide.alt}
                    />
                  </div>
                )
              })}
            </Carousel>
          </Box>
        </CarouselBox>
      </Wrapper>
    </Flex>
  )
}

export default Moodboards
