import React, { Fragment } from 'react'

import { Role } from 'types/User'

import { AppState } from 'store/store'
import { connect } from 'react-redux'
import { UserState } from 'store/reducers/userReducer'
import * as ADMIN_ROUTES from 'routes/admin'
import * as USER_ROUTES from 'routes/user'
import { Redirect, Switch, Route } from 'react-router-dom'
import { AdminNav } from 'components/Navigation'
import UserManagementPage from './UserManagement'

import { AddOnTable } from 'components/AddOn'
import PrintProviderTable from './PrintProviderManagement'

import { CardStyle as C, PageLayout as P } from 'styles'
import InventoryGroupTable from '../../Inventory/lib/InventoryGroupTable'
import ProductTable from '../../Product/lib/ProductManagement'
import InventoryGroup from '../../Inventory/lib/InventoryGroup'
import { AdminProductView } from '../../Product'
import PrintLocationEditor from './PrintLocationEditor'
import { TagTable } from 'components/Tag'
import CouponTable from '../../Coupon/CouponManagement'
import { StoreManagement, AdminStore } from 'components/Store'

import { DesignStudioPage } from 'components/Design'
import AddOnPage from 'components/AddOn/lib/AddOnPage'
import GoogleAuthorization from '../../EmailBot/lib/GoogleAuthorization'
import EmailBot, {
  EmailTemplateTable,
  EmailTemplateEditor,
} from '../../EmailBot'
import PromotionTable from '../../Promotion/lib/PromotionManagement'
import { LogoMods, OrderDashboard, OrdersTable } from 'components/Logistics'
import AdminShipping from '../../Shipping/lib/AdminShipping'
import WebsiteSettings from './WebsiteSettings'
import PrintLocationPage from './PrintLocationPage'

import CouponPage from '../../Coupon/CouponPage'
import UserPage from './UserPage'
import TagPage from '../../Tag/lib/TagPage'
import WarehouseTable from '../../Inventory/lib/WearhouseTable'
import WarehousePage from '../../Inventory/lib/WarehousePage'
import FilesPage from './FilesPage'
import CustomOrderPage from '../../CustomOrder/CustomOrderPage'
import CustomOrderScratch from '../../CustomOrder/CustomOrderScratch'
import RetailLocationsTable from '../../PayInStore/lib/RetailLocationsTable'
import RetailLocationsPage from '../../PayInStore/lib/RetailLocationsPage'

interface DashboardProps {
  user: UserState
}

const DashboardBase: React.FC<DashboardProps> = props => {
  return (
    <C.card>
      <h1>Admin Portal</h1>
      <h3>Welcome back {props.user.credentials.name}</h3>
    </C.card>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

const Dashboard = connect(mapStateToProps, mapActionsToProps)(DashboardBase)

interface AdminProps {
  user: UserState
}

const Admin: React.FC<AdminProps> = props => (
  <Fragment>
    {props.user.credentials.role === Role.ADMIN ||
    props.user.credentials.role === Role.MANAGER ? (
      <P.Page>
        <AdminNav />
        <P.Content verticalNav>
          <Switch>
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN}
              component={Dashboard}
            />
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN_USERS}
              component={UserManagementPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_USERS}/:userId`}
              component={UserPage}
            />
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}
              component={InventoryGroupTable}
            />
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN_INVENTORY_PROVIDERS}
              component={WarehouseTable}
            />
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN_PRODUCTS}
              component={ProductTable}
            />
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN_ADD_ONS}
              component={AddOnTable}
            />
            <Route
              exact={true}
              path={ADMIN_ROUTES.ADMIN_PRINT_PROVIDERS}
              component={PrintProviderTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}/:inventoryGroupId`}
              component={InventoryGroup}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_PRODUCTS}/:productId`}
              component={AdminProductView}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_PRODUCTS}/:productId/design`}
              component={DesignStudioPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_PRINT_LOCATION_EDITOR}/:viewId`}
              component={PrintLocationEditor}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_ADD_ONS}/:addOnId`}
              component={AddOnPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_COUPONS}/:couponId`}
              component={CouponPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_PRINT_LOCATIONS}/:printLocationId`}
              component={PrintLocationPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_TAGS}`}
              component={TagTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_COUPONS}`}
              component={CouponTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_PROMOTIONS}`}
              component={PromotionTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_STORES}`}
              component={StoreManagement}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_STORES}/:storeId`}
              component={AdminStore}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_EMAIL_BOT}`}
              component={EmailBot}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_EMAIL_BOT_TEMPLATES}`}
              component={EmailTemplateTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_EMAIL_BOT_TEMPLATES}/:emailTemplateId`}
              component={EmailTemplateEditor}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_OAUTH}`}
              component={GoogleAuthorization}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_ORDERS}`}
              component={OrdersTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_ORDERS}/:orderId`}
              component={OrderDashboard}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_LOGO_MODS}/:orderItemId`}
              component={LogoMods}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_SHIPPING}`}
              component={AdminShipping}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_WEBSITE_SETTINGS}`}
              component={WebsiteSettings}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_TAGS}/:tagId`}
              component={TagPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_INVENTORY_PROVIDERS}/:warehouseId`}
              component={WarehousePage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_FILES}`}
              component={FilesPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_CUSTOM_ORDER}`}
              component={CustomOrderPage}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_CUSTOM_ORDER}/scratch`}
              component={CustomOrderScratch}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_RETAIL_LOCATIONS}`}
              component={RetailLocationsTable}
            />
            <Route
              exact={true}
              path={`${ADMIN_ROUTES.ADMIN_RETAIL_LOCATIONS}/:retailLocationId`}
              component={RetailLocationsPage}
            />
          </Switch>
        </P.Content>
      </P.Page>
    ) : (
      <Redirect to={USER_ROUTES.SIGN_IN} />
    )}
  </Fragment>
)

export default connect(mapStateToProps, mapActionsToProps)(Admin)
