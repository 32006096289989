import React, { useState } from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from '../../../utils'
import { withTheme } from 'styled-components'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import {
  ButtonStyle as B,
  DivLayout as D,
  FormStyle as F,
  ModalLayout as M,
  UtiliesStyle as U,
} from '../../../styles'
import { Role } from '../../../types'
import PASSWORD_RESET_REQUEST from '../../../gql/PASSWORD_RESET_REQUEST'
import PermissionEditor from './PermissionEditor'

const UPGRADE_USER = gql`
  mutation upgradeUser($id: ID!, $role: Role!) {
    upgradeUser(id: $id, role: $role) {
      name
    }
  }
`

const DOWNGRADE_USER = gql`
  mutation downgradeUser($id: ID!) {
    downgradeUser(id: $id) {
      name
    }
  }
`

const EDIT_USER = gql`
  mutation editUser($id: ID!, $name: String!, $email: String!) {
    editUser(id: $id, name: $name, email: $email) {
      id
    }
  }
`

const MAKE_MANAGER = gql`
  mutation makeManager($userId: ID!, $storeId: ID!) {
    makeManager(userId: $userId, storeId: $storeId) {
      id
    }
  }
`

const REMOVE_MANAGER = gql`
  mutation removeManager($userId: ID!, $storeId: ID!) {
    removeManager(userId: $userId, storeId: $storeId) {
      id
    }
  }
`

import GET_SOME_STORES from 'components/Store/lib/gql/GET_SOME_STORES'

const UserEditForm = ({ user, toggle, refetch, visible }: any) => {
  const [name, setName] = useState<string>(user.name)
  const [email, setEmail] = useState<string>(user.email)

  const [EditUser, { loading }] = useMutation(EDIT_USER)

  const handleEditClick = async () => {
    try {
      await EditUser({
        variables: {
          id: user.id,
          name,
          email,
        },
      })
      toggle(false)
      refetch()
    } catch (e: any) {
      return <Error message={e.message} />
    }
  }
  if (loading) return <Loader />

  const invalid = name === '' || email === ''

  return (
    <>
      {visible && (
        <M.Modal>
          <h2>Edit User</h2>
          <B.Exit onClick={() => toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Email
              <F.Input
                value={email}
                type={'text'}
                placeholder={'email'}
                onChange={e => setEmail(e.target.value)}
              />
            </F.Label>
            <B.Button disabled={invalid} onClick={() => handleEditClick()}>
              Update
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

const GET_USER = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      name
      id
      email
      role
      googleId
      managerPermissions
      retailLocation {
        id
      }
      storeManager {
        id
      }
      files {
        url
        id
        filename
      }
    }
  }
`

const UserPage = (props: any) => {
  const [upgradeUser] = useMutation(UPGRADE_USER)
  const [downgradeUser] = useMutation(DOWNGRADE_USER)
  const handleMakeAdmin = (id: string, role: Role) => {
    upgradeUser({ variables: { id: id, role: role } })
      .catch((error: any) => <Error message={error.message} />)
      .then(() => {
        refetch && refetch()
      })
  }
  const handleRemoveAdmin = (id: string) => {
    downgradeUser({ variables: { id: id } })
      .catch((error: any) => <Error message={error.message} />)
      .then(() => refetch && refetch())
  }
  const { loading, data, error, refetch } = useQuery(GET_USER, {
    variables: {
      userId: props.match.params.userId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [PasswordResetRequest] = useMutation(PASSWORD_RESET_REQUEST)
  const handleResetPasswordRequest = (e: any) => {
    e.preventDefault()
    PasswordResetRequest({
      variables: {
        email: data.getUser.email,
      },
    }).then(() => {
      alert('Password Reset email sent!')
    })
  }

  const [modalOpen, toggleModalOpen] = useState<boolean>(false)
  const {
    theme: { colors },
  } = props

  const [MakeManager] = useMutation(MAKE_MANAGER)
  const [RemoveManager] = useMutation(REMOVE_MANAGER)

  const { data: stores, refetch: storeRefetch } = useQuery(GET_SOME_STORES, {
    variables: {
      searches: [],
    },
  })

  return (
    <>
      {error && <Error message={error.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getUser.name}
          </Heading>
          <Box margin="30px 0">
            <U.status
              color={data.getUser.googleId ? 'green' : 'red'}
              style={{ cursor: 'pointer' }}
              // onClick={() =>
              //   data.getAddOn.available ? handleMakeUnavailableClick() : null
              // }
            >
              <span>●</span>
              {data.getUser.googleId ? 'Connected to Google' : 'Not Connected'}
            </U.status>
          </Box>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  User Info
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <UserEditForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetch}
                  user={data.getUser}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Name:{' '}
                </Heading>
                <Text color={colors.text.default}>{data.getUser.name}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Email:{' '}
                </Heading>
                <Text color={colors.text.default}>{data.getUser.email}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Role:{' '}
                </Heading>
                <Text color={colors.text.default}>{data.getUser.role}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                {data.getUser.role === Role.ADMIN ? (
                  <td>
                    <B.SubtleButton
                      onClick={() => handleRemoveAdmin(data.getUser.id)}
                    >
                      Remove Admin
                    </B.SubtleButton>
                  </td>
                ) : data.getUser.role === Role.MANAGER ? (
                  <td>
                    <B.SubtleButton
                      onClick={() =>
                        handleMakeAdmin(data.getUser.id, Role.ADMIN)
                      }
                    >
                      Make Admin
                    </B.SubtleButton>
                    <B.SubtleButton
                      onClick={() => handleRemoveAdmin(data.getUser.id)}
                    >
                      Remove Manager
                    </B.SubtleButton>
                  </td>
                ) : (
                  <td>
                    <B.SubtleButton
                      onClick={() =>
                        handleMakeAdmin(data.getUser.id, Role.MANAGER)
                      }
                    >
                      Make Manager
                    </B.SubtleButton>
                    <B.SubtleButton
                      onClick={() =>
                        handleMakeAdmin(data.getUser.id, Role.ADMIN)
                      }
                    >
                      Make Admin
                    </B.SubtleButton>
                  </td>
                )}
              </Flex>
              <Box margin="40px 0 20px 0">
                <Button
                  bg="red"
                  color="white"
                  maxWidth
                  disabled={data.getUser.email === ''}
                  onClick={(e: any) => handleResetPasswordRequest(e)}
                >
                  Send Password Reset Link
                </Button>
              </Box>
              <Box margin={'40px 0 20px 0'}>
                {data.getUser.files.map((file: any) => (
                  <img
                    width={'100px'}
                    key={file.id}
                    src={file.url}
                    alt={file.filename}
                  />
                ))}
              </Box>
              {/*{data.getUser.role === 'MANAGER' && (*/}
              <PermissionEditor
                userId={data.getUser.id}
                user={data.getUser}
                refetch={refetch}
              />
              {/*)}*/}
              <Box margin="40px 0 20px 0">
                <Heading element="h2" color={colors.text.default}>
                  Store Manager
                </Heading>
                {stores ? (
                  <>
                    {stores.getSomeStores.map((store: any) => (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="20px 0"
                      >
                        <Heading element="h4" color={colors.text.default}>
                          {store.name}
                        </Heading>
                        {store.managers
                          ?.map((m: any) => m.id)
                          .includes(data.getUser.id) ? (
                          <B.SubtleButton
                            onClick={() =>
                              RemoveManager({
                                variables: {
                                  storeId: store.id,
                                  userId: data.getUser.id,
                                },
                              }).then(() => {
                                refetch()
                                storeRefetch()
                              })
                            }
                          >
                            Remove Manager
                          </B.SubtleButton>
                        ) : (
                          <B.SubtleButton
                            onClick={() =>
                              MakeManager({
                                variables: {
                                  storeId: store.id,
                                  userId: data.getUser.id,
                                },
                              }).then(() => {
                                refetch()
                                storeRefetch()
                              })
                            }
                          >
                            Make Manager
                          </B.SubtleButton>
                        )}
                      </Flex>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(UserPage)
