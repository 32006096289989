import { gql } from 'apollo-boost'

export default gql`
  query getSomePrintLocations($offset: Int, $limit: Int) {
    getSomePrintLocations(
      searches: ["available"]
      offset: $offset
      limit: $limit
    ) {
      id
      available
      name
      width
      height
      price
    }
  }
`
