import React from 'react'
import { TCoupon } from 'types'
import { Card, Flex, Heading, Box } from '@sweaterplanet/nucleus-style'

interface PromotionProps {
  coupon: TCoupon
  couponDescription: string
  onClose: () => void
}

const Promotion: React.FC<PromotionProps> = props => {
  const {
    coupon: { id, name, type, value },
    couponDescription,
    onClose,
  } = props
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bg="green"
      width="100%"
    >
      <Box margin="10px 0 10px 50px">
        <Heading element="h4" color="white">
          {value}
          {type.includes('PERCENT') ? '%' : '$'} {name}
        </Heading>
      </Box>
      <Flex margin="10px 30px" alignItems="center">
        <Heading element="h4" color="white">
          {couponDescription}
        </Heading>
        <Card round padding="10px 20px" bg="paper" margin="0 30px">
          <Heading element="h4" color="black">
            {id}
          </Heading>
        </Card>
        <Box style={{ cursor: 'pointer' }} onClick={() => onClose()}>
          <Heading element="h4" color="white">
            X
          </Heading>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Promotion
