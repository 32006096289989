import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Heading, Text, Flex } from '@sweaterplanet/nucleus-style'
import permissions from '../../../constants/permissions'
import { ButtonStyle as B } from '../../../styles'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Role, TUser } from '../../../types'
import { withTheme } from 'styled-components'
import { GET_SOME_RETAIL_LOCATIONS } from '../../../gql/GET_SOME_RETAIL_LOCATIONS'

const UPDATE_MANAGER = gql`
  mutation updateManager(
    $userId: ID!
    $permissions: [String]
    $storeIds: [ID]
    $retailLocationId: ID
  ) {
    updateManager(
      userId: $userId
      permissions: $permissions
      storeIds: $storeIds
      retailLocationId: $retailLocationId
    ) {
      id
    }
  }
`

interface IPermissionEditorProps {
  userId: String
  user: TUser
  refetch?: () => void
  theme?: any
}

const managerOnlyPermissions = [
  'WEBSITE_SETTINGS',
  'FILES',
  'PRODUCTS',
  'DECORATIONS',
  'TAGS',
  'ORDERS',
  'SHIPPING',
  'INVENTORY',
  'WAREHOUSES',
  'STORES',
  'COUPONS',
  'PROMOTIONS',
  'RETAIL_LOCATIONS',
]

const PermissionEditor: React.FC<IPermissionEditorProps> = ({
  userId,
  user,
  refetch,
  theme,
}) => {
  const [checked, setChecked] = useState<String[]>([])
  const [UpdateManager] = useMutation(UPDATE_MANAGER)
  const [retailLocation, setRetailLocation] = useState<string>(
    user.retailLocation?.id ?? ''
  )

  const { data: getSomeRetailLocationsData } = useQuery(
    GET_SOME_RETAIL_LOCATIONS,
    {
      variables: {
        searches: [],
      },
    }
  )

  const { colors } = theme
  const handleUpdateManagerPermissions = () => {
    console.log({
      userId,
      permissions: checked,
    })
    UpdateManager({
      variables: {
        userId,
        permissions: checked,
        retailLocationId: retailLocation,
      },
    }).then(() => {
      refetch && refetch()
    })
  }

  // Add/Remove checked item from list
  const handleCheck = (event: any) => {
    let updatedList = [...checked]
    if (event.target.checked) {
      updatedList = [...checked, event.target.value]
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1)
    }
    setChecked(updatedList)
  }
  console.log(user)

  useEffect(() => {
    user.managerPermissions && setChecked(user.managerPermissions)
    user.retailLocation && setRetailLocation(user.retailLocation.id)
  }, [user])

  return (
    <Box>
      <Heading element="h2" color={colors.text.default}>
        Manager Permissions
      </Heading>
      <br />
      {getSomeRetailLocationsData && (
        <Flex>
          <Text color={colors.text.default}>Set Retail Location</Text>
          <select
            style={{ marginLeft: '10px' }}
            onChange={e => setRetailLocation(e.target.value)}
            value={retailLocation}
          >
            <option value="">Select Retail Location</option>
            {getSomeRetailLocationsData.getSomeRetailLocations.map(
              (rL: any) => (
                <option key={rL.id} value={rL.id}>
                  {rL.name}
                </option>
              )
            )}
          </select>
        </Flex>
      )}
      <br />
      <br />
      {permissions.map((permission, index) => (
        <>
          {user.role === Role.MANAGER ? (
            <label key={index}>
              <Text color={colors.text.default}>
                <input
                  value={permission}
                  type="checkbox"
                  onChange={handleCheck}
                  checked={checked.includes(permission)}
                />{' '}
                {permission}
              </Text>
              <br />
            </label>
          ) : (
            !managerOnlyPermissions.includes(permission) && (
              <label key={index}>
                <Text color={colors.text.default}>
                  <input
                    value={permission}
                    type="checkbox"
                    onChange={handleCheck}
                    checked={checked.includes(permission)}
                  />{' '}
                  {permission}
                </Text>
                <br />
              </label>
            )
          )}
        </>
      ))}
      <br />
      <B.Button onClick={handleUpdateManagerPermissions}>Update</B.Button>
    </Box>
  )
}

export default withTheme(PermissionEditor)
