import { gql } from 'apollo-boost'

export default gql`
  mutation sendEmail(
    $to: String!
    $template: String!
    $fromName: String!
    $firstName: String
    $lastName: String
    $groupName: String
    $organizationName: String
    $designId: ID
    $primaryColor: String
    $sendAtTime: Date
    $customLink: String
    $customHtml: String
  ) {
    sendEmail(
      to: $to
      template: $template
      fromName: $fromName
      firstName: $firstName
      lastName: $lastName
      groupName: $groupName
      organizationName: $organizationName
      designId: $designId
      primaryColor: $primaryColor
      sendAtTime: $sendAtTime
      customLink: $customLink
      customHtml: $customHtml
    ) {
      id
      status
    }
  }
`
