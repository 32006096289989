import { gql } from 'apollo-boost'

export default gql`
  query getSomeTags($searches: [String!], $offset: Int, $limit: Int) {
    getSomeTags(searches: $searches, offset: $offset, limit: $limit) {
      id
      content
      color
      icon
    }
  }
`
