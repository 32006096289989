import { gql } from 'apollo-boost'

export default gql`
  mutation generateInventory(
    $itemCode: String!
    $name: String!
    $description: String
    $styleId: Int!
    $sizes: [JSONObject!]!
    $colors: [JSONObject!]!
    $inventoryProviderId: ID!
    $templateBasePrice: Float
    $templatePriceBreaks: JSONObject
  ) {
    generateInventory(
      itemCode: $itemCode
      name: $name
      description: $description
      styleId: $styleId
      sizes: $sizes
      colors: $colors
      inventoryProviderId: $inventoryProviderId
      templateBasePrice: $templateBasePrice
      templatePriceBreaks: $templatePriceBreaks
    ) {
      id
    }
  }
`
