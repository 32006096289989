import { gql } from 'apollo-boost'

export default gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        retailLocation {
          id
          name
        }
        id
        name
        email
        phone
        role
        managerPermissions
        cart {
          id
          cartItems {
            id
          }
        }
      }
    }
  }
`
