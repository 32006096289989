import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

// Styles
import { Text } from '@sweaterplanet/nucleus-style'
import { Nav, NavLinks, NavLink, NavLogo, NavLogoImg } from 'styles/lib/nav'

// Routes
import * as USER_ROUTES from 'routes/user'
import * as ADMIN_ROUTES from 'routes/admin'

// Types
import { AppState } from 'store/store'
import { UserState } from 'store/reducers/userReducer'
import { Role } from 'types/User'

// Components
import CartNav from './CartNav'
import { SignOut } from 'components/Auth'
import DarkModeSwitch from 'components/DarkModeSwitch'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Logo from 'static/logo'
import Title from 'static/title'

interface NavProps {
  user: UserState
  whitelabelNav?: boolean
  whitelabelWebsite?: string
  whitelabelLogo?: string
  whitelabelStoreId?: string
  isStore?: boolean
}

const DesktopNav: React.FC<NavProps> = props => {
  const {
    user: { authenticated, credentials },
    whitelabelNav,
    whitelabelWebsite,
    whitelabelLogo,
    whitelabelStoreId,
  } = props

  const history = useHistory()

  return (
    <Nav>
      <NavLinks>
        <NavLink>
          {whitelabelNav && whitelabelLogo ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={whitelabelWebsite}
            >
              <NavLogoImg
                src={whitelabelLogo}
                alt={whitelabelWebsite}
                width={'100px'}
                height={'100px'}
              />
            </a>
          ) : (
            <NavLogo>
              <Title />
            </NavLogo>
          )}
        </NavLink>
        {(credentials.role === Role.ADMIN ||
          credentials.role === Role.MANAGER) && (
          <NavLink>
            <Text>
              <Link to={ADMIN_ROUTES.ADMIN}>Admin</Link>
            </Text>
          </NavLink>
        )}
        <NavLink>
          <Text>
            <Link
              to={
                whitelabelStoreId
                  ? `${USER_ROUTES.STORE}/${whitelabelStoreId}`
                  : USER_ROUTES.PRODUCTS
              }
            >
              Products
            </Link>
          </Text>
        </NavLink>

        {!(
          history.location.pathname.includes(USER_ROUTES.STORE) ||
          props.whitelabelStoreId
        ) && (
          <NavLink>
            <Text>
              <a rel="noopener noreferrer" href={USER_ROUTES.GALLERY}>
                Gallery
              </a>
            </Text>
          </NavLink>
        )}

        {/* {!(
          history.location.pathname.includes(USER_ROUTES.STORE) ||
          props.whitelabelStoreId
        ) && (
          <NavLink>
            <Text>
              <Link to={USER_ROUTES.ABOUT}>About</Link>
            </Text>
          </NavLink>
        )} */}
        {!(
          history.location.pathname.includes(USER_ROUTES.STORE) ||
          props.whitelabelStoreId
        ) && (
          <NavLink>
            <Text>
              <Link to={USER_ROUTES.CONTACT}>Contact</Link>
            </Text>
          </NavLink>
        )}
        {!!props.user.credentials.retailLocation && (
          <NavLink>
            <Text>
              <a rel="noopener noreferrer" href={USER_ROUTES.COLLECTIONS}>
                Collections
              </a>
            </Text>
          </NavLink>
        )}
        {/*<NavLink>*/}
        {/*  <Text>*/}
        {/*    <Link to={USER_ROUTES.CATALOG}>Catalog</Link>*/}
        {/*  </Text>*/}
        {/*</NavLink>*/}
      </NavLinks>
      <NavLinks>
        {authenticated &&
        (credentials.role === Role.ADMIN ||
          credentials.role === Role.USER ||
          credentials.role === Role.MANAGER) ? (
          <>
            <NavLink>
              <Text>
                <Link to={USER_ROUTES.ACCOUNT}>
                  <FontAwesomeIcon icon={faUser as any} /> {credentials.name}
                </Link>
              </Text>
            </NavLink>
            <NavLink>
              <SignOut />
            </NavLink>
          </>
        ) : (
          <>
            <NavLink>
              <Text>
                <Link
                  to={`${USER_ROUTES.SIGN_IN}${
                    props.whitelabelStoreId
                      ? '?store=' + props.whitelabelStoreId
                      : ''
                  }`}
                >
                  Sign In
                </Link>
              </Text>
            </NavLink>
            <NavLink>
              <Text>
                <Link
                  to={`${USER_ROUTES.SIGN_UP}${
                    props.whitelabelStoreId
                      ? '?store=' + props.whitelabelStoreId
                      : ''
                  }
                `}
                >
                  Sign Up
                </Link>
              </Text>
            </NavLink>
          </>
        )}
        {authenticated && (
          <NavLink>
            <CartNav storeId={whitelabelStoreId} />
          </NavLink>
        )}
        <NavLink>
          <DarkModeSwitch />
        </NavLink>
      </NavLinks>
    </Nav>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

export default connect(mapStateToProps)(DesktopNav)
