import { gql } from 'apollo-boost'

export default gql`
  mutation setWebsiteSettings(
    $previewColors: [JSONObject!]!
    $popularSearches: [ID!]!
    $displayCoupon: ID
    $homePageMessage: String
  ) {
    setWebsiteSettings(
      previewColors: $previewColors
      popularSearches: $popularSearches
      displayCoupon: $displayCoupon
      homePageMessage: $homePageMessage
    ) {
      id
    }
  }
`
