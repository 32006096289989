import React, { useState, useEffect } from 'react'
import { ModalLayout as M, ButtonStyle as B, FormStyle as F } from 'styles'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import { TStore } from 'types'
import CREATE_STORE from './gql/CREATE_STORE'
import EDIT_STORE from './gql/EDIT_STORE'
import * as ADMIN_ROUTES from '../../../routes/admin'
import { useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { Flex, Box, Button, Tag } from '@sweaterplanet/nucleus-style'

import { Input } from 'styles/lib/form'

import { SEARCH_USER } from 'gql/SEARCH_USER'
import { withTheme } from 'styled-components'

interface IStoreFormProps {
  visible: boolean
  toggle: (value: boolean) => void
  refetch: () => void
  store?: TStore
  theme: any
}

const MAKE_MANAGER = gql`
  mutation makeManager($userId: ID!, $storeId: ID!) {
    makeManager(userId: $userId, storeId: $storeId) {
      id
    }
  }
`

const REMOVE_MANAGER = gql`
  mutation removeManager($userId: ID!, $storeId: ID!) {
    removeManager(userId: $userId, storeId: $storeId) {
      id
    }
  }
`

const GET_ALL_USERS = gql`
  query getAllUsers($searches: [String!], $offset: Int, $limit: Int) {
    getAllUsers(searches: $searches, offset: $offset, limit: $limit) {
      name
      id
      email
      role
      googleId
    }
  }
`

const StoreForm: React.FC<IStoreFormProps> = props => {
  const { visible, toggle, refetch, store } = props

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [logo, setLogo] = useState<any | undefined>(undefined)
  const [bannerImg, setBannerImg] = useState<any | undefined>(undefined)
  const [moodboard, setMoodboard] = useState<any | undefined>(undefined)
  const [slug, setSlug] = useState<string | undefined>(undefined)
  const [removeBanner, setRemoveBanner] = useState<boolean>(false)
  const [removeLogo, setRemoveLogo] = useState<boolean>(false)
  const [removeMoodboard, setRemoveMoodboard] = useState<boolean>(false)
  const [whitelabelNav, setWhitelabelNav] = useState<string>('')
  const [isPublic, setIsPublic] = useState<boolean>(false)

  const [CreateStore, { loading }] = useMutation(CREATE_STORE, {
    variables: {
      name,
      description,
      logo,
      bannerImg,
      moodboard,
      slug,
      isPublic,
    },
  })

  const [EditStore, { loading: editLoading }] = useMutation(EDIT_STORE)

  const [makeManager] = useMutation(MAKE_MANAGER)

  const [removeManager] = useMutation(REMOVE_MANAGER)

  const { data: getAllUsers } = useQuery(GET_ALL_USERS)

  const [search, setSearch] = useState('')

  const [searchUser] = useMutation(SEARCH_USER, {
    variables: {
      search,
    },
    onCompleted: data => {
      makeManager({
        variables: {
          userId: data.searchUser.id,
          storeId: store?.id,
        },
      }).then(() => {
        refetch()
      })
    },
  })

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const searchClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (search !== '') {
      searchUser()
      setSearch('')
    }
  }

  useEffect(() => {
    if (store) {
      setName(store.name)
      setDescription(store.description)
      setIsPublic(store.isPublic || false)
      setSlug(store.slug)
      setWhitelabelNav(store.whitelabelNav || '')
    }
  }, [store])

  const invalid = name === ''

  const history = useHistory()

  const handleSubmitClick = async () => {
    try {
      if (store) {
        await EditStore({
          variables: {
            id: store.id,
            name,
            description,
            logo,
            bannerImg,
            moodboard,
            slug,
            removeBanner,
            removeLogo,
            removeMoodboard,
            whitelabelNav,
            isPublic,
          },
        })
        toggle(false)
        refetch()
      } else {
        const resp = await CreateStore()
        toggle(false)
        history.push(`${ADMIN_ROUTES.ADMIN_STORES}/${resp.data.createStore.id}`)
      }
    } catch (error: any) {
      return <Error message={error.message} />
    }
  }

  if (loading || editLoading) return <Loader />

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const onChange = (
    {
      target: {
        validity,
        files: [file],
      },
    }: any,
    type: string
  ) => {
    if (validity.valid) {
      if (type === 'logo') setLogo(file)
      else if (type === 'bannerImg') setBannerImg(file)
      else if (type === 'moodboard') setMoodboard(file)
    }
  }

  return (
    <>
      {visible && (
        <M.Modal>
          <h2>{store ? 'Edit Store' : 'New Store'}</h2>
          <B.Exit onClick={() => toggle(false)}>X</B.Exit>
          <F.Form>
            <F.Label>
              Name
              <F.Input
                value={name}
                type={'text'}
                placeholder={'name'}
                onChange={e => setName(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Slug
              <F.Input
                value={slug}
                type={'text'}
                placeholder={'slug'}
                onChange={e => setSlug(e.target.value)}
              />
            </F.Label>
            <F.Label>
              Description
              <F.Input
                value={description}
                type={'text'}
                placeholder={'description'}
                onChange={e => setDescription(e.target.value)}
              />
            </F.Label>
            <F.Label>
              {' '}
              Upload Logo (Square)
              <F.upload
                type="file"
                accept=".png"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event, 'logo')
                }
                onClick={onInputClick}
              />
            </F.Label>
            <F.Label>
              {' '}
              Upload Banner (3 x 1 Rectangle)
              <F.upload
                type="file"
                accept=".png"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event, 'bannerImg')
                }
                onClick={onInputClick}
              />
            </F.Label>
            <F.Label>
              {' '}
              Upload Moodboard (Any)
              <F.upload
                type="file"
                accept=".png"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event, 'moodboard')
                }
                onClick={onInputClick}
              />
            </F.Label>
            <F.Label>
              {' '}
              Public
              <F.Input
                checked={isPublic}
                type={'checkbox'}
                onChange={() => setIsPublic(!isPublic)}
              />
            </F.Label>
            {store && (
              <>
                <F.Label>
                  {' '}
                  Whitelist Nav Website
                  <F.Input
                    value={whitelabelNav}
                    placeholder={'website url'}
                    type={'text'}
                    onChange={e => setWhitelabelNav(e.target.value)}
                  />
                </F.Label>
                <F.Label>
                  {' '}
                  Remove Logo
                  <F.Input
                    checked={removeLogo}
                    type={'checkbox'}
                    onChange={() => setRemoveLogo(!removeLogo)}
                  />
                </F.Label>
                <F.Label>
                  {' '}
                  Remove Banner
                  <F.Input
                    checked={removeBanner}
                    type={'checkbox'}
                    onChange={() => setRemoveBanner(!removeBanner)}
                  />
                </F.Label>
                <F.Label>
                  {' '}
                  Remove Moodboard
                  <F.Input
                    checked={removeMoodboard}
                    type={'checkbox'}
                    onChange={() => setRemoveMoodboard(!removeMoodboard)}
                  />
                </F.Label>
                <Flex flexDirection="column" margin={'20px 0'}>
                  <F.Label>Managers</F.Label>

                  <Flex flexDirection="row" alignItems="center">
                    <Input
                      value={search}
                      onChange={searchChange}
                      type="text"
                      placeholder="Search"
                    />
                    <Box marginLeft="20px">
                      <Button
                        bg="green"
                        color="white"
                        size="xs"
                        onClick={searchClick}
                      >
                        Search
                      </Button>
                    </Box>
                  </Flex>

                  <Flex flexDirection="column">
                    {store.managers?.map((manager: any) => (
                      <div key={manager.id}>
                        <F.checkbox
                          checked={true}
                          type={'checkbox'}
                          onChange={() =>
                            removeManager({
                              variables: {
                                userId: manager.id,
                                storeId: store.id,
                              },
                            }).then(() => refetch())
                          }
                        />
                        {manager.name} - {manager.email}
                      </div>
                    ))}
                  </Flex>
                  <Flex flexDirection="column" margin={'10px 0'}>
                    <select
                      onChange={e =>
                        makeManager({
                          variables: {
                            userId: e.target.value,
                            storeId: store.id,
                          },
                        }).then(() => refetch())
                      }
                    >
                      <option value={''}>+ Add a new manager</option>
                      {getAllUsers?.getAllUsers?.map((user: any) => (
                        <option value={user.id}>
                          {user.name} - {user.email}
                        </option>
                      ))}
                    </select>
                  </Flex>
                </Flex>
              </>
            )}
            <B.Button
              type="button"
              disabled={invalid}
              onClick={() => handleSubmitClick()}
            >
              {store ? 'Update' : 'Create Store'}
            </B.Button>
          </F.Form>
        </M.Modal>
      )}
    </>
  )
}

export default withTheme(StoreForm)
