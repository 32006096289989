import React, { useEffect, useState } from 'react'
import { TVariant } from 'types/Variant'
import { TOrder } from 'types/Order'
import Mockup from 'components/Mockup'
import {
  Flex,
  Text,
  Box,
  ProgressBar,
  Card,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { AppState } from 'store/store'
import { connect } from 'react-redux'
import { TOrderItem } from '../../../types'
import { FormStyle as F, TableLayout as T } from '../../../styles'
import { TVariantSelection } from '../../../types/VariantSelection'

interface OrderProps {
  order: TOrder
  selectedColors: TVariant[]
  theme: ITheme
  darkMode: boolean
  showDetails: boolean
  setShowDetails: (showDetails: boolean) => void
}

const Order: React.FC<OrderProps> = props => {
  const {
    order: { paid, orderItems, id, createdAt, fulfillmentStatus },
    theme: { colors },
    darkMode,
    showDetails,
    setShowDetails,
  } = props

  const [createdDate, setCreatedDate] = useState('')

  useEffect(() => {
    if (createdAt) {
      const date = new Date(parseInt(createdAt)).toDateString()
      setCreatedDate(date)
    }
  }, [createdAt])

  return (
    <tr style={{ width: '100%' }}>
      <td style={{ borderBottom: `1px solid ${props.theme.colors.contrast}` }}>
        <Flex flexDirection="column">
          <Flex flexDirection="column" marginRight="30px">
            <Box margin="5px 0">
              <Text color={colors.card.text}>Order #{id}</Text>
            </Box>
            <Box margin="5px 0" width="fit-content">
              <Text color={colors.text.default}>{createdDate}</Text>
            </Box>
            <Box margin="5px 0" width="fit-content">
              {/* {JSON.stringify(orderItems)} */}
              <Text color={colors.text.default}>
                Subtotal: $
                {orderItems
                  .map(oI => oI.subtotal)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )}
              </Text>
              <Text color={colors.text.default}>
                Shipping: ${props.order.shippingPrice}
              </Text>
              <Text color={colors.text.default}>
                Total (incl. tax): ${props.order.price}
              </Text>
              {props.order.shipping && (
                <Box margin="20px 0">
                  <Text bold color={colors.text.default}>
                    Contact Information
                  </Text>
                  <br />
                  <Text color={colors.text.default}>
                    {props.order.shipping[0].firstName}{' '}
                    {props.order.shipping[0].lastName}
                    <br />
                    {props.order.shipping[0].email}
                    <br />
                  </Text>
                </Box>
              )}
            </Box>
          </Flex>
          <Flex
            flexDirection="row"
            flexWrap={'wrap'}
            minWidth={'calc(100vw - 1000px)'}
            padding={'20px 0'}
          >
            {showDetails ? (
              <Flex flexDirection="column" width="100%">
                {/* <Box margin="20px 0">
                  <Box margin="20px 0">
                    <Text bold color={colors.text.default}>
                      Order Status
                    </Text>
                  </Box>
                  <Flex
                    justifyContent="space-between"
                    whiteSpace="nowrap"
                    minWidth={'120px'}
                  >
                    <Text bold color={colors.text.default}>
                      {fulfillmentStatus || 'PRINTING'}
                      {paid !== null && paid !== true && ''}
                    </Text>
                  </Flex>
                  <ProgressBar
                    color="green"
                    dark={darkMode}
                    value={
                      fulfillmentStatus === 'PROCESSING'
                        ? 0
                        : fulfillmentStatus === 'APPROVED'
                        ? 20
                        : fulfillmentStatus === 'PRINTING'
                        ? 40
                        : fulfillmentStatus === 'PRINTED'
                        ? 60
                        : fulfillmentStatus === 'SHIPPED'
                        ? 80
                        : fulfillmentStatus === 'DELIVERED'
                        ? 100
                        : 0
                    }
                  />
                </Box>{' '} */}
                <Box margin="20px 0">
                  {props.order.pickupLocation ? (
                    <div>
                      <Text bold color={colors.text.default}>
                        Pickup Location
                      </Text>
                      <br />
                      <Text color={colors.text.default}>
                        {props.order.pickupLocation.name}
                        <br />
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <Text bold color={colors.text.default}>
                        Shipping Address
                      </Text>
                      <br />
                      {props.order.shipping && (
                        <Text color={colors.text.default}>
                          {props.order.shipping[0].address2
                            ? `${props.order.shipping[0].address2} - `
                            : ``}
                          {props.order.shipping[0].address1}
                          <br />
                          {props.order.shipping[0].city}
                          <br />
                          {props.order.shipping[0].state}
                          <br />
                          {props.order.shipping[0].postCode}
                          <br />
                        </Text>
                      )}
                    </div>
                  )}
                </Box>
                <Box margin="20px 0">
                  <Text bold color={colors.text.default}>
                    Order Items
                  </Text>

                  {orderItems.map((oi: TOrderItem, index) => (
                    <Card
                      round
                      bg={colors.card.background}
                      padding={'20px'}
                      marginRight={'20px'}
                      marginBottom={'20px'}
                      marginTop={'20px'}
                      minWidth={'calc(100vw - 1040px)'}
                    >
                      <Flex justifyContent="flex-start" marginBottom="10px">
                        <>
                          <Flex flexDirection="row" marginRight="30px">
                            {orderItems[
                              index
                            ]?.product?.inventoryGroup?.views?.map((v, i) => (
                              <Mockup
                                view={v}
                                color={
                                  orderItems[
                                    index
                                  ]?.variantSelection[0]?.variants?.find(
                                    v => v.variantGroup.name === 'Color'
                                  )?.value || '#000000'
                                }
                                width="100px"
                                height="100px"
                                designs={orderItems[index]?.designs}
                              />
                            ))}
                          </Flex>
                          <Box>
                            <T.VariantSelectionsTable>
                              <tbody>
                                {orderItems[index].variantSelection.map(
                                  (
                                    variantSelection: TVariantSelection,
                                    index: number
                                  ) => {
                                    const colorIndex =
                                      variantSelection.variants.findIndex(
                                        (variant: TVariant) =>
                                          variant.variantGroup.name === 'Color'
                                      )
                                    const color =
                                      variantSelection.variants[colorIndex]
                                    const sizeIndex =
                                      variantSelection.variants.findIndex(
                                        (variant: TVariant) =>
                                          variant.variantGroup.name === 'Size'
                                      )
                                    const size =
                                      variantSelection.variants[sizeIndex]

                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Box marginRight={'10px'}>
                                            <Text color={colors.text.default}>
                                              {variantSelection.quantity} x
                                            </Text>
                                          </Box>
                                        </td>
                                        <td>
                                          <Flex
                                            flexDirection="row"
                                            alignItems="center"
                                          >
                                            <F.Color
                                              type="button"
                                              color={color?.value}
                                            />
                                            <Box marginLeft={'10px'}>
                                              <Text color={colors.text.default}>
                                                {color?.name}
                                              </Text>
                                            </Box>
                                          </Flex>
                                        </td>
                                        <td>
                                          <Box marginLeft={'10px'}>
                                            <Text color={colors.text.default}>
                                              {size?.name}
                                            </Text>
                                          </Box>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                              </tbody>
                            </T.VariantSelectionsTable>
                          </Box>
                        </>
                      </Flex>
                    </Card>
                  ))}
                </Box>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowDetails(false)}
                >
                  Hide Details
                </a>
              </Flex>
            ) : (
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => setShowDetails(true)}
              >
                Show Details
              </a>
            )}
          </Flex>
        </Flex>
      </td>
    </tr>
  )
}

const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withTheme(Order))
