import { gql } from 'apollo-boost'

export default gql`
  mutation editUser(
    $id: ID!
    $name: String!
    $email: String!
    $phone: String
    $company: String
  ) {
    editUser(
      id: $id
      name: $name
      email: $email
      phone: $phone
      company: $company
    ) {
      id
      name
      email
      phone
      company
    }
  }
`
