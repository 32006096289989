import { gql } from 'apollo-boost'

export default gql`
  mutation signup(
    $email: String!
    $password: String!
    $name: String!
    $phone: String
    $company: String
    $locationSlug: String
  ) {
    signup(
      email: $email
      password: $password
      name: $name
      phone: $phone
      company: $company
      locationSlug: $locationSlug
    ) {
      token
      user {
        id
        name
        email
        company
        role
        managerPermissions
        phone
      }
    }
  }
`
