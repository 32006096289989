import React, { useState } from 'react'
import { TView } from 'types/View'
import { TPrintLocationCoordinateMap } from 'types/PrintLocationCoordinateMap'
import { SLEEVE_CUSTOMIZATION } from 'constants/sleeveCustomization'

interface IMockupProps {
  view: TView
  color: string
  width?: string
  height?: string
  selectedPrintLocationCoordinateMap: TPrintLocationCoordinateMap | null
  setSelectedPrintLocationCoordinateMap: (
    value: TPrintLocationCoordinateMap
  ) => void
}

const Mockup: React.FC<IMockupProps> = props => {
  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
    CTMa: 1,
    CTMe: 0,
    CTMf: 0,
  })
  const [selectedElement, setSelectedElement] = useState<SVGRectElement | null>(
    null
  )
  const [printLocation, setPrintLocation] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [selectedCorner, setSelectedCorner] = useState<SVGRectElement | null>(
    null
  )

  const {
    view: { printLocations, boundingBox, texture, base },
    color,
    width,
    height,
    selectedPrintLocationCoordinateMap,
    setSelectedPrintLocationCoordinateMap,
  } = props
  const cornerWidth = 20

  function getMousePosition(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) {
    const CTM = event.currentTarget.getScreenCTM() //Current Transformation Matrix for screen

    return CTM
      ? {
          x: (event.clientX - CTM.e) / CTM.a,
          y: (event.clientY - CTM.f) / CTM.d,
          CTMa: CTM.a,
          CTMe: CTM.e,
          CTMf: CTM.f,
        }
      : {
          x: event.clientX,
          y: event.clientY,
          CTMa: 1,
          CTMe: 0,
          CTMf: 0,
        }
  }

  const handleMouseDown = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const element = event.target as HTMLElement | SVGElement
    let offsetData: typeof offset

    if (element.classList.contains('draggable')) {
      setSelectedCorner(null)
      setSelectedElement(
        document.getElementById(`selectedPrintLocation`) as
          | HTMLElement
          | SVGElement as SVGRectElement
      )

      offsetData = getMousePosition(event)
      const x = element.getAttribute('x')
      const y = element.getAttribute('y')
      offsetData.x -= x ? parseFloat(x) : 0
      offsetData.y -= y ? parseFloat(y) : 0

      setOffset(offsetData)
    } else if (element.classList.contains('resize')) {
      setSelectedCorner(element as SVGRectElement)

      const printLocation = document.getElementById(`selectedPrintLocation`) as
        | HTMLElement
        | SVGElement as SVGRectElement
      setSelectedElement(printLocation)

      offsetData = getMousePosition(event)
      const x = printLocation.getAttribute('x')
      const y = printLocation.getAttribute('y')
      offsetData.x -= x ? parseFloat(x) : 0
      offsetData.y -= y ? parseFloat(y) : 0
      setOffset(offsetData)

      const bBox = printLocation.getBoundingClientRect()
      setPrintLocation({
        x: (bBox.x - offsetData.CTMe) / offsetData.CTMa,
        y: (bBox.y - offsetData.CTMf) / offsetData.CTMa,
        width: bBox.width / offsetData.CTMa,
        height: bBox.height / offsetData.CTMa,
      })
    }
  }

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.preventDefault()

    if (selectedElement) {
      const bBox = selectedElement.getBoundingClientRect()

      // Coordinate of the click event with respect to the SVG origin
      const coord = getMousePosition(event)

      const width = bBox.width / offset.CTMa
      const height = bBox.height / offset.CTMa

      const left = coord.x - offset.x
      const right = left + width
      const top = coord.y - offset.y
      const bottom = top + height

      if (!selectedCorner) {
        selectedElement.setAttribute('x', left.toFixed(2))
        if (selectedElement.dataset.sleevecustomization === 'true') {
          document
            .getElementById(`sleeveText`)
            ?.setAttribute('x', left.toFixed(2))
        }

        document.getElementById(`nw`)?.setAttribute('x', left.toFixed(2))
        document
          .getElementById(`ne`)
          ?.setAttribute('x', (right - cornerWidth).toFixed(2))
        document.getElementById(`sw`)?.setAttribute('x', left.toFixed(2))
        document
          .getElementById(`se`)
          ?.setAttribute('x', (right - cornerWidth).toFixed(2))

        selectedElement.setAttribute('y', top.toFixed(2))
        if (selectedElement.dataset.sleevecustomization === 'true') {
          document
            .getElementById(`sleeveText`)
            ?.setAttribute('y', bottom.toFixed(2))
        }

        document.getElementById(`nw`)?.setAttribute('y', top.toFixed(2))
        document.getElementById(`ne`)?.setAttribute('y', top.toFixed(2))
        document
          .getElementById(`sw`)
          ?.setAttribute('y', (bottom - cornerWidth).toFixed(2))
        document
          .getElementById(`se`)
          ?.setAttribute('y', (bottom - cornerWidth).toFixed(2))
      } else {
        const dx = printLocation.x - left
        const dy = printLocation.y - top

        switch (selectedCorner.id.substring(0, 2)) {
          case 'nw':
            selectedElement.setAttribute('x', left.toFixed(2))
            document.getElementById(`nw`)?.setAttribute('x', left.toFixed(2))
            document.getElementById(`sw`)?.setAttribute('x', left.toFixed(2))

            selectedElement.setAttribute('y', top.toFixed(2))
            document.getElementById(`nw`)?.setAttribute('y', top.toFixed(2))
            document.getElementById(`ne`)?.setAttribute('y', top.toFixed(2))

            selectedElement.setAttribute(
              'width',
              (printLocation.width + dx).toFixed(2)
            )
            selectedElement.setAttribute(
              'height',
              (printLocation.height + dy).toFixed(2)
            )
            break

          case 'ne':
            document
              .getElementById(`ne`)
              ?.setAttribute(
                'x',
                (printLocation.x + width - cornerWidth).toFixed(2)
              )
            document
              .getElementById(`se`)
              ?.setAttribute(
                'x',
                (printLocation.x + width - cornerWidth).toFixed(2)
              )

            selectedElement.setAttribute('y', top.toFixed(2))
            document.getElementById(`nw`)?.setAttribute('y', top.toFixed(2))
            document.getElementById(`ne`)?.setAttribute('y', top.toFixed(2))

            selectedElement.setAttribute(
              'width',
              (printLocation.width - dx).toFixed(2)
            )
            selectedElement.setAttribute(
              'height',
              (printLocation.height + dy).toFixed(2)
            )
            break

          case 'sw':
            selectedElement.setAttribute('x', left.toFixed(2))
            document.getElementById(`nw`)?.setAttribute('x', left.toFixed(2))
            document.getElementById(`sw`)?.setAttribute('x', left.toFixed(2))

            document
              .getElementById(`sw`)
              ?.setAttribute(
                'y',
                (printLocation.y + height - cornerWidth).toFixed(2)
              )
            document
              .getElementById(`se`)
              ?.setAttribute(
                'y',
                (printLocation.y + height - cornerWidth).toFixed(2)
              )

            selectedElement.setAttribute(
              'width',
              (printLocation.width + dx).toFixed(2)
            )
            selectedElement.setAttribute(
              'height',
              (printLocation.height - dy).toFixed(2)
            )
            break

          case 'se':
            document
              .getElementById(`ne`)
              ?.setAttribute(
                'x',
                (printLocation.x + width - cornerWidth).toFixed(2)
              )
            document
              .getElementById(`se`)
              ?.setAttribute(
                'x',
                (printLocation.x + width - cornerWidth).toFixed(2)
              )

            document
              .getElementById(`sw`)
              ?.setAttribute(
                'y',
                (printLocation.y + height - cornerWidth).toFixed(2)
              )
            document
              .getElementById(`se`)
              ?.setAttribute(
                'y',
                (printLocation.y + height - cornerWidth).toFixed(2)
              )

            selectedElement.setAttribute(
              'width',
              (printLocation.width - dx).toFixed(2)
            )
            selectedElement.setAttribute(
              'height',
              (printLocation.height - dy).toFixed(2)
            )
            break
        }
      }
    }
  }

  const endDrag = () => {
    if (selectedElement && selectedPrintLocationCoordinateMap) {
      const bBox = selectedElement.getBoundingClientRect()
      const printLocationX = (bBox.x - offset.CTMe) / offset.CTMa
      const printLocationY = (bBox.y - offset.CTMf) / offset.CTMa
      const width = bBox.width / offset.CTMa
      const height = bBox.height / offset.CTMa

      setSelectedPrintLocationCoordinateMap({
        ...selectedPrintLocationCoordinateMap,
        coordinateMap: [
          { x: printLocationX, y: printLocationY },
          { x: printLocationX + width, y: printLocationY },
          { x: printLocationX, y: printLocationY + height },
          { x: printLocationX + width, y: printLocationY + height },
        ],
      })
    }

    setSelectedElement(null)
    setSelectedCorner(null)
  }

  return (
    <>
      <svg
        id="mockup"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={endDrag}
        onMouseLeave={endDrag}
        width={width ? width : '100%'}
        height={height ? height : '550px'}
        viewBox={`0 0 ${boundingBox[3].x} ${boundingBox[3].y}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id={props.view.id}>
            <path d={base} />
          </clipPath>
        </defs>
        <g style={{ clipPath: `url(#${props.view.id}` }}>
          <rect width="100%" height="100%" fill={color} />
          {printLocations.map(
            (
              printLocationCoordinateMap: TPrintLocationCoordinateMap,
              index: number
            ) => {
              const x1 = printLocationCoordinateMap.coordinateMap[0].x
              const x2 = printLocationCoordinateMap.coordinateMap[3].x
              const y1 = printLocationCoordinateMap.coordinateMap[0].y
              const y2 = printLocationCoordinateMap.coordinateMap[3].y

              return selectedPrintLocationCoordinateMap?.id !==
                printLocationCoordinateMap.id &&
                printLocationCoordinateMap.printLocation.name !==
                  SLEEVE_CUSTOMIZATION ? (
                <rect
                  x={x1}
                  y={y1}
                  width={x2 - x1}
                  height={y2 - y1}
                  fill="transparent"
                  style={{ stroke: 'white', strokeWidth: '2px' }}
                />
              ) : (
                <text
                  x={x1}
                  y={y2}
                  fontSize="xxx-large"
                  id={`sleeveText`}
                  style={{ transformBox: 'fill-box' }}
                  transform={`rotate(${printLocationCoordinateMap.rotate})`}
                >
                  Name
                </text>
              )
            }
          )}
          {printLocations.map(
            (
              printLocationCoordinateMap: TPrintLocationCoordinateMap,
              index: number
            ) => (
              <g key={index}>
                {selectedPrintLocationCoordinateMap &&
                selectedPrintLocationCoordinateMap.id ===
                  printLocationCoordinateMap.id &&
                printLocationCoordinateMap.printLocation.name !==
                  SLEEVE_CUSTOMIZATION ? (
                  <>
                    <rect
                      id={`selectedPrintLocation`}
                      className={'draggable'}
                      x={selectedPrintLocationCoordinateMap.coordinateMap[0].x}
                      y={selectedPrintLocationCoordinateMap.coordinateMap[0].y}
                      width={
                        selectedPrintLocationCoordinateMap.coordinateMap[3].x -
                        selectedPrintLocationCoordinateMap.coordinateMap[0].x
                      }
                      height={
                        selectedPrintLocationCoordinateMap.coordinateMap[3].y -
                        selectedPrintLocationCoordinateMap.coordinateMap[0].y
                      }
                      fill="transparent"
                      cursor={'move'}
                      style={{ stroke: 'white', strokeWidth: '2px' }}
                    />
                    <rect
                      id={`nw`}
                      className="resize"
                      width={cornerWidth}
                      height={cornerWidth}
                      fill="black"
                      cursor="nw-resize"
                      x={selectedPrintLocationCoordinateMap.coordinateMap[0].x}
                      y={selectedPrintLocationCoordinateMap.coordinateMap[0].y}
                    />
                    <rect
                      id={`ne`}
                      className="resize"
                      width={cornerWidth}
                      height={cornerWidth}
                      fill="black"
                      cursor="ne-resize"
                      x={
                        selectedPrintLocationCoordinateMap.coordinateMap[3].x -
                        cornerWidth
                      }
                      y={selectedPrintLocationCoordinateMap.coordinateMap[0].y}
                    />
                    <rect
                      id={`sw`}
                      className="resize"
                      width={cornerWidth}
                      height={cornerWidth}
                      fill="black"
                      cursor="sw-resize"
                      x={selectedPrintLocationCoordinateMap.coordinateMap[0].x}
                      y={
                        selectedPrintLocationCoordinateMap.coordinateMap[3].y -
                        cornerWidth
                      }
                    />
                    <rect
                      id={`se`}
                      className="resize"
                      width={cornerWidth}
                      height={cornerWidth}
                      fill="black"
                      cursor="se-resize"
                      x={
                        selectedPrintLocationCoordinateMap.coordinateMap[3].x -
                        cornerWidth
                      }
                      y={
                        selectedPrintLocationCoordinateMap.coordinateMap[3].y -
                        cornerWidth
                      }
                    />
                  </>
                ) : (
                  selectedPrintLocationCoordinateMap && (
                    <>
                      <rect
                        id={`selectedPrintLocation`}
                        className={'draggable'}
                        x={
                          selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        }
                        y={
                          selectedPrintLocationCoordinateMap.coordinateMap[0].y
                        }
                        width={
                          selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .x -
                          selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        }
                        height={
                          selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .y -
                          selectedPrintLocationCoordinateMap.coordinateMap[0].y
                        }
                        data-sleevecustomization="true"
                        fill="transparent"
                        cursor={'move'}
                        style={{ stroke: 'white', strokeWidth: '2px' }}
                      />
                      <text
                        id={`sleeveText`}
                        x={
                          selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        }
                        y={
                          selectedPrintLocationCoordinateMap.coordinateMap[3].y
                        }
                        fontSize="xxx-large"
                        style={{ transformBox: 'fill-box' }}
                        transform={`rotate(${selectedPrintLocationCoordinateMap.rotate})`}
                      >
                        Name
                      </text>
                    </>
                  )
                )}
              </g>
            )
          )}
          {selectedPrintLocationCoordinateMap && (
            <g
              display={
                selectedPrintLocationCoordinateMap.id === '' ? 'block' : 'none'
              }
            >
              {selectedPrintLocationCoordinateMap.printLocation.name !==
              SLEEVE_CUSTOMIZATION ? (
                <>
                  <rect
                    id={`selectedPrintLocation`}
                    className={'draggable'}
                    x={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        : 0
                    }
                    y={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[0].y
                        : 0
                    }
                    width={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .x -
                          selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        : 0
                    }
                    height={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .y -
                          selectedPrintLocationCoordinateMap.coordinateMap[0].y
                        : 0
                    }
                    fill="transparent"
                    cursor={'move'}
                    style={{
                      stroke: 'white',
                      strokeWidth: '2px',
                      display:
                        selectedPrintLocationCoordinateMap &&
                        selectedPrintLocationCoordinateMap.id === ''
                          ? 'block'
                          : 'none',
                    }}
                  />
                  <rect
                    id={`nw`}
                    className="resize"
                    width={cornerWidth}
                    height={cornerWidth}
                    fill="black"
                    cursor="nw-resize"
                    x={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        : 0
                    }
                    y={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[0].y
                        : 0
                    }
                  />
                  <rect
                    id={`ne`}
                    className="resize"
                    width={cornerWidth}
                    height={cornerWidth}
                    fill="black"
                    cursor="ne-resize"
                    x={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .x - cornerWidth
                        : 0
                    }
                    y={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[0].y
                        : 0
                    }
                  />
                  <rect
                    id={`sw`}
                    className="resize"
                    width={cornerWidth}
                    height={cornerWidth}
                    fill="black"
                    cursor="sw-resize"
                    x={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[0].x
                        : 0
                    }
                    y={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .y - cornerWidth
                        : 0
                    }
                  />
                  <rect
                    id={`se`}
                    className="resize"
                    width={cornerWidth}
                    height={cornerWidth}
                    fill="black"
                    cursor="se-resize"
                    x={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .x - cornerWidth
                        : 0
                    }
                    y={
                      selectedPrintLocationCoordinateMap
                        ? selectedPrintLocationCoordinateMap.coordinateMap[3]
                            .y - cornerWidth
                        : 0
                    }
                  />
                </>
              ) : (
                <>
                  <text
                    x={selectedPrintLocationCoordinateMap.coordinateMap[0].x}
                    y={selectedPrintLocationCoordinateMap.coordinateMap[3].y}
                    fontSize="xxx-large"
                    id={`sleeveText`}
                    style={{ transformBox: 'fill-box' }}
                    transform={`rotate(${selectedPrintLocationCoordinateMap.rotate})`}
                  >
                    Name
                  </text>
                  <rect
                    id={`selectedPrintLocation`}
                    className={'draggable'}
                    data-sleevecustomization="true"
                    x={selectedPrintLocationCoordinateMap.coordinateMap[0].x}
                    y={selectedPrintLocationCoordinateMap.coordinateMap[0].y}
                    width={130}
                    height={50}
                    fill="transparent"
                    cursor={'move'}
                    style={{ stroke: 'white', strokeWidth: '2px' }}
                  />
                </>
              )}
            </g>
          )}
          <image
            width={boundingBox[3].x}
            height={boundingBox[3].y}
            href={texture}
            pointerEvents="none"
          />
        </g>
      </svg>
    </>
  )
}

export default Mockup
