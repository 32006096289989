import React from 'react'
import { ITheme } from 'types/Theme'
import { Content, Page } from 'styles/lib/page'
import Navigation from 'components/Navigation'
import { withTheme } from 'styled-components'

import ProductsList from './ProductsList'

interface IProductsPageProps {
  theme: ITheme
}

const ProductsPage: React.FC<IProductsPageProps> = props => {
  const { theme } = props
  return (
    <Page>
      <Navigation />
      <Content padding={'0'}>
        <ProductsList theme={theme} />
      </Content>
    </Page>
  )
}

export default withTheme(ProductsPage)
