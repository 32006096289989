import { gql } from 'apollo-boost'

export default gql`
  mutation reorderAddOnByLocation(
    $addOnByLocationId: ID!
    $productId: ID!
    $up: Boolean!
  ) {
    reorderAddOnByLocation(
      addOnByLocationId: $addOnByLocationId
      productId: $productId
      up: $up
    ) {
      status
    }
  }
`
