import React from 'react'
import CheckoutInput from './CheckoutInput'
import { TAddress } from 'types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Flex } from '@sweaterplanet/nucleus-style'

interface FormProps {
  data: TAddress
  setData: (data: TAddress) => void

  addressIndex: number
  setAddressIndex: (index: number) => void
  step: number
  setSaveBillingAddressLock?: (value: boolean) => void
  setBillingAccountIndex?: (index: number) => void
  setSaveBillingAccount?: (value: boolean) => void
  // setSaveBillingAccountLock?: (value: boolean) => void

  handleNextStep?: () => void
}

const Form: React.FC<FormProps> = props => {
  const {
    data,
    setData,
    addressIndex,
    setAddressIndex,
    step,
    setSaveBillingAddressLock,
    setBillingAccountIndex,
    setSaveBillingAccount,
    // setSaveBillingAccountLock,
    handleNextStep,
  } = props

  const handleOnInputChange = (name: string, value: string) => {
    const addressData = { ...data }

    if (addressIndex !== -2) {
      // Sets Shipping/Billing Address to New Address whenever the person types
      setAddressIndex(-2)
      addressData.id = ''
      if (setSaveBillingAddressLock) setSaveBillingAddressLock(false)

      // Sets the BillingAccount select to None and allows to toggle save BillingAccount
      if (
        step === 1 &&
        setBillingAccountIndex &&
        setSaveBillingAccount
        // && setSaveBillingAccountLock
      ) {
        setBillingAccountIndex(-1)
        setSaveBillingAccount(false)
        // setSaveBillingAccountLock(false)
      }
    }

    setData({ ...addressData, [name]: value })
  }

  const formSubmit = () => {
    if (handleNextStep) handleNextStep()
  }

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      validationSchema={Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        address1: Yup.string().required('Address is required'),
        country: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State/Province is required'),
        postCode: Yup.string().required('Postal Code is required'),
        phone: Yup.string().required('Phone Number is required'),
      })}
      onSubmit={formSubmit}
    >
      {({ values, errors, handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="on">
          <Flex width="100%" flexDirection="column" justifyContent="center">
            <Flex
              width="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <CheckoutInput
                title="First Name"
                name="firstName"
                type="text"
                width="50%"
                value={values.firstName}
                error={errors.firstName}
                autoComplete="given-name"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('firstName', event.target.value)
                  // handleChange(event)
                }}
              />
              <CheckoutInput
                title="Last Name"
                name="lastName"
                type="text"
                width="50%"
                value={values.lastName}
                error={errors.lastName}
                autoComplete="family-name"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('lastName', event.target.value)
                  // handleChange(event)
                }}
              />
            </Flex>
            <Flex width="100%">
              <CheckoutInput
                title="Email"
                name="email"
                type="text"
                width="100%"
                value={values.email}
                autoComplete="email"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('email', event.target.value)
                  // handleChange(event)
                }}
              />
            </Flex>
            <Flex width="100%">
              <CheckoutInput
                title="Address Line 1"
                name="address1"
                type="text"
                width="66.66%"
                value={values.address1}
                error={errors.address1}
                autoComplete="address-line1"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('address1', event.target.value)
                  // handleChange(event)
                }}
              />
              <CheckoutInput
                title="Address Line 2"
                name="address2"
                type="text"
                width="33.33%"
                value={values.address2}
                autoComplete="address-line2"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('address2', event.target.value)
                  // handleChange(event)
                }}
              />
            </Flex>
            <Flex width="100%">
              <CheckoutInput
                title="City"
                name="city"
                type="text"
                width="33.33%"
                value={values.city}
                error={errors.city}
                autoComplete="address-level2"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('city', event.target.value)
                  // handleChange(event)
                }}
              />
              <CheckoutInput
                title="Province"
                name="state"
                type="text"
                width="33.33%"
                isSelect={true}
                isState={true}
                value={values.state}
                error={errors.state}
                autoComplete="address-level1"
                onSelectChange={(
                  event: React.ChangeEvent<HTMLSelectElement>
                ) => {
                  handleOnInputChange('state', event.target.value)
                  // handleChange(event)
                }}
              />
              <CheckoutInput
                title="Country"
                name="country"
                type="text"
                width="33.33%"
                isSelect={true}
                isCountry={true}
                value={values.country}
                error={errors.country}
                autoComplete="country"
                onSelectChange={(
                  event: React.ChangeEvent<HTMLSelectElement>
                ) => {
                  handleOnInputChange('country', event.target.value)
                  // handleChange(event)
                }}
              />
            </Flex>
            <Flex width="100%">
              <CheckoutInput
                title="Postal Code"
                name="postCode"
                type="text"
                width="33.33%"
                value={values.postCode}
                error={errors.postCode}
                autoComplete="postal-code"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('postCode', event.target.value)
                  // handleChange(event)
                }}
              />
              <CheckoutInput
                title="Phone Number"
                name="phone"
                type="tel"
                width="66.66%"
                value={values.phone}
                error={errors.phone}
                autoComplete="tel"
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnInputChange('phone', event.target.value)
                  // handleChange(event)
                }}
              />
            </Flex>
          </Flex>
          <button id={`form${step}`} type="submit" hidden={true}>
            Submit
          </button>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </form>
      )}
    </Formik>
  )
}

export default Form
