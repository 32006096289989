import { gql } from 'apollo-boost'

export default gql`
  mutation createStore(
    $name: String!
    $description: String
    $logo: Upload
    $bannerImg: Upload
    $moodboard: Upload
    $slug: String
    $isPublic: Boolean
  ) {
    createStore(
      name: $name
      description: $description
      logo: $logo
      bannerImg: $bannerImg
      moodboard: $moodboard
      slug: $slug
      isPublic: $isPublic
    ) {
      id
    }
  }
`
