import styled from 'styled-components'
import { NAV_HEIGHT, NAV_WIDTH } from 'constants/styleConfig'

export const Page = styled.div`
  background: ${p => p.theme.colors.page.background};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`

interface IContentProps {
  verticalNav?: boolean
  padding?: string
}

export const Content = styled.div<IContentProps>`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${p => p.theme.fonts.primary};
  color: ${p => p.theme.colors.text.default};
  ${p =>
    p.verticalNav &&
    `
    margin-top: 0;
    margin-left: ${NAV_WIDTH}px;
    width: calc(100vw - ${NAV_WIDTH})
  `};
  ${p => (p.padding ? `padding: ${p.padding} !important;` : null)};
  min-height: calc(100vh - ${NAV_HEIGHT}px - ${p => 50 * 2}px);

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding: 20px 30px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    padding: 20px 40px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    padding: 20px 50px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding: 20px 70px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    padding: 20px 100px;
  }
`
