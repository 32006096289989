import { gql } from 'apollo-boost'

export default gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      auth {
        token
      }
      file {
        id
        filename
        mimetype
        encoding
        url
      }
    }
  }
`
