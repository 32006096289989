import React from 'react'
import { Link } from 'react-router-dom'

export default (props: { fill?: string }) => (
  <Link to={'/'}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 595.36 87.25"
      fill={props.fill}
    >
      <path d="M19.06.75C32.67.75,37.59,7.12,37.59,19v4.91c0,9.31-2.72,15.07-11.92,16.91,9.85,1.84,13.61,8.7,13.61,18.38v7.47c0,12.38-6,19.85-19.83,19.85H0V.75Zm-3,37.86c10.62,0,15.55-2.69,15.55-13.6V19.49c0-8.94-3-13.6-12.7-13.6h-13V38.61Zm3.37,42.77c10,0,13.87-5.27,13.87-14.83V58.83c0-11.15-5.32-15.07-16.08-15.07H6V81.38Z" />
      <path d="M59,21.69C59,9.19,65.08,0,78.81,0c14,0,20.09,9.19,20.09,21.69V65.56c0,12.5-6.09,21.69-20.09,21.69C65.08,87.25,59,78.06,59,65.56Zm6,44.24c0,9.31,3.88,16.17,13.86,16.17S93,75.24,93,65.93V21.32C93,12,89,5.15,78.84,5.15S65,12,65,21.32Z" />
      <path d="M157.36,21.44v1.84h-5.7v-2.2c0-9.07-3.63-15.93-13.48-15.93S124.7,11.89,124.7,21c0,20.83,32.79,21.2,32.79,44.84,0,12.38-5.7,21.45-19.57,21.45s-19.57-9.07-19.57-21.45V61.39h5.7v4.78c0,9.19,3.76,15.81,13.74,15.81s13.74-6.62,13.74-15.81c0-20.58-32.79-21-32.79-44.85,0-12.86,6-21.2,19.31-21.32,13.74,0,19.31,9.07,19.31,21.44" />
      <path d="M215.69,21.44v1.84H210v-2.2c0-9.07-3.63-15.93-13.48-15.93S183,11.89,183,21c0,20.83,32.79,21.2,32.79,44.84,0,12.38-5.7,21.45-19.57,21.45s-19.57-9.07-19.57-21.45V61.39h5.7v4.78c0,9.19,3.76,15.81,13.74,15.81s13.74-6.62,13.74-15.81c0-20.58-32.79-21-32.79-44.85,0-12.86,6-21.2,19.31-21.32,13.74,0,19.31,9.07,19.31,21.44" />
      <polygon points="253.55 53.55 233.2 0.74 239.42 0.74 256.79 45.83 274.16 0.74 279.99 0.74 259.51 53.55 259.51 86.51 253.55 86.51 253.55 53.55" />
      <path d="M341.3,21.93V33.09c0,13.72-7,21.2-21.26,21.2h-6.73V86.51H299.05V.75h21c14.26,0,21.26,7.47,21.26,21.19m-28-8.94V42H320c4.54,0,7-2,7-8.08V21.08C327,15,324.58,13,320,13Z" />
      <path d="M403.39,86.51H389L386.59,71h-17.5l-2.5,15.56h-13.1L368,.75h20.86Zm-32.53-27.2h13.73l-6.87-43.38Z" />
      <polygon points="433.08 24.38 433.08 86.51 420.25 86.51 420.25 0.74 438.13 0.74 452.78 52.08 452.78 0.74 465.48 0.74 465.48 86.51 450.84 86.51 433.08 24.38" />
      <path d="M486.1.75h22.55c14.25,0,21.25,7.47,21.25,21.19V65.33c0,13.72-7,21.19-21.25,21.19H486.1ZM500.35,13V74.27h8c4.53,0,7.25-2.21,7.25-8.33V21.32c0-6.12-2.72-8.33-7.25-8.33Z" />
      <path d="M595.36,86.51H581L578.51,71H561l-2.43,15.56H545.46L560,.75h20.86Zm-32.53-27.2h13.76l-6.87-43.38Z" />
      <rect x="0" y="0" width="100%" height="100%" fill-opacity="0" />
    </svg>
  </Link>
)
