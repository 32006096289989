import React from 'react'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'

import { useMediaQuery } from 'react-responsive'

export default ({
  whitelabelNav,
  whitelabelLogo,
  whitelabelWebsite,
  whitelabelStoreId,
}: {
  whitelabelNav?: boolean
  whitelabelLogo?: string
  whitelabelWebsite?: string
  whitelabelStoreId?: string
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1300px)' })
  return (
    <>
      {isTabletOrMobile ? (
        <MobileNav
          whitelabelNav={whitelabelNav}
          whitelabelLogo={whitelabelLogo}
          whitelabelWebsite={whitelabelWebsite}
          whitelabelStoreId={whitelabelStoreId}
        />
      ) : (
        <DesktopNav
          whitelabelNav={whitelabelNav}
          whitelabelLogo={whitelabelLogo}
          whitelabelWebsite={whitelabelWebsite}
          whitelabelStoreId={whitelabelStoreId}
        />
      )}
    </>
  )
}
