import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

//Styles
import { Box, Card, Flex, Heading, Text } from '@sweaterplanet/nucleus-style'
import { Page } from 'styles/lib/page'
import { Content } from 'styles/lib/page'
import { withTheme } from 'styled-components'

// Routes
import { AppState } from 'store/store'
import { connect } from 'react-redux'
import { UserState } from 'store/reducers/userReducer'
import * as USER_ROUTES from 'routes/user'
import { Redirect } from 'react-router-dom'

// Themes
import { ITheme } from 'types/Theme'

// Components
import SignInForm from 'components/Auth/lib/SignInForm'
import Navigation from 'components/Navigation'
import { Role } from '../../../types'

interface SignInPageProps {
  user: UserState
  theme: ITheme
  location: {
    search: string
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

const SignInPage: React.FC<SignInPageProps> = props => {
  const storeId = useMemo(() => {
    return new URLSearchParams(props.location.search).get('store')
  }, [props.location.search])

  const {
    theme,
    theme: { colors },
  } = props

  return (
    <>
      {!(
        props.user.authenticated &&
        (props.user.credentials.role === Role.USER ||
          props.user.credentials.role === Role.ADMIN)
      ) ? (
        <Page>
          <Navigation
            whitelabelStoreId={storeId || undefined}
            whitelabelNav={!!storeId}
          />
          <Content>
            <Card round bg={colors.card.background}>
              <Box padding="40px 20px">
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  style={{ textAlign: 'center' }}
                >
                  <Heading element="h2" color={colors.text.default}>
                    Sign In
                  </Heading>
                  <Box margin="20px 0">
                    <Text bold color={colors.text.default}>
                      Don't have an account?{' '}
                      <Link to={USER_ROUTES.SIGN_UP}>Create Account</Link>
                    </Text>
                  </Box>
                </Flex>
                <SignInForm theme={theme} storeId={storeId || undefined} />
              </Box>
            </Card>
          </Content>
        </Page>
      ) : (
        <Redirect to={USER_ROUTES.HOME} />
      )}
    </>
  )
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTheme(SignInPage))
