import React, { useState, useEffect } from 'react'
import ProductLink from './ProductLink'

import { TTag } from 'types/Tag'
import { TVariantGroup } from 'types/VariantGroup'
import { TProduct } from 'types/Product'

import Mockup from 'components/Mockup'

import { useMutation } from '@apollo/react-hooks'
import DELETE_PRODUCT from './gql/DELETE_PRODUCT'
import { Error } from 'utils'

import { ApolloQueryResult } from 'apollo-boost'
import {
  Card,
  Flex,
  Tag,
  Heading,
  Button,
  Box,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'

import { ITheme } from 'types/Theme'
import { TDesign } from 'types/Design'
import { TView } from 'types/View'
import { TAddOnByLocation } from 'types'

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

interface IProductCardProps {
  product: TProduct
  isAdmin?: boolean
  refetch?: (
    variables?: { id: string } | undefined
  ) => Promise<ApolloQueryResult<any>>
  theme: ITheme
  color?: { name?: string; value: string }
  handleTagClick?: (tag: TTag) => void
  previewURL?: string
  previewID?: string
  previewAddOnByLocation?: TAddOnByLocation
  showOrderQuantity?: number
  dec?: string
}

const ProductCard: React.FC<IProductCardProps> = props => {
  const {
    product: {
      id,
      name,
      tags,
      basePrice,
      variantGroups,
      inventoryGroup: { views },
      designs,
      isDesignable,
      defaultColor,
      thumbnail,
    },
    isAdmin,
    refetch,
    theme,
    handleTagClick,
    previewURL,
    previewID,
    previewAddOnByLocation,
    dec,
  } = props

  // const [colorIndex, setColorIndex] = useState(-1)
  const [firstColor, setFirstColor] = useState<string>('black')
  const [firstView, setFirstView] = useState<TView | undefined>(undefined)
  const [printCost, setPrintCost] = useState<number>(0)

  useEffect(() => {
    let totalAddOnPrice = 0
    let bulkAddOnPrices: Record<string, number[]> = {}
    let baseAddOnPrice = 0
    let backupDesigns = designs ?? []
    let localDesigns = [...backupDesigns]

    if (
      previewURL &&
      props.product.isDesignable &&
      props.product.addOnsByLocation[0].addOn
    ) {
      localDesigns.push({
        addOn:
          previewAddOnByLocation?.addOn ||
          props.product.addOnsByLocation[0].addOn,
      } as TDesign)
    }

    if (localDesigns) {
      localDesigns.forEach((design: TDesign, index) => {
        let bulkAddOnPrice = design.addOn?.basePrice || 0
        if (design.addOn?.priceBreaks) {
          console.log('Price Breaks', design.addOn.priceBreaks)
          for (const x of Object.keys(design.addOn.priceBreaks)) {
            if (1 >= parseInt(x)) {
              bulkAddOnPrice = design.addOn.priceBreaks[x][index]
            }

            bulkAddOnPrices[x] =
              design.addOn?.priceBreaks && design.addOn.priceBreaks[x]
                ? design.addOn.priceBreaks[x]
                : []
          }

          totalAddOnPrice += bulkAddOnPrice
          baseAddOnPrice += bulkAddOnPrices[1]
            ? bulkAddOnPrices[1][index]
            : design.addOn.basePrice

          console.log(
            'bulk add on price for quantity',
            1,
            'at design',
            index,
            'is',
            bulkAddOnPrice,
            'making the total add on price',
            totalAddOnPrice
          )
        }
        // totalAddOnPrice += design.addOn.basePrice
      })
      setPrintCost(totalAddOnPrice || baseAddOnPrice)
    }
  }, [
    designs,
    previewURL,
    props.product.addOnsByLocation,
    props.product.isDesignable,
    previewAddOnByLocation,
  ])

  useEffect(() => {
    const colorIndex = variantGroups.findIndex(
      (variantGroup: TVariantGroup) => variantGroup.name === 'Color'
    )
    const color =
      colorIndex !== -1 ? variantGroups[colorIndex].variants[0].value : 'black'

    // setColorIndex(colorIndex)
    setFirstColor(color)

    const view = views.length > 0 ? views[0] : undefined
    setFirstView(view)

    if (previewURL) {
      const previewView = views
        .flatMap(v => ({
          view: v,
          pLs: v.printLocations.flatMap(pL => pL.printLocation.id),
        }))
        .find(vA =>
          vA.pLs.includes(
            previewAddOnByLocation?.location.id ||
              props.product.addOnsByLocation[0].location.id
          )
        )?.view

      if (previewView) {
        setFirstView(previewView)
      }
    }
  }, [
    variantGroups,
    views,
    previewURL,
    props.product.addOnsByLocation,
    previewAddOnByLocation,
  ])

  const [selectedProductColor, setSelectedProductColor] = useState<
    any | undefined
  >(undefined)

  useEffect(() => {
    if (props.color) setSelectedProductColor(props.color)
  }, [props.color])

  const [deleteProduct, { error }] = useMutation(DELETE_PRODUCT, {
    variables: { id: id },
  })

  const handleDeleteClick = async () => {
    await deleteProduct()
    if (refetch) await refetch()
  }

  return (
    <>
      <Card
        round
        bg={theme.colors.card.background}
        width="300px"
        padding="20px"
      >
        {props.showOrderQuantity ? (
          <div style={{ margin: '-40px -40px 10px 0' }}>
            <Flex width="100%" justifyContent="flex-end">
              <div
                style={{
                  background: theme.colors.button.background,
                  borderRadius: '20px',
                  padding: '5px',
                  color: 'white',
                  minWidth: '16px',
                  textAlign: 'center',
                }}
              >
                {props.showOrderQuantity}
              </div>
            </Flex>
          </div>
        ) : (
          <> </>
        )}
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box margin="0 0 10px 0">
              <ProductLink
                color={selectedProductColor}
                previewID={previewID}
                previewAddOnByLocationId={previewAddOnByLocation?.id}
                productID={id}
                isAdmin={!!isAdmin}
                dec={dec}
              >
                <Heading element="h3" color={theme.colors.text.default}>
                  {name}
                </Heading>
              </ProductLink>
            </Box>
            {isAdmin && (
              <Button
                bg="red"
                size="xs"
                color="white"
                onClick={() => handleDeleteClick()}
              >
                Delete
              </Button>
            )}
            {error && <Error message={error.message} />}
          </Flex>
          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
          >
            {tags.map(
              (tag: TTag, index: number) =>
                tag.id !== 'featured' && (
                  <Box margin="5px 5px 0 0" key={index}>
                    <Tag
                      key={tag.id}
                      onClick={() =>
                        handleTagClick ? handleTagClick(tag) : null
                      }
                      button
                      size="xs"
                      color={tag.color}
                      text={`${tag.icon ? tag.icon : ''} ${tag.content}`}
                    />
                  </Box>
                )
            )}
          </Flex>
        </Flex>
        <ProductLink
          color={selectedProductColor}
          previewID={previewID}
          previewAddOnByLocationId={previewAddOnByLocation?.id}
          productID={id}
          isAdmin={!!isAdmin}
          dec={dec}
        >
          {firstView ? (
            <Box margin={'10px'}>
              {thumbnail && !previewURL && !selectedProductColor ? (
                <img src={thumbnail.url} alt={'thumbnail'} width="100%" />
              ) : (
                <Mockup
                  view={firstView}
                  color={
                    selectedProductColor
                      ? selectedProductColor.value
                      : defaultColor
                      ? defaultColor
                      : firstColor
                  }
                  designs={designs}
                  previewURL={isDesignable ? previewURL : ''}
                  addOnsByLocation={props.product.addOnsByLocation}
                  previewAddOnByLocation={previewAddOnByLocation}
                />
              )}
            </Box>
          ) : (
            <img
              width={'100%'}
              src="https://firebasestorage.googleapis.com/v0/b/tsg-nucleus.appspot.com/o/textures%2Fhoodie_back.png?alt=media&token=c3318278-716f-4480-8f48-bf1c698b14f5"
              alt={props.product.name}
            />
          )}
        </ProductLink>
        {/*{colorIndex !== -1 && (*/}
        {/*  <Box margin="20px 0">*/}
        {/*    <ColorPicker*/}
        {/*      colors={variantGroups[colorIndex].variants}*/}
        {/*      setColor={setSelectedProductColor}*/}
        {/*      width={'24px'}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*)}*/}
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading element="h4" color={theme.colors.text.default}>
            ${formatter.format(basePrice + printCost)}
          </Heading>
          <ProductLink
            previewID={previewID}
            previewAddOnByLocationId={previewAddOnByLocation?.id}
            color={selectedProductColor}
            productID={id}
            isAdmin={!!isAdmin}
            dec={dec}
          >
            <Button size="xs" bg="green" color="white">
              {isDesignable ? 'Customize' : 'View'}
            </Button>
          </ProductLink>
        </Flex>
      </Card>
    </>
  )
}

export default withTheme(ProductCard)
