import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { SignUp, SignIn } from 'components/Auth'
import { Account } from 'components/Account'
import HomePage from 'pages/home'
import Admin from 'components/Admin'
import { ProductsPage, ProductPage } from 'components/Product'
import { DesignStudioPage } from 'components/Design'
import { CheckoutPage, ConfirmationPage } from 'components/Checkout'
import CatalogPage from 'pages/catalog'
import PasswordReset from '../../Auth/lib/PasswordReset'

import * as USER_ROUTES from 'routes/user'
import * as ADMIN_ROUTES from 'routes/admin'

import CartPage from 'components/Cart'
import { ThemeProvider } from 'styled-components'
import { DarkTheme, GlobalStyle, LightTheme } from 'styles'
import { AppState, persistor } from 'store/store'
import { connect } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Loader } from 'utils'
import StorePage from 'components/Store/lib/StorePage'

import ScrollToTop from './ScrollToTop'
import LandingPage from 'components/Promotion/lib/LandingPage'
// import AboutPage from 'components/About'
import TermsPage from 'components/Terms'
import GalleryPage from '../../Gallery'
import CollectionsPage from 'components/Store/lib/CollectionsPage'
import StoreManagerPage from 'components/Store/lib/StoreManagerPage'
import AboutPage from 'components/About'
import ContactForm from 'components/ContactForm'
import ContactPage from 'components/Contact'

function AppBase(props: any) {
  return (
    <ThemeProvider theme={props.theme.darkMode ? DarkTheme : LightTheme}>
      {/* @ts-ignore */}
      <PersistGate loading={<Loader />} persistor={persistor}>
        <Helmet>
          <title>Bossy Panda</title>
          <meta
            name="description"
            content="Custom Garment Prints. Come visit us, our in-house design team will work with you to produce a completely authentic custom design."
          />
        </Helmet>
        <ScrollToTop>
          <GlobalStyle />
          <Route exact path={USER_ROUTES.HOME} component={HomePage} />
          <Route path={USER_ROUTES.SIGN_UP} component={SignUp} />
          <Route path={USER_ROUTES.SIGN_IN} component={SignIn} />
          <Route path={USER_ROUTES.PASSWORD_RESET} component={PasswordReset} />
          <Route path={USER_ROUTES.ACCOUNT} component={Account} />
          <Route path={USER_ROUTES.CART} component={CartPage} />
          <Route path={USER_ROUTES.CATALOG} component={CatalogPage} />
          <Route path={ADMIN_ROUTES.ADMIN} component={Admin} />
          <Route path={USER_ROUTES.ABOUT} component={AboutPage} />
          <Route path={USER_ROUTES.CONTACT} component={ContactPage} />
          <Route
            path={`${USER_ROUTES.PRODUCTS}/:productQuery?`}
            component={ProductsPage}
          />
          <Route path={`${USER_ROUTES.GALLERY}`} component={GalleryPage} />
          <Route
            exact
            path={`${USER_ROUTES.PRODUCT}/:productId`}
            component={ProductPage}
          />
          <Route
            exact
            path={`${USER_ROUTES.PRODUCT}/:productId/design`}
            component={DesignStudioPage}
          />
          <Route path={USER_ROUTES.CHECKOUT} component={CheckoutPage} />
          <Route
            path={`${USER_ROUTES.CONFIRMATION}/:orderId`}
            component={ConfirmationPage}
          />
          <Route path={`${USER_ROUTES.STORE}/:storeId`} component={StorePage} />
          <Route
            path={`${USER_ROUTES.STORE_MANAGER}/:storeId`}
            component={StoreManagerPage}
          />
          <Route
            path={`${USER_ROUTES.PROMOTION}/:promotionId`}
            component={LandingPage}
          />
          {/*<Route path={`${USER_ROUTES.ABOUT}`} component={AboutPage} />*/}
          <Route path={`${USER_ROUTES.TERMS}`} component={TermsPage} />
          <Route
            path={`${USER_ROUTES.COLLECTIONS}`}
            component={CollectionsPage}
          />
        </ScrollToTop>
      </PersistGate>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: AppState) => ({
  theme: state.theme,
})

const App = connect(mapStateToProps)(AppBase)

export default App
