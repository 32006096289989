import { gql } from 'apollo-boost'

export default gql`
  query getAddOn($id: ID!) {
    getAddOn(id: $id) {
      id
      name
      publicName
      description
      updatedAt
      tags {
        id
        color
        icon
        content
      }
      available
      basePrice
      priceBreaks
    }
  }
`
