import React, { FC, useEffect, useState } from 'react'
import { TVariant } from '../../../types'

import { Flex } from '@sweaterplanet/nucleus-style'
import styled from 'styled-components'

interface ISizeInputWrapperProps {
  sizeIndex: number
  size: TVariant
  index: number
  darkMode: boolean
  variantSelections: Record<
    string,
    Record<string, { quantity: number; customizations: { name: string }[] }>
  >[]
  productColors: TVariant[]
  selectedColorIndex: number
  handleQuantityChange: (arg0: any, arg1: any, arg2: any, arg3: any) => void
}

const theme = {
  fonts: {
    body: "'Helvetica', -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', sans-serif",
  },
  colors: {
    paper: {
      light: '#F1F2F4',
      base: '#E2E6E9',
      dark: '#C5CDD3',
    },
    slate: {
      light: '#36414A',
      base: '#21272C',
      dark: '#0F1215',
    },
  },
}
const Wrapper = styled(Flex)`
  float: left;
  margin-right: 10px;
`

const InputBox = styled(Flex)`
  width: min-content;
  height: 36px;
  background-color: ${props =>
    props.dark ? `${theme.colors.slate.light}` : `${theme.colors.paper.dark}`};
  margin: 0 auto;
  position: relative;
  flex-direction: row;
  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
      background-color: ${(props: { dark: boolean }) =>
        props.dark
          ? `${theme.colors.slate.base}`
          : `${theme.colors.paper.base}`};
    `}
`

const Label = styled.p<{ dark: boolean; disabled: boolean }>`
  font-family: ${theme.fonts.body};
  font-size: 10px;
  font-weight: bold;
  padding: 3px;
  padding-left: 12px;
  color: ${theme.colors.slate.base};
  text-align: left;
  text-transform: uppercase;
  ${(props: { dark: boolean }) =>
    props.dark &&
    `
     color: ${theme.colors.paper.base};
  `}
  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
     opacity: 50%;
  `}
`

const Input = styled.input`
  font-family: ${theme.fonts.body};
  font-size: 16px;
  font-weight: bold;
  border: 0;
  outline: 0;
  background: transparent;
  text-align: center;
  color: ${theme.colors.slate.base};
  border-bottom: 1px solid ${theme.colors.slate.base};
  width: 20px;
  height: 16px;
  margin: 8px;
  margin-right: 8px;
  :disabled {
    opacity: 50%;
    pointer-events: none;
  }
  /* Hide number input errors */
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Placeholder styles */
  ::placeholder {
    color: ${theme.colors.slate.base};
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
  :focus::placeholder {
    color: transparent;
  }
  ::-webkit-input-placeholder {
    color: ${theme.colors.slate.base};
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
  :-ms-input-placeholder {
    color: ${theme.colors.slate.base};
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
  ${(props: { dark: boolean }) =>
    props.dark &&
    `
    color: ${theme.colors.paper.base};
    border-bottom: 1px solid ${theme.colors.paper.base};
    ::placeholder {
      color: ${theme.colors.paper.base};
    }
  `}
`

const SizeInputWrapper: FC<ISizeInputWrapperProps> = props => {
  const [val, setVal] = useState<number | ''>('')

  useEffect(() => {
    const q =
      props.variantSelections[props.index][
        `${props.productColors[props.selectedColorIndex].id}/${
          props.productColors[props.selectedColorIndex].name
        }/${props.productColors[props.selectedColorIndex].value}`
      ][`${props.size.id}/${props.size.name}/${props.size.value}`].quantity
    // setVal(q)
    if (q === 0) {
      setVal('')
    } else {
      setVal(q)
    }
  }, [props])

  const handleChange = (event: any) => {
    props.handleQuantityChange(
      event,
      props.index,
      props.productColors[props.selectedColorIndex],
      props.size
    )
    if (val === 0) {
      setVal('')
    }
  }

  return (
    <>
      <Wrapper flexDirection="column">
        <InputBox dark={props.darkMode}>
          <Label dark={props.darkMode} disabled={false}>
            {props.size.name}
          </Label>
          <Input
            dark={props.darkMode}
            value={val}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event)
            }
            type="number"
            placeholder={'0'}
          />
        </InputBox>
      </Wrapper>
    </>
  )
}
export default SizeInputWrapper
