import styled from 'styled-components'
import { Spacing, spacing, NAV_HEIGHT } from 'constants/styleConfig'

type direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type justify =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
type align = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
type wrap = 'wrap' | 'wrap-reverse'
type display = 'inline' | 'block' | 'none'
type position = 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky'

interface IFlexboxProps {
  direction?: direction
  justify?: justify
  align?: align
  wrap?: wrap
  even?: boolean
}

export const flex = styled.div<IFlexboxProps>`
  display: flex;
  flex-direction: ${p => p.direction || 'row'};
  justify-content: ${p => p.justify || 'space-between'};
  align-items: ${p => p.align || 'center'};
  flex-wrap: ${p => p.wrap || 'nowrap'};
  div {
    ${p => (p.even ? 'flex-grow: 1; flex-basis: 0;' : 'flex-grow: 0')};
  }
  //flex: 1 1 auto
`

interface IFixedDimensions {
  minW?: string
  maxW?: string
  minH?: string
  maxH?: string
}

export const fixedDimensions = styled.div<IFixedDimensions>`
  ${p => (p.maxW ? `max-width: ${p.maxW}` : null)};
  ${p => (p.minW ? `min-width: ${p.minW}` : null)};
  ${p => (p.maxW ? `max-width: ${p.maxW}` : null)};
  ${p => (p.minH ? `min-height: ${p.minH}` : null)};
`

interface IPadProps {
  v: Spacing | string
  h: Spacing | string
}

export const pad = styled.div<IPadProps>`
  padding: ${p => spacing[p.v] || p.v} ${p => spacing[p.h] || p.h};
`

interface ICardProps {
  background: string
  rounded?: boolean
}

export const card = styled.div<ICardProps>`
  border-radius: ${p => (p.rounded === false ? 'unset' : '20px')};
  background: ${p => p.background};
  max-width: 2000px;
`

interface IDivProps {
  display?: display
  position?: position
  top?: string
  bottom?: string
  left?: string
  right?: string
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  border?: string
  margin?: string
}

export const iv = styled.div<IDivProps>`
  ${p => (p.display ? `display: ${p.display};` : null)}
  ${p => (p.position ? `position: ${p.position};'` : null)}
  ${p => (p.top ? `top: ${p.top};` : null)}
  ${p => (p.bottom ? `bottom: ${p.bottom};` : null)}
  ${p => (p.left ? `left: ${p.left};` : null)}
  ${p => (p.right ? `right: ${p.right};` : null)}
  ${p => (p.width ? `width: ${p.width};` : null)}
  ${p => (p.maxWidth ? `max-width: ${p.maxWidth};` : null)}
  ${p => (p.height ? `height: ${p.height};` : null)}
    ${p => (p.maxHeight ? `max-height: ${p.maxHeight};` : null)}

  ${p => (p.border ? `border: ${p.border};` : null)}
  ${p => (p.margin ? `margin: ${p.margin};` : null)}
`

interface IGridContainerProps {
  width?: string
  height?: string
  margin?: string
  direction?: direction

  v?: Spacing | string
  h?: Spacing | string
  maxW?: string
}

export const gridContainer = styled.div<IGridContainerProps>`
  color: ${p => p.theme.colors.table.text};
  width: ${p => p.width || '100%'};
  min-height: ${p => p.height || `calc(100vh - ${NAV_HEIGHT}px)`};
  margin: ${p => p.margin || 'unset'};
  flex-direction: ${p => p.direction || 'row'};
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-grow: 0;
  padding: ${p => spacing[p.v ? p.v : 'none'] || p.v}
    ${p => spacing[p.h ? p.h : 'none'] || p.h};
  ${p => (p.maxW ? `max-Width: ${p.maxW}` : null)};
  ${p => (p.maxW ? `flex-basis: ${p.maxW}` : null)};
`

interface IGridProps {
  v?: Spacing | string
  h?: Spacing | string
  maxW?: string

  threshold?: string
  width?: string
}

export const gridItem = styled.div<IGridProps>`
  color: ${p => p.theme.colors.table.text};
  padding: ${p => spacing[p.v ? p.v : 'none'] || p.v}
    ${p => spacing[p.h ? p.h : 'none'] || p.h};
  max-width: ${p => p.maxW || '100%'};
  flex-basis: ${p => p.maxW || '100%'};
  margin: 0px;
  box-sizing: border-box;
  display: block;
  flex-grow: 0;
  ${p =>
    p.threshold && p.width
      ? `@media (max-width: ${p.threshold}) {
      flex-basis: 100%;
      margin: auto;
      max-width: ${p.width};
  }`
      : null}
`
