import styled from 'styled-components'
import { Spacing, spacing, NAV_HEIGHT } from 'constants/styleConfig'

type direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type justify =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
type align = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
type wrap = 'wrap' | 'wrap-reverse'

interface IFlexProps {
  direction?: direction
  justify?: justify
  align?: align
  wrap?: boolean
  even?: boolean
  width?: string
  padding?: string
  reverse?: boolean
}

export const grid = styled.div``

export const row = styled.div<IFlexProps>`
  display: flex;
  flex-direction: ${p => (p.reverse ? 'row-reverse' : 'row')};
  justify-content: ${p => p.justify || 'space-between'};
  align-items: ${p => p.align || 'center'};
  flex-wrap: ${p => (p.wrap ? 'wrap' : 'nowrap')};
  div {
    ${p =>
      p.even
        ? 'flex-grow: 1; flex-basis: 0;'
        : 'flex-grow: 0; flex-basis: auto;'};
  }
  width: ${p => (p.width ? p.width : 'unset')};
  padding: ${p => (p.padding ? p.padding : 'unset')};
`

export const column = styled.div<IFlexProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${p => p.justify || 'flex-start'};
  align-items: ${p => p.align || 'center'};
  flex-wrap: ${p => (p.wrap ? 'wrap' : 'nowrap')};
  width: ${p => (p.width ? p.width : 'unset')};
  ${p => (p.padding ? `padding: ${p.padding} !important;` : null)}

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding: ${p => p.theme.sizing.xs.padding / 2}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    padding: ${p => p.theme.sizing.sm.padding / 2}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    padding: ${p => p.theme.sizing.md.padding / 2}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding: ${p => p.theme.sizing.lg.padding / 2}px;
  }
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    padding: ${p => p.theme.sizing.xl.padding / 2}px;
  }
`

interface IGridContainerProps {
  width?: string
  height?: string
  margin?: string
  direction?: direction
  justify?: justify
  align?: align

  v?: Spacing | string
  h?: Spacing | string
  maxW?: string
}

export const container = styled.div<IGridContainerProps>`
  width: ${p => p.width || '100%'};
  min-height: ${p => p.height || `calc(100vh - ${NAV_HEIGHT}px)`};
  margin: ${p => p.margin || 'unset'};
  flex-direction: ${p => p.direction || 'row'};
  display: flex;
  justify-content: ${p => p.justify || 'flex-start'};
  align-items: ${p => p.align || 'flex-start'};
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-grow: 0;
  padding: ${p => spacing[p.v ? p.v : 'none'] || p.v}
    ${p => spacing[p.h ? p.h : 'none'] || p.h};
  ${p => (p.maxW ? `max-Width: ${p.maxW}` : null)};
  ${p => (p.maxW ? `flex-basis: ${p.maxW}` : null)};
`

interface IItemProps {
  v?: Spacing | string
  h?: Spacing | string
  maxW?: string
  minW?: string
}

export const item = styled.div<IItemProps>`
  padding: ${p => spacing[p.v ? p.v : 'none'] || p.v}
    ${p => spacing[p.h ? p.h : 'none'] || p.h};
  max-width: ${p => p.maxW || '100%'};
  flex-basis: ${p => p.maxW || '100%'};
  margin: 0px;
  box-sizing: border-box;
  display: block;
  flex-grow: 0;
  min-width: ${p => p.minW || 'unset'};
`
