import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

// types
import { TProduct } from 'types/Product'
import { ITheme } from 'types/Theme'

// Components
import { Loader, Error } from 'utils'
import ProductCard from 'components/Product/lib/ProductCard'

// Routes
import * as USER_ROUTES from 'routes/user'

// Styles
import { Box, Button, Flex, Heading, Text } from '@sweaterplanet/nucleus-style'

// GQL
import GET_SOME_AVAILABLE_PRODUCTS from '../gql/GET_SOME_AVAILABLE_PRODUCTS'

interface IProductsPreviewProps {
  theme: ITheme
  darkMode: boolean
  columns?: number
  title?: string
  description?: string
  img?: string
  history: any
}

const TextBox = styled(Flex)`
  flex-direction: column;
  text-align: right;
  float: right;
  width: 80%;

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    padding-top: 2rem;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    padding-top: 1rem;
  }
`

const DescriptionBox = styled(Box)`
  margin-bottom: 2rem;

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    width: 100%;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    margin-left: 30%;
    width: 70%;
  }
`

const ProductsPreview: React.FC<IProductsPreviewProps> = props => {
  const {
    darkMode,
    theme: { colors },
    history,
  } = props

  const [products, setProducts] = useState<TProduct[]>([])
  const { data, loading, refetch } = useQuery(GET_SOME_AVAILABLE_PRODUCTS, {
    variables: {
      limit: 3,
      withStores: false,
    },
  })

  useEffect(() => {
    refetch().catch(error => {
      return <Error message={error.message} />
    })
  })

  useEffect(() => {
    if (data) {
      setProducts(data.getSomeAvailableProducts)
    }
  }, [data])

  if (products) {
    return (
      <Flex
        width="100%"
        minHeight={`100vh`}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <TextBox>
          <Box width="100%" marginBottom="1rem">
            <Heading element="h2" color={colors.text.default}>
              {props.title}
            </Heading>
          </Box>
          <DescriptionBox>
            <Text color={colors.text.default}>{props.description}</Text>
          </DescriptionBox>
        </TextBox>
        <Flex
          width="100%"
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
        >
          {loading && <Loader />}
          {products.map(
            (product: TProduct, index: number) =>
              product.available && (
                <Box margin="1rem" key={index}>
                  <ProductCard product={product} />
                </Box>
              )
          )}
        </Flex>
        <Button
          onClick={() => history.push(USER_ROUTES.PRODUCTS)}
          bg={colors.text.default}
          color={darkMode ? 'slate' : 'white'}
        >
          See more
        </Button>
      </Flex>
    )
  }

  return (
    <Flex
      width="100%"
      minHeight={`100vh`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <TextBox>
        <Box width="100%" marginBottom="1rem">
          <Heading element="h2" color={colors.text.default}>
            {props.title}
          </Heading>
        </Box>
        <DescriptionBox>
          <Text color={colors.text.default}>{props.description}</Text>
        </DescriptionBox>
      </TextBox>
      <Button
        onClick={() => history.push(USER_ROUTES.PRODUCTS)}
        bg={colors.text.default}
        color={darkMode ? 'slate' : 'white'}
      >
        See more
      </Button>
    </Flex>
  )
}

export default ProductsPreview
