import React from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom'
import { withTheme } from 'styled-components'

// Store
import { AppState } from 'store/store'
import { UserState } from 'store/reducers/userReducer'

// Types
import { ITheme } from 'types/Theme'

interface CatalogProps {
  user: UserState
  darkMode: boolean
  theme: ITheme
}

/**
 * The catalog PDF should always be named "catalog.pdf" and replace the old catalog in the /public folder so clients can always return to the same URL.
 */

const CatalogPage: React.FC<CatalogProps & RouteComponentProps> = () => {
  const history = useHistory()

  function renderRedirect() {
    history.push('/catalog.pdf')
    history.go(0)
  }

  return (
    <>
      <Redirect to="/catalog.pdf" />
      {renderRedirect()}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withRouter(withTheme(CatalogPage)))
