import { gql } from 'apollo-boost'

export default gql`
  mutation upsertTagByContent(
    $content: String!
    $color: String
    $icon: String
  ) {
    upsertTagByContent(content: $content, color: $color, icon: $icon) {
      id
    }
  }
`
