import React from 'react'

import { gql } from 'apollo-boost'

import PrintLocationCreateForm from './PrintLocationCreateForm'
import { DataTable } from '../index'

import DELETE_PRINT_LOCATION from 'gql/DELETE_PRINT_LOCATION'
import { ADMIN_PRINT_LOCATIONS } from 'routes/admin'

const GET_PRINT_LOCATIONS = gql`
  query getSomePrintLocations($searches: [String!], $offset: Int, $limit: Int) {
    getSomePrintLocations(searches: $searches, offset: $offset, limit: $limit) {
      id
      available
      name
      width
      height
      price
    }
  }
`

interface IPrintLocationTable {}

const PrintLocationTable: React.FC<IPrintLocationTable> = () => {
  return (
    <DataTable
      getDataQuery={GET_PRINT_LOCATIONS}
      getDataQueryName="getSomePrintLocations"
      deleteDataQuery={DELETE_PRINT_LOCATION}
      headers={['Name', 'Available', 'Price', 'Width (in)', 'Height (in)']}
      properties={['name', 'available', 'price', 'width', 'height']}
      ROUTE={ADMIN_PRINT_LOCATIONS}
      title="Decoration Locations"
    >
      {createFormProps => <PrintLocationCreateForm {...createFormProps} />}
    </DataTable>
  )
}
export default PrintLocationTable
