import { gql } from 'apollo-boost'

export default gql`
  mutation createAddOn(
    $name: String!
    $description: String
    $basePrice: Float!
    $priceBreaks: JSONObject
    $available: Boolean!
  ) {
    createAddOn(
      name: $name
      description: $description
      basePrice: $basePrice
      priceBreaks: $priceBreaks
      available: $available
    ) {
      id
    }
  }
`
