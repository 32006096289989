import React, { useState, useEffect } from 'react'
import Navigation from 'components/Navigation'
import { PageLayout as Page } from 'styles'
import {
  Flex,
  Tag,
  Heading,
  Button,
  Box,
  Image,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from '../../../types'
import { Error, Loader } from 'utils'
import { useQuery } from '@apollo/react-hooks'
import GET_PROMOTION from './gql/GET_PROMOTION'
import { TProduct } from 'types'
import ProductCard from 'components/Product/lib/ProductCard'
import CopyToClipboard from 'react-copy-to-clipboard'
import Promotion from './Promotion'

interface LandingPageProps {
  match: {
    params: {
      promotionId: string
    }
  }
  theme: ITheme
}

const LandingPage: React.FC<LandingPageProps> = props => {
  const {
    match: {
      params: { promotionId },
    },
    theme: { colors },
  } = props

  const [isPromotionOpen, setIsPromotionOpen] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_PROMOTION, {
    variables: {
      id: promotionId,
    },
  })

  useEffect(() => {
    refetch().catch(error => {
      return <Error message={error.message} />
    })
  })

  useEffect(() => {
    if (data && data.getPromotion.coupon) {
      setIsPromotionOpen(true)
    }
  }, [data])

  return (
    <Page.Page>
      {isPromotionOpen && (
        <Promotion
          coupon={data.getPromotion.coupon}
          couponDescription={data.getPromotion.couponDescription}
          onClose={() => setIsPromotionOpen(false)}
        />
      )}
      <Navigation />
      <Page.Content>
        {error && <Error message={error.message} />}
        {loading && <Loader />}
        {data && (
          <Flex
            width="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {data.getPromotion.bannerImg && (
              <Box marginBottom="5rem" maxWidth="600px">
                <Image width="100%" src={data.getPromotion.bannerImg.url} />
              </Box>
            )}
            <Flex flexDirection="column">
              <Heading element="h1" color={colors.text.default}>
                {data.getPromotion.name}
              </Heading>
              <Box margin="30px 0">
                <Heading element="h3" color={colors.text.default}>
                  {data.getPromotion.description}
                </Heading>
              </Box>
              {data.getPromotion.coupon && (
                <Box marginBottom="5rem">
                  <CopyToClipboard text={data.getPromotion.coupon.id}>
                    <Button maxWidth bg="green" color="white">
                      Copy Coupon
                    </Button>
                  </CopyToClipboard>
                </Box>
              )}
            </Flex>
          </Flex>
        )}
        <Flex
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap={'wrap'}
        >
          <Box margin="1rem">
            <Heading element="h1" color={colors.text.default}>
              Trending Products
            </Heading>
          </Box>
          <Box margin="1rem">
            <Tag color="red" text="📈 Trending" />
          </Box>
        </Flex>
        <Flex
          marginTop="50px"
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
        >
          {loading && <Loader />}
          {data &&
            data.getPromotion.products.map(
              (product: TProduct, index: number) =>
                product.available && (
                  <Box margin="1rem" key={index}>
                    <ProductCard product={product} />
                  </Box>
                )
            )}
        </Flex>
      </Page.Content>
    </Page.Page>
  )
}

export default withTheme(LandingPage)
