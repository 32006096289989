import React from 'react'
import { ITheme } from '../../../types'
import { Heading, Box } from '@sweaterplanet/nucleus-style'

interface IOrderNotesProps {
  setOrderNotes: (arg0: string) => void
  theme: ITheme
}

const OrderNotes: React.FC<IOrderNotesProps> = ({
  setOrderNotes,
  theme: { colors },
}) => {
  const handleOrderNotesChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOrderNotes(e.target.value)
  }

  return (
    <Box marginLeft="10px" marginRight="10px" marginTop={'20px'}>
      <Box paddingBottom="10px">
        <Heading element="h4" color={colors.text.default}>
          Order Notes
        </Heading>
      </Box>
      <textarea
        style={{ width: '100%', minHeight: '200px' }}
        onChange={handleOrderNotesChange}
      ></textarea>
    </Box>
  )
}

export default OrderNotes
