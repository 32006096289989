import { SET_LIGHT_MODE, SET_DARK_MODE, AppActions } from 'types/actions'
import { Dispatch } from 'redux'

export const setDarkMode = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_DARK_MODE })
}

export const setLightMode = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_LIGHT_MODE })
}
