import React from 'react'
import { gql } from 'apollo-boost'
import * as ADMIN_ROUTES from '../../../routes/admin'
import DataTable from './DataTable'

const GET_ALL_USERS = gql`
  query getAllUsers($searches: [String!], $offset: Int, $limit: Int) {
    getAllUsers(searches: $searches, offset: $offset, limit: $limit) {
      name
      id
      email
      role
      googleId
    }
  }
`

const UserManagementPage = () => {
  return (
    <DataTable
      getDataQuery={GET_ALL_USERS}
      getDataQueryName="getAllUsers"
      headers={['Name', 'Email', 'Role', 'Connected to Google']}
      properties={['name', 'email', 'role', 'googleId']}
      ROUTE={ADMIN_ROUTES.ADMIN_USERS}
      title="Users"
    />
  )
}

export default UserManagementPage
