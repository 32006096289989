import React from 'react'
import { Button } from '@sweaterplanet/nucleus-style'
import { ITheme } from 'types'

interface IContactFormProps {
  darkMode: boolean
  theme: ITheme
  email?: string
}

// TODO: NEEDS EMAIL LOGIC TO ENABLE FORM

// const ContactForm: React.FC<IContactFormProps> = (props) => {
//     const [subject, setSubject] = useState('')
//     const [name, setName] = useState('')
//     const [email, setEmail] = useState('')
//     const [message, setMessage] = useState('')
//
//     const {
//         theme: {colors},
//     } = props;
//
//     return (
//         <Card
//             round
//             bg={colors.card.background}
//             width={'100%'}
//         >
//             <Flex flexDirection="column" width={'100%'}>
//                 <Box>
//                     <Input
//                         type="text"
//                         value={subject}
//                         placeholder="Subject"
//                         onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
//                             setSubject(event.target.value)
//                         }
//                     />
//                 </Box>
//                 <Flex alignItems="center">
//                     <Box>
//                         <Input
//                             type="text"
//                             value={name}
//                             placeholder="Name"
//                             onChange={(
//                                 event: React.ChangeEvent<HTMLInputElement>
//                             ) => setName(event.target.value)}
//                         />
//                     </Box>
//                     <Box>
//                         <Input
//                             type="text"
//                             value={email}
//                             placeholder="Email"
//                             onChange={(
//                                 event: React.ChangeEvent<HTMLInputElement>
//                             ) => setEmail(event.target.value)}
//                         />
//                     </Box>
//                 </Flex>
//                 <Box>
//                     <Textarea
//                         placeholder="Message"
//                         value={message}
//                         onChange={(
//                             event: React.ChangeEvent<HTMLTextAreaElement>
//                         ) => setMessage(event.target.value)}
//                     />
//                 </Box>
//                 <Box>
//                     <Button bg="blue" color="white" maxWidth size="md">
//                         Send
//                     </Button>
//                 </Box>
//             </Flex>
//         </Card>
//     )
// }

const ContactForm: React.FC<IContactFormProps> = props => {
  return (
    <div>
      <Button
        link
        bg={'green'}
        color={'white'}
        size={'md'}
        href={props.email || 'mailto:info@bossypanda.com'}
      >
        Send us an email
      </Button>
    </div>
  )
}

export default ContactForm
