import { Box, Flex, Heading, Text } from '@sweaterplanet/nucleus-style'
import React from 'react'
import { ITheme } from 'types/Theme'

interface ITermsProps {
  theme: ITheme
  title: string
  description?: string
  terms: string
}

const Terms: React.FC<ITermsProps> = props => {
  const {
    title,
    description,
    terms,
    theme: { colors },
  } = props
  return (
    <Flex flexDirection="column" width="100%">
      <Box margin="0 50px">
        <Heading element="h1" color={colors.text.default}>
          {title}
        </Heading>
      </Box>
      <Box margin="30px 50px">
        <Heading element="h3" color={colors.text.default}>
          {description}
        </Heading>
      </Box>
      <Box margin="0 50px">
        <Text color={colors.text.default} style={{ whiteSpace: 'pre-wrap' }}>
          {terms}
        </Text>
      </Box>
    </Flex>
  )
}

export default Terms
