import {
  UserActionTypes,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_ADMIN,
  ADD_TO_CART,
  SET_CART_NUMBER,
  REMOVE_FROM_CART,
} from 'types/actions'
import { TRetailLocation } from 'types/RetailLocation'
import { Role } from 'types/User'

export interface UserState {
  authenticated: boolean
  credentials: any
  cartItems: number
}

const initialState: UserState = {
  authenticated: false,
  credentials: {
    id: '',
    email: '',
    phone: '',
    name: '',
    role: Role.USER,
    manager_permissions: [],
    retailLocation: null,
  },
  cartItems: 0,
}

export default (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      }
    case SET_UNAUTHENTICATED:
      return initialState
    case SET_USER:
      return {
        ...state,
        credentials: {
          ...action.payload,
        },
      }
    case SET_ADMIN:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          role: Role.ADMIN,
        },
      }
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: state.cartItems + 1,
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems === 0 ? 0 : state.cartItems - 1,
      }
    case SET_CART_NUMBER:
      return {
        ...state,
        cartItems: action.payload,
      }
    default:
      return state
  }
}
