import React, { useState } from 'react'
import { ButtonStyle as B, FormStyle as F } from '../../styles'

export default ({ options, onChange }: any) => {
  const [data, setData] = React.useState(options)
  const [allOn, setAllOn] = useState<boolean>(false)

  const toggle = (index: any) => {
    const newData = [...data]
    newData.splice(index, 1, {
      label: data[index].label,
      checked: !data[index].checked,
    })
    setData(newData)
    onChange(newData.filter(x => x.checked))
  }

  const toggleAll = () => {
    let newData = data.map((d: { label: string; checked: any }) => {
      d.checked = !allOn
      return d
    })
    setData(newData)
    setAllOn(!allOn)
    onChange(newData.filter((x: { checked: any }) => x.checked))
  }

  const handleClick = (e: any) => {
    e.preventDefault()
    toggleAll()
  }

  return (
    <>
      <B.SubtleButton onClick={handleClick}>Toggle All</B.SubtleButton>
      {data.map((item: any, index: any) => (
        <F.Label key={item.label}>
          {item.label}
          <F.Input
            readOnly
            type="checkbox"
            checked={item.checked || false}
            onClick={() => toggle(index)}
          />
        </F.Label>
      ))}
    </>
  )
}
