export type TCouponID = string

export type TCoupon = {
  id: TCouponID
  name: string
  available: boolean
  type: CouponType
  value: number
  maxUses: number
  uses: number
  validFrom: string
  validUntil: string
}

export enum CouponType {
  CART_AMOUNT = 'CART_AMOUNT',
  CART_PERCENT = 'CART_PERCENT',
  SHIPPING_PERCENT = 'SHIPPING_PERCENT',
}
