import React, { useEffect, useState } from 'react'
import Navigation from 'components/Navigation'
import {
  PageLayout as P,
  DivStyle as Div,
  HrStyle as Hr,
  ButtonStyle,
  FormStyle,
} from 'styles'
import Form from './Form'
import ShippingSpeed from './ShippingSpeed'
// import { CreditCard } from './CreditCardForm'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { TCart, TAddress, TBillingAccount, TCoupon, ITheme } from 'types'
import { AppState } from 'store/store'
import { setCartNumber } from 'store/actions/userActions'
import { connect } from 'react-redux'
import {
  Flex,
  Heading,
  Box,
  Text,
  Button,
  Tag,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import CartCheckout from 'components/Cart/lib/CartCheckout'
import StripeElement from '../../Payment'
import { GET_SOME_RETAIL_LOCATIONS } from '../../../gql/GET_SOME_RETAIL_LOCATIONS'
import PickupLocation from './PickupLocation'
import { TPickupData } from '../../../types/Pickup'
import PickupForm from './PickupForm'
import { UserState } from '../../../store/reducers/userReducer'
import { useMediaQuery } from 'react-responsive'
import CREATE_PAYMENT_INTENT from '../../../gql/CREATE_PAYMENT_INTENT'
import { TRetailLocation } from '../../../types/RetailLocation'
import OrderNotes from './OrderNotes'
import GET_USER from 'gql/GET_USER'
import { SEARCH_USER } from 'gql/SEARCH_USER'
import { Input } from 'styles/lib/form'
import { Loader } from 'utils'

interface checkoutProps {
  user: UserState
  location: {
    state: {
      cart: TCart
      subTotal: number
      shippingAddresses: TAddress[]
      billingAddresses: TAddress[]
      billingAccount: TBillingAccount[]
      defaultShippingAddress: TAddress
      defaultBillingAddress: TAddress
      coupon: TCoupon | null
      referralCode: string | null
      discount: number
      cartItemTotals: number[]
    }
  }
  history: any
  setCartNumber: (value: number) => void
  theme: ITheme
  darkMode: boolean
}

const CheckoutPage: React.FC<checkoutProps> = props => {
  const {
    cart,
    subTotal,
    shippingAddresses,
    // billingAddresses,
    // billingAccount,
    defaultShippingAddress,
    // defaultBillingAddress,
    cartItemTotals,
  } = props.location.state
  const {
    theme: { colors },
  } = props

  const { data: getSomeRetailLocationsData } = useQuery(
    GET_SOME_RETAIL_LOCATIONS,
    {
      variables: {
        searches: [],
        limit: 100,
        offset: 0,
      },
    }
  )

  const [step, setStep] = useState(0)

  const [total, setTotal] = useState(0)
  const [tax, setTax] = useState(-1)
  const [shippingPrice, setShippingPrice] = useState<undefined | number>(
    undefined
  )
  const [shippingSpeed, setShippingSpeed] = useState<undefined | string>(
    undefined
  )
  const [coupon, setCoupon] = useState<TCoupon | null>(
    props.location.state.coupon
  )
  const [referralCode, setReferralCode] = useState<string | null>(
    props.location.state.referralCode
  )
  const [discount, setDiscount] = useState(props.location.state.discount)

  const [shippingAddressIndex, setShippingAddressIndex] = useState(
    defaultShippingAddress ? -1 : -2
  )

  const [orderNotes, setOrderNotes] = useState<string | undefined>()

  // If no default billing address is set, use same as shipping address
  // const [billingAddressIndex, setBillingAddressIndex] = useState(
  //   defaultBillingAddress ? -1 : -3
  // )

  // const [billingAccountIndex, setBillingAccountIndex] = useState(-1)

  // const [saveBillingAddress, setSaveBillingAddress] = useState(false)
  // const [saveBillingAccount, setSaveBillingAccount] = useState(false)
  // const [saveBillingAccountLock, setSaveBillingAccountLock] = useState(false)

  const [selectedShippingSpeed, setSelectedShippingSpeed] = useState(0)
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(0)
  const [selectedPayInStoreLocation, setSelectedPayInStoreLocation] = useState<
    number | null
  >(null)

  const [CreatePaymentIntent] = useMutation(CREATE_PAYMENT_INTENT)

  const UserCreateEnabled = props.user.credentials.managerPermissions?.includes(
    'ENABLE_CHECKOUT_USER_CREATION'
  )

  const emptyFormData: TAddress = {
    id: '',
    firstName: UserCreateEnabled
      ? ''
      : props.user.credentials.name.split(' ')[0],
    lastName: UserCreateEnabled
      ? ''
      : props.user.credentials.name.split(' ')[1] || '',
    business: '',
    email: UserCreateEnabled ? '' : props.user.credentials.email,
    address1: '',
    address2: '',
    country: '',
    city: '',
    state: '',
    postCode: '',
    phone: UserCreateEnabled ? '' : props.user.credentials.phone,
  }
  const emptyPickupData: TPickupData = {
    firstName: UserCreateEnabled
      ? ''
      : props.user.credentials.name.split(' ')[0],
    lastName: UserCreateEnabled
      ? ''
      : props.user.credentials.name.split(' ')[1] || '',
    email: UserCreateEnabled ? '' : props.user.credentials.email,
    phone: UserCreateEnabled ? '' : props.user.credentials.phone,
  }

  const [address, setAddress] = useState<TAddress>(emptyFormData)
  const [pickupData, setPickupData] = useState<TPickupData>(emptyPickupData)
  // const [billing, setBilling] = useState<TAddress>(emptyFormData)

  const [clientSecret, setClientSecret] = useState()
  const [search, setSearch] = useState('')
  const [searches, setSearches] = useState<string[]>([])

  const [searchUser] = useMutation(SEARCH_USER, {
    variables: {
      search,
    },
    onCompleted: data => {
      if (data.searchUser) {
        console.log('BERE', data.searchUser)
        setAddress({
          id: data.searchUser.id,
          firstName: data.searchUser.name.split(' ')[0],
          lastName: data.searchUser.name.split(' ')[1] || '',
          business: data.searchUser.business,
          email: data.searchUser.email,
          address1: '',
          address2: '',
          country: '',
          city: '',
          state: '',
          postCode: '',
          phone: data.searchUser.phone,
        })
        setPickupData({
          email: data.searchUser.email,
          phone: data.searchUser.phone,
          firstName: data.searchUser.name.split(' ')[0],
          lastName: data.searchUser.name.split(' ')[1] || '',
        })
        // setBilling(data.searchUser[0].billing)
      }
    },
  })

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }
  const searchClick = () => {
    if (search !== '') {
      setSearches([search])
      searchUser({
        variables: {
          search,
        },
      })
      setSearch('')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchClick()
    }
  }

  const handleOnSearchRemove = (index: number) => {
    setSearches([])
    setAddress(emptyFormData)
    setPickupData(emptyPickupData)
  }

  const handleNextStep = () => {
    // if (step === 0 && billingAddressIndex === -3) setBilling(address)

    if (step === 1) {
      if (deliveryType === 'Pickup') {
        console.log('pickup', pickupData, 0)
        setStep(step + 1)
      } else {
        console.log('here', shippingSpeed, shippingPrice)
        setStep(step + 1)
      }
      CreatePaymentIntent({ variables: paymentVariables })
        .then(response => {
          console.log(response.data)
          setClientSecret(response.data.createPaymentIntent.paymentToken)
          setOrderId && setOrderId(response.data.createPaymentIntent.id)
        })
        .catch(err => {
          alert(err.message)
          // console.log(err)
        })
    } else {
      setStep(step + 1)
    }
  }

  // const emptyCreditCard: CreditCard = {
  //   name: '',
  //   number: '',
  //   expiry: '',
  //   cvc: '',
  //   focus: 'number',
  // }

  // const [creditCard, setCreditCard] = useState(emptyCreditCard)

  const [createNewUser, setCreateNewUser] = useState(
    props.user.credentials.managerPermissions?.includes(
      'ENFORCE_CHECKOUT_USER_CREATION'
    ) ||
      props.user.credentials.managerPermissions?.includes(
        'ENABLE_CHECKOUT_USER_CREATION'
      )
  )

  const [deliveryType, setDeliveryType] = useState<string | null>(null)

  const paymentVariables = {
    billingAddress: {},
    billingAccount: {
      id: '',
      name: '',
      number: '',
      cvc: '',
      expiry: '',
    },
    amount: Math.floor(total * 100),
    cart: cart,
    shipping:
      deliveryType === 'Pickup' ? { ...emptyFormData, ...pickupData } : address,
    splitShipping: false,
    saveBillingAddress: false,
    saveBillingAccount: false,
    coupon: coupon,
    cartItemTotals: cartItemTotals,
    referralCode: referralCode,
    shippingSpeed: deliveryType === 'Pickup' ? 'Pickup' : shippingSpeed,
    shippingPrice: deliveryType === 'Pickup' ? 0.0 : shippingPrice,
    deliveryType,
    createNewUser,
    pickupLocationID:
      deliveryType === 'Pickup'
        ? getSomeRetailLocationsData?.getSomeRetailLocations[
            selectedPickupLocation
          ]?.id
        : undefined,
  }

  const [nextStepDisabled, setNextStepDisabled] = useState(false)
  const [paymentType, setPaymentType] = useState<string | null>(null)
  const [orderId, setOrderId] = useState<string | undefined>()

  useEffect(() => {
    if (step === 0) {
      console.log(deliveryType, pickupData)

      if (deliveryType === 'Pickup') {
        if (
          pickupData.firstName &&
          pickupData.lastName &&
          pickupData.email &&
          pickupData.phone
        ) {
          setNextStepDisabled(false)
        } else {
          setNextStepDisabled(true)
        }
      } else {
        if (address.country && address.state && address.state.length === 2) {
          setNextStepDisabled(false)
        } else {
          setNextStepDisabled(true)
        }
      }
    }
    if (step === 1) {
      setNextStepDisabled(false)
    }
    if (step === 2) {
      if (orderId) {
        console.log('here 2')
        if (paymentType === 'Pay In Store') {
          if (selectedPayInStoreLocation === null) {
            setNextStepDisabled(true)
          } else {
            setNextStepDisabled(false)
          }
        } else {
          setNextStepDisabled(false)
        }
      } else {
        setNextStepDisabled(true)
      }
    }
  }, [
    step,
    address,
    deliveryType,
    pickupData,
    paymentType,
    selectedPayInStoreLocation,
    orderId,
  ])

  const [deliveryTypeSelectionOn, setDeliveryTypeSelectionOn] = useState(true)
  const [paymentTypeSelectionOn, setPaymentTypeSelectionOn] = useState(true)

  useEffect(() => {
    setDeliveryTypeSelectionOn(true)
    if (
      props.user.credentials.managerPermissions?.includes('DISABLE_SHIPPING')
    ) {
      setDeliveryType('Pickup')
      setDeliveryTypeSelectionOn(false)
    } else if (props.user.credentials.retailLocation) {
      setDeliveryType('Pickup')
      setDeliveryTypeSelectionOn(true)
    } else {
      setDeliveryType('Shipping')
      setDeliveryTypeSelectionOn(true)
    }
    if (props.user.credentials.managerPermissions?.includes('PAY_IN_STORE')) {
      if (
        props.user.credentials.managerPermissions?.includes(
          'DISABLE_ONLINE_PAYMENT'
        )
      ) {
        setPaymentType('Pay In Store')
        setPaymentTypeSelectionOn(false)
      }
    } else {
      setPaymentTypeSelectionOn(false)
      setPaymentType('Online Payment')
    }
  }, [props.user])

  const headers = [
    deliveryTypeSelectionOn
      ? 'How would you like to receive your order?'
      : deliveryType === 'Pickup'
      ? 'Pickup Information'
      : 'Shipping Address',
    deliveryType === 'Pickup' ? 'Pickup Location' : 'Shipping Speed',
    paymentTypeSelectionOn
      ? 'How would you like to pay?'
      : paymentType === 'Pay In Store'
      ? 'Select Your Store'
      : 'Enter Your Credit Card Information',
  ]

  const breakpoint = useMediaQuery({ maxWidth: 1000 })

  useEffect(() => {
    console.log(selectedPickupLocation)
    if (getSomeRetailLocationsData && props.user.credentials.retailLocation) {
      if (deliveryType === 'Pickup') {
        if (
          (selectedPickupLocation === null ||
            selectedPickupLocation === -1 ||
            selectedPickupLocation === undefined) &&
          getSomeRetailLocationsData?.getSomeRetailLocations.length > 0
        )
          setSelectedPickupLocation(
            getSomeRetailLocationsData?.getSomeRetailLocations.findIndex(
              (location: TRetailLocation) =>
                location.id === props.user.credentials.retailLocation.id
            ) || 0
          )
      }
      if (
        props.user.credentials.managerPermissions.includes('PAY_IN_STORE') &&
        paymentType === 'Pay In Store'
      ) {
        if (
          (selectedPickupLocation === null ||
            selectedPickupLocation === -1 ||
            selectedPickupLocation === undefined) &&
          getSomeRetailLocationsData?.getSomeRetailLocations.length > 0
        )
          setSelectedPayInStoreLocation(
            getSomeRetailLocationsData?.getSomeRetailLocations.findIndex(
              (location: TRetailLocation) =>
                location.id === props.user.credentials.retailLocation.id
            ) || 0
          )
      }
    }
  }, [
    getSomeRetailLocationsData,
    paymentType,
    deliveryType,
    props.user.credentials,
    selectedPickupLocation,
  ])

  useEffect(() => {
    if (
      selectedPickupLocation !== null &&
      selectedPickupLocation !== undefined
    ) {
      setSelectedPayInStoreLocation(selectedPickupLocation)
    }
  }, [selectedPickupLocation])

  const handleAddressSelect = (value: string) => {
    const index = parseInt(value)
    console.log(value, index, step)
    if (step === 0) {
      if (index === -1) setAddress(defaultShippingAddress)
      else if (index > -1) setAddress(shippingAddresses[index])
      setShippingAddressIndex(index)
    }
  }

  // const {
  //   loading: PickupOptionsLoading,
  //   data: PickupOptionsData,
  //   error: PickupOptionsError,
  // } = useQuery(GET_SOME_RETAIL_LOCATIONS, {
  //   variables: { searches: [] },
  // })

  // const { data: meData } = useQuery(GET_USER, {})

  // useEffect(() => {
  //   if (PickupOptionsData && meData?.me?.retailLocation?.id) {
  //     const defaultPickupLocationIndex =
  //       PickupOptionsData.getSomeRetailLocations.findIndex(
  //         (location: any) => location.name === meData.me.retailLocation.name
  //       )
  //     setSelectedPickupLocation(defaultPickupLocationIndex)
  //     setSelectedPayInStoreLocation(defaultPickupLocationIndex)
  //   }
  // }, [PickupOptionsData, meData])

  return (
    <P.Page>
      <Navigation />
      <P.Content>
        <Flex
          flexDirection={breakpoint ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
        >
          <Box
            // float="left"
            marginRight="2rem"
            // max-width="100%"
            flexGrow={'1'}
            minWidth={breakpoint ? '100%' : '600px'}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Div.circle width="35px" height="35px">
                  <Heading element="h4" color={colors.text.default}>
                    {step + 1}
                  </Heading>
                </Div.circle>
                <Box margin="0 20px">
                  <Heading element="h3" color={colors.text.default}>
                    {headers[step]}
                  </Heading>
                </Box>
              </Flex>
              {/*{step === 2 && (*/}
              {/*  <>*/}
              {/*    <CheckoutInput*/}
              {/*      isBillingAddress={true}*/}
              {/*      title={'Select Billing Address'}*/}
              {/*      type="text"*/}
              {/*      width="250px"*/}
              {/*      isSelect*/}
              {/*      addresses={billingAddresses}*/}
              {/*      value={billingAddressIndex}*/}
              {/*      onSelectChange={(*/}
              {/*        event: React.ChangeEvent<HTMLSelectElement>*/}
              {/*      ) => handleAddressSelect(event.target.value)}*/}
              {/*      defaultAddress={defaultBillingAddress}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*)}*/}
            </Flex>
            <Box margin="40px 0">
              <Hr.Line />
            </Box>
            {step === 0 && (
              <>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={'20px'}
                >
                  {deliveryTypeSelectionOn && (
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex>
                        <Box margin="0 10px">
                          <ButtonStyle.SubtleButton
                            bg={
                              deliveryType === 'Shipping'
                                ? colors.button.background
                                : undefined
                            }
                            color={
                              deliveryType === 'Shipping'
                                ? colors.button.text
                                : undefined
                            }
                            onClick={() => setDeliveryType('Shipping')}
                          >
                            Shipping
                          </ButtonStyle.SubtleButton>
                        </Box>
                        <Box marginRight="0 10px">
                          <ButtonStyle.SubtleButton
                            bg={
                              deliveryType === 'Pickup'
                                ? colors.button.background
                                : undefined
                            }
                            color={
                              deliveryType === 'Pickup'
                                ? colors.button.text
                                : undefined
                            }
                            onClick={() => setDeliveryType('Pickup')}
                          >
                            Pickup
                          </ButtonStyle.SubtleButton>
                        </Box>
                      </Flex>
                      {props.user.credentials.managerPermissions.includes(
                        'ENABLE_CHECKOUT_USER_CREATION'
                      ) && (
                        <Flex flexDirection="row" alignItems="center">
                          <Input
                            value={search}
                            onChange={searchChange}
                            onKeyDown={handleKeyDown}
                            type="text"
                            placeholder="Search"
                          />
                          <Box marginLeft="20px">
                            <Button
                              bg="green"
                              color="white"
                              size="xs"
                              onClick={searchClick}
                            >
                              Search
                            </Button>
                          </Box>
                          <Flex flexDirection="row" alignItems="center">
                            <Flex flexDirection="row" flexWrap="wrap">
                              {searches.length > 0 &&
                                searches.map((search, index) => (
                                  <Box key={index} padding="10px 0 10px 15px">
                                    <Tag
                                      size="xs"
                                      button
                                      color={colors.contrast}
                                      text={search}
                                      onClick={() =>
                                        handleOnSearchRemove(index)
                                      }
                                    />
                                  </Box>
                                ))}
                            </Flex>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex
                  marginRight="10px"
                  marginLeft="10px"
                  marginBottom="40px"
                  alignItems={'center'}
                >
                  <input
                    type={'checkbox'}
                    style={{
                      display:
                        props.user.credentials.managerPermissions?.includes(
                          'ENABLE_CHECKOUT_USER_CREATION'
                        ) &&
                        !props.user.credentials.managerPermissions?.includes(
                          'ENFORCE_CHECKOUT_USER_CREATION'
                        ) &&
                        deliveryType
                          ? 'unset'
                          : 'none',
                    }}
                    checked={createNewUser}
                    onClick={() => setCreateNewUser(!createNewUser)}
                  />
                  <Box
                    marginLeft="10px"
                    style={{
                      display:
                        props.user.credentials.managerPermissions?.includes(
                          'ENABLE_CHECKOUT_USER_CREATION'
                        ) &&
                        !props.user.credentials.managerPermissions?.includes(
                          'ENFORCE_CHECKOUT_USER_CREATION'
                        ) &&
                        deliveryType
                          ? 'unset'
                          : 'none',
                    }}
                  >
                    <Text color={colors.text.default}>Create New User?</Text>
                  </Box>
                </Flex>
              </>
            )}
            {step === 0 && (
              <>
                {deliveryType === 'Shipping' && (
                  <>
                    <Box margin="0 0 20px 10px">
                      <Flex justifyContent={'space-between'}>
                        <Heading element="h4" color={colors.text.default}>
                          Enter Your Shipping Address
                        </Heading>
                        <FormStyle.checkoutSelect
                          onChange={e => handleAddressSelect(e.target.value)}
                          width={'200px'}
                        >
                          <option disabled selected>
                            Saved Addresses
                          </option>
                          {shippingAddresses.map((address, index) => (
                            <option key={index} value={index}>
                              {address.address1}
                            </option>
                          ))}
                        </FormStyle.checkoutSelect>
                      </Flex>
                    </Box>
                    {address && (
                      <Form
                        data={address}
                        setData={setAddress}
                        addressIndex={shippingAddressIndex}
                        setAddressIndex={setShippingAddressIndex}
                        step={step}
                        handleNextStep={handleNextStep}
                      />
                    )}
                  </>
                )}
                {deliveryType === 'Pickup' && (
                  <>
                    <Box margin="0 0 20px 10px">
                      <Flex justifyContent={'space-between'}>
                        <Heading element="h4" color={colors.text.default}>
                          Enter Your Pickup Information
                        </Heading>
                      </Flex>
                    </Box>
                    <PickupForm
                      data={pickupData}
                      setData={setPickupData}
                      step={step}
                      handleNextStep={handleNextStep}
                    />
                  </>
                )}
              </>
            )}
            {step === 1 && (
              <>
                {deliveryType === 'Shipping' ? (
                  <ShippingSpeed
                    selectedShipping={selectedShippingSpeed}
                    setSelectedShipping={setSelectedShippingSpeed}
                    setShippingPrice={setShippingPrice}
                    setShippingSpeed={setShippingSpeed}
                    darkMode={props.darkMode}
                    country={address.country}
                  />
                ) : (
                  <>
                    {/* {PickupOptionsLoading && <Loader />}
                    {PickupOptionsError && (
                      <Text color={colors.text.danger}>
                        {PickupOptionsError.message}
                      </Text>
                    )} */}
                    <PickupLocation
                      pickupOptionsData={getSomeRetailLocationsData}
                      selectedPickupLocation={selectedPickupLocation}
                      setSelectedPickupLocation={setSelectedPickupLocation}
                      darkMode={props.darkMode}
                    />
                  </>
                )}
              </>
            )}
            {/*{step === 2 && (*/}
            {/*  <>*/}
            {/*    <Form*/}
            {/*      data={billing}*/}
            {/*      setData={setBilling}*/}
            {/*      addressIndex={billingAddressIndex}*/}
            {/*      setAddressIndex={setBillingAddressIndex}*/}
            {/*      step={step}*/}
            {/*      setSaveBillingAddressLock={setSaveBillingAddressLock}*/}
            {/*      setSaveBillingAccount={setSaveBillingAccount}*/}
            {/*      // setSaveBillingAccountLock={setSaveBillingAccountLock}*/}
            {/*      handleNextStep={handleNextStep}*/}
            {/*    />*/}
            {/*    <Flex*/}
            {/*      margin="20px 0"*/}
            {/*      style={{ cursor: 'pointer' }}*/}
            {/*      alignItems="center"*/}
            {/*      onClick={*/}
            {/*        saveBillingAddressLock*/}
            {/*          ? () => null*/}
            {/*          : () => handleSaveBillingAddress()*/}
            {/*      }*/}
            {/*    >*/}
            {/*      /!*<F.checkoutCheckbox>*!/*/}
            {/*      /!*  <input*!/*/}
            {/*      /!*    type="checkbox"*!/*/}
            {/*      /!*    checked={saveBillingAddress}*!/*/}
            {/*      /!*    onChange={() => null}*!/*/}
            {/*      /!*  />*!/*/}
            {/*      /!*  <FontAwesomeIcon icon={faCheck} className="check" />*!/*/}
            {/*      /!*</F.checkoutCheckbox>*!/*/}
            {/*      /!*<Text margin="20px 0" color={colors.text.default}>*!/*/}
            {/*      /!*  Save Billing Address?*!/*/}
            {/*      /!*</Text>*!/*/}
            {/*    </Flex>*/}
            {/*  </>*/}
            {/*)}*/}
            {step === 2 && (
              <>
                {paymentTypeSelectionOn && (
                  <Flex marginBottom={'40px'}>
                    <Box margin="0 10px">
                      <ButtonStyle.SubtleButton
                        bg={
                          paymentType === 'Pay In Store'
                            ? colors.button.background
                            : undefined
                        }
                        color={
                          paymentType === 'Pay In Store'
                            ? colors.button.text
                            : undefined
                        }
                        onClick={() => setPaymentType('Pay In Store')}
                      >
                        Pay In Store
                      </ButtonStyle.SubtleButton>
                    </Box>
                    <Box marginRight="0 10px">
                      <ButtonStyle.SubtleButton
                        bg={
                          paymentType === 'Online Payment'
                            ? colors.button.background
                            : undefined
                        }
                        color={
                          paymentType === 'Online Payment'
                            ? colors.button.text
                            : undefined
                        }
                        onClick={() => setPaymentType('Online Payment')}
                      >
                        Online Payment
                      </ButtonStyle.SubtleButton>
                    </Box>
                  </Flex>
                )}
              </>
            )}
            {step === 2 && (
              <>
                {paymentType === 'Pay In Store' ? (
                  <Box marginLeft="10px">
                    <Box margin="0 0 20px 0">
                      <Flex justifyContent={'space-between'}>
                        <Heading element="h4" color={colors.text.default}>
                          Select your store for payment
                        </Heading>
                      </Flex>
                    </Box>
                    <FormStyle.checkoutSelect
                      width={'100%'}
                      style={{
                        backgroundColor:
                          selectedPayInStoreLocation === null ||
                          selectedPayInStoreLocation === undefined
                            ? colors.card.background2
                            : colors.button.background,
                      }}
                      onChange={e =>
                        setSelectedPayInStoreLocation(parseInt(e.target.value))
                      }
                      value={selectedPayInStoreLocation ?? undefined}
                    >
                      <option disabled selected>
                        Select a Store
                      </option>
                      {getSomeRetailLocationsData?.getSomeRetailLocations.map(
                        (location: any, index: number) => (
                          <option key={index} value={index}>
                            {location.name}
                          </option>
                        )
                      )}
                    </FormStyle.checkoutSelect>
                  </Box>
                ) : paymentType === 'Online Payment' ? (
                  <StripeElement
                    total={total}
                    vars={paymentVariables}
                    orderId={orderId}
                    setOrderId={setOrderId}
                    clientSecret={clientSecret}
                    orderNotes={orderNotes}
                  />
                ) : (
                  <></>
                )}
                <OrderNotes setOrderNotes={setOrderNotes} theme={props.theme} />
              </>
            )}
          </Box>
          <CartCheckout
            subTotal={subTotal}
            coupon={coupon}
            setCoupon={setCoupon}
            referralCode={referralCode}
            setReferralCode={setReferralCode}
            tax={tax}
            address={address}
            setTax={setTax}
            shippingPrice={shippingPrice}
            shippingSpeed={shippingSpeed}
            total={total}
            setTotal={setTotal}
            discount={discount}
            setDiscount={setDiscount}
            step={step}
            setStep={setStep}
            handleNextStep={handleNextStep}
            // creditCard={creditCard}
            disabled={nextStepDisabled}
            deliveryType={deliveryType}
            paymentType={paymentType}
            orderId={orderId}
            setOrderId={setOrderId}
            selectedPayInStoreLocation={
              selectedPayInStoreLocation !== null &&
              selectedPayInStoreLocation !== undefined &&
              getSomeRetailLocationsData?.getSomeRetailLocations[
                selectedPayInStoreLocation
              ]
            }
            orderNotes={orderNotes}
          />
        </Flex>
      </P.Content>
    </P.Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  darkMode: state.theme.darkMode,
  user: state.user,
})

const mapActionsToProps = {
  setCartNumber,
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTheme(CheckoutPage))
