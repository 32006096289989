import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ButtonStyle as B, FormStyle as F, ModalLayout as M } from 'styles'
import UPSERT_TAG_BY_CONTENT from './gql/UPSERT_TAG_BY_CONTENT'
import { Error, Loader } from 'utils'
import GET_SOME_TAGS from './gql/GET_SOME_TAGS'

interface ITagCreateFormProps {
  id: string
  visible: boolean
  toggle: any
  refetch?: () => any
}

const TagEditForm: React.FC<ITagCreateFormProps> = props => {
  const [color, setColor] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const { loading, data } = useQuery(GET_SOME_TAGS, {
    variables: {
      searches: [props.id],
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setColor(data?.getSomeTags[0].color)
    setIcon(data?.getSomeTags[0].icon)
  }, [data])

  const [UpsertTagByContent] = useMutation(UPSERT_TAG_BY_CONTENT, {
    variables: {
      content: props.id,
      color: color === '' ? undefined : color,
      icon: icon === '' ? undefined : icon,
    },
  })

  const handleOnTagEdit = () => {
    UpsertTagByContent()
      .then(response => {
        if (!response.errors && props.refetch) {
          props.toggle(false)
          props.refetch()
        }
      })
      .catch(e => setError(e.message))
  }

  return (
    <>
      {props.visible && (
        <M.Modal>
          <h2>Edit Tag</h2>
          <B.Exit onClick={() => props.toggle(null)}>X</B.Exit>
          {data && (
            <>
              <F.Form>
                <F.Label>
                  Content (can't edit)
                  <F.Input
                    value={data.getSomeTags[0].id}
                    type={'text'}
                    placeholder={'content'}
                    disabled={true}
                  />
                </F.Label>
                <F.Label>
                  Color
                  <F.Input
                    value={color}
                    type={'text'}
                    placeholder={'color'}
                    onChange={e => setColor(e.target.value)}
                  />
                </F.Label>
                <F.Label>
                  Icon
                  <F.Input
                    value={icon}
                    type={'text'}
                    placeholder={'icon'}
                    onChange={e => setIcon(e.target.value)}
                  />
                </F.Label>
                <B.Button onClick={() => handleOnTagEdit()}>
                  Update Tag
                </B.Button>
              </F.Form>
            </>
          )}
          {loading && <Loader />}
        </M.Modal>
      )}
      {error && <Error message={error} />}
    </>
  )
}

export default TagEditForm
