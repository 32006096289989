import React, { useState } from 'react'
import { TVariant } from 'types'
import { GridLayout as G, UtiliesStyle as U } from 'styles'

interface VariantPickerProps {
  variants: TVariant[]
  setVariant?: React.Dispatch<React.SetStateAction<TVariant>>
  unavailableVariants?: TVariant[]
}

const ColorPicker: React.FC<VariantPickerProps> = props => {
  const [tooltipVisibility, setTooltipVisibility] = useState<string | null>(
    null
  )
  return (
    <G.row wrap justify="flex-start">
      {props.variants.map((variant: TVariant) => (
        <>
          {!props.unavailableVariants
            ?.map(uV => uV.value)
            ?.includes(variant.value) && (
            <U.variant key={variant.id}>
              <U.variantValue
                onMouseEnter={() => setTooltipVisibility(variant.id)}
                onMouseLeave={() => setTooltipVisibility(null)}
                onClick={() => props.setVariant && props.setVariant(variant)}
              >
                {variant.value}
              </U.variantValue>
              {variant.name && (
                <U.Tooltip visible={tooltipVisibility === variant.id}>
                  {variant.name}
                </U.Tooltip>
              )}
            </U.variant>
          )}
        </>
      ))}
    </G.row>
  )
}

export default ColorPicker
