import React from 'react'
import { destroyToken } from 'utils'
import { AppState } from 'store/store'
import { HOME, STORE } from 'routes/user'
import { signOut } from 'store/actions/userActions'
import { connect } from 'react-redux'
import { ButtonStyle as B } from 'styles'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useGoogleLogout } from 'react-google-login'

interface SignOutProps {
  signOut: () => void
  storeId?: string
}

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const SignOut: React.FC<SignOutProps & RouteComponentProps> = props => {
  const onLogoutSuccess = () => {
    console.log('success: ')
  }
  const onFailure = () => {
    console.log('fail: ')
  }

  const { signOut } = useGoogleLogout({
    clientId: clientId || '',
    onLogoutSuccess,
    onFailure,
  })

  const signOutClick = (e: any) => {
    e.preventDefault()
    destroyToken()
    signOut()
    props.signOut()
    props.history.push(props.storeId ? `${STORE}/${props.storeId}` : HOME)
    window.location.reload()
  }

  return (
    <B.SubtleButton onClick={e => signOutClick(e)}>Sign Out</B.SubtleButton>
  )
}

const mapStateToProps = (state: AppState) => ({})

const mapActionsToProps = {
  signOut,
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(SignOut))
