import {
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_ADMIN,
  AppActions,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_CART_NUMBER,
} from 'types/actions'
import { TUser } from 'types/User'
import { Dispatch } from 'redux'

export const signIn = (user: TUser) => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: SET_USER,
    payload: user,
  })
  dispatch({ type: SET_AUTHENTICATED })
}

export const signOut = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_UNAUTHENTICATED })
  // dispatch({
  //   type: SET_USER,
  //   payload: null,
  // })
}

export const setUser = (user: TUser) => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: SET_USER,
    payload: user,
  })
}

export const setAdmin = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: SET_ADMIN })
}

export const addToCart = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: ADD_TO_CART })
}

export const removeFromCart = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: REMOVE_FROM_CART })
}

export const setCartNumber =
  (num: number) => (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SET_CART_NUMBER,
      payload: num,
    })
  }
