import React from 'react'
import { ADMIN_STORES } from 'routes/admin'
import StoreCreateForm from './StoreForm'
import { DataTable } from 'components/Admin/index'
import GET_SOME_STORES from './gql/GET_SOME_STORES'
import DELETE_STORE from './gql/DELETE_STORE'

interface StoreTableProps {}

const StoreTable: React.FC<StoreTableProps> = props => {
  return (
    <DataTable
      getDataQuery={GET_SOME_STORES}
      getDataQueryName="getSomeStores"
      deleteDataQuery={DELETE_STORE}
      headers={['Name', 'Revenue', 'Orders', 'Last Order', 'Products']}
      properties={['name', 'revenue', 'orders', 'purchased', '#products']}
      ROUTE={ADMIN_STORES}
      title="Stores"
    >
      {createFormProps => <StoreCreateForm {...createFormProps} />}
    </DataTable>
  )
}

export default StoreTable
