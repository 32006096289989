import styled from 'styled-components'

interface ITooltipProps {
  visible: boolean
}

export const Tooltip = styled.span<ITooltipProps>`
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  margin: 35px 0 0 -40px;
  width: 120px;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  z-index: 1;
  background-color: ${p => p.theme.colors.tooltip.background};
  color: ${p => p.theme.colors.tooltip.text};
`

export const variant = styled.div`
  position: relative;
  padding: 3px 5px;
`

export const variantValue = styled.div`
  background-color: ${p => p.theme.colors.tooltip.background};
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IStatusProps {
  color?: string
}

export const status = styled.span<IStatusProps>`
  padding: 10px;
  border-radius: 20px;
  background-color: ${p => p.theme.colors.tooltip.background};
  color: ${p => p.theme.colors.tooltip.text};
  span {
    color: ${p => p.color || p.theme.colors.tooltip.text};
    padding-right: 5px;
  }
`
