import { gql } from 'apollo-boost'

export const GET_SOME_RETAIL_LOCATIONS = gql`
  query getSomeRetailLocations(
    $searches: [String!]
    $limit: Int
    $offset: Int
  ) {
    getSomeRetailLocations(
      searches: $searches
      limit: $limit
      offset: $offset
    ) {
      id
      name
      description
      locationCode
      slug
      authToken
      managers {
        id
        name
        email
      }
    }
  }
`
