import React, { useEffect, useState } from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import MockupEdit from './MockupEdit'
import SINGLE_UPLOAD from '../../../gql/SINGLE_UPLOAD'

import {
  ButtonStyle as B,
  DivLayout as D,
  GridLayout as G,
  TableLayout as T,
  CardStyle as C,
  FormStyle as F,
} from 'styles'
import { TView } from 'types/View'
import { TPrintLocationCoordinateMap } from 'types/PrintLocationCoordinateMap'
import { TPrintLocation } from 'types/PrintLocation'
import { SLEEVE_CUSTOMIZATION } from 'constants/sleeveCustomization'
import DELETE_PRINT_LOCATION_COORDINATE_MAP from '../../../gql/DELETE_PRINT_LOCATION_COORDINATE_MAP'
import UPSERT_VIEW from '../../Product/lib/gql/UPSERT_VIEW'
import * as ADMIN_ROUTES from '../../../routes/admin'

const GET_VIEW = gql`
  query getView($id: ID!) {
    getView(id: $id) {
      id
      name
      boundingBox
      base
      texture
      InventoryGroup {
        id
      }
      printLocations {
        id
        coordinateMap
        rotate
        printLocation {
          id
          available
          name
          width
          height
          price
        }
      }
    }
  }
`

const GET_PRINT_LOCATIONS = gql`
  query getSomePrintLocations($offset: Int, $limit: Int) {
    getSomePrintLocations(
      searches: ["available"]
      offset: $offset
      limit: $limit
    ) {
      id
      available
      name
      width
      height
      price
    }
  }
`

interface IViewManagementPageProps {
  match: {
    params: {
      viewId: string
    }
  }
}

const newPrintLocationCoordinateMap = {
  id: '',
  printLocation: {
    id: '',
    available: false,
    name: '',
    width: 0,
    height: 0,
  },
  // TODO: Find a way to always put new PrintLocations in the center of the View
  coordinateMap: [
    { x: 400, y: 400 },
    { x: 600, y: 400 },
    { x: 400, y: 600 },
    { x: 600, y: 600 },
  ],
  rotate: 0,
}

const ViewManagement: React.FC<IViewManagementPageProps> = props => {
  const [
    selectedPrintLocationCoordinateMap,
    setSelectedPrintLocationCoordinateMap,
  ] = useState<TPrintLocationCoordinateMap | null>(null)

  const { loading, data, error, refetch } = useQuery(GET_VIEW, {
    variables: {
      id: props.match.params.viewId,
    },
  })

  useEffect(() => {
    if (data) {
      setViewName(data.getView.name)
      setBase(data.getView.base)
      setTexture(data.getView.texture)
    }
  }, [data])

  const [viewName, setViewName] = useState('')
  const [base, setBase] = useState('')
  const [texture, setTexture] = useState('')

  const [upsertView] = useMutation(UPSERT_VIEW)

  const handleClick = () => {
    upsertView({
      variables: {
        id: props.match.params.viewId,
        name: viewName,
        boundingBox: data.getView.boundingBox,
        base: base,
        texture: texture,
        inventoryGroupId: data.getView.InventoryGroup.id,
      },
    })
      .then(() => {
        refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }
  const [singleUpload] = useMutation(SINGLE_UPLOAD)

  const onChange = async ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      try {
        const FILE = await singleUpload({ variables: { file } })
        if (FILE.data) {
          console.log(FILE.data)
          setTexture(FILE.data.singleUpload.file.url)
        }
      } catch (error: any) {
        return <Error message={error.message} />
      }
    }
  }

  if (error) {
    return <Error message={error.message} />
  }
  if (loading && !data) {
    return <Loader />
  }
  return (
    <>
      {data && (
        <>
          <D.flex align={'flex-start'} justify={'flex-start'}>
            <div
              style={{
                position: 'absolute',
                left: '200px',
                paddingTop: '20px',
              }}
            >
              <a
                href={`${ADMIN_ROUTES.ADMIN_INVENTORY_GROUPS}/${data.getView.InventoryGroup.id}`}
              >
                {`<`} Back to Inventory Group
              </a>
              {/*<h2>{data.getView.name}</h2>*/}
            </div>
            <D.flex direction={'column'} align={'flex-start'}>
              <F.Label>
                Name
                <F.Input
                  value={viewName}
                  onChange={e => setViewName(e.target.value)}
                />
              </F.Label>
              <F.Label>
                Base
                <F.Input value={base} onChange={e => setBase(e.target.value)} />
              </F.Label>
              <F.Label>
                Texture
                <F.Input
                  value={texture}
                  onChange={e => setTexture(e.target.value)}
                />
              </F.Label>
              <F.Label>
                Upload Texture
                <F.upload
                  type="file"
                  accept=".png"
                  onChange={onChange}
                  // onClick={onInputClick}
                />
              </F.Label>
              <B.DangerButton onClick={handleClick}>Update</B.DangerButton>
            </D.flex>
          </D.flex>
          <C.card>
            <D.flex>
              <T.Table>
                <tr>
                  <th>Status</th>
                  <th>Decoration Location</th>
                  <th>Size</th>
                  <th>Remove</th>
                </tr>
                <PrintLocationTable
                  view={data.getView}
                  selectedPrintLocationCoordinateMap={
                    selectedPrintLocationCoordinateMap
                  }
                  setSelectedPrintLocationCoordinateMap={
                    setSelectedPrintLocationCoordinateMap
                  }
                  refetch={refetch}
                />
              </T.Table>
              <MockupEdit
                width="450px"
                view={data.getView}
                color={'gray'}
                selectedPrintLocationCoordinateMap={
                  selectedPrintLocationCoordinateMap
                }
                setSelectedPrintLocationCoordinateMap={
                  setSelectedPrintLocationCoordinateMap
                }
              />
            </D.flex>
          </C.card>
        </>
      )}
    </>
  )
}

interface IPrintLocationEditorProps {
  view: TView
  selectedPrintLocationCoordinateMap: TPrintLocationCoordinateMap | null
  setSelectedPrintLocationCoordinateMap: (
    value: TPrintLocationCoordinateMap | null
  ) => void
  refetch: any
}

const PrintLocationTable: React.FC<IPrintLocationEditorProps> = ({
  view,
  selectedPrintLocationCoordinateMap,
  setSelectedPrintLocationCoordinateMap,
  refetch,
}) => {
  const { loading, data, error } = useQuery(GET_PRINT_LOCATIONS)
  const [editIndex, setEditIndex] = useState(-1)

  const [DeletePrintLocationCoordinateMap] = useMutation(
    DELETE_PRINT_LOCATION_COORDINATE_MAP
  )

  const handleEditClick = (printLocation: TPrintLocation, index: number) => {
    if (editIndex === index) {
      setSelectedPrintLocationCoordinateMap(null)
      setEditIndex(-1)
    } else {
      const alreadyExistIndex = view.printLocations.findIndex(
        printLocationCoordinateMap =>
          printLocationCoordinateMap.printLocation.id === printLocation.id
      )
      if (alreadyExistIndex !== -1) {
        setSelectedPrintLocationCoordinateMap(
          view.printLocations[alreadyExistIndex]
        )
      } else {
        if (printLocation.name === SLEEVE_CUSTOMIZATION) {
          setSelectedPrintLocationCoordinateMap({
            ...newPrintLocationCoordinateMap,
            coordinateMap: [
              { x: 400, y: 400 },
              { x: 530, y: 400 },
              { x: 400, y: 450 },
              { x: 530, y: 450 },
            ],
            printLocation: printLocation,
          })
        } else {
          setSelectedPrintLocationCoordinateMap({
            ...newPrintLocationCoordinateMap,
            printLocation: printLocation,
          })
        }
      }
      setEditIndex(index)
    }
  }

  const handleDelete = (printLocation: TPrintLocation, index: number) => {
    const alreadyExistIndex = view.printLocations.findIndex(
      printLocationCoordinateMap =>
        printLocationCoordinateMap.printLocation.id === printLocation.id
    )
    if (alreadyExistIndex !== -1) {
      DeletePrintLocationCoordinateMap({
        variables: { id: view.printLocations[alreadyExistIndex].id },
      }).then(() => refetch())
    }
  }

  if (error) {
    return <Error message={error.message} />
  }
  if (loading && !data) {
    return <Loader />
  }
  return (
    <>
      {data &&
        data.getSomePrintLocations.map(
          (printLocation: TPrintLocation, index: number) => (
            <>
              <tr key={printLocation.id}>
                <td onClick={() => handleEditClick(printLocation, index)}>
                  {view.printLocations
                    .map(p => p.printLocation.id)
                    .includes(printLocation.id) ? (
                    <B.SubtleButton>Edit</B.SubtleButton>
                  ) : (
                    <B.SubtleButton>Add</B.SubtleButton>
                  )}
                </td>
                <td>{printLocation.name}</td>

                <td>
                  {printLocation.width} x {printLocation.height}
                </td>
                <td>
                  {view.printLocations
                    .map(p => p.printLocation.id)
                    .includes(printLocation.id) && (
                    <B.SubtleButton
                      style={{ backgroundColor: 'red', color: 'white' }}
                      onClick={e => handleDelete(printLocation, index)}
                    >
                      Delete
                    </B.SubtleButton>
                  )}
                </td>
              </tr>
              {editIndex === index && selectedPrintLocationCoordinateMap && (
                <PrintLocationEditor
                  view={view}
                  printLocationCoordinateMap={
                    selectedPrintLocationCoordinateMap
                  }
                  setSelectedPrintLocationCoordinateMap={
                    setSelectedPrintLocationCoordinateMap
                  }
                  refetch={refetch}
                />
              )}
            </>
          )
        )}
    </>
  )
}

interface IPrintLocationEditor {
  view: TView
  printLocationCoordinateMap: TPrintLocationCoordinateMap
  setSelectedPrintLocationCoordinateMap: (
    value: TPrintLocationCoordinateMap
  ) => void
  refetch: any
}

const UPSERT_PRINT_LOCATION_COORDINATE_MAP = gql`
  mutation upsertPrintLocationCoordinateMap(
    $id: ID
    $printLocationId: ID
    $coordinateMap: [JSONObject]!
    $rotate: Int!
    $viewId: ID
  ) {
    upsertPrintLocationCoordinateMap(
      id: $id
      printLocationId: $printLocationId
      coordinateMap: $coordinateMap
      rotate: $rotate
      viewId: $viewId
    ) {
      id
    }
  }
`

const PrintLocationEditor: React.FC<IPrintLocationEditor> = ({
  view,
  printLocationCoordinateMap,
  setSelectedPrintLocationCoordinateMap,
  refetch,
}) => {
  const x1 = Math.round(printLocationCoordinateMap.coordinateMap[0].x)
  const x2 = Math.round(printLocationCoordinateMap.coordinateMap[3].x)
  const y1 = Math.round(printLocationCoordinateMap.coordinateMap[0].y)
  const y2 = Math.round(printLocationCoordinateMap.coordinateMap[3].y)
  const rotate = printLocationCoordinateMap.rotate

  const [AddPrintLocation] = useMutation(UPSERT_PRINT_LOCATION_COORDINATE_MAP)

  const handleInputChange = (
    value: number,
    coordinateMapIndex: number,
    isX: boolean
  ) => {
    const temp = { ...printLocationCoordinateMap }
    if (isX) temp.coordinateMap[coordinateMapIndex].x = value
    else temp.coordinateMap[coordinateMapIndex].y = value

    setSelectedPrintLocationCoordinateMap(temp)
  }

  const handleRotateChange = (value: number) => {
    const temp = { ...printLocationCoordinateMap }
    temp.rotate = value
    setSelectedPrintLocationCoordinateMap(temp)
  }

  const handleUpsert = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    // TODO: Add more conditions?
    if (x2 - x1 < 50 || y2 - y1 < 50) {
      return <Error message="PrintLocation must be at least 50 x 50 pixels" />
    } else {
      const variables: any = {
        printLocationId: printLocationCoordinateMap.printLocation.id,
        coordinateMap: [
          { x: x1, y: y1 },
          { x: x2, y: y1 },
          { x: x1, y: y2 },
          { x: x2, y: y2 },
        ],
        rotate: rotate,
        viewId: view.id,
      }
      if (printLocationCoordinateMap.id !== '')
        variables.id = printLocationCoordinateMap.id

      AddPrintLocation({ variables: variables })
        .then(() => refetch())
        .catch(error => <Error message={error.message} />)
    }
  }

  return (
    <tr>
      <td colSpan={3}>
        <F.Form>
          <F.Label>
            <p>(x1, y1)</p>
            <G.row>
              <F.Input
                type={'number'}
                name={'x1'}
                value={x1}
                onChange={e => handleInputChange(+e.target.value, 0, true)}
              />
              <F.Input
                type={'number'}
                name={'y1'}
                value={y1}
                onChange={e => handleInputChange(+e.target.value, 0, false)}
              />
            </G.row>
          </F.Label>
          <F.Label>
            <p>(x2, y2)</p>
            <G.row>
              <F.Input
                type={'number'}
                name={'x2'}
                value={x2}
                onChange={e => handleInputChange(+e.target.value, 0, true)}
              />
              <F.Input
                type={'number'}
                name={'y1'}
                value={y1}
                onChange={e => handleInputChange(+e.target.value, 3, false)}
              />
            </G.row>
          </F.Label>
          <F.Label>
            <p>(x3, y3)</p>
            <G.row>
              <F.Input
                type={'number'}
                name={'x1'}
                value={x1}
                onChange={e => handleInputChange(+e.target.value, 0, true)}
              />
              <F.Input
                type={'number'}
                name={'y2'}
                value={y2}
                onChange={e => handleInputChange(+e.target.value, 3, false)}
              />
            </G.row>
          </F.Label>
          <F.Label>
            <p>(x4, y4)</p>
            <G.row>
              <F.Input
                type={'number'}
                name={'x2'}
                value={x2}
                onChange={e => handleInputChange(+e.target.value, 3, true)}
              />
              <F.Input
                type={'number'}
                name={'y2'}
                value={y2}
                onChange={e => handleInputChange(+e.target.value, 3, false)}
              />
            </G.row>
          </F.Label>
          {printLocationCoordinateMap.printLocation.name ===
            SLEEVE_CUSTOMIZATION && (
            <F.Label>
              <p>Rotate</p>
              <G.row>
                <F.Input
                  type={'number'}
                  name={'rotate'}
                  value={rotate}
                  onChange={e => handleRotateChange(parseInt(e.target.value))}
                />
              </G.row>
            </F.Label>
          )}
          <B.Button onClick={handleUpsert}>
            {view.printLocations
              .map(p => p.printLocation.id)
              .includes(printLocationCoordinateMap.printLocation.id)
              ? 'Update'
              : 'Add'}
          </B.Button>
        </F.Form>
      </td>
    </tr>
  )
}

export default ViewManagement
