import { TProduct } from '../../../types'
import React, { useState } from 'react'
import { ButtonStyle as B, ModalLayout as M } from '../../../styles'
import MultiSelectCheckbox from '../../../utils/lib/MultiSelectCheckbox'
import EDIT_PRODUCT_VARIANTS from './gql/EDIT_PROUCT_VARIANTS'
import { useMutation } from '@apollo/react-hooks'

interface IAvailableVariantPickerModalProps {
  visible: boolean
  toggle: any
  refetchProduct: () => void
  product?: TProduct
}

const AvailableVariantPickerModal: React.FC<
  IAvailableVariantPickerModalProps
> = props => {
  const { visible, toggle, refetchProduct, product } = props

  const [unavailableVariants, setUnavailableVariants] = useState<
    string[] | undefined
  >(product?.unavailableVariants?.map(v => v.id))

  const [editProductVariants] = useMutation(EDIT_PRODUCT_VARIANTS)

  const onSubmit = async () => {
    await editProductVariants({
      variables: {
        id: product?.id,
        unavailableVariants: unavailableVariants,
      },
    })
    refetchProduct()
    toggle()
  }

  return (
    <>
      {visible && (
        <M.Modal>
          {product && (
            <>
              <h2>Make Sizes and Colors Unavailable</h2>
              <p>Click check to make it unavailable</p>
              <B.Exit onClick={() => toggle(false)}>X</B.Exit>
              <MultiSelectCheckbox
                options={product?.variantGroups.flatMap((vg: any) =>
                  vg.variants.map((v: any) => ({
                    label: v.id,
                    checked: product?.unavailableVariants
                      ?.map(uV => uV.id)
                      .includes(v.id),
                  }))
                )}
                onChange={(data: any) =>
                  setUnavailableVariants(data.map((data: any) => data.label))
                }
              />
              <B.Button onClick={onSubmit}>Update</B.Button>
            </>
          )}
        </M.Modal>
      )}
    </>
  )
}

export default AvailableVariantPickerModal
