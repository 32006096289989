import { gql } from 'apollo-boost'

export default gql`
  query getSomeInventoryGroups(
    $searches: [String!]
    $offset: Int
    $limit: Int
  ) {
    getSomeInventoryGroups(
      searches: $searches
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      updatedAt
      tags {
        id
        color
        icon
        content
      }
      available
    }
  }
`
