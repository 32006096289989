import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface IMarkdownProps {
  markdown: string
}
const Markdown: React.FC<IMarkdownProps> = ({ markdown }) => {
  return (
    <>
      <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
    </>
  )
}

export default Markdown
