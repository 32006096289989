import React from 'react'
import { UserState } from 'store/reducers/userReducer'
import { ITheme } from 'types/Theme'
import { Page } from 'styles/lib/page'
import { withTheme } from 'styled-components'
import ProductView from './ProductView'
import { AppState } from 'store/store'
import { connect } from 'react-redux'

interface IProductPageProps {
  user: UserState
  theme: ITheme
  darkMode: boolean
}

const ProductPage: React.FC<IProductPageProps> = props => {
  const { theme, darkMode } = props

  return (
    <Page>
      <ProductView theme={theme} darkMode={darkMode} />
    </Page>
  )
}
const mapStateToProps = (state: AppState) => ({
  user: state.user,
  darkMode: state.theme.darkMode,
})

export default connect(mapStateToProps)(withTheme(ProductPage))
