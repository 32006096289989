import React from 'react'
import { ITheme } from 'types'
import { Box, Flex, Heading, Text } from '@sweaterplanet/nucleus-style'

import ContactForm from 'components/ContactForm'
// Styles

interface IContactFormProps {
  theme: ITheme
  darkMode: boolean
  title: string
  description?: string
}

const ContactUs: React.FC<IContactFormProps> = props => {
  const {
    darkMode,
    theme: { colors },
  } = props

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={'20px 0'}
      paddingBottom={'40px'}
    >
      <Box padding={'10px 0'}></Box>
      <Box padding={'20px 0'}>
        <Text color={colors.text.default}>{props.description}</Text>
      </Box>
      <Box padding={'10px 0'}>
        <ContactForm theme={props.theme} darkMode={darkMode} />
      </Box>
    </Flex>
  )
}

export default ContactUs
