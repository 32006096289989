import { gql } from 'apollo-boost'

export default gql`
  query getFile($id: ID!) {
    getFile(id: $id) {
      id
      filename
      mimetype
      encoding
      url
      thumbnail
    }
  }
`
