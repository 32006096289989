import { gql } from 'apollo-boost'

export default gql`
  query getInventoryProvider($id: String!) {
    getInventoryProvider(id: $id) {
      id
      name
      value
    }
  }
`
