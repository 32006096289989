import React, { useEffect } from 'react'
import { TableLayout as T } from 'styles'
import { Flex, Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types'
import { useQuery } from '@apollo/react-hooks'
import GET_SHIPPING_OPTIONS from '../../../gql/GET_SHIPPING_OPTIONS'
import { Error, Loader } from '../../../utils'

interface ShippingSpeedProps {
  selectedShipping: number
  setSelectedShipping: (index: number) => void
  setShippingSpeed: (value: string) => void
  setShippingPrice: (value: number) => void
  country?: string

  theme: ITheme
  darkMode: boolean
}

const ShippingSpeed: React.FC<ShippingSpeedProps> = props => {
  const {
    loading: ShippingOptionsLoading,
    data: ShippingOptionsData,
    error: ShippingOptionsError,
  } = useQuery(GET_SHIPPING_OPTIONS, {
    // variables: { country: props.country || undefined },
  })
  const {
    setSelectedShipping,
    setShippingPrice,
    setShippingSpeed,
    theme: { colors },
  } = props

  useEffect(() => {
    if (ShippingOptionsData) {
      const availOptions = ShippingOptionsData.getShippingOptions.filter(
        (sO: { countries: string[] }) =>
          sO.countries.includes(props.country ?? '')
      )

      const option = availOptions[0]

      if (option) {
        setSelectedShipping(0)
        setShippingPrice(option.price)
        setShippingSpeed(option.name)
      }
    }
  }, [
    setShippingPrice,
    setShippingSpeed,
    setSelectedShipping,
    ShippingOptionsData,
    props.country,
  ])

  const handleSelectShipping = (index: number, value: number) => {
    setSelectedShipping(index)
    setShippingPrice(value)
    setShippingSpeed(
      ShippingOptionsData.getShippingOptions.filter(
        (sO: { countries: string[] }) =>
          sO.countries.includes(props.country ?? '')
      )[index].name
    )
  }
  console.log(props.country)

  return (
    <>
      {ShippingOptionsError && <Error message={ShippingOptionsError.message} />}
      {ShippingOptionsLoading ? (
        <Loader />
      ) : (
        ShippingOptionsData && (
          <T.ShippingTable>
            <thead>
              <tr>
                <th>Shipping Method</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {ShippingOptionsData.getShippingOptions
                .filter((sO: { countries: string[] }) =>
                  sO.countries.includes(props.country ?? '')
                )
                .map((shippingOption: any, i: number) => (
                  <tr
                    onClick={() =>
                      handleSelectShipping(i, shippingOption.price)
                    }
                    style={{
                      backgroundColor:
                        props.selectedShipping === i
                          ? colors.button.background
                          : 'unset',
                    }}
                  >
                    <td>
                      <Flex flexDirection="column">
                        <Text bold color={colors.text.default}>
                          {shippingOption.name}
                        </Text>
                      </Flex>
                    </td>
                    <td>
                      <Flex flexDirection="column">
                        <Text bold color={colors.text.default}>
                          {shippingOption.description}
                        </Text>
                      </Flex>
                    </td>
                    <td>
                      <Text bold color={colors.text.default}>
                        {shippingOption.price}
                      </Text>
                    </td>
                  </tr>
                ))}
            </tbody>
          </T.ShippingTable>
        )
      )}
    </>
  )
}

export default withTheme(ShippingSpeed)
