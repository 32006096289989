import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { AppActions } from 'types/actions'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import userReducer from './reducers/userReducer'
import dataReducer from './reducers/dataReducer'
import themeReducer from './reducers/themeReducer'

const intialState = {}

const reduxDevTools =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const rootReducer = combineReducers({
  user: userReducer,
  data: dataReducer,
  theme: themeReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'theme'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type AppState = ReturnType<typeof persistedReducer>

export const store = createStore(
  persistedReducer,
  intialState,
  reduxDevTools(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>))
)

export const persistor = persistStore(store)
