export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const DESIGN_STUDIO = '/design'

export const PASSWORD_RESET = '/passwordreset'
export const PRODUCTS = '/products'
export const PRODUCT = '/p'
export const CONFIRMATION_REDIRECT = 'http://localhost:3000'
export const CART = '/cart'
export const CATALOG = '/catalog'
export const CHECKOUT = '/checkout'
export const CONFIRMATION = '/confirmation'
export const ORDERS = '/orders'
export const STORE = '/store'
export const STORE_MANAGER = '/storemanager'
export const TERMS = '/terms'
export const ABOUT = '/about'
export const PROMOTION = '/promotion'
export const PAY_IN_STORE = '/payinstore'
export const CONTACT = '/contact'
export const GALLERY = '/gallery'
export const COLLECTIONS = '/collections'
