import styled from 'styled-components'

import { Box, Flex } from '@sweaterplanet/nucleus-style'

const ButtonBox = styled(Box)`
  margin-top: 4rem;
`

const CarouselBox = styled(Box)`
  align-items: center;
  justify-content: space-around;

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    margin-top: 4rem;
    width: 100%;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    width: 50%;
  }
`

const DescriptionBox = styled(Box)`
  margin-top: 4rem;
  width: 80%;
`

const TextBox = styled(Box)`
  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    width: 100%;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    width: 50%;
  }
`

const Wrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (min-width: ${p => p.theme.breakpoints.xs}px) {
    flex-direction: column;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}px) {
    flex-direction: row;
  }
`

export { ButtonBox, CarouselBox, DescriptionBox, TextBox, Wrapper }
