import { gql } from 'apollo-boost'

export default gql`
  query getInventoryGroup($id: ID!) {
    getInventoryGroup(id: $id) {
      id
      name
      description
      updatedAt
      styleId
      templateAddOns {
        id
        name
      }
      inventory {
        inventoryProvider {
          id
        }
      }
      tags {
        id
        color
        icon
        content
      }
      available
      views {
        id
        name
        boundingBox
        base
        texture
      }
      variantGroups {
        id
        name
        variants {
          id
          name
          value
        }
      }
      templateBasePrice
      templatePriceBreaks
    }
  }
`
