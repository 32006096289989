import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Error, Loader } from 'utils'
import { DivLayout as D, UtiliesStyle as U } from 'styles'
import {
  Flex,
  Heading,
  Button,
  Box,
  Text,
  Card,
} from '@sweaterplanet/nucleus-style'
import GET_COUPON from 'gql/GET_COUPON'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import CouponCreateForm from './CouponCreateForm'

interface CouponPageProps {
  match: {
    params: {
      couponId: string
    }
  }
  theme: ITheme
}

const CouponPage: React.FC<CouponPageProps> = props => {
  const {
    theme: { colors },
  } = props
  const {
    loading,
    data,
    error: getCouponError,
    refetch: refetchProduct,
  } = useQuery(GET_COUPON, {
    variables: {
      id: props.match.params.couponId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [modalOpen, toggleModalOpen] = useState<boolean>(false)

  return (
    <>
      {getCouponError && <Error message={getCouponError.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Heading element="h1" color={colors.text.default}>
            {data.getCoupon.name}
          </Heading>
          <Box margin="30px 0">
            <U.status
              color={data.getCoupon.available ? 'green' : 'red'}
              style={{ cursor: 'pointer' }}
              // onClick={() =>
              //   data.getAddOn.available ? handleMakeUnavailableClick() : null
              // }
            >
              <span>●</span>
              {data.getCoupon.available ? 'available' : 'unavailable'}
            </U.status>
            {/* {data.getAddOn.available === false && (
              <U.status>
                <B.subtle onClick={handleMakeAvailableClick}>
                Make Available
              </B.subtle>
              </U.status>
            )} */}
          </Box>
          <D.flex wrap={'wrap'} align={'flex-start'} justify="center">
            <Card bg={colors.card.background} round padding="30px">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading element="h4" color={colors.text.default}>
                  AddOn Info
                </Heading>
                <Box marginLeft="30px">
                  <Button size="sm" onClick={() => toggleModalOpen(!modalOpen)}>
                    Edit
                  </Button>
                </Box>
                <CouponCreateForm
                  visible={modalOpen}
                  toggle={toggleModalOpen}
                  refetch={refetchProduct}
                  coupon={data.getCoupon}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Code:{' '}
                </Heading>
                <Text color={colors.text.default}>{data.getCoupon.id}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Name:{' '}
                </Heading>
                <Text color={colors.text.default}>{data.getCoupon.name}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Value:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getCoupon.value}
                  {data.getCoupon.type === 'CART_PERCENT'
                    ? '%'
                    : data.getCoupon.type === 'CART_AMOUNT'
                    ? '$'
                    : '% OFF SHIPPING'}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Max Uses:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getCoupon.maxUses}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Valid From:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getCoupon.validFrom}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="20px 0"
              >
                <Heading element="h4" color={colors.text.default}>
                  Valid Until:{' '}
                </Heading>
                <Text color={colors.text.default}>
                  {data.getCoupon.validUntil}
                </Text>
              </Flex>
            </Card>
          </D.flex>
        </>
      )}
    </>
  )
}

export default withTheme(CouponPage)
