import { gql } from 'apollo-boost'

export default gql`
  query getSomeProducts($searches: [String!], $offset: Int, $limit: Int) {
    getSomeProducts(
      searches: $searches
      offset: $offset
      limit: $limit
      excludeStoreProducts: true
    ) {
      id
      name
      basePrice
      available
      isDesignable
      defaultColor
      addOnsByLocation {
        id
        addOn {
          id
          basePrice
          priceBreaks
        }
        location {
          id
        }
      }
      designs {
        id
        file {
          id
          url
          filename
        }
        addOn {
          id
          name
          publicName
          basePrice
          priceBreaks
        }
        printLocation {
          id
          name
          price
        }
        rotate
        flip
        vectorize
        origin
        w
        h
      }
      thumbnail {
        id
        url
      }
      tags {
        id
        content
        color
        icon
      }
      variantGroups {
        id
        name
        variants {
          id
          value
          name
        }
      }
      inventoryGroup {
        id
        name
        views {
          id
          name
          boundingBox
          base
          texture
          printLocations {
            id
            printLocation {
              id
              name
              available
              width
              height
            }
            coordinateMap
          }
        }
      }
      store {
        id
      }
    }
  }
`
