import React, { useEffect, useState } from 'react'
import Navigation from 'components/Navigation'
import { useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import { PageLayout as P } from 'styles'
import GET_MANAGED_STORE from './gql/GET_MANAGED_STORE'
import { TOrderItem, TProduct, TVariantSelection } from 'types'
import ProductCard from 'components/Product/lib/ProductCard'
import { Flex, Heading, Box, Text, Image } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types'
import { UserState } from 'store/reducers/userReducer'
import { connect } from 'react-redux'
import { AppState } from 'store/store'
import { SubtleButton } from 'styles/lib/button'
import * as USER_ROUTES from 'routes/user'

import {
  ButtonStyle as B,
  CardStyle as C,
  GridLayout as G,
  DivLayout as D,
  TableLayout as T,
} from '../../../styles'
import { DataTable } from 'components/Admin'
import { Table } from 'styles/lib/table'
import Order from 'components/Account/lib/Order'

interface StoreManagerPageProps {
  match: {
    params: {
      storeId: string
    }
  }
  theme: ITheme
  user: UserState
}

const StoreManagerPageBase: React.FC<StoreManagerPageProps> = props => {
  const { loading, data, error, refetch } = useQuery(GET_MANAGED_STORE, {
    variables: { id: props.match.params.storeId },
  })

  const [showDetails, setShowDetails] = useState<boolean[]>([])

  return (
    <P.Page>
      {error && <Error message={error.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Navigation
            whitelabelNav={!!data.getManagedStore.store.whitelabelNav}
            whitelabelWebsite={data.getManagedStore.store.whitelabelNav}
            whitelabelLogo={
              data.getManagedStore.store.whitelabelNav &&
              data.getManagedStore.store.logo?.url
            }
            whitelabelStoreId={data.getManagedStore.store.id}
          />
          <P.Content>
            <D.flex justify={'space-around'}>
              {data.getManagedStore.store.logo && (
                <Image
                  style={{ paddingRight: '20px' }}
                  width={'50px'}
                  src={data.getManagedStore.store.logo?.url}
                  alt="N/A"
                />
              )}

              <h1 style={{ color: props.theme.colors.contrast }}>
                {data.getManagedStore.store.name}
              </h1>
            </D.flex>

            <G.row>
              <a
                href={`${USER_ROUTES.STORE}/${
                  data.getManagedStore.store.slug ||
                  data.getManagedStore.store.id
                }`}
              >{`${
                process.env.REACT_APP_BE_URL?.includes('localhost')
                  ? 'http://localhost:3000'
                  : process.env.REACT_APP_BE_URL?.includes(
                      'nucleus-backend-dev'
                    )
                  ? 'https://dev.bossypanda.com'
                  : 'https://bossypanda.com'
              }${USER_ROUTES.STORE}/${
                data.getManagedStore.store.slug || data.getManagedStore.store.id
              }`}</a>
            </G.row>

            <G.row>
              <p
                style={{
                  paddingRight: '10px',
                  marginRight: '10px',
                  borderRight: `1px solid`,
                  color: props.theme.colors.contrast,
                  borderColor: props.theme.colors.contrast,
                }}
              >
                {
                  data.getManagedStore.orders.filter(
                    (o: any) => o.paid || o.payInStoreLocation?.id
                  ).length
                }{' '}
                orders
              </p>
              <p
                style={{
                  paddingRight: '10px',
                  marginRight: '10px',
                  borderRight: `1px solid`,
                  color: props.theme.colors.contrast,
                  borderColor: props.theme.colors.contrast,
                }}
              >
                {Array.from(
                  new Set(
                    data.getManagedStore.store.products.flatMap(
                      (p: { OrderItem: any[] }) =>
                        p.OrderItem.filter(
                          oI => oI.Order.paid || oI.Order.payInStoreLocation?.id
                        ).map((oI: { Order: { id: string } }) => oI.Order.id)
                    )
                  )
                )
                  .map(o => {
                    return data.getManagedStore.orders
                      .find((oI: { id: string }) => oI.id === o)
                      .orderItems.reduce(
                        (partialSum: number, a: TOrderItem) =>
                          partialSum +
                          a.variantSelection.reduce(
                            (partialSum: number, b: TVariantSelection) =>
                              partialSum + b.quantity,
                            0
                          ),
                        0
                      )
                  })
                  .reduce(
                    (partialSum: number, a: number) => partialSum + a,
                    0
                  )}{' '}
                items sold
              </p>
              <p style={{ color: props.theme.colors.contrast }}>
                $
                {data.getManagedStore.store.products
                  .flatMap((p: { OrderItem: any[] }) =>
                    p.OrderItem.filter(
                      oI => oI.Order.paid || oI.Order.payInStoreLocation?.id
                    ).flatMap((oI: { subtotal: number }) => oI.subtotal)
                  )
                  .reduce((partialSum: number, a: number) => partialSum + a, 0)
                  .toFixed(2)}
              </p>
            </G.row>
            <G.row>
              <Box
                width="fit-content"
                float="right"
                maxWidth="100%"
                marginTop="2rem"
                style={{ overflow: 'hidden' }}
              >
                <Box maxWidth="100%" whiteSpace="nowrap">
                  <T.CartTable>
                    <thead>
                      <tr>
                        <th>
                          <Text bold color={props.theme.colors.contrast}>
                            Order Details
                          </Text>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <tr>
                          <td colSpan={1}>
                            <Loader />
                          </td>
                        </tr>
                      )}
                      {data.getManagedStore.orders
                        .filter((o: any) => o.paid || o.payInStoreLocation?.id)
                        .map((o: any, index: number) => (
                          <Order
                            theme={props.theme}
                            order={o}
                            selectedColors={[]}
                            showDetails={showDetails[index]}
                            setShowDetails={showDetails =>
                              setShowDetails(prevShowDetails => {
                                const newShowDetails = [...prevShowDetails].map(
                                  () => false
                                )
                                newShowDetails[index] = showDetails
                                return newShowDetails
                              })
                            }
                          />
                        ))}
                    </tbody>
                  </T.CartTable>
                </Box>
              </Box>
            </G.row>
          </P.Content>
        </>
      )}
    </P.Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

const StoreManagerPage = connect(
  mapStateToProps,
  mapActionsToProps
)(withTheme(StoreManagerPageBase))

export default StoreManagerPage
