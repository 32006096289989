import React, { useEffect, useState } from 'react'
import Navigation from 'components/Navigation'
import { useQuery } from '@apollo/react-hooks'
import { Loader, Error } from 'utils'
import { PageLayout as P } from 'styles'
import GET_STORE from './gql/GET_STORE'
import { TProduct } from 'types'
import ProductCard from 'components/Product/lib/ProductCard'
import { Flex, Heading, Box, Text, Image } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types'
import { UserState } from 'store/reducers/userReducer'
import { connect } from 'react-redux'
import { AppState } from 'store/store'
import { SubtleButton } from 'styles/lib/button'

import * as USER_ROUTES from 'routes/user'

interface IStorePageProps {
  match: {
    params: {
      storeId: string
    }
  }
  theme: ITheme
  user: UserState
}

const StorePageBase: React.FC<IStorePageProps> = props => {
  const {
    theme: { colors },
    user,
  } = props

  const { loading, data, error, refetch } = useQuery(GET_STORE, {
    variables: { id: props.match.params.storeId },
  })

  useEffect(() => {
    refetch().catch(error => <Error message={error.message} />)
  })

  const [sortBy, setSortBy] = useState<
    'popularity' | 'price' | 'name' | 'garment'
  >('popularity')

  const [sortedProducts, setSortedProducts] = useState<TProduct[] | undefined>()

  useEffect(() => {
    if (data && data.getStore.products) {
      const products = data.getStore.products.filter(
        (p: TProduct) => p.available
      )
      if (sortBy === 'popularity') {
        setSortedProducts(
          products.sort(
            (a: TProduct, b: TProduct) =>
              (b.orderCount || 0) - (a.orderCount || 0)
          )
        )
      } else if (sortBy === 'price') {
        setSortedProducts(
          products.sort(
            (a: TProduct, b: TProduct) =>
              a.designs.reduce((a, b) => a + b.addOn.basePrice, 0) +
              a.basePrice -
              (b.designs.reduce((a, b) => a + b.addOn.basePrice, 0) +
                b.basePrice)
          )
        )
      } else if (sortBy === 'name') {
        setSortedProducts(
          products.sort((a: TProduct, b: TProduct) =>
            a.name.localeCompare(b.name)
          )
        )
      } else if (sortBy === 'garment') {
        setSortedProducts(
          products.sort((a: TProduct, b: TProduct) =>
            a.inventoryGroup.name.localeCompare(b.inventoryGroup.name)
          )
        )
        setSortedProducts(products)
      }
    }
  }, [data, sortBy])

  return (
    <P.Page>
      {error && <Error message={error.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Navigation
            whitelabelNav={!!data.getStore.whitelabelNav}
            whitelabelWebsite={data.getStore.whitelabelNav}
            whitelabelLogo={
              data.getStore.whitelabelNav && data.getStore.logo?.url
            }
            whitelabelStoreId={data.getStore.id}
          />
          <P.Content>
            <Flex
              flexDirection="column"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                margin="2rem 0"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Box>
                  {data.getStore.bannerImg && (
                    <Image
                      src={data.getStore.logo.url}
                      circle
                      width="8rem"
                      height="8rem"
                    />
                  )}
                </Box>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  margin="1.5rem"
                  width="100%"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <Heading element="h1" color={colors.text.default}>
                      {data.getStore.name}
                    </Heading>

                    {data.getStore.managers
                      .map((m: any) => m.id)
                      .includes(user.credentials.id) ||
                    user.credentials.role === 'ADMIN' ? (
                      <Box marginLeft="30px">
                        <SubtleButton
                          onClick={() =>
                            (window.location.href = `${USER_ROUTES.STORE_MANAGER}/${data.getStore.id}`)
                          }
                        >
                          Manage
                        </SubtleButton>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Box margin="1rem 0" maxWidth="900px">
                    <Text color={colors.text.default}>
                      {data.getStore.description}
                    </Text>
                  </Box>
                  {/*<Flex flexDirection="row" margin="10px 0">*/}
                  {/*  <Box margin="0 1rem 0 0">*/}
                  {/*    <FontAwesomeIcon*/}
                  {/*      icon={faFacebook as IconProp}*/}
                  {/*      size="2x"*/}
                  {/*      color={colors.contrast}*/}
                  {/*      style={{ cursor: 'pointer' }}*/}
                  {/*    />*/}
                  {/*  </Box>*/}
                  {/*  <Box margin="0 1rem">*/}
                  {/*    <FontAwesomeIcon*/}
                  {/*      icon={faYoutube as IconProp}*/}
                  {/*      size="2x"*/}
                  {/*      color={colors.contrast}*/}
                  {/*      style={{ cursor: 'pointer' }}*/}
                  {/*    />*/}
                  {/*  </Box>*/}
                  {/*  <Box margin="0 1rem">*/}
                  {/*    <FontAwesomeIcon*/}
                  {/*      icon={faInstagram as IconProp}*/}
                  {/*      size="2x"*/}
                  {/*      color={colors.contrast}*/}
                  {/*      style={{ cursor: 'pointer' }}*/}
                  {/*    />*/}
                  {/*  </Box>*/}
                  {/*  <Box margin="0 1rem">*/}
                  {/*    <FontAwesomeIcon*/}
                  {/*      icon={faEnvelope}*/}
                  {/*      size="2x"*/}
                  {/*      color={colors.contrast}*/}
                  {/*      style={{ cursor: 'pointer' }}*/}
                  {/*    />*/}
                  {/*  </Box>*/}
                  {/*</Flex>*/}
                </Flex>
              </Flex>
              {data.getStore.bannerImg && (
                <Flex
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  marginBottom="5rem"
                >
                  <Image width="100%" src={data.getStore.bannerImg.url} />
                </Flex>
              )}
              <Flex
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin="1rem"
              >
                <Text color={colors.text.default}>Sort by:</Text>
                <Box margin="0 1rem">
                  <select
                    value={sortBy}
                    onChange={(e: any) => setSortBy(e.target.value)}
                  >
                    <option value="popularity">Popularity</option>
                    <option value="price">Price</option>
                    <option value="name">Name</option>
                    <option value="garment">Garment</option>
                  </select>
                </Box>
              </Flex>
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                {sortedProducts?.length &&
                  sortedProducts.map(
                    (product: TProduct, index: number) =>
                      product.available && (
                        <Box margin="1rem" key={index}>
                          <ProductCard product={product} />
                        </Box>
                      )
                  )}
              </Flex>
            </Flex>
          </P.Content>
        </>
      )}
    </P.Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const mapActionsToProps = {}

const StorePage = connect(
  mapStateToProps,
  mapActionsToProps
)(withTheme(StorePageBase))

export default StorePage
