import React, { useState, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// Components
import DesignViewer from 'components/Design/lib/DesignViewer'
import { Error, Loader } from 'utils'
import ColorPicker from 'components/ColorPicker'
import BulkPricingTable from 'components/BulkPricingTable'
import AddToCartButton from 'components/AddToCartButton'

// Types
import { TView } from 'types/View'
import { TVariant } from 'types/Variant'
import { TTag } from 'types/Tag'
import { TAddOn } from 'types/AddOn'
import { ITheme } from 'types/Theme'
import { TDesign, TTempDesign } from 'types/Design'

// Styles
import { Input } from 'styles/lib/form'
import {
  Card,
  Flex,
  Tag,
  Heading,
  Button,
  Box,
  Text,
  Switch,
  // Link,
  QuestionMark,
} from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'

// GQL
import GET_PRODUCT from './gql/GET_PRODUCT'

// Utils
import { TColorObj, sortColorsByHue, constructColor } from 'utils/lib/color'

// Imports
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import LayerPicker from '../../Design/lib/LayerPicker'
import AddOnPicker from '../../Design/lib/AddOnPicker'
import GET_FILE from '../../../gql/GET_FILE'
import { TAddOnByLocation } from '../../../types/AddOnByLocation'
import { TPrintLocation } from '../../../types/PrintLocation'
import { TPrintLocationCoordinateMap } from '../../../types'
import Navigation from '../../Navigation'
import { Content } from '../../../styles/lib/page'
import SizeInputWrapper from './SizeInputWrapper'
import Markdown from 'components/Markdown'
import { useMediaQuery } from 'react-responsive'
import DELETE_PRODUCT from './gql/DELETE_PRODUCT'

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

interface IProductPageProps {
  match: {
    params: {
      productId: string
    }
  }
  theme: ITheme
  darkMode: boolean
  history: any
  location: {
    search: any
  }
}

const ProductView: React.FC<
  IProductPageProps & RouteComponentProps
> = props => {
  const {
    theme: { colors },
  } = props

  const { loading, data, error, refetch } = useQuery(GET_PRODUCT, {
    variables: { id: props.match.params.productId.split('?')[0] },
  })

  const search = props.location.search // could be '?foo=bar'
  const params = useMemo(() => new URLSearchParams(search), [search])
  const color = params.get('color') // bar
  const previewID = params.get('previewID')
  const previewAddOnByLocationId = params.get('previewLoc')
  const dec = params.get('dec')

  const [loaded, setLoaded] = useState(false)
  // Product States
  const [sizeIndex, setSizeIndex] = useState(-1)
  const [sizes, setSizes] = useState<TVariant[]>([])
  const [colorIndex, setColorIndex] = useState(-1)
  const [selectedColor, setSelectedColor] = useState<TVariant>({
    id: '',
    name: '',
    value: '#808080',
    available: true,
    variantGroup: {
      id: '',
      name: 'Color',
      variants: [],
    },
  })
  const [addToCartError, setAddToCartError] = useState('')
  const [productColors, setProductColors] = useState<TVariant[]>([])
  // const [, setSelectedColors] = useState<TVariant[]>([])
  const [selectedColorIndexes, setSelectedColorIndexes] = useState<number[]>([])
  const [isMultiple, setIsMultiple] = useState(false)
  // const [isSleeveCustomization, setIsSleeveCustomization] = useState(false)
  const isSleeveCustomization = false
  const [printCost, setPrintCost] = useState<number>(0)
  const [basePrintCost, setBasePrintCost] = useState<number>(0)
  const [printCostPriceBreaks, setPrintCostPriceBreaks] = useState<
    Record<string, number[]>
  >({})

  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [defaultColor, setDefaultColor] = useState<TVariant>()

  const [variantSelections, setVariantSelections] = useState<
    Record<
      string,
      Record<string, { quantity: number; customizations: { name: string }[] }>
    >[]
  >([])
  const [initialSizesWithQuantity, setInitialSizesWithQuantity] = useState<
    Record<string, { quantity: number; customizations: { name: string }[] }>
  >({})
  const [isDesignStudio, setIsDesignStudio] = useState(false)

  // Tour states
  const [isTour, setIsTour] = useState(() => {
    const defaultValue = false
    const openTour = localStorage.getItem('isTour')
    return openTour !== null ? JSON.parse(openTour) : defaultValue
  })
  const [tourStep, setTourStep] = useState<number | undefined>(undefined)

  useEffect(() => {
    localStorage.setItem('isTour', JSON.stringify(isTour))
  }, [isTour])

  // Design Studio States
  const [activeView, setActiveView] = useState<TView | null>(null)
  const [addOnError, setAddOnError] = useState(false)
  const [tempDesigns, setTempDesigns] = useState<TTempDesign[]>([])
  const [selectedDesign, setSelectedDesign] = useState<TTempDesign>({
    designIndex: -2,
    file: {
      id: '',
      encoding: '',
      filename: '',
      mimetype: '',
      url: '',
      thumbnail: '',
    },
    addOn: {
      available: false,
      id: '',
      name: '',
      publicName: '',
      basePrice: 0,
      priceBreaks: undefined,
    },
    addOnIndex: -1,
    view: {
      base: '',
      boundingBox: [],
      id: '',
      name: '',
      printLocations: [],
      texture: '',
    },
    printLocation: {
      available: false,
      height: 0,
      id: '',
      name: '',
      width: 0,
    },
    printLocationIndex: -1,
    origin: { x: 0, y: 0 },
    w: 0,
    h: 0,
  })
  const [selectedPrintLocationIndex, setSelectedPrintLocationIndex] =
    useState(-1)
  const defaultImageWidth = 100
  const defaultImageHeight = 100

  const [availableAddOns, setAvailableAddOns] = useState<TAddOn[] | undefined>()

  const [selectedAddOn, setSelectedAddOn] = useState<TAddOn>()
  const [selectedAddOnIndex, setSelectedAddOnIndex] = useState<number>()

  const handleSetSelectedAddOn = (addOn: TAddOn, index: number) => {
    setSelectedAddOn(addOn)
    setSelectedAddOnIndex(index)

    setAddOnError(false)

    const selectedDesignData = {
      ...selectedDesign,
      addOn: addOn,
      addOnIndex: index,
    }

    setSelectedDesign(selectedDesignData)
    // const tempDesignsData = tempDesigns.map((t: TTempDesign) => ({
    //   ...t,
    //   addOn: addOn,
    //   addOnIndex: index,
    // }))
    const tempDesignsData = [...tempDesigns].map((t: TTempDesign) => ({
      ...t,
      addOn: addOn,
      addOnIndex: index,
    }))

    // tempDesignsData[selectedDesignData.designIndex] = selectedDesignData
    setTempDesigns(tempDesignsData)
  }

  ////////

  useEffect(() => {
    if (data?.getProduct?.addOnsByLocation && tempDesigns) {
      const activeLocations = tempDesigns.map(
        tempDesign => tempDesign.printLocation
      )
      const uniqueActiveLocations = Array.from(
        new Set(activeLocations.map(a => a.id))
      ).map(id => {
        return activeLocations.find(a => a.id === id)
      })

      const allAddOns: TAddOn[] = Array.from(
        new Set(
          data.getProduct.addOnsByLocation.map(
            (aOBL: TAddOnByLocation) => aOBL.addOn
          )
        )
      )

      const addOnsByLocationFiltered = data.getProduct.addOnsByLocation.filter(
        (aOBL: TAddOnByLocation) => {
          return uniqueActiveLocations.filter(uAL => {
            console.log('HERE', uAL)
            console.log('HERE1', aOBL.location)
            console.log(
              'HERE2',
              uAL && containsPrintLocation(uAL, aOBL.location)
            )
            return uAL && containsPrintLocation(uAL, aOBL.location)
          })
        }
      )

      const addOnsFiltered = Array.from(
        new Set(
          addOnsByLocationFiltered.map((a: TAddOnByLocation) => a.addOn.id)
        )
      ).map(id => allAddOns.find(a => a.id === id))

      setAvailableAddOns(addOnsFiltered as TAddOn[])

      if (dec && addOnsFiltered && !selectedAddOnIndex && !selectedAddOn) {
        const index = addOnsFiltered?.findIndex(a => a?.id === dec)
        setSelectedAddOn(addOnsFiltered[index] || undefined)
        setSelectedAddOnIndex(index)
      }
    }
  }, [tempDesigns, data, dec, selectedAddOnIndex, selectedAddOn])

  const [bulkPrice, setBulkPrice] = useState<number | null>(null)

  useEffect(() => {
    let baseAddOnPrice = 0
    let totalAddOnPrice = 0
    let bulkAddOnPrices: Record<string, number[]> = {}

    const totalQuantity = variantSelections
      .flatMap(vS => Object.values(vS))
      .flatMap(vSv => Object.values(vSv))
      .flatMap(vSvv => vSvv.quantity)
      .reduce((prev, curr) => prev + curr, 0)

    if (data) {
      const priceBreakKeys = Object.keys(data.getProduct.priceBreaks)
      setBulkPrice(null)
      for (const x of priceBreakKeys) {
        if ((totalQuantity || 1) >= parseInt(x)) {
          setBulkPrice(data.getProduct.priceBreaks[x])
        }
      }
    }
    if (data && (tempDesigns || data.getProduct?.designs)) {
      const loadedDesigns = data.getProduct.designs || []
      const designs = [...loadedDesigns, ...tempDesigns]
      console.log('Designs: ', designs)
      designs.forEach((design: TTempDesign | TDesign, index) => {
        let bulkAddOnPrice = design.addOn?.basePrice || 0
        if (design.addOn?.priceBreaks) {
          console.log('Price Breaks', design.addOn.priceBreaks)
          for (const x of Object.keys(design.addOn.priceBreaks)) {
            if ((totalQuantity || 1) >= parseInt(x)) {
              bulkAddOnPrice = design.addOn.priceBreaks[x][index]
            }

            bulkAddOnPrices[x] =
              design.addOn?.priceBreaks && design.addOn.priceBreaks[x]
                ? design.addOn.priceBreaks[x]
                : []
          }

          totalAddOnPrice += bulkAddOnPrice
          baseAddOnPrice += bulkAddOnPrices[1]
            ? bulkAddOnPrices[1][index]
            : design.addOn.basePrice

          console.log(
            'bulk add on price for quantity',
            totalQuantity || 1,
            'at design',
            index,
            'is',
            bulkAddOnPrice,
            'making the total add on price',
            totalAddOnPrice
          )
        }
      })
    }
    setBasePrintCost(baseAddOnPrice)
    setPrintCost(totalAddOnPrice)
    setTotalQuantity(totalQuantity)
    setPrintCostPriceBreaks(bulkAddOnPrices)
  }, [data, tempDesigns, variantSelections])

  const mergePriceBreaks = (data: TDesign[]) => {
    const addOnPriceBreaks: Record<string, number[]> = {}

    data.forEach((design: TDesign) => {
      const currentPriceBreaks: Record<string, number[]> =
        design.addOn.priceBreaks!

      for (let [key, value] of Object.entries(currentPriceBreaks)) {
        if (addOnPriceBreaks[key]) {
          addOnPriceBreaks[key] = addOnPriceBreaks[key].map((p, i) => value[i])
        } else {
          if (design.addOn.priceBreaks) {
            addOnPriceBreaks[key] = design.addOn.priceBreaks[key].map(
              (p, i) => value[i]
            )
          }
        }
      }
    })

    console.log('ADD ON PRICE BREAKS AFTER MERGE: ', addOnPriceBreaks)
    return addOnPriceBreaks
  }

  useEffect(() => {
    if (data) {
      setPrintCostPriceBreaks(
        mergePriceBreaks([...data.getProduct.designs, ...tempDesigns])
      )
    }
  }, [data, tempDesigns])

  useEffect(() => {
    refetch().catch(error => <Error message={error.message} />)
  })

  useEffect(() => {
    if (data) {
      setActiveView(data.getProduct.inventoryGroup.views[0])

      const sizeIndexData = data.getProduct.variantGroups.findIndex(
        (variantGroup: any) => variantGroup.name === 'Size'
      )
      if (sizeIndexData !== -1) {
        setSizeIndex(sizeIndexData)
        const sortedSizes = sortSizes(
          data.getProduct.variantGroups[sizeIndexData].variants
        )
        setSizes(sortedSizes)

        const sizesWithQuantity: Record<
          string,
          { quantity: number; customizations: { name: string }[] }
        > = {}
        sortedSizes.forEach((size: TVariant) => {
          sizesWithQuantity[`${size.id}/${size.name}/${size.value}`] = {
            quantity: 0,
            customizations: [],
          }
        })
        setInitialSizesWithQuantity(sizesWithQuantity)
      }

      const colorIndexData = data.getProduct.variantGroups.findIndex(
        (variantGroup: any) => variantGroup.name === 'Color'
      )
      setColorIndex(colorIndexData)
      if (colorIndexData !== -1) {
        const colorObjects: TColorObj[] = []
        data.getProduct.variantGroups[colorIndexData].variants.forEach(
          (color: TVariant) => {
            colorObjects.push(constructColor(color))
          }
        )
        const sortedColors = sortColorsByHue(colorObjects).map(
          (colorObject: TColorObj) => colorObject.variantFields
        )

        // const sortedColors = colorObjects.map(
        //   (colorObject: TColorObj) => colorObject.variantFields
        // )

        setProductColors(sortedColors)

        const defaultColor =
          data.getProduct.defaultColor &&
          sortedColors.filter(s => s.value === data.getProduct.defaultColor)[0]

        if (defaultColor) {
          setSelectedColor(defaultColor)
          setDefaultColor(defaultColor)
        } else {
          setSelectedColor(sortedColors[0])
          setDefaultColor(sortedColors[0])
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (productColors && productColors.length) {
      if (defaultColor) {
        document.getElementById(`swatch-${defaultColor.name}`)?.click()
      }
      if (color) {
        const findCol = productColors.find(c => c.name === `${color}`)
        if (findCol) {
          document.getElementById(`swatch-${findCol.name}`)?.click()
        } else if (!defaultColor) {
          document.getElementById(`swatch-${productColors[0].name}`)?.click()
        }
      }
      setLoaded(true)
    }
  }, [color, productColors, defaultColor])

  const containsPrintLocation = (
    printLocation: TPrintLocation,
    location: TPrintLocation
  ) => {
    return location.id === printLocation.id
  }
  const { data: previewFileData } = useQuery(GET_FILE, {
    variables: {
      id: previewID,
    },
  })
  useEffect(() => {
    if (previewFileData && data && data.getProduct.isDesignable) {
      const previewAddOnByLocation: TAddOnByLocation | undefined =
        data.getProduct.addOnsByLocation.find(
          (addOnByLocation: TAddOnByLocation) =>
            addOnByLocation.id === previewAddOnByLocationId
        )
      const printLocation =
        previewAddOnByLocation?.location ||
        data.getProduct.addOnsByLocation[0].location

      const designIndex =
        selectedDesign.file.id !== '' && selectedDesign.designIndex >= 0
          ? selectedDesign.designIndex + 1
          : tempDesigns.length

      const firstAddOn =
        previewAddOnByLocation?.addOn ||
        data.getProduct.addOnsByLocation[0]?.addOn

      const firstAddOnIndex = data.getProduct.addOnsByLocation.findIndex(
        (addOnByLocation: TAddOnByLocation) =>
          previewAddOnByLocation
            ? addOnByLocation.addOn.id === previewAddOnByLocation?.addOn.id &&
              addOnByLocation.location.id ===
                previewAddOnByLocation?.location.id
            : containsPrintLocation(printLocation, addOnByLocation.location)
      )
      // const firstAddOnIndex = 3
      const printLocationCoordinateMaps =
        data.getProduct.inventoryGroup.views.flatMap((view: any) =>
          view.printLocations.flatMap((PL: any) => PL)
        )

      const coordinateMap = printLocationCoordinateMaps.find(
        (pLCM: TPrintLocationCoordinateMap) =>
          pLCM.printLocation.id === printLocation.id
      ).coordinateMap

      const designData: TTempDesign = {
        designIndex: designIndex,
        addOn: firstAddOn,
        addOnIndex: firstAddOnIndex,
        file: previewFileData.getFile,
        view: data.getProduct.inventoryGroup.views[0],
        printLocation: printLocation,
        printLocationIndex: 0,
        origin: { x: 0, y: 0 },
        w: coordinateMap[3]?.x - coordinateMap[0]?.x,
        h: coordinateMap[3]?.y - coordinateMap[0]?.y,
      }
      setSelectedDesign(designData)
      const previewView = data.getProduct.inventoryGroup.views
        .flatMap((v: TView) => ({
          view: v,
          pLs: v.printLocations.flatMap(pL => pL.printLocation.id),
        }))
        .find((vA: { view: TView; pLs: string[] }) =>
          vA.pLs.includes(
            previewAddOnByLocation?.location.id ||
              data.getProduct.addOnsByLocation[0].location.id
          )
        )?.view

      if (previewView) {
        setActiveView(previewView)
      } else {
        setActiveView(data.getProduct.inventoryGroup.views[0])
      }
      const tempDesignsData = tempDesigns.splice(0)
      tempDesignsData.splice(designIndex, 0, designData)
      for (let i = designIndex; i < tempDesignsData.length; i++) {
        tempDesignsData[i].designIndex = i
      }
      setTempDesigns(tempDesignsData)
    }
  }, [previewFileData, data]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortSizes = (sizes: TVariant[]) => {
    const orderedSizesObject: Record<string, TVariant | null> = {
      XS: null,
      S: null,
      M: null,
      L: null,
      XL: null,
      '2XL': null,
      '3XL': null,
      '4XL': null,
      '5XL': null,
    }

    sizes.forEach((size: TVariant) => {
      if (Object.keys(orderedSizesObject).includes(size.name))
        orderedSizesObject[size.name] = size
    })

    const values = Object.values(orderedSizesObject)
    for (let i = 0; i < values.length; i++) {
      if (values[i] !== null) break
      else if (values[i] === null && i === values.length - 1) return sizes
    }

    const orderedSizes: TVariant[] = []
    values.forEach((size: TVariant | null) => {
      if (size !== null) orderedSizes.push(size)
    })

    if (orderedSizes.length < sizes.length) return sizes
    else return orderedSizes
  }

  const handleAlternateViewClick = (view: TView) => {
    setActiveView(view)
  }

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    color: TVariant,
    size: TVariant
  ) => {
    setAddToCartError('')
    let num = parseInt(event.target.value)
    if (num < 0 || isNaN(num)) num = 0

    const tempVariantSelections = [...variantSelections]
    const customizations =
      tempVariantSelections[index][`${color.id}/${color.name}/${color.value}`][
        `${size.id}/${size.name}/${size.value}`
      ].customizations

    if (!isSleeveCustomization) {
      tempVariantSelections[index][`${color.id}/${color.name}/${color.value}`][
        `${size.id}/${size.name}/${size.value}`
      ] = { quantity: num, customizations: [] }
    } else {
      if (num > customizations.length) {
        tempVariantSelections[index][
          `${color.id}/${color.name}/${color.value}`
        ][`${size.id}/${size.name}/${size.value}`] = {
          quantity: num,
          customizations: [
            ...customizations,
            ...Array.from({ length: num - customizations.length }).map(() => ({
              name: '',
            })),
          ],
        }
      } else if (num < customizations.length) {
        const diff = customizations.length - num
        for (let i = 0; i < diff; i++) {
          customizations.pop()
        }
        tempVariantSelections[index][
          `${color.id}/${color.name}/${color.value}`
        ][`${size.id}/${size.name}/${size.value}`] = {
          quantity: num,
          customizations: [...customizations],
        }
      }
    }

    setVariantSelections(tempVariantSelections)
  }

  const handleSetMultiple = () => {
    if (isMultiple) {
      const tempSelectedColorIndexes =
        selectedColorIndexes.length > 0 ? [selectedColorIndexes[0]] : []
      setSelectedColorIndexes(tempSelectedColorIndexes)

      const tempVariantSelections =
        variantSelections.length > 0 ? [variantSelections[0]] : []
      setVariantSelections(tempVariantSelections)
    }
    setIsMultiple(!isMultiple)
  }

  // const handleSetIsSleeveCustomization = () => {
  //   const tempVariantSelections = [...variantSelections]
  //   if (isSleeveCustomization) {
  //     tempVariantSelections.forEach(
  //       (
  //         variantSelection: Record<
  //           string,
  //           Record<
  //             string,
  //             { quantity: number; customizations: { name: string }[] }
  //           >
  //         >,
  //         index: number
  //       ) => {
  //         Object.entries(variantSelection).forEach(
  //           (
  //             colorWithSizes: [
  //               string,
  //               Record<
  //                 string,
  //                 { quantity: number; customizations: { name: string }[] }
  //               >
  //             ]
  //           ) => {
  //             Object.entries(colorWithSizes[1]).forEach(
  //               (
  //                 sizeWithQuantity: [
  //                   string,
  //                   { quantity: number; customizations: { name: string }[] }
  //                 ]
  //               ) => {
  //                 tempVariantSelections[index][colorWithSizes[0]][
  //                   sizeWithQuantity[0]
  //                 ] = {
  //                   quantity: sizeWithQuantity[1].quantity,
  //                   customizations: [],
  //                 }
  //               }
  //             )
  //           }
  //         )
  //       }
  //     )
  //   } else {
  //     tempVariantSelections.forEach(
  //       (
  //         variantSelection: Record<
  //           string,
  //           Record<
  //             string,
  //             { quantity: number; customizations: { name: string }[] }
  //           >
  //         >,
  //         index: number
  //       ) => {
  //         Object.entries(variantSelection).forEach(
  //           (
  //             colorWithSizes: [
  //               string,
  //               Record<
  //                 string,
  //                 { quantity: number; customizations: { name: string }[] }
  //               >
  //             ]
  //           ) => {
  //             Object.entries(colorWithSizes[1]).forEach(
  //               (
  //                 sizeWithQuantity: [
  //                   string,
  //                   { quantity: number; customizations: { name: string }[] }
  //                 ]
  //               ) => {
  //                 tempVariantSelections[index][colorWithSizes[0]][
  //                   sizeWithQuantity[0]
  //                 ] = {
  //                   quantity: sizeWithQuantity[1].quantity,
  //                   customizations: Array.from({
  //                     length: sizeWithQuantity[1].quantity,
  //                   }).map(() => ({ name: '' })),
  //                 }
  //               }
  //             )
  //           }"block":
  //         )
  //       }
  //     )
  //   }
  //   setIsSleeveCustomization(!isSleeveCustomization)
  // }

  const handleCustomizationNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    colorKey: string,
    sizeKey: string,
    customizationIndex: number
  ) => {
    const tempVariantSelections = [...variantSelections]
    tempVariantSelections[index][colorKey][sizeKey].customizations[
      customizationIndex
    ].name = event.target.value
    setVariantSelections(tempVariantSelections)
  }

  const tourConfig = [
    {
      selector: '[data-tut="tour_1"]',
      content: () => (
        <Box marginRight="20px">
          <Text color={colors.text.default}>Step 1: Choose a color!</Text>
        </Box>
      ),
      style: {
        backgroundColor: colors.card.background2,
      },
      action: () => setTourStep(undefined),
    },
    {
      selector: '[data-tut="tour_2"]',
      content: () => (
        <Box marginRight="20px">
          <Text color={colors.text.default}>
            You can choose multiple colors!
          </Text>
        </Box>
      ),
      style: {
        backgroundColor: colors.card.background2,
      },
    },
    {
      selector: '[data-tut="tour_3"]',
      content: () => (
        <Box marginRight="20px">
          <Text color={colors.text.default}>Let's start designing!</Text>
        </Box>
      ),
      style: {
        backgroundColor: colors.card.background2,
      },
      action: () => {
        if (isDesignStudio && addOnError) {
          setTourStep(7)
        } else {
          setIsDesignStudio(false)
        }
      },
    },
    {
      selector: '[data-tut="tour_4"]',
      content: () => (
        <Box marginRight="20px">
          <Text color={colors.text.default}>
            Click on a print area to add an image!
          </Text>
          <Text color={colors.text.default}>Upload an image your computer</Text>
          <Text color={colors.text.default}>JPG, PNG, SVG</Text>
        </Box>
      ),
      style: {
        backgroundColor: colors.card.background2,
      },
      action: () => {
        if (!isDesignStudio) setIsDesignStudio(true)
        setTourStep(undefined)
      },
    },
    // {
    //   selector: '[data-tut="tour_6"]',
    //   content: () => (
    //     <Box marginRight="20px">
    //       <Box marginBottom="20px">
    //         <Text color={colors.text.default}>
    //           Awesome you can click on your uploads to select or delete them.
    //         </Text>
    //       </Box>
    //     </Box>
    //   ),
    //   style: {
    //     backgroundColor: colors.card.background2,
    //   },
    //   action: () => setTourStep(undefined),
    // },
    {
      selector: '[data-tut="tour_7"]',
      content: () => (
        <Box marginRight="20px">
          <Text color={colors.text.default}>
            Now it's time to pick your print method.
          </Text>
          <Text color={colors.text.default}>
            Make sure you do this for every upload.
          </Text>
        </Box>
      ),
      style: {
        backgroundColor: colors.card.background2,
      },
      action: () => setTourStep(undefined),
    },
    {
      selector: '',
      content: () => (
        <Box marginRight="20px">
          <Text color={colors.text.default}>Don't worry, have fun!</Text>
          <Text color={colors.text.default}>
            All orders are reviewed by a real person.
          </Text>
        </Box>
      ),
      style: {
        backgroundColor: colors.card.background2,
      },
    },
  ]

  const disableBody = (target: HTMLDivElement) => disableBodyScroll(target)
  const enableBody = (target: HTMLDivElement) => enableBodyScroll(target)

  const [showUploadBoxes, setShowUploadBoxes] = useState(false)

  const handleSetShowUploadBoxes = () => {
    setShowUploadBoxes(!showUploadBoxes)
  }

  useEffect(() => {
    if (data && data.getProduct.isDesignable) {
      setShowUploadBoxes(true)
    }
  }, [data])

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1300px)' })

  const [descriptionHidden, setDescriptionHidden] = useState(isTabletOrMobile)

  // useEffect(() => {
  //   if (selectedColor && loaded && selectedColor.name !== params.get('color')) {
  //     params.set('color', selectedColor.name)
  //     props.history.push({ search: params.toString() })
  //   }
  // }, [selectedColor, params, props.history, loaded])

  return (
    <>
      {error && <Error message={error.message} />}
      {loading && <Loader />}
      {data && (
        <>
          <Navigation
            whitelabelNav={!!data.getProduct.store?.whitelabelNav}
            whitelabelWebsite={data.getProduct.store?.whitelabelNav}
            whitelabelLogo={
              data.getProduct.store?.whitelabelNav &&
              data.getProduct.store?.logo?.url
            }
            whitelabelStoreId={data.getProduct.store?.id}
          />

          <Content>
            <Tour
              steps={tourConfig}
              isOpen={isTour}
              disableDotsNavigation={true}
              rounded={5}
              goToStep={tourStep}
              onAfterOpen={(target: HTMLDivElement) => {
                disableBody(target)
                if (isDesignStudio) setTourStep(5)
                else setTourStep(0)
              }}
              onBeforeClose={enableBody}
              onRequestClose={() => setIsTour(false)}
            />
            <div
              style={{
                position: 'absolute',
                width: '85vw',
                display: 'flex',
                justifyContent: isTabletOrMobile
                  ? 'space-around'
                  : 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box>
                <div
                  style={{
                    position: isTabletOrMobile ? 'relative' : 'fixed',
                    top: isTabletOrMobile ? '0px' : '130px',
                    left: isTabletOrMobile ? '0px' : '5%',
                    width: isTabletOrMobile ? '100%' : '45%',
                  }}
                >
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {isDesignStudio ? (
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        width="100%"
                      >
                        <Box margin="0 10px">
                          <QuestionMark dark={props.darkMode ? true : false} />
                        </Box>
                        <Card round bg={colors.card.background} padding="20px">
                          <Heading element="h4" color={colors.text.default}>
                            Don't Worry, Have Fun!
                          </Heading>
                          <Text color={colors.text.default}>
                            All Orders are reviewed by a real person and
                            approved by you
                          </Text>
                        </Card>
                      </Flex>
                    ) : (
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width={'100%'}
                      >
                        <Button
                          bg="green"
                          color="white"
                          onClick={() => setIsTour(true)}
                          size={'sm'}
                        >
                          Guide
                        </Button>
                        {data.getProduct.isDesignable && (
                          <Box padding="0 10px">
                            <Switch
                              color="green"
                              id="uploadBoxToggle"
                              checked={showUploadBoxes}
                              onChange={() => handleSetShowUploadBoxes()}
                            />
                          </Box>
                        )}
                      </Flex>
                    )}
                    <Box data-tut="tour_4">
                      <DesignViewer
                        setActiveView={handleAlternateViewClick}
                        activeView={activeView}
                        views={data.getProduct.inventoryGroup.views}
                        color={selectedColor.value}
                        designs={data.getProduct.designs}
                        tempDesigns={tempDesigns}
                        setTempDesigns={setTempDesigns}
                        selectedDesign={selectedDesign}
                        setSelectedDesign={setSelectedDesign}
                        showPrintLocations={data.getProduct.isDesignable}
                        selectedPrintLocationIndex={selectedPrintLocationIndex}
                        setSelectedPrintLocationIndex={
                          setSelectedPrintLocationIndex
                        }
                        isDesignStudio={showUploadBoxes}
                        isSleeveCustomization={isSleeveCustomization}
                        addOnsByLocation={data.getProduct.addOnsByLocation}
                        product={data.getProduct}
                        selectedAddOn={selectedAddOn}
                        selectedAddOnIndex={selectedAddOnIndex}
                      />
                    </Box>
                  </Flex>
                </div>
              </Box>

              <Flex
                flexDirection="column"
                height="100%"
                width={'100%'}
                maxWidth={'522px'}
              >
                <Card
                  round
                  bg={colors.card.background}
                  padding="20px"
                  margin="10px"
                  // marginBottom="-10px"
                >
                  <Flex flexDirection="column" alignItems="flex-start">
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      flexWrap={'wrap'}
                      width={'100%'}
                    >
                      <Heading element="h2" color={colors.text.default}>
                        {data.getProduct.name}
                      </Heading>
                      <Box>
                        {bulkPrice ? (
                          <Heading element="h3" color={colors.text.default}>
                            <del style={{ color: 'red' }}>
                              $
                              {formatter.format(
                                data.getProduct.basePrice + basePrintCost
                              )}
                            </del>
                            <br />${formatter.format(bulkPrice + printCost)}
                          </Heading>
                        ) : (
                          <Heading element="h3" color={colors.text.default}>
                            $
                            {formatter.format(
                              data.getProduct.basePrice + printCost
                            )}
                          </Heading>
                        )}
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      wrap="wrap"
                      justifyContent="flex-start"
                    >
                      {data.getProduct.tags.map(
                        (tag: TTag, index: number) =>
                          tag.id !== 'featured' && (
                            <Box key={index} margin="5px 10px 0 0">
                              <Tag
                                color={tag.color}
                                text={`${tag?.icon || '' + ' ' || ''}${
                                  tag.content
                                }`}
                              />
                            </Box>
                          )
                      )}
                    </Flex>
                    {/* toggle box */}

                    <Box margin="0 0 0 0" width="100%">
                      <Text
                        color={colors.text.default}
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        <p
                          style={{
                            display: descriptionHidden ? 'none' : '',
                            color: colors.text.default,
                          }}
                        >
                          <Markdown
                            markdown={`${
                              data.getProduct.description
                                ? data.getProduct.description.replaceAll(
                                    '| |',
                                    '|\n|'
                                  )
                                : ' '
                            }`}
                          />
                        </p>
                        {!data.getProduct.isDesignable &&
                        data.getProduct.designs.length ? (
                          <div style={{ display: 'flex', marginTop: '20px' }}>
                            {
                              // new Array.from(new Set(data.getProduct.designs.map((design: TDesign) => design.addOn.publicName || design.addOn.name))].map((addOnName: string, index: number) => (
                              //   <p key={index} style={{ color: colors.text.default }}>
                              //     <b>{addOnName}</b>
                              //   </p>
                              // ))
                              Array.from(
                                new Set(
                                  data.getProduct.designs.map(
                                    (design: TDesign) =>
                                      design.addOn.publicName ||
                                      design.addOn.name
                                  )
                                )
                              ).map((addOnName: any, index: number) => (
                                <div style={{ marginRight: '10px' }}>
                                  <Tag
                                    size="xs"
                                    // button
                                    color={
                                      colors.text.default !== 'slate'
                                        ? 'white'
                                        : 'black'
                                    }
                                    text={addOnName}
                                  />
                                </div>
                              ))
                            }
                          </div>
                        ) : (
                          <></>
                        )}
                        {data.getProduct.description ? (
                          <p
                            onClick={() =>
                              setDescriptionHidden(!descriptionHidden)
                            }
                            style={{
                              textDecoration: 'underline',
                              color: colors.text.default,
                              cursor: 'pointer',
                            }}
                          >
                            {descriptionHidden ? 'Show' : 'Hide'} Description
                          </p>
                        ) : (
                          <div style={{ marginBottom: '-10px' }}></div>
                        )}
                      </Text>
                    </Box>
                  </Flex>
                </Card>
                {data.getProduct.isDesignable ? (
                  <div
                    style={
                      {
                        // opacity: tempDesigns.length ? 1 : 0,
                        // height: tempDesigns.length ? 'auto' : '0px',
                      }
                    }
                  >
                    <Card
                      round
                      bg={colors.card.background}
                      padding="0 20px 20px 20px"
                      margin="10px"
                    >
                      <Flex flexDirection="column" width={'100%'}>
                        {
                          <AddOnPicker
                            selectedDesign={selectedDesign}
                            selectedAddOn={selectedAddOn}
                            selectedAddOnIndex={selectedAddOnIndex}
                            availableAddOns={availableAddOns}
                            handleSetSelectedAddOn={handleSetSelectedAddOn}
                            activeView={activeView}
                            product={data.getProduct}
                            darkMode={props.darkMode}
                            tempDesigns={tempDesigns}
                            tempDesignLength={
                              tempDesigns.length +
                              data.getProduct.designs.length
                            }
                            totalPrintCost={printCost}
                            quantity={totalQuantity}
                            bulkAddOnPrices={printCostPriceBreaks}
                          />
                        }
                      </Flex>
                      <Box width="100%">
                        {/* <Heading element="h4" color={colors.text.default}>
                          Designs
                        </Heading> */}
                        <Flex
                          flexDirection="row"
                          justifyContent="space-between"
                          width="100%"
                          style={
                            tempDesigns.length === 0
                              ? { visibility: 'hidden', height: '0px' }
                              : {}
                          }
                        >
                          <Flex flexDirection="column" width={'100%'}>
                            <LayerPicker
                              setActiveView={setActiveView}
                              tempDesigns={tempDesigns}
                              selectedDesign={selectedDesign}
                              setSelectedDesign={setSelectedDesign}
                              setTempDesigns={setTempDesigns}
                              product={data.getProduct}
                              defaultImageWidth={defaultImageWidth}
                              defaultImageHeight={defaultImageHeight}
                            />
                          </Flex>
                        </Flex>
                      </Box>
                    </Card>
                  </div>
                ) : (
                  <div></div>
                )}
                <Card
                  round
                  bg={colors.card.background}
                  padding="20px"
                  margin="10px"
                >
                  <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap={'wrap'}
                    width={'100%'}
                  >
                    {/* {data.getProduct.inventoryGroup.id && (*/}
                    {/*  <Heading element="h4" color={colors.text.default}>*/}
                    {/*    {data.getProduct.inventoryGroup.name}*/}
                    {/*  </Heading>*/}
                    {/*)} */}
                    {data.getProduct.priceBreaks && (
                      <>
                        <div style={{ marginBottom: '20px' }}>
                          <Heading element="h4" color={colors.text.default}>
                            Bulk Pricing
                          </Heading>
                        </div>

                        <BulkPricingTable
                          basePrice={data.getProduct.basePrice}
                          priceBreaks={data.getProduct.priceBreaks}
                          printCost={basePrintCost}
                          printCostPriceBreaks={printCostPriceBreaks}
                          tempDesignsLength={
                            tempDesigns.length + data.getProduct.designs.length
                          }
                          quantity={totalQuantity}
                        />
                      </>
                    )}
                  </Flex>
                </Card>
                <Card
                  round
                  bg={colors.card.background}
                  padding="20px"
                  margin="10px"
                >
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    // minHeight="480px"
                  >
                    <Box>
                      <Box>
                        <Flex
                          flexDirection="row"
                          justifyContent="space-between"
                          margin="0 0 20px 0"
                        >
                          <Heading element="h4" color={colors.text.default}>
                            Colors
                          </Heading>
                          {productColors.length -
                            data.getProduct.unavailableVariants.filter(
                              (uV: any) => uV.variantGroup.name === 'Color'
                            ).length >
                            1 && (
                            <Flex
                              flexDirection="row"
                              alignItems="center"
                              data-tut="tour_2"
                            >
                              <Box padding="0 10px">
                                <Heading
                                  element="h4"
                                  color={colors.text.default}
                                >
                                  Multiple
                                </Heading>
                              </Box>
                              <Box padding="0 10px">
                                <Switch
                                  color="green"
                                  id="multipleToggle"
                                  checked={isMultiple}
                                  onChange={() => handleSetMultiple()}
                                />
                              </Box>
                            </Flex>
                          )}
                        </Flex>
                        <Box paddingBottom="18px" data-tut="tour_1">
                          {colorIndex !== -1 && (
                            <ColorPicker
                              colors={productColors}
                              setColor={setSelectedColor}
                              selectedColorIndexes={selectedColorIndexes}
                              setSelectedColorIndexes={setSelectedColorIndexes}
                              isMultiple={isMultiple}
                              variantSelections={variantSelections}
                              setVariantSelections={setVariantSelections}
                              initialSizeIdsWithQuantity={
                                initialSizesWithQuantity
                              }
                              setAddToCartError={setAddToCartError}
                              unavailableVariants={
                                data.getProduct.unavailableVariants
                              }
                            />
                          )}
                        </Box>
                      </Box>
                      <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        margin="10px 0"
                      >
                        <Heading element="h4" bold color={colors.text.default}>
                          Sizes
                        </Heading>
                        {/*<Flex*/}
                        {/*  flexDirection="row"*/}
                        {/*  alignItems="center"*/}
                        {/*  data-tut="tour_9"*/}
                        {/*>*/}
                        {/*  <Box padding="0 10px">*/}
                        {/*    <Text color={colors.text.default}>*/}
                        {/*      Sleeve Customization*/}
                        {/*    </Text>*/}
                        {/*  </Box>*/}
                        {/*  <Box padding="0 10px">*/}
                        {/*    <Switch*/}
                        {/*      id="sleezeCustomizationToggle"*/}
                        {/*      checked={isSleeveCustomization}*/}
                        {/*      onChange={() => handleSetIsSleeveCustomization()}*/}
                        {/*    />*/}
                        {/*  </Box>*/}
                        {/*</Flex>*/}
                      </Flex>
                      <Flex flexDirection="column" justifyContent="flex-start">
                        {selectedColorIndexes.length === 0 && (
                          <Text color={colors.text.default}>
                            Please select a color first!
                          </Text>
                        )}
                        {selectedColorIndexes.map(
                          (selectedColorIndex: number, index: number) => (
                            <Box key={index} margin="10px 0">
                              <Flex flexDirection="row" alignItems="center">
                                <Box
                                  width="30px"
                                  height="30px"
                                  style={{
                                    backgroundColor:
                                      productColors[selectedColorIndex].value,
                                  }}
                                />
                                <Box margin="0 20px">
                                  <Text color={colors.text.default}>
                                    {productColors[selectedColorIndex].name}
                                  </Text>
                                </Box>
                              </Flex>
                              <Flex
                                flexDirection="row"
                                justifyContent="flex-start"
                                flexWrap="wrap"
                                margin={'20px 0'}
                              >
                                {sizeIndex !== -1 &&
                                  sizes.map(
                                    (size: TVariant, sizeIndex: number) => (
                                      <>
                                        {!data.getProduct.unavailableVariants
                                          ?.map((uV: { id: any }) => uV.id)
                                          ?.includes(size.id) && (
                                          <SizeInputWrapper
                                            sizeIndex={sizeIndex}
                                            size={size}
                                            index={index}
                                            darkMode={props.darkMode}
                                            variantSelections={
                                              variantSelections
                                            }
                                            productColors={productColors}
                                            selectedColorIndex={
                                              selectedColorIndex
                                            }
                                            handleQuantityChange={
                                              handleQuantityChange
                                            }
                                          />
                                        )}
                                      </>
                                    )
                                  )}
                              </Flex>
                            </Box>
                          )
                        )}
                      </Flex>
                      {isSleeveCustomization && (
                        <>
                          <Flex
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            margin="20px 0"
                          >
                            <Text bold color={colors.text.default}>
                              Sleeve Customization
                            </Text>
                          </Flex>
                          <Flex
                            flexDirection="column"
                            justifyContent="flex-start"
                          >
                            {variantSelections.length === 0 && (
                              <Text color={colors.text.default}>
                                Please select at least one size first!
                              </Text>
                            )}
                            {variantSelections.map(
                              (
                                variantSelection: Record<
                                  string,
                                  Record<
                                    string,
                                    {
                                      quantity: number
                                      customizations: { name: string }[]
                                    }
                                  >
                                >,
                                index: number
                              ) =>
                                Object.entries(variantSelection).map(
                                  (
                                    colorWithSizes: [
                                      string,
                                      Record<
                                        string,
                                        {
                                          quantity: number
                                          customizations: { name: string }[]
                                        }
                                      >
                                    ]
                                  ) =>
                                    Object.entries(colorWithSizes[1]).map(
                                      (
                                        sizeWithQuantity: [
                                          string,
                                          {
                                            quantity: number
                                            customizations: {
                                              name: string
                                            }[]
                                          }
                                        ]
                                      ) =>
                                        !isNaN(sizeWithQuantity[1].quantity) &&
                                        sizeWithQuantity[1].quantity > 0 &&
                                        Array.from(
                                          {
                                            length:
                                              sizeWithQuantity[1].customizations
                                                .length,
                                          },
                                          (_, customizationIndex) => (
                                            <Box
                                              key={customizationIndex}
                                              margin="10px 0"
                                            >
                                              <Flex
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                              >
                                                <Flex alignItems="center">
                                                  <Box
                                                    width="30px"
                                                    height="30px"
                                                    style={{
                                                      backgroundColor:
                                                        colorWithSizes[0].split(
                                                          '/'
                                                        )[2],
                                                    }}
                                                  />
                                                  <Box margin="0 20px">
                                                    <Text
                                                      color={
                                                        colors.text.default
                                                      }
                                                    >
                                                      {
                                                        colorWithSizes[0].split(
                                                          '/'
                                                        )[1]
                                                      }
                                                      ,{' '}
                                                      {
                                                        sizeWithQuantity[0].split(
                                                          '/'
                                                        )[1]
                                                      }
                                                    </Text>
                                                  </Box>
                                                </Flex>
                                                <Flex
                                                  alignItems="center"
                                                  bg="green"
                                                  padding="10px"
                                                  flexGrow={1}
                                                >
                                                  <Text
                                                    color={colors.text.default}
                                                    style={{
                                                      fontSize: '14px',
                                                    }}
                                                  >
                                                    Text
                                                  </Text>
                                                  <Input
                                                    type="text"
                                                    value={
                                                      variantSelections[index][
                                                        colorWithSizes[0]
                                                      ][sizeWithQuantity[0]]
                                                        .customizations[
                                                        customizationIndex
                                                      ].name
                                                    }
                                                    onChange={(
                                                      event: React.ChangeEvent<HTMLInputElement>
                                                    ) =>
                                                      handleCustomizationNameChange(
                                                        event,
                                                        index,
                                                        colorWithSizes[0],
                                                        sizeWithQuantity[0],
                                                        customizationIndex
                                                      )
                                                    }
                                                    width="100%"
                                                    style={{
                                                      margin: '0px',
                                                      padding: '5px 0 5px 15px',
                                                    }}
                                                  ></Input>
                                                </Flex>
                                              </Flex>
                                            </Box>
                                          )
                                        )
                                    )
                                )
                            )}
                          </Flex>
                        </>
                      )}
                    </Box>
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box margin="10px 0">
                        <Text
                          color="red"
                          style={{
                            visibility:
                              addToCartError === '' ? 'hidden' : 'visible',
                          }}
                        >
                          {addToCartError}
                        </Text>
                      </Box>
                      <AddToCartButton
                        product={data.getProduct}
                        setError={setAddToCartError}
                        tempDesigns={tempDesigns}
                        variantSelections={variantSelections}
                        isStore={!data.getProduct.isDesignable}
                        storeId={data.getProduct.store?.id}
                      />
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
            </div>
          </Content>
        </>
      )}
    </>
  )
}

export default withRouter(withTheme(ProductView))
