import React from 'react'
import { gql } from 'apollo-boost'
import * as ADMIN_ROUTES from '../../../routes/admin'
import DataTable from './DataTable'
import DELETE_FILE from '../../../gql/DELETE_FILE'

const GET_SOME_FILES = gql`
  query getSomeFiles($searches: [String!], $offset: Int, $limit: Int) {
    getSomeFiles(searches: $searches, offset: $offset, limit: $limit) {
      id
      filename
      url
      owner {
        id
        name
      }
    }
  }
`

const FilesPage = () => {
  return (
    <DataTable
      getDataQuery={GET_SOME_FILES}
      getDataQueryName="getSomeFiles"
      headers={['Name', 'File', 'Owner', 'Delete']}
      properties={['filename', 'url', 'owner']}
      deleteDataQuery={DELETE_FILE}
      ROUTE={ADMIN_ROUTES.ADMIN_FILES}
      title="Files"
    />
  )
}

export default FilesPage
