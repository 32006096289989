import * as React from 'react'
import styled from 'styled-components'

const Error = (props: { message?: string; showInProd?: boolean } | any) => {
  const StyledError = styled.div`
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    padding: 25px;
    position: absolute;
    font-size: 15px;
    font-weight: bold;
    background: #ff0000;
    color: white;
    z-index: 10;
    display: ${process.env.NODE_ENV === 'development' || props.showInProd
      ? 'block'
      : 'none'};
  `
  return (
    <StyledError>
      {props.message}
      {props.children}
    </StyledError>
  )
}

export default Error
