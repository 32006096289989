import React from 'react'
import ProductForm from './ProductForm'
import * as ADMIN_ROUTES from 'routes/admin'
import GET_SOME_PRODUCTS from './gql/GET_SOME_PRODUCTS'
import DataTable from 'components/Admin/lib/DataTable'
// import DELETE_PRODUCT from './gql/DELETE_PRODUCT'

interface IProductTable {}

const ProductTable: React.FC<IProductTable> = props => {
  const [showInactive, setShowInactive] = React.useState(false)

  return (
    <>
      {/* Toogle switch */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '1rem' }}>Show Inactive</div>
          <label className="switch">
            <input
              type="checkbox"
              onChange={() => setShowInactive(!showInactive)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <DataTable
        getDataQuery={GET_SOME_PRODUCTS}
        getDataQueryName="getSomeProducts"
        // deleteDataQuery={DELETE_PRODUCT}
        headers={['Name', 'Status', 'Inventory', 'Tags']}
        properties={['name', 'available', 'inventoryGroup', 'tags']}
        ROUTE={ADMIN_ROUTES.ADMIN_PRODUCTS}
        title="Products"
        hideInactiveProducts={!showInactive}
      >
        {createFormProps => <ProductForm {...createFormProps} />}
      </DataTable>
    </>
  )
}

export default ProductTable
