import React, { useState, useEffect, FC } from 'react'
import Markdown from './markdown'
import { FormStyle as F, DivLayout as D } from 'styles'

interface IMarkdownTextareaProps {
  markdown: string
  setMarkdown: (markdown: string) => void
}

const MarkdownTextarea: FC<IMarkdownTextareaProps> = ({
  markdown,
  setMarkdown,
}) => {
  return (
    <D.flex>
      <D.iv width="50%">
        <F.Textarea
          value={markdown}
          placeholder={'# Markdown'}
          onChange={e => setMarkdown(e.target.value)}
        />
      </D.iv>
      <D.iv width="50%">
        <Markdown markdown={markdown} />
      </D.iv>
    </D.flex>
  )
}

export default MarkdownTextarea
