import { gql } from 'apollo-boost'

export default gql`
  mutation upsertEmailTemplate(
    $id: ID
    $available: Boolean
    $name: String!
    $html: String!
  ) {
    upsertEmailTemplate(
      id: $id
      available: $available
      name: $name
      html: $html
    ) {
      id
      name
      html
      available
    }
  }
`
